import { yupResolver } from '@hookform/resolvers/yup';
import { Card, CardActions, Container, Divider } from '@mui/material';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import Header from '../../components/header';
import LoadingButton from '../../components/loading-button';
import { PositionFormFields } from '../../components/position';
import { handleHookFormErrors } from '../../helpers';
import { useTitle } from '../../hooks';
import { usePositionValidationSchema } from '../../hooks/validation/position';
import { apiRoutes, request, routes } from '../../lib';
import { ApiError } from '../../model';
import {
  Position,
  PositionFormValues,
  getPositionFormValues,
} from '../../model/position';

const CreatePosition = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  useTitle(t('Neue Funktion'));

  const validationSchema = usePositionValidationSchema();

  const {
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting },
  } = useForm<PositionFormValues>({
    mode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues: getPositionFormValues(),
  });

  const onSubmit = async (values: PositionFormValues) => {
    await request<Position>(apiRoutes.positionCreate, 'post', values)
      .then((res) => {
        enqueueSnackbar(t('Funktion wurde erfolgreich erstellt.'), {
          variant: 'success',
        });
        history.push(routes.position(res.data.id));
      })
      .catch((err: AxiosError<ApiError>) => {
        enqueueSnackbar(err.response?.data.message, { variant: 'error' });
        handleHookFormErrors(err, setError);
      });
  };

  const detailBreadcrumbs = [
    { label: t('Home'), link: routes.dashboard },
    { label: t('Funktionen'), link: routes.positions },
  ];

  return (
    <Container maxWidth="md">
      <Header title={t('Neu')} breadcrumbs={detailBreadcrumbs} />
      <form onSubmit={handleSubmit((values) => onSubmit(values))} noValidate>
        <Card>
          <PositionFormFields control={control} />
          <Divider />
          <CardActions>
            <LoadingButton
              type="submit"
              size="medium"
              color="primary"
              variant="contained"
              loading={isSubmitting}
            >
              {t('Speichern')}
            </LoadingButton>
          </CardActions>
        </Card>
      </form>
    </Container>
  );
};

export default CreatePosition;
