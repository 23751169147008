import { CardContent, Grid, Typography } from '@mui/material';
import React, { VoidFunctionComponent } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { apiRoutes } from '../../lib';
import { Person, PressPassFormValues } from '../../model';
import { StyledCardTitle } from '../globals';
import PaginatedAutocomplete from '../paginated-autocomplete';

export const PersonSelector: VoidFunctionComponent<{
  control: Control<PressPassFormValues>;
}> = ({ control }) => {
  const { t } = useTranslation();

  return (
    <>
      <CardContent>
        <StyledCardTitle variant="h6" color="secondary" gutterBottom>
          {t('Presseausweis für Person erstellen')}
        </StyledCardTitle>
        <Grid
          container
          spacing={3}
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="personSelector"
              render={({ field, fieldState }) => (
                <PaginatedAutocomplete
                  name="person"
                  label={t('Person')}
                  value={field.value}
                  onChange={(ids) => field.onChange(ids?.[0])}
                  optionLabel={(option: Person) =>
                    `${option.displayName} (${option.id})`
                  }
                  dataUrl={apiRoutes.persons}
                  textFieldProps={{
                    error: fieldState.isTouched && fieldState.invalid,
                    helperText: fieldState.error?.message,
                  }}
                  filters={{ withoutPressPass: true }}
                />
              )}
            />
          </Grid>
        </Grid>
        <Typography variant="caption">
          {t(
            'Wenn keine Person ausgewählt wird, wird eine neue Person erstellt.'
          )}
        </Typography>
      </CardContent>
    </>
  );
};
