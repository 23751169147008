import { Chip } from '@mui/material';
import React, { VoidFunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { PressPassStatus } from '../../model';

export const PressPassStatusBadge: VoidFunctionComponent<{
  status: PressPassStatus;
}> = ({ status }) => {
  const { t } = useTranslation();

  switch (status) {
    case PressPassStatus.Created:
      return (
        <Chip
          label={t(`pressPass.status.${status}`)}
          size="small"
          color="info"
        />
      );
    case PressPassStatus.PhotoUpdated:
      return (
        <Chip
          label={t(`pressPass.status.${status}`)}
          size="small"
          color="info"
        />
      );
      case PressPassStatus.ReadyForExport:
      return (
        <Chip
          label={t(`pressPass.status.${status}`)}
          size="small"
          color="info"
        />
      );
  }
};
