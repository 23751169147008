import React from 'react';
import { useApi } from '../../hooks';
import { apiRoutes } from '../../lib';
import { Nullable } from '../../model';
import { Import, isSchemaResponse } from '../../model/import';
import LoadingContainer from '../loading-container';
import FileUpload from './file-upload';
import WorksheetSelector from './worksheet-selector';

export const ImportWizard = () => {
  const { data, isLoading } = useApi<Nullable<Import>>(apiRoutes.importCurrent);

  if (isLoading) {
    return <LoadingContainer />;
  }

  return (
    <>
      <FileUpload schema={data?.schema || []} />
      {data && !isSchemaResponse(data) && (
        <WorksheetSelector importEntity={data} />
      )}
    </>
  );
};
