import { Button } from '@mui/material';
import React, { VoidFunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Company, Salutation } from '../../model';
import { AttachEmail } from "@mui/icons-material";

export const MailToButton: VoidFunctionComponent<{ company: Company, filename: string }> = ({
                                                                                              company,
                                                                                              filename
                                                                                            }) => {
  const { t } = useTranslation();
  const newLine = '%0D%0A';
  const salutation = company.responsiblePerson ? `${company.responsiblePerson.salutation && company.responsiblePerson.salutation == Salutation.Female ? 'Sehr geehrte Frau' : (company.responsiblePerson.salutation == Salutation.Male) ? 'Sehr geehrter Herr' : ''} ${company.responsiblePerson.lastName}` : 'Sehr geehrte Damen und Herren';
  const emailTo = company.responsiblePerson?.companies?.find(c => c.company.id === company.id)?.email || company.responsiblePerson?.email;

  const bodyText = `wir dürfen Sie über folgende Änderungen zur Aktualisierung bzw. Verwaltung der Presseausweise informieren:${newLine}${newLine}Mit der Neuausgabe der Presseausweise für das Jahr 2024 entfällt die jährliche Ausgabe von Jahresmarken, was den administrativen Aufwand für die Verlage deutlich entlasten soll.${newLine}${newLine}Da die Qualität der Presseausweise, die vom Kuratorium für Presseausweise und dessen Trägerverbänden herausgegeben werden, maßgeblich davon bestimmt ist, dass die Inhaber der Presseausweise auch bezugsberechtigt sind, dürfen wir Sie mit folgenden Maßnahmen um Ihre Unterstützung bitten:${newLine}${newLine}- Bitte melden Sie uns umgehend Verluste von Presseausweisen.${newLine}- Bitte melden Sie uns Austritte aus Ihrem Unternehmen, sofern es sich um Presseausweisinhaber handelt.   Wir dürfen Sie auch darum bitten, den Presseausweis von Mitarbeitern, die das Unternehmen verlassen, möglichst zurückzufordern.${newLine}${newLine}Die von Ihnen gemeldeten Presseausweise werden von uns umgehend auf „inaktiv“ gesetzt, sodass bei Abruf des QR-Codes der Vermerk „ungültig“ aufscheint.${newLine}${newLine}`;
  const bodyText2 = `Darüber hinaus werden wir Ihnen zweimal jährlich (Juni/Juli – Dezember/Jänner) eine Übersicht der Presseausweisinhaber Ihres Unternehmens zukommen lassen, mit der Bitte, diese zu prüfen, zu aktualisieren und von der Geschäftsführung des Verlages unterzeichnet zurückzusenden. Um auch hier administrativen Aufwand einzusparen, werden wir aber bei den jährlichen Verlängerungen von der Einholung der Verlängerungsformulare, die vom Antragsteller und vom Verlag gegengezeichnet werden mussten, absehen.${newLine}${newLine}`;
  const bodyText3 = `Die aktuelle Liste Ihres Unternehmens finden Sie hier:${newLine}${newLine}${filename}${newLine}${newLine}${newLine}`;
  const bodyText4 = `Bitte übermitteln Sie uns diese bis spätestens 5. September 2024 per Mail an  presseausweis@voez.at bzw. presseausweis@oezv.or.at.${newLine}${newLine}Herzlichen Dank für Ihre Unterstützung!${newLine}${newLine}Für Fragen stehe ich Ihnen gerne zur Verfügung,${newLine}`;
  const bodyFooter = `mit herzlichen Grüßen${newLine}${newLine}Nadja Vaskovich`;
  return (
    <>
      {company && (
        <Button
          color="primary"
          variant="contained"
          target="_blank"
          href={`mailto:${emailTo ? emailTo : ''}?subject=Information zur Umstellung der Presseausweise - ${encodeURIComponent(company.name)}&body=${salutation},${newLine}${newLine}${bodyText}${bodyText2}${bodyText3}${bodyText4}${bodyFooter}`}
          startIcon={<AttachEmail/>}
        >
          {t('Senden')}
        </Button>
      )}
    </>
  );
};
