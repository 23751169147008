import { Add } from '@mui/icons-material';
import { Button, CardContent, Typography } from '@mui/material';
import React, { VoidFunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import HttpError from '../../components/http-error';
import { LicenseeBaseData } from '../../components/licensee';
import LoadingContainer from '../../components/loading-container';
import { routes, useLicenseeDetailsApi } from '../../lib';
import { Company } from '../../model';

const CompanyLicensee: VoidFunctionComponent<{ company: Company }> = ({
  company,
}) => {
  const { t } = useTranslation();
  const { data, isLoading, error } = useLicenseeDetailsApi(company.id);

  if (error) {
    return (
      <HttpError
        error={error}
        actions={
          <Button component={RouterLink} to={routes.company(company.id)}>
            {t('Zurück zum Unternehmen')}
          </Button>
        }
      />
    );
  }

  if (isLoading || !data) {
    return <LoadingContainer />;
  }

  if (!data.license) {
    return (
      <CardContent>
        <Typography>
          {t('Es wurden noch keine Lizenznehmer Daten hinterlegt')}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          component={RouterLink}
          to={routes.licenseeEdit(data.id)}
        >
          <Add /> {t('Lizenznehmer')}
        </Button>
      </CardContent>
    );
  }

  return <LicenseeBaseData licensee={data} />;
};

export default CompanyLicensee;
