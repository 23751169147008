import { Chip } from '@mui/material';
import React, { VoidFunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { PressPassApplicationStatus } from '../../model';

export const PressPassApplicationStatusBadge: VoidFunctionComponent<{
  status: PressPassApplicationStatus;
}> = ({ status }) => {
  const { t } = useTranslation();

  switch (status) {
    case PressPassApplicationStatus.New:
      return (
        <Chip
          label={t(`pressPassApplication.status.${status}`)}
          size="small"
          color="error"
        />
      );
    case PressPassApplicationStatus.UpdatedPhoto:
      return (
        <Chip
          label={t(`pressPassApplication.status.${status}`)}
          size="small"
          color="info"
        />
      );
    case PressPassApplicationStatus.UpdatedPhotoDone:
      return (
        <Chip
          label={t(`pressPassApplication.status.${status}`)}
          size="small"
          color="success"
        />
      );
    case PressPassApplicationStatus.Done:
      return (
        <Chip
          label={t(`pressPassApplication.status.${status}`)}
          size="small"
          color="success"
        />
      );
    case PressPassApplicationStatus.Denied:
      return (
        <Chip label={t(`pressPassApplication.status.${status}`)} size="small" />
      );
  }

  return null;
};
