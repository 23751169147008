import { Edit, Visibility } from '@mui/icons-material';
import { Link, TableCell, TableRow } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { routes } from '../../lib';
import { DateFormat, Medium, Role } from '../../model';
import FormattedDate from '../formatted-date';
import { CompactTableCell } from '../globals';
import Guarded from '../guarded';
import { IconLink } from '../icon-link';
import { PaginationTable } from '../pagination';
import PaginationTableHeadCell from '../pagination/table-head-cell';

export const MediumList: FunctionComponent<{ media?: Medium[] }> = ({
  media,
}) => {
  const { t } = useTranslation();

  return (
    <PaginationTable
      items={media}
      renderRow={(medium) => (
        <TableRow key={medium.id} hover={true}>
          <CompactTableCell>
            <Link to={routes.medium(medium.id)} component={RouterLink}>
              {medium.referenceId}
            </Link>
          </CompactTableCell>
          <CompactTableCell>
            <Link to={routes.medium(medium.id)} component={RouterLink}>
              {medium.name}
            </Link>
          </CompactTableCell>
          <TableCell>
            {medium.company && (
              <Link
                component={RouterLink}
                to={routes.company(medium.company.id)}
              >
                {medium.company.name} {medium.company.term || ''}
              </Link>
            )}
          </TableCell>
          <TableCell>{medium.phone}</TableCell>
          <TableCell>{medium.website}</TableCell>
          <Guarded requiredRole={Role.PDN}>
            <TableCell>
              {medium.lastBillingDate ? (
                <FormattedDate
                  date={medium.lastBillingDate}
                  format={DateFormat.Default}
                />
              ) : (
                '-'
              )}
            </TableCell>
          </Guarded>
          <TableCell>
            {medium.responsiblePerson && (
              <Link
                component={RouterLink}
                to={routes.person(medium.responsiblePerson.id)}
              >
                {medium.responsiblePerson?.displayName}
              </Link>
            )}
          </TableCell>
          <TableCell>
            {medium.licenseResponsiblePerson && (
              <Link
                component={RouterLink}
                to={routes.person(medium.licenseResponsiblePerson.id)}
              >
                {medium.licenseResponsiblePerson?.displayName}
              </Link>
            )}
          </TableCell>
          <CompactTableCell align={'right'}>
            <Guarded requiredRole={Role.General}>
              <IconLink
                to={routes.medium(medium.id)}
                tooltip={t('Ansehen')}
                icon={<Visibility />}
              />
            </Guarded>
            <Guarded requiredRole={Role.General}>
              <IconLink
                to={routes.mediumEdit(medium.id)}
                tooltip={t('Bearbeiten')}
                icon={<Edit />}
              />
            </Guarded>
          </CompactTableCell>
        </TableRow>
      )}
    >
      <TableRow>
        <PaginationTableHeadCell>
          {t('Titel-ID')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell sort="title">
          {t('Titel')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell sort="company">
          {t('Unternehmen')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell>{t('Telefon')}</PaginationTableHeadCell>
        <PaginationTableHeadCell>{t('Webseite')}</PaginationTableHeadCell>
        <Guarded requiredRole={Role.PDN}>
          <PaginationTableHeadCell>{t('Letzte Abr')}</PaginationTableHeadCell>
        </Guarded>
        <PaginationTableHeadCell>
          {t('PA-Verantwortliche/r')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell>
          {t('PDN-Verantwortliche/r')}
        </PaginationTableHeadCell>
        <TableCell width={110} />
      </TableRow>
    </PaginationTable>
  );
};
