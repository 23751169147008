import { Add, Cancel, Star, StarOutline } from '@mui/icons-material';
import {
  Autocomplete,
  Card,
  CardContent,
  Checkbox,
  Fab,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { yellow } from '@mui/material/colors';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import React, { VoidFunctionComponent, useEffect, useState } from 'react';
import { Control, Controller, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { asyncForEach } from '../../helpers';
import { useUnlimitedPaginationApi } from '../../hooks';
import { apiRoutes, request } from '../../lib';
import {
  Company,
  CompanyPerson,
  Dict,
  ID,
  Person,
  PersonFormValues,
} from '../../model';
import { Position } from '../../model/position';
import Error from '../error';
import { StyledCardTitle, StyledColoredCardContent } from '../globals';
import PaginatedAutocomplete from '../paginated-autocomplete';

export const PersonCompanyFormFields: VoidFunctionComponent<{
  person: Person;
  control: Control<PersonFormValues>;
}> = ({ person, control }) => {
  const { t } = useTranslation();
  const [assistantOptions, setAssistantOptions] = useState<
    Dict<CompanyPerson[]>
  >({});
  const { enqueueSnackbar } = useSnackbar();

  const { fields, remove, append } = useFieldArray({
    control,
    name: 'companies',
  });

  useEffect(() => {
    asyncForEach(fields, async (field, index) => {
      await assistantOptionsMutation.mutateAsync({
        index,
        companyId: +field.company,
      });
    });
  }, [fields]);

  const {
    data: positions,
    isLoading: positionsLoading,
    error: positionsError,
  } = useUnlimitedPaginationApi<Record<string, never>, Position>(
    apiRoutes.positions
  );

  const assistantOptionsMutation = useMutation(
    async ({ index, companyId }: { index: number; companyId?: ID }) => {
      // Reset options if company is unselected
      if (!companyId) {
        const options = assistantOptions;
        options[index] = [];
        setAssistantOptions(options);
        return;
      }
      const response = await request<CompanyPerson[]>(
        apiRoutes.personAssistantOptions(person.id, companyId),
        'get'
      );
      return { response, index };
    },
    {
      onSuccess: (res) => {
        if (!res) {
          return;
        }
        const options = assistantOptions;
        options[res.index] = res.response.data;
        setAssistantOptions(options);
      },
      onError: (err: AxiosError) => {
        enqueueSnackbar(
          err.response?.data.message || t('Leider ist etwas schief gelaufen.'),
          {
            variant: 'error',
          }
        );
      },
    }
  );

  const removeCompanyPerson = (index: number) => {
    if (
      !window.confirm(
        t(
          `Verknüpfung wird beim Speichern der Person gelöscht. Verknüpfte Verteiler werden ebenfalls gelöscht. Wollen Sie die Verknüpfung wirklich löschen?`
        )
      )
    ) {
      return;
    }
    remove(index);
  };

  if (positionsError) {
    return (
      <CardContent>
        <Error title={'Error'} />
      </CardContent>
    );
  }

  return (
    <CardContent>
      <StyledCardTitle variant="h6" color="secondary" gutterBottom>
        {t('Verknüpfungen')}
      </StyledCardTitle>
      {fields.map((field, index) => (
        <Card key={field.id} style={{ marginBottom: '1em' }}>
          <StyledColoredCardContent>
            <Grid
              container
              spacing={1}
              justifyContent="space-between"
              alignContent="center"
              alignItems="center"
            >
              <Grid item xs={12} md={10}>
                <Grid container spacing={1}>
                  <Grid item style={{ display: 'none' }}>
                    <Controller
                      control={control}
                      name={`companies.${index}.companyPersonId` as const}
                      defaultValue={field.companyPersonId as any}
                      render={({ field }) => (
                        <TextField type="hidden" value={field.value} />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      name={`companies.${index}.company` as const}
                      defaultValue={field.company as any}
                      render={({ field, fieldState }) => (
                        <PaginatedAutocomplete
                          name={`companies.${index}`}
                          label={t('Unternehmen')}
                          value={field.value}
                          onChange={async (ids) => {
                            if (!ids || ids.length === 0) {
                              return;
                            }
                            field.onChange(ids[0]);
                            await assistantOptionsMutation.mutateAsync({
                              index,
                              companyId: ids[0],
                            });
                          }}
                          optionLabel={(option: Company) =>
                            `${option.name} ${option.term || ''} (${option.id})`
                          }
                          dataUrl={apiRoutes.companies}
                          textFieldProps={{
                            required: true,
                            error: fieldState.isTouched && fieldState.invalid,
                            helperText: fieldState.error?.message,
                          }}
                          initialData={person.companies.map((pc) => pc.company)}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      name={`companies.${index}.defaultCompany` as const}
                      render={({ field }) => (
                        <FormControlLabel
                          defaultChecked={field.value}
                          control={
                            <Checkbox
                              checked={field.value}
                              onChange={field.onChange}
                              color="primary"
                              style={{ color: yellow[800] }}
                              checkedIcon={<Star />}
                              icon={<StarOutline />}
                            />
                          }
                          label={
                            field.value
                              ? t<string>('Primär Unternehmen')
                              : t<string>('als Primärunternehmen wählen')
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      name={`companies.${index}.position` as const}
                      defaultValue={field.position as any}
                      render={({ field, fieldState }) => (
                        <Autocomplete
                          options={positions}
                          loading={positionsLoading}
                          loadingText={t('Lädt...')}
                          noOptionsText={t('Keine Funktion verfügbar')}
                          getOptionLabel={(option) => option.title}
                          value={
                            positions.find(
                              (position) => position.id === field.value
                            ) || null
                          }
                          onChange={(event, value) => field.onChange(value?.id)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={t('Funktion')}
                              error={fieldState.isTouched && fieldState.invalid}
                              helperText={fieldState.error?.message}
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      name={`companies.${index}.descriptivePosition` as const}
                      defaultValue={field.descriptivePosition as any}
                      render={({ field, fieldState }) => (
                        <TextField
                          label={t('Funktion')}
                          fullWidth
                          {...field}
                          error={fieldState.isTouched && fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      name={`companies.${index}.email` as const}
                      defaultValue={field.email as any}
                      render={({ field, fieldState }) => (
                        <TextField
                          label={t('E-Mail Adresse')}
                          fullWidth
                          autoComplete="off"
                          {...field}
                          error={fieldState.isTouched && fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      name={`companies.${index}.phone` as const}
                      defaultValue={field.phone as any}
                      render={({ field, fieldState }) => (
                        <TextField
                          label={t('Telefonnummer')}
                          fullWidth
                          {...field}
                          error={fieldState.isTouched && fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      name={`companies.${index}.mobilePhone` as const}
                      defaultValue={field.mobilePhone as any}
                      render={({ field, fieldState }) => (
                        <TextField
                          label={t('Mobil Telefon')}
                          fullWidth
                          {...field}
                          error={fieldState.isTouched && fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      name={`companies.${index}.assistant` as const}
                      defaultValue={field.assistant as any}
                      render={({ field, fieldState }) => (
                        <Autocomplete
                          options={
                            assistantOptions && assistantOptions[index]
                              ? assistantOptions[index]
                              : []
                          }
                          loading={
                            assistantOptionsMutation.isLoading ||
                            !assistantOptions ||
                            (assistantOptions && !assistantOptions[index])
                          }
                          loadingText={t('Lädt...')}
                          noOptionsText={t('Keine Person verfügbar')}
                          getOptionLabel={(option) => option.person.displayName}
                          value={
                            assistantOptions && assistantOptions[index]
                              ? assistantOptions[index].find(
                                  (companyPerson) =>
                                    companyPerson.id === field.value
                                ) || null
                              : null
                          }
                          onChange={(event, value) => field.onChange(value?.id)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={t('Assistenz')}
                              error={fieldState.isTouched && fieldState.invalid}
                              helperText={fieldState.error?.message}
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      name={`companies.${index}.invoiceContactId` as const}
                      defaultValue={field.email as any}
                      render={({ field, fieldState }) => (
                        <TextField
                          label={t('BMD-Kontaktnummer')}
                          fullWidth
                          autoComplete="off"
                          {...field}
                          error={fieldState.isTouched && fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={2} textAlign="right">
                <Tooltip title={t<string>('Verknüpfung löschen')}>
                  <IconButton
                    type="button"
                    onClick={() => removeCompanyPerson(index)}
                  >
                    <Cancel />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </StyledColoredCardContent>
        </Card>
      ))}
      <Grid container spacing={5} justifyContent="space-around">
        <Grid item>
          <Tooltip title={t<string>('Weitere Verknüpfung')}>
            <Fab
              type="button"
              color="primary"
              size="small"
              onClick={() =>
                append({
                  company: '',
                  person: person.id,
                  position: null,
                  assistant: null,
                  descriptivePosition: '',
                  email: '',
                  phone: '',
                  defaultCompany: false,
                  companyPersonId: '',
                })
              }
            >
              <Add />
            </Fab>
          </Tooltip>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="caption">
            {t(
              'Die Verknüpfungen müssen zuerst gespeichert werden, um sie Verteilern zuweisen zu können.'
            )}
          </Typography>
        </Grid>
      </Grid>
    </CardContent>
  );
};
