import { yupResolver } from '@hookform/resolvers/yup';
import { Card, CardActions, Container, Divider } from '@mui/material';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router';
import Header from '../../components/header';
import LoadingButton from '../../components/loading-button';
import { TariffFormFields } from '../../components/tariff';
import { formatPayloadDate, handleHookFormErrors } from '../../helpers';
import { useTitle } from '../../hooks';
import { useTariffValidationSchema } from '../../hooks/validation/tariff';
import { apiRoutes, request, routes } from '../../lib';
import { PayRate, TariffFormValues, getTariffFormValues } from '../../model';

const CreateTariff = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  useTitle(t('Neue Tarifliste'));

  const validationSchema = useTariffValidationSchema();

  const {
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting },
  } = useForm<TariffFormValues>({
    mode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues: getTariffFormValues(),
  });

  const submitMutation = useMutation(
    async (values: TariffFormValues) => {
      values.validFrom = formatPayloadDate(values.validFrom);
      values.validTo = formatPayloadDate(values.validTo);
      return await request<PayRate>(apiRoutes.tariffCreate, 'post', values);
    },
    {
      onSuccess: (res) => {
        enqueueSnackbar(t('Tarifliste wurde erfolgreich erstellt.'), {
          variant: 'success',
        });
        history.push(routes.tariff(res.data.id));
      },
      onError: (err: AxiosError) => {
        enqueueSnackbar(
          err.response?.data.message || t('Leider ist etwas schief gelaufen.'),
          { variant: 'error' }
        );
        handleHookFormErrors(err, setError);
      },
    }
  );

  const detailBreadcrumbs = [
    { label: t('Home'), link: routes.dashboard },
    { label: t('Tariflisten'), link: routes.tariffs },
  ];

  return (
    <Container maxWidth="md">
      <Header title={t('Neu')} breadcrumbs={detailBreadcrumbs} />
      <form
        onSubmit={handleSubmit((values) => submitMutation.mutateAsync(values))}
        noValidate
      >
        <Card>
          <TariffFormFields control={control} />
          <Divider />
          <CardActions>
            <LoadingButton
              type="submit"
              size="medium"
              color="primary"
              variant="contained"
              loading={isSubmitting}
            >
              {t('Speichern')}
            </LoadingButton>
          </CardActions>
        </Card>
      </form>
    </Container>
  );
};

export default CreateTariff;
