import { Delete, Edit, FileDownload } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Card,
  CardContent,
  Container,
  FormControlLabel,
  Grid,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { StyledCardTitle, StyledCheckbox } from '../../components/globals';
import { GridData } from '../../components/grid-data';
import Guarded from '../../components/guarded';
import Header from '../../components/header';
import HttpError from '../../components/http-error';
import LoadingContainer from '../../components/loading-container';
import { MailingListRecipientList } from '../../components/mailing-list';
import { ModifiedEntity } from '../../components/modified-entity';
import { useTitle } from '../../hooks';
import {
  apiRoutes,
  request,
  routes,
  useMailingListDetailsApi,
} from '../../lib';
import { Role } from '../../model';

const MailingListDetails = () => {
  const { t } = useTranslation();
  const { mailingListId } = useParams<{ mailingListId: string | undefined }>();
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, error, data } = useMailingListDetailsApi(
    mailingListId as unknown as number
  );
  const history = useHistory();
  useTitle(data?.name || t('Verteiler Details'));
  const [deleteLoading, setDeleteLoading] = useState(false);

  if (error) {
    return (
      <HttpError
        error={error}
        actions={
          <Button component={RouterLink} to={routes.mailingLists}>
            {t('Zurück zu Verteiler')}
          </Button>
        }
      />
    );
  }

  if (isLoading || !data) {
    return <LoadingContainer />;
  }

  const detailBreadcrumbs = [
    { label: t('Home'), link: routes.dashboard },
    { label: t('Verteiler'), link: routes.mailingLists },
  ];

  const handleDelete = async () => {
    const confirm = window.confirm(
      t(`Wollen Sie den Verteiler {{name}} wirklich löschen?`, {
        name: data.name,
      })
    );
    if (!confirm) {
      return;
    }
    setDeleteLoading(true);
    await request(apiRoutes.mailingList(data.id), 'delete')
      .then(() => {
        enqueueSnackbar(t('Verteiler wurde erfolgreich gelöscht.'), {
          variant: 'success',
        });
        history.push(routes.mailingLists);
      })
      .catch(() =>
        enqueueSnackbar(t('Verteiler konnte nicht gelöscht werden.'), {
          variant: 'error',
        })
      )
      .finally(() => setDeleteLoading(false));
  };

  return (
    <Container>
      <Header
        title={data.name}
        breadcrumbs={detailBreadcrumbs}
        search={{ route: routes.mailingLists, param: 'search' }}
        actions={
          <>
            <Guarded requiredRole={Role.General}>
              <Button
                variant="outlined"
                color="primary"
                href={`${
                  process.env.REACT_APP_API_URL
                }/${routes.mailingListExport(data.id)}`}
                startIcon={<FileDownload />}
              >
                {t('Empfänger Export')}
              </Button>{' '}
              <Button
                variant="contained"
                color="primary"
                component={RouterLink}
                to={routes.mailingListEdit(data.id)}
                startIcon={<Edit />}
              >
                {t('Bearbeiten')}
              </Button>{' '}
              <LoadingButton
                variant="contained"
                color="secondary"
                onClick={handleDelete}
                startIcon={<Delete />}
                loading={deleteLoading}
              >
                {t('Löschen')}
              </LoadingButton>
            </Guarded>
            <ModifiedEntity entity={data} />
          </>
        }
      />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <StyledCardTitle variant="h6" color="secondary" gutterBottom>
                {t('Stammdaten')}
              </StyledCardTitle>
              <Grid container spacing={3}>
                <GridData xs={12} md={5} label={t<string>('Name')}>
                  {data.name}
                </GridData>
                <GridData
                  xs={12}
                  md={5}
                  label={t<string>('Interner Kommentar')}
                >
                  {data.comment}
                </GridData>
                <Grid item xs={12} md={2}>
                  <FormControlLabel
                    control={
                      <StyledCheckbox
                        readOnly={true}
                        name="enabled"
                        color="primary"
                        checked={data.enabled}
                        disableRipple={true}
                      />
                    }
                    label={
                      data.enabled ? t<string>('Aktiv') : t<string>('Inaktiv')
                    }
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <MailingListRecipientList mailingList={data} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default MailingListDetails;
