import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { request } from '../lib';
import { Dict, Entity } from '../model';
import { FilterParams } from './use-filters';

export interface PaginationResponse<TEntity extends Entity> {
  total: number;
  filtered: number;
  results: TEntity[];
}

export const usePaginationApi = <
  TFilters extends Dict,
  TEntity extends Entity,
  TContext extends Dict = Dict
>(
  url: string,
  params: FilterParams<TFilters, TContext>,
  headers?: Dict
) => {
  const fetchData = async (params: FilterParams<TFilters, TContext>) => {
    const { data } = await request<PaginationResponse<TEntity>>(
      url,
      'get',
      undefined,
      {
        filters: params.filters,
        page: params.page,
        pageSize: params.pageSize,
        order: [[params.orderBy, params.order]],
        context: params.context,
      },
      headers
    );
    return data;
  };

  return useQuery<PaginationResponse<TEntity>, AxiosError>(
    [
      url,
      params.page,
      params.pageSize,
      params.orderBy,
      params.order,
      params.filters,
      params.context,
    ],
    () => fetchData(params),
    {
      keepPreviousData: true,
    }
  );
};
