import { yupResolver } from '@hookform/resolvers/yup';
import { LockOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Alert, Avatar, Container, Grid, TextField } from '@mui/material';
import React, { FunctionComponent, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { useLoginValidationSchema, useTitle } from '../../hooks';
import { apiRoutes, request } from '../../lib';
import { HttpStatus } from '../../model';

const StyledAlert = styled(Alert)`
  margin-bottom: 8px;
`;

const StyledAvatar = styled(Avatar)`
  margin: 3em auto 1em;
`;

interface FormValues {
  email: string;
  password: string;
}

const Login: FunctionComponent = () => {
  useTitle('Login');

  // Update in e2e test as well (search for "loginThrottle")
  const loginThrottle = 1000;
  const [showError, setShowError] = useState(false);
  const [submittable, setSubmittable] = useState(true);
  const validation = useLoginValidationSchema();

  const {
    control,
    handleSubmit,
    formState: { isValid, isSubmitting },
  } = useForm<FormValues>({
    mode: 'all',
    resolver: yupResolver(validation),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const onSubmit = async (values: FormValues) => {
    if (!submittable) {
      return;
    }
    setShowError(false);
    setSubmittable(false);
    await request(apiRoutes.login, 'post', {
      username: values.email,
      password: values.password,
    })
      .then((res) => {
        if (res.status !== HttpStatus.Ok) {
          return setShowError(true);
        }
        window.location.reload();
      })
      .catch(() => setTimeout(() => setShowError(true), loginThrottle))
      .finally(() => setTimeout(() => setSubmittable(true), loginThrottle));
  };

  return (
    <Container maxWidth={`xs`}>
      <StyledAvatar>
        <LockOutlined />
      </StyledAvatar>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container>
          <Grid item xs={12} style={{ minHeight: '5.5em' }}>
            <Controller
              control={control}
              name="email"
              render={({ field, fieldState }) => (
                <TextField
                  id="email"
                  label="E-Mail"
                  fullWidth
                  {...field}
                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} style={{ minHeight: '5.5em' }}>
            <Controller
              control={control}
              name="password"
              render={({ field, fieldState }) => (
                <TextField
                  id="password"
                  type="password"
                  label="Password"
                  fullWidth
                  {...field}
                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            {showError && (
              <StyledAlert severity={`error`}>Login failed.</StyledAlert>
            )}
          </Grid>
          <Grid item xs={12}>
            <LoadingButton
              type="submit"
              size="medium"
              fullWidth
              variant="contained"
              color="primary"
              disabled={!isValid || !submittable}
              loading={isSubmitting || !submittable}
            >
              Sign In
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default Login;
