import { VoidFunctionComponent } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { routes } from '../../lib';
import LogsDetails from './details';
import LogsOverview from './overview';

const Logs: VoidFunctionComponent = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <LogsOverview />
      </Route>
      <Route path={routes.log(':logId')}>
        <LogsDetails />
      </Route>
    </Switch>
  );
};

export default Logs;
