export type FilterWidth = 'small' | 'medium' | 'large' | number;

export function getWidth(width: FilterWidth) {
  switch (width) {
    case 'small':
      return 120;
    case 'medium':
      return 200;
    case 'large':
      return 310;
  }
  return width;
}

export * from './date-picker';
export * from './input-search';
export * from './select';
export * from './switch';
