import { yupResolver } from '@hookform/resolvers/yup';
import { ChevronRight } from '@mui/icons-material';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import React, { VoidFunctionComponent } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import FormLogo from '../../components/form-logo';
import { StyledCardTitle } from '../../components/globals';
import LoadingButton from '../../components/loading-button';
import MultiUpload from '../../components/multi-upload';
import {
  buildFormData,
  formatPayloadDate,
  handleHookFormErrors,
} from '../../helpers';
import { useTitle } from '../../hooks';
import { useClient } from '../../hooks/use-client';
import { usePressPassApplicationValidationSchema } from '../../hooks/validation/press-pass-application';
import { apiRoutes, request } from '../../lib';
import {
  Client,
  Origin,
  PressPassApplication,
  PressPassApplicationFormValues,
  Salutation,
  getPressPassApplicationFormValues,
} from '../../model';
import SuccessCard from './success-card';

export const FormPageFullForm: VoidFunctionComponent = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const client = useClient();

  useTitle(t('Antrag erstellen'), '');

  const validationSchema = usePressPassApplicationValidationSchema();

  const {
    control,
    handleSubmit,
    setError,
    formState: { isValid, isSubmitting },
  } = useForm<PressPassApplicationFormValues>({
    mode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues: getPressPassApplicationFormValues(
      client === Client.Oezv ? Origin.Oezv : Origin.Voez
    ),
  });

  const submitMutation = useMutation(
    async (values: PressPassApplicationFormValues) => {
      values.birthdate = formatPayloadDate(values.birthdate) || '';
      return await request<PressPassApplication>(
        apiRoutes.pressPassApplicationCreate,
        'post',
        buildFormData<PressPassApplicationFormValues>(values, (formData) => {
          values.attachments.forEach((attachment, index) => {
            if (!attachment.file) {
              return;
            }
            formData.append(`attachment_${index}`, attachment.file);
          });
        })
      );
    },
    {
      onSuccess: () => {
        enqueueSnackbar(t('Antrag wurde erfolgreich abgeschickt.'), {
          variant: 'success',
        });
      },
      onError: (err: AxiosError) => {
        enqueueSnackbar(
          err.response?.data.message ||
          t('Antrag konnte nicht erstellt werden'),
          {
            variant: 'error',
          }
        );
        handleHookFormErrors(err, setError);
      },
    }
  );

  return (
    <Container maxWidth="md">
      <Box>
        <FormLogo/>
        <Typography
          variant="h3"
          component="h1"
          textAlign="center"
          marginBottom="1em"
        >
          {t('Presseausweis Antrag')}
        </Typography>
      </Box>
      {submitMutation.isSuccess ? (
        <SuccessCard
          text={t<string>(
            'Ihr Antrag für einen Presseausweis wurde erfolgreich abgeschickt und die Daten übermittelt. Wir werden zeitnah Ihren Antrag bearbeiten.'
          )}
        />
      ) : (
        <form
          onSubmit={handleSubmit((values) =>
            submitMutation.mutateAsync(values)
          )}
        >
          <Card style={{ marginBottom: '4em' }}>
            <CardContent>
              <StyledCardTitle variant="h6" color="secondary" gutterBottom>
                {t('Kontaktdaten')}
              </StyledCardTitle>
              <Grid container spacing={3}>
                <Grid item style={{ display: 'none' }}>
                  <Controller
                    control={control}
                    name="membership"
                    render={({ field }) => (
                      <TextField type="hidden" value={field.value}/>
                    )}
                  />
                </Grid>
                <Grid item md={12}>
                  <FormControl variant="standard">
                    <FormLabel>{t('Anrede')}</FormLabel>
                    <Controller
                      control={control}
                      name="salutation"
                      render={({ field }) => (
                        <RadioGroup
                          value={field.value}
                          onChange={field.onChange}
                          sx={{ flexDirection: 'row' }}
                        >
                          <FormControlLabel
                            value={Salutation.Female}
                            control={<Radio/>}
                            label={t<string>(
                              `person.salutation.${Salutation.Female}`
                            )}
                          />
                          <FormControlLabel
                            value={Salutation.Male}
                            control={<Radio/>}
                            label={t<string>(
                              `person.salutation.${Salutation.Male}`
                            )}
                          />
                          <FormControlLabel
                            value={Salutation.Other}
                            control={<Radio/>}
                            label={t<string>(
                              `person.salutation.${Salutation.Other}`
                            )}
                          />
                        </RadioGroup>
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    control={control}
                    name={'firstName'}
                    render={({ field, fieldState }) => (
                      <TextField
                        label={t('Vorname')}
                        fullWidth
                        required
                        {...field}
                        error={fieldState.isTouched && fieldState.invalid}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    control={control}
                    name={'lastName'}
                    render={({ field, fieldState }) => (
                      <TextField
                        label={t('Nachname')}
                        fullWidth
                        required
                        {...field}
                        error={fieldState.isTouched && fieldState.invalid}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    control={control}
                    name={'phone'}
                    render={({ field, fieldState }) => (
                      <TextField
                        label={t('Telefonnummer')}
                        fullWidth
                        required
                        {...field}
                        error={fieldState.isTouched && fieldState.invalid}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    control={control}
                    name={'email'}
                    render={({ field, fieldState }) => (
                      <TextField
                        label={t('E-Mail Adresse')}
                        fullWidth
                        required
                        {...field}
                        error={fieldState.isTouched && fieldState.invalid}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider/>
            <CardContent>
              <StyledCardTitle variant="h6" color="secondary" gutterBottom>
                {t('Medium/Verlag')}
              </StyledCardTitle>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Controller
                    control={control}
                    name={'medium'}
                    render={({ field, fieldState }) => (
                      <TextField
                        fullWidth
                        label={t('Medium/Verlag')}
                        {...field}
                        required
                        error={fieldState.isTouched && fieldState.invalid}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    control={control}
                    name={'mediumAddress'}
                    render={({ field, fieldState }) => (
                      <TextField
                        fullWidth
                        label={t('Adresse Medium/Verlag')}
                        {...field}
                        error={fieldState.isTouched && fieldState.invalid}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider/>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Typography variant="body1">
                    {t(
                      'Für einen Neuantrag auf Ausstellung eines Presseausweises durch den Verband Österreichischer Zeitungen benötigen wir folgende Dokumente:'
                    )}
                  </Typography>
                  <List dense={true}>
                    <ListItem>
                      <ListItemIcon>
                        <ChevronRight/>
                      </ListItemIcon>
                      <ListItemText
                        primary={t(
                          'Antragsformular Bestätigung des Arbeitgebers'
                        )}
                        secondary={t(
                          'mit Bestätigung des Erreichens der Verdienstgrenze'
                        )}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <ChevronRight/>
                      </ListItemIcon>
                      <ListItemText
                        primary={t(
                          'Staatsbürgerschaftsnachweis oder Kopie des Reisepasses'
                        )}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <ChevronRight/>
                      </ListItemIcon>
                      <ListItemText
                        primary={t('Strafregisterauszug')}
                        secondary={t('nicht älter als 3 Monate')}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <ChevronRight/>
                      </ListItemIcon>
                      <ListItemText
                        primary={t('Foto')}
                        secondary={t('Portrait- oder Passfoto')}
                      />
                    </ListItem>
                  </List>
                  <StyledCardTitle variant="h6" color="secondary" gutterBottom>
                    <b>{t('Verlängerungen')}</b>
                  </StyledCardTitle>
                  <Typography variant="body1">
                    {t('Bei Antrag auf Verlängerung eines Presseausweises, übermitteln Sie uns bitte die unterzeichneten Verlängerungsformulare.'
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <MultiUpload control={control}/>
                </Grid>
              </Grid>
            </CardContent>
            <Divider/>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="confirmationPrivacy"
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={field.value}
                            onChange={field.onChange}
                            color="primary"
                          />
                        }
                        label={t<string>(
                          'Sollten Sie die Daten für sich selbst eingeben, ' +
                          'erteilen Sie hiermit die Einwillung zur Verarbeitung der Daten im Sinne der DSGVO zu. ' +
                          'Sollten Sie für eine andere Person Daten eingegeben haben, ' +
                          'versichern Sie im Auftrag der Person zu agieren und die entsprechende Einwilligung eingeholt zu haben.'
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="confirmationMoral"
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={field.value}
                            onChange={field.onChange}
                            color="primary"
                          />
                        }
                        label={t<string>(
                          'Ich bestätige alle Angaben nach bestem Wissen und Gewissen getroffen zu haben.'
                        )}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <LoadingButton
                type="submit"
                size="medium"
                color="primary"
                disabled={!isValid}
                variant="contained"
                loading={isSubmitting}
              >
                {t('Antrag jetzt abschicken')}
              </LoadingButton>
            </CardActions>
          </Card>
        </form>
      )}
    </Container>
  );
};

export default FormPageFullForm;
