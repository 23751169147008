import { Delete, Edit, Visibility } from '@mui/icons-material';
import { LoadingButton, TabContext } from '@mui/lab';
import { Button, Card, Divider, Paper, Tab, Tabs } from '@mui/material';
import { AxiosError } from 'axios';
import { StyledContainer, StyledTabPanel } from 'components/globals';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router';
import { Link as RouterLink, useParams, useRouteMatch } from 'react-router-dom';
import { CompanyBaseData } from '../../components/company';
import Guarded from '../../components/guarded';
import Header from '../../components/header';
import HttpError from '../../components/http-error';
import {
  LicenseeBaseData,
  LicenseeCredits,
  LicenseeJournal,
} from '../../components/licensee';
import LoadingContainer from '../../components/loading-container';
import { ModifiedEntity } from '../../components/modified-entity';
import { useTitle } from '../../hooks';
import { apiRoutes, request, routes, useLicenseeDetailsApi } from '../../lib';
import { Role, getCompanyDisplayName } from '../../model';

const LicenseeDetails = () => {
  const { t } = useTranslation();
  const { licenseeId } = useParams<{ licenseeId: string }>();
  const { isLoading, error, data } = useLicenseeDetailsApi(+licenseeId);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  useTitle(data?.name || t('Lizenznehmer Details'));

  const base = routes.licensee(licenseeId);
  const tabRoutes = {
    company: `${base}/company`,
    credits: `${base}/credits`,
    journal: `${base}/journal`,
    details: `${base}/`,
  };
  const routeMatch = useRouteMatch(Object.values(tabRoutes));
  const currentTab = routeMatch?.path || tabRoutes.details;

  const mutation = useMutation(
    async () => {
      if (!data) {
        return;
      }

      return await request(apiRoutes.licensee(data.id), 'delete');
    },
    {
      onSuccess: () => {
        enqueueSnackbar(t('Lizenznehmer wurde erfolgreich gelöscht.'), {
          variant: 'success',
        });
        history.push(routes.licensees);
      },
      onError: (err: AxiosError) => {
        enqueueSnackbar(
          err.response?.data.message ||
            t('Lizenznehmer konnte nicht gelöscht werden.'),
          {
            variant: 'error',
          }
        );
      },
    }
  );

  if (error) {
    return (
      <HttpError
        error={error}
        actions={
          <Button component={RouterLink} to={routes.companies}>
            {t('Zurück zu Unternehmen')}
          </Button>
        }
      />
    );
  }

  if (isLoading || !data) {
    return <LoadingContainer />;
  }

  const detailBreadcrumbs = [
    { label: t('Home'), link: routes.dashboard },
    { label: t('Lizenznehmer'), link: routes.licensees },
  ];

  return (
    <StyledContainer>
      <Header
        title={getCompanyDisplayName(data)}
        breadcrumbs={detailBreadcrumbs}
        search={{ route: routes.licensees, param: 'search' }}
        actions={
          <>
            <Guarded requiredRole={Role.PDN}>
              <Button
                variant="outlined"
                color="primary"
                component={RouterLink}
                to={routes.company(data.id)}
              >
                <Visibility /> {t('Unternehmen')}
              </Button>{' '}
              <Button
                variant="contained"
                color="primary"
                component={RouterLink}
                to={routes.licenseeEdit(data.id)}
              >
                <Edit /> {t('Bearbeiten')}
              </Button>{' '}
              <LoadingButton
                variant="contained"
                color="secondary"
                loading={mutation.isLoading}
                onClick={() => {
                  if (
                    !window.confirm(
                      t(
                        `Wollen Sie den Lizenznehmer {{name}} wirklich löschen?`,
                        {
                          name: data.name,
                        }
                      )
                    )
                  ) {
                    return;
                  }

                  mutation.mutate();
                }}
              >
                <Delete /> {t('Löschen')}
              </LoadingButton>
            </Guarded>
            <ModifiedEntity entity={data} />
          </>
        }
      />
      <Card>
        <Paper square={true}>
          <TabContext value={currentTab}>
            <Tabs
              value={currentTab}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab
                label={t('Unternehmen')}
                component={RouterLink}
                to={tabRoutes.company}
                value={tabRoutes.company}
              />
              <Tab
                label={t('PDN')}
                component={RouterLink}
                to={tabRoutes.details}
                value={tabRoutes.details}
              />
              <Tab
                label={t('Guthaben')}
                component={RouterLink}
                to={tabRoutes.credits}
                value={tabRoutes.credits}
              />
              <Tab
                label={t('Nutzungsdaten')}
                component={RouterLink}
                to={tabRoutes.journal}
                value={tabRoutes.journal}
              />
            </Tabs>
            <Divider />
            <StyledTabPanel value={tabRoutes.details}>
              <LicenseeBaseData licensee={data} />
            </StyledTabPanel>
            <StyledTabPanel value={tabRoutes.company}>
              <CompanyBaseData company={data} />
            </StyledTabPanel>
            <StyledTabPanel value={tabRoutes.credits}>
              <LicenseeCredits licensee={data} />
            </StyledTabPanel>
            <StyledTabPanel value={tabRoutes.journal}>
              <LicenseeJournal licensee={data} />
            </StyledTabPanel>
          </TabContext>
        </Paper>
      </Card>
    </StyledContainer>
  );
};

export default LicenseeDetails;
