import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { routes } from '../../lib';
import CreateImport from './create';
import ImportDetails from './details';
import ImportOverview from './overview';

const Import = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <ImportOverview />
      </Route>
      <Route path={routes.importCreate}>
        <CreateImport />
      </Route>
      <Route path={routes.import(':importId')}>
        <ImportDetails />
      </Route>
    </Switch>
  );
};

export default Import;
