import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Card, CardActions, Container, Divider } from '@mui/material';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router';
import { Link as RouterLink, useParams } from 'react-router-dom';
import Header from '../../components/header';
import HttpError from '../../components/http-error';
import LoadingButton from '../../components/loading-button';
import LoadingContainer from '../../components/loading-container';
import { PayRateFormFields } from '../../components/pay-rate';
import { handleHookFormErrors } from '../../helpers';
import { useTitle } from '../../hooks';
import { usePayRateValidationSchema } from '../../hooks/validation/pay-rate';
import { apiRoutes, request, routes, usePayRateDetailsApi } from '../../lib';
import { PayRate, PayRateFormValues, getPayRateFormValues } from '../../model';

const EditPayRate = () => {
  const { t } = useTranslation();
  const { payRateId } = useParams<{ payRateId: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const { isLoading, error, data } = usePayRateDetailsApi(+payRateId);

  useTitle(data?.name || t('Lizenzpaket bearbeiten'));

  const validationSchema = usePayRateValidationSchema();

  const {
    control,
    handleSubmit,
    setError,
    formState: { isValid, isSubmitting },
    reset,
  } = useForm<PayRateFormValues>({
    mode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues: getPayRateFormValues(data),
  });

  useEffect(() => reset(getPayRateFormValues(data)), [reset, data]);

  const submitMutation = useMutation(
    async (values: PayRateFormValues) => {
      if (!data) {
        return;
      }
      return await request<PayRate>(apiRoutes.payRate(data.id), 'put', values);
    },
    {
      onSuccess: (res) => {
        if (!res) {
          return;
        }
        enqueueSnackbar(t('Lizenzpaket wurde erfolgreich bearbeitet.'), {
          variant: 'success',
        });
        history.push(routes.payRate(res.data.id));
      },
      onError: (err: AxiosError) => {
        enqueueSnackbar(
          err.response?.data.message || t('Leider ist etwas schief gelaufen.'),
          { variant: 'error' }
        );
        handleHookFormErrors(err, setError);
      },
    }
  );

  if (error) {
    return (
      <HttpError
        error={error}
        actions={
          <Button component={RouterLink} to={routes.payRates}>
            {t('Zurück zu Lizenzpaketen')}
          </Button>
        }
      />
    );
  }

  if (isLoading || !data) {
    return <LoadingContainer />;
  }

  const detailBreadcrumbs = [
    { label: t('Home'), link: routes.dashboard },
    { label: t('Lizenzpaket'), link: routes.payRates },
    { label: data.name, link: routes.payRate(data.id) },
    { label: t('Bearbeiten') },
  ];

  return (
    <Container maxWidth="md">
      <Header title={data.name} breadcrumbs={detailBreadcrumbs} />
      <form
        onSubmit={handleSubmit((values) => submitMutation.mutateAsync(values))}
      >
        <Card>
          <PayRateFormFields control={control} />
          <Divider />
          <CardActions>
            <LoadingButton
              type="submit"
              size="medium"
              color="primary"
              disabled={!isValid}
              variant="contained"
              loading={isSubmitting}
            >
              {t('Speichern')}
            </LoadingButton>
          </CardActions>
        </Card>
      </form>
    </Container>
  );
};

export default EditPayRate;
