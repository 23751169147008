import { Avatar } from '@mui/material';
import { VoidFunctionComponent } from 'react';
import styled from 'styled-components';

const StyledAvatar = styled(Avatar)`
  height: 180px;
  width: 150px;
  margin-bottom: 0.5em;
`;

// New date to src for invalidating cache
const PressPassPhoto: VoidFunctionComponent<{
  src: string;
}> = ({ src }) => (
  <StyledAvatar variant="square" src={`${src}?${+new Date()}`} />
);

export default PressPassPhoto;
