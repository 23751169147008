import {
  Addressable,
  Blameable,
  Commentable,
  Company,
  DateTimeString,
  ID,
  LegacyEntity,
  Medium,
  Membership,
  Nullable,
  PressPassPerson,
  PressPassPersonFormValues,
  Timestampable,
  getPressPassPersonFormValues,
} from './index';
import { Position } from './position';

export enum EmploymentStatus {
  Selfemployed = 'selfemployed',
  Employed = 'employed',
  Freelance = 'freelance',
}

export enum PressPassStatus {
  Created = 'created',
  PhotoUpdated = 'photo_updated',
  ReadyForExport = 'ready_for_export',
}

export enum BillTo {
  Company = 'company',
  Person = 'person',
  Unknown = 'unknown',
}

export interface Badge extends Timestampable, Blameable {
  id: ID;
  pressPass: PressPass;
  year: string;
  carBadge: boolean;
}

export interface PressPass
  extends Blameable,
    Commentable,
    Timestampable,
    Addressable,
    LegacyEntity {
  id: ID;
  person: PressPassPerson;
  employmentStatus?: EmploymentStatus;
  status: PressPassStatus;
  medium?: Medium;
  position?: Position;
  carBadgeDate?: DateTimeString;
  badgeDate?: DateTimeString;
  photoUpdatedAt?: DateTimeString;
  pass: boolean;
  passDate?: DateTimeString;
  passPrintDate?: DateTimeString;
  passNumber?: string;
  passWithdrawal: boolean;
  passWithdrawalDate?: DateTimeString;
  billTo?: BillTo;
  enabled: boolean;
  photo: Nullable<string>;
  photoUpload: Nullable<File>;
  membership: Nullable<Membership>;
  phone: Nullable<string>;
  mobilePhone: Nullable<string>;
  email: Nullable<string>;
  company?: Company;
  currentBadge: Nullable<Badge>;
  checkUrl: Nullable<string>;
  validHash: Nullable<boolean>;
  pressPassSuspended: PressPassSuspended[];
}

export interface PressPassSuspended extends Timestampable, Blameable {
  id: ID;
  passNumber: string;
  date: DateTimeString;
  comment: string;
}

export interface PressPassExtensionResponse {
  status: number,
  data: string;
  companyId?: ID;
}

export interface PressPassFilters {
  search: string;
  medium: string;
  noMark: string;
  membership: string;
  company: string;
  enabled: string;
  status: string;
  billTo: string;
}

export interface PressPassFormValues {
  person: PressPassPersonFormValues;
  employmentStatus: Nullable<EmploymentStatus>;
  status: Nullable<PressPassStatus>;
  company: Nullable<ID>;
  medium: Nullable<ID>;
  position: Nullable<ID>;
  carBadgeDate: Nullable<DateTimeString>;
  photoUpdatedAt: Nullable<DateTimeString>;
  badgeDate: Nullable<DateTimeString>;
  passDate: Nullable<DateTimeString>;
  passPrintDate: Nullable<DateTimeString>;
  passNumber: Nullable<string>;
  passWithdrawal: boolean;
  passWithdrawalDate: Nullable<DateTimeString>;
  billTo: Nullable<BillTo>;
  enabled: boolean;
  street: Nullable<string>;
  zipCode: Nullable<string>;
  city: Nullable<string>;
  country: Nullable<string>;
  email: Nullable<string>;
  phone: Nullable<string>;
  mobilePhone: Nullable<string>;
  comment: Nullable<string>;
  photoUpload: Nullable<File>;
  deletePhoto: boolean;
  personSelector: Nullable<ID>;
  membership: string;
  badge: {
    year: string;
    carBadge: boolean;
  };
}

export const getPressPassFormValues = (
  pressPass?: PressPass,
  nextPressPassNumber?: string
): PressPassFormValues => ({
  person: getPressPassPersonFormValues(pressPass?.person),
  employmentStatus: pressPass?.employmentStatus || EmploymentStatus.Employed,
  status: pressPass?.status || PressPassStatus.Created,
  medium: pressPass?.medium?.id || null,
  company: pressPass?.company?.id || null,
  position: pressPass?.position?.id || null,
  carBadgeDate: pressPass?.carBadgeDate || '',
  photoUpdatedAt: pressPass?.photoUpdatedAt || '',
  badgeDate: pressPass?.badgeDate || '',
  passDate: pressPass?.passDate || '',
  passPrintDate: pressPass?.passPrintDate || '',
  passNumber: pressPass?.passNumber || nextPressPassNumber || '',
  passWithdrawal: pressPass?.passWithdrawal || false,
  passWithdrawalDate: pressPass?.passWithdrawalDate || '',
  billTo: pressPass?.billTo || BillTo.Company,
  enabled: pressPass?.enabled || false,
  street: pressPass?.street || '',
  zipCode: pressPass?.zipCode || '',
  city: pressPass?.city || '',
  country: pressPass?.country || '',
  email: pressPass?.email || '',
  phone: pressPass?.phone || '',
  mobilePhone: pressPass?.mobilePhone || '',
  comment: pressPass?.comment || '',
  photoUpload: null,
  deletePhoto: false,
  personSelector: pressPass?.person.id || null,
  membership: pressPass?.membership || Membership.Voez,
  badge: pressPass?.currentBadge
    ? {
      year: pressPass?.currentBadge.year,
      carBadge: pressPass?.currentBadge.carBadge,
    }
    : {
      year: '',
      carBadge: false,
    },
});
