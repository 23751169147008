import { Visibility } from '@mui/icons-material';
import { Link, TableCell, TableRow } from '@mui/material';
import React, { VoidFunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { routes } from '../../lib';
import { DateFormat, PressPassApplicationWithPressPass } from '../../model';
import FormattedDate from '../formatted-date';
import { CompactTableCell } from '../globals';
import { IconLink } from '../icon-link';
import { MembershipChip } from '../membership-chip';
import { PaginationTable } from '../pagination';
import PaginationTableHeadCell from '../pagination/table-head-cell';
import { PressPassApplicationStatusBadge } from './status';

export const PressPassApplicationUpdateList: VoidFunctionComponent<{
  pressPassApplications?: PressPassApplicationWithPressPass[];
}> = ({ pressPassApplications }) => {
  const { t } = useTranslation();

  return (
    <PaginationTable
      items={pressPassApplications}
      renderRow={(pressPassApplication) => (
        <TableRow key={pressPassApplication.id} hover={true}>
          <CompactTableCell>
            <Link to={routes.pressPass(pressPassApplication.pressPass.id)} component={RouterLink}>
              {pressPassApplication.passNumber}
            </Link>
          </CompactTableCell>
          <CompactTableCell>
            <Link to={routes.pressPassUpdateApplication(pressPassApplication.id)} component={RouterLink}>
              {pressPassApplication.pressPass.person.firstName} {pressPassApplication.pressPass.person.lastName}
            </Link>
          </CompactTableCell>
          <CompactTableCell>
            <>
            {pressPassApplication.pressPass.medium &&
            <Link to={routes.medium(pressPassApplication.pressPass.medium.id)} component={RouterLink}>
              {pressPassApplication.pressPass.medium.name}
            </Link>}
            </>
          </CompactTableCell>
          <TableCell>
            <Link to={routes.pressPassUpdateApplication(pressPassApplication.id)} component={RouterLink}>
              {pressPassApplication.email}
            </Link>
          </TableCell>
          <TableCell>
            <FormattedDate
              date={pressPassApplication.createdAt}
              format={DateFormat.DateTime}
            />
          </TableCell>
          <TableCell>
            <FormattedDate
              date={pressPassApplication.updatedAt}
              format={DateFormat.DateTime}
            />
          </TableCell>
          <TableCell align="center">
            <MembershipChip membership={pressPassApplication.membership} />
          </TableCell>
          <TableCell align="center">
            <PressPassApplicationStatusBadge
              status={pressPassApplication.status}
            />
          </TableCell>
          <CompactTableCell align={'right'}>
            <IconLink
              to={routes.pressPassUpdateApplication(pressPassApplication.id)}
              tooltip={t('Ansehen')}
              icon={<Visibility />}
            />
          </CompactTableCell>
        </TableRow>
      )}
    >
      <TableRow>
        <PaginationTableHeadCell sort="passNumber">
          {t('Ausweisnummer')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell>
          {t('Name')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell>
          {t('Medium')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell sort="lastName">
          {t('E-Mail (Kontakt)')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell sort="createdAt">
          {t('Eingangsdatum')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell sort="updatedAt">
          {t('Bearbeitungsdatum')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell sort="membership" align="center">
          {t('Mitglied')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell sort="status" align="center">
          {t('Status')}
        </PaginationTableHeadCell>
        <TableCell width={110} />
      </TableRow>
    </PaginationTable>
  );
};
