import { CardContent, Divider, Grid, TextField } from '@mui/material';
import React, { VoidFunctionComponent } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PositionFormValues } from '../../model/position';
import { StyledCardTitle } from '../globals';

export const PositionFormFields: VoidFunctionComponent<{
  control: Control<PositionFormValues>;
}> = ({ control }) => {
  const { t } = useTranslation();

  return (
    <>
      <CardContent>
        <StyledCardTitle variant="h6" color="secondary" gutterBottom>
          {t('Informationen')}
        </StyledCardTitle>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name={'title'}
              render={({ field, fieldState }) => (
                <TextField
                  label={t('Titel')}
                  fullWidth
                  required
                  {...field}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Controller
              control={control}
              name={'comment'}
              render={({ field, fieldState }) => (
                <TextField
                  label={t('Interner Kommentar')}
                  fullWidth
                  multiline={true}
                  {...field}
                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
        </Grid>
      </CardContent>
    </>
  );
};
