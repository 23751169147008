import { Clear } from '@mui/icons-material';
import {
  CardContent,
  Checkbox,
  Grid,
  Link,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';
import { config } from '../../config';
import {
  UseQueryStateOptions,
  useDebounceState,
  useFilteredPaginationApi,
  useQueryState,
} from '../../hooks';
import { apiRoutes, request, routes } from '../../lib';
import { ID, MailingList, MailingListPerson } from '../../model';
import {
  CompactTableCell,
  FilterFormControl,
  StyledCardTitle,
} from '../globals';
import LoadingIconButton from '../loading-icon-button';
import { PaginationCard, PaginationTable } from '../pagination';
import PaginationTableHeadCell from '../pagination/table-head-cell';
import { MailingListRecipientForm } from './recipient-form';

export const MailingListRecipientList: FunctionComponent<{
  mailingList: MailingList;
  edit?: boolean;
}> = ({ mailingList, edit = false }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const queryStateOptions: UseQueryStateOptions = { action: 'replace' };
  const [deletingMailingListPerson, setDeletingMailingListPerson] = useState<
    ID[]
  >([]);
  const [search, setFilterSearch] = useQueryState(
    '',
    'search',
    queryStateOptions
  );
  const [inputSearch, setInputSearch] = useDebounceState(
    search,
    setFilterSearch
  );

  const { context } = useFilteredPaginationApi<
    { search: string; mailingList: ID },
    MailingListPerson
  >(
    apiRoutes.mailingListPersons(mailingList.id),
    { search, mailingList: mailingList.id },
    'id',
    'desc',
    config.pageSize
  );

  const deleteMailingListPersonMutation = useMutation(
    async (mailingListPerson: MailingListPerson) => {
      setDeletingMailingListPerson([
        ...deletingMailingListPerson,
        mailingListPerson.id,
      ]);
      return {
        id: mailingListPerson.id,
        response: await request<MailingListPerson>(
          apiRoutes.mailingListPerson(mailingListPerson.id),
          'delete'
        ),
      };
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(
          apiRoutes.mailingListPersons(mailingList.id)
        );
        enqueueSnackbar(t('Person wurde erfolgreich aus Verteiler entfernt.'), {
          variant: 'success',
        });
      },
      onError: () => {
        enqueueSnackbar(t('Person konnte nicht entfernt werden.'), {
          variant: 'error',
        });
      },
      onSettled: (res) => {
        if (!res) {
          return;
        }
        setDeletingMailingListPerson([
          ...deletingMailingListPerson.filter(
            (mailingListPerson) => mailingListPerson !== res.id
          ),
        ]);
      },
    }
  );

  return (
    <PaginationCard
      context={context}
      hasFilters={!!search}
      resetFilters={() => {
        setFilterSearch('');
      }}
      header={
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <StyledCardTitle>
                {t('Empfänger')} {edit && <>{t(' verwalten')}</>}
              </StyledCardTitle>
            </Grid>
            {edit && (
              <Grid item xs={12} md={8}>
                <MailingListRecipientForm mailingList={mailingList} />
              </Grid>
            )}
          </Grid>
        </CardContent>
      }
      filters={
        <>
          <FilterFormControl>
            <TextField
              label={t('Suche')}
              variant="outlined"
              placeholder={t('zB: Name')}
              value={inputSearch}
              onChange={(e) => setInputSearch(e.target.value)}
              size="small"
            />
          </FilterFormControl>
        </>
      }
    >
      <PaginationTable
        renderRow={(mailingListPerson: MailingListPerson) => (
          <TableRow key={mailingListPerson.id} hover={true}>
            <CompactTableCell>
              <Link
                component={RouterLink}
                to={routes.person(mailingListPerson.person.id)}
              >
                {mailingListPerson.person.displayName}
              </Link>
            </CompactTableCell>
            <TableCell>
              {mailingListPerson.companyPerson ? (
                <Link
                  component={RouterLink}
                  to={routes.company(
                    mailingListPerson.companyPerson.company.id
                  )}
                >
                  {mailingListPerson.companyPerson.company.name}
                </Link>
              ) : (
                '-'
              )}
            </TableCell>
            <TableCell>
              {mailingListPerson.companyPerson
                ? mailingListPerson.companyPerson.email
                : mailingListPerson.person.email}
            </TableCell>
            <TableCell>
              <Checkbox
                checked={mailingListPerson.person.enabled}
                readOnly={true}
                name="enabled"
                color="primary"
                disableRipple={true}
                style={{ cursor: 'auto' }}
              />
            </TableCell>
            <CompactTableCell align={'right'}>
              {edit && (
                <Tooltip title={t<string>('Verknüpfung löschen')}>
                  <LoadingIconButton
                    loading={
                      deleteMailingListPersonMutation.isLoading &&
                      !!deletingMailingListPerson.find(
                        (person) => person === mailingListPerson.id
                      )
                    }
                    onClick={() => {
                      const confirm = window.confirm(
                        t(
                          `Wollen Sie die Person {{name}} von dem Verteiler wirklich entfernen?`,
                          {
                            name: mailingListPerson.person.displayName,
                          }
                        )
                      );
                      if (!confirm) {
                        return;
                      }

                      deleteMailingListPersonMutation.mutate(mailingListPerson);
                    }}
                  >
                    <Clear />
                  </LoadingIconButton>
                </Tooltip>
              )}
            </CompactTableCell>
          </TableRow>
        )}
      >
        <TableRow>
          <PaginationTableHeadCell sort="name">
            {t('Name')}
          </PaginationTableHeadCell>
          <PaginationTableHeadCell sort="company">
            {t('Unternehmen')}
          </PaginationTableHeadCell>
          <PaginationTableHeadCell>
            {t('E-Mail Adresse')}
          </PaginationTableHeadCell>
          <PaginationTableHeadCell>{t('Aktiv')}</PaginationTableHeadCell>
          <TableCell width={110} />
        </TableRow>
      </PaginationTable>
    </PaginationCard>
  );
};
