import { useMemo } from 'react';
import * as yup from 'yup';
import { useUserValidation } from '../user';

export const useProfileValidationSchema = () => {
  const userValidation = useUserValidation();

  return useMemo(() => {
    return yup.object({
      username: userValidation.username,
      fullName: userValidation.fullName,
      email: userValidation.email,
      currentPassword: userValidation.currentPassword,
      password: userValidation.password,
      passwordRepeat: userValidation.passwordRepeat(),
    });
  }, [userValidation]);
};

export const useCredentialValidationSchema = () => {
  const userValidation = useUserValidation();

  return useMemo(() => {
    return yup.object({
      email: userValidation.email,
      fullName: userValidation.fullName,
    });
  }, [userValidation]);
};

export const usePasswordValidationSchema = () => {
  const userValidation = useUserValidation();

  return useMemo(() => {
    return yup.object({
      currentPassword: userValidation.currentPassword,
      plainPassword: userValidation.password,
      passwordRepeat: userValidation.passwordRepeat('plainPassword'),
    });
  }, [userValidation]);
};
