import { TableCell, TableSortLabel } from '@mui/material';
import React, { PropsWithChildren } from 'react';
import { usePaginationContext } from './context';

const PaginationTableHeadCell = ({
  sort,
  width,
  align,
  padding,
  children,
}: PropsWithChildren<{
  sort?: string;
  width?: number | string;
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
  padding?: 'normal' | 'checkbox' | 'none';
}>) => {
  const { params, sort: onSort } = usePaginationContext();
  return (
    <TableCell
      width={width}
      align={align}
      padding={padding || 'normal'}
      sortDirection={params && params.orderBy === sort ? params.order : false}
    >
      {sort && onSort ? (
        <TableSortLabel
          active={(params && params.orderBy) === sort}
          direction={params && params.orderBy === sort ? params.order : 'asc'}
          onClick={onSort(sort)}
        >
          {children}
        </TableSortLabel>
      ) : (
        <>{children}</>
      )}
    </TableCell>
  );
};

export default PaginationTableHeadCell;
