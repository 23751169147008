import { Add } from '@mui/icons-material';
import { Button } from '@mui/material';
import React, { VoidFunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FilterInputSearch } from '../../components/filter';
import {
  StyledContainer,
  StyledFilterFormControl,
} from '../../components/globals';
import Header from '../../components/header';
import { MailingList } from '../../components/mailing';
import PaginatedAutocomplete from '../../components/paginated-autocomplete';
import { PaginationCard } from '../../components/pagination';
import { config } from '../../config';
import {
  UseQueryStateOptions,
  useDebounceState,
  useFilteredPaginationApi,
  useQueryState,
  useTitle,
} from '../../hooks';
import { apiRoutes, routes } from '../../lib';
import {
  Mailing,
  MailingFilters,
  MailingListFilters,
  MailingList as MailingListType,
} from '../../model';

const MailingOverview: VoidFunctionComponent = () => {
  const { t } = useTranslation();
  useTitle(t('Mailing'));

  const breadcrumbs = [
    { label: t('Home'), link: routes.dashboard },
    { label: t('Mailing') },
  ];

  const queryStateOptions: UseQueryStateOptions = { action: 'replace' };
  const [search, setFilterSearch] = useQueryState(
    '',
    'search',
    queryStateOptions
  );
  const [inputSearch, setInputSearch] = useDebounceState(
    search,
    setFilterSearch
  );

  const [mailingList, setFilterMailingList] = useQueryState(
    '',
    'mailingList',
    queryStateOptions
  );
  const [inputMailingList, setInputMailingList] = useDebounceState(
    mailingList,
    setFilterMailingList
  );

  const { context } = useFilteredPaginationApi<MailingFilters, Mailing>(
    apiRoutes.mailings,
    { search, mailingList },
    'delivery',
    'desc',
    config.pageSize
  );

  const {
    data: mailingLists,
    isFetching: mailingListsIsFetching,
    isLoading: mailingListsIsLoading,
  } = useFilteredPaginationApi<MailingListFilters, MailingListType>(
    apiRoutes.mailingLists,
    { id: +inputMailingList },
    'name',
    'desc',
    1
  );

  return (
    <StyledContainer data-test="mailing-content">
      <Header
        title={t('Mailing')}
        breadcrumbs={breadcrumbs}
        actions={
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to={routes.mailingCreate}
            startIcon={<Add />}
          >
            {t('Neues Mailing')}
          </Button>
        }
      />
      <PaginationCard
        context={context}
        hasFilters={!!(search || mailingList)}
        resetFilters={() => {
          setFilterSearch('');
          setFilterMailingList('');
        }}
        filters={
          <>
            <FilterInputSearch
              value={inputSearch}
              label={t('Suche')}
              onChange={setInputSearch}
            />
            <StyledFilterFormControl>
              <PaginatedAutocomplete
                name="mailingLists"
                label={t('Verteiler')}
                value={mailingList}
                onChange={(ids) =>
                  ids.length === 0
                    ? setInputMailingList('')
                    : setInputMailingList(ids[0].toString())
                }
                initialData={mailingLists?.results}
                multiple={false}
                loading={mailingListsIsLoading || mailingListsIsFetching}
                optionLabel={(mailingList) => mailingList.name}
                dataUrl={apiRoutes.mailingLists}
                textFieldProps={{
                  variant: 'outlined',
                  size: 'small',
                }}
              />
            </StyledFilterFormControl>
          </>
        }
      >
        <MailingList />
      </PaginationCard>
    </StyledContainer>
  );
};

export default MailingOverview;
