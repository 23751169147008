import {
  Addressable,
  Blameable,
  Commentable,
  CompanyPerson,
  CompanyPersonFormValues,
  DateTimeString,
  ID,
  LegacyEntity,
  MailingListPersonFormValues,
  Nullable,
  Timestampable,
  User,
} from './';
import { MailingListPerson } from './mailing-list';

export interface Person
  extends Timestampable,
    Blameable,
    Commentable,
    Addressable,
    LegacyEntity {
  id: ID;
  displayName: string;
  salutation: Salutation;
  firstName: string;
  lastName: string;
  titleBefore: Nullable<string>;
  titleAfter: Nullable<string>;
  salutationPosition: Nullable<string>;
  email: Nullable<string>;
  phone: Nullable<string>;
  mobilePhone: Nullable<string>;
  user: Nullable<User>;
  dateOfApproval: Nullable<DateTimeString>;
  dateOfEnd: Nullable<DateTimeString>;
  typeOfContact: Nullable<string>;
  enabled: boolean;
  companies: CompanyPerson[];
  mailingLists: MailingListPerson[];
  pressPassId: Nullable<ID>;
}

export interface PressPassPerson extends Person {
  nationality: Nullable<string>;
  birthdate: Nullable<DateTimeString>;
  birthplace: Nullable<string>;
}

export interface PersonFormValues
  extends Omit<
    Person,
    | 'id'
    | 'displayName'
    | 'user'
    | 'companies'
    | 'mailingLists'
    | 'state'
    | 'pressPassId'
    | keyof Timestampable
    | keyof Blameable
    | keyof LegacyEntity
  > {
  companies: CompanyPersonFormValues[];
  mailingLists: MailingListPersonFormValues[];
}

export interface PressPassPersonFormValues {
  id: Nullable<ID>;
  salutation: Salutation;
  firstName: string;
  lastName: string;
  titleBefore: Nullable<string>;
  titleAfter: Nullable<string>;
  salutationPosition: Nullable<string>;
  nationality: Nullable<string>;
  birthdate: Nullable<DateTimeString>;
  birthplace: Nullable<string>;
}

export interface PersonEmailOptions {
  email: string;
  companyPerson: Nullable<ID>;
  name: string;
  default: boolean;
}

export interface PersonFilters {
  search: string;
  company: string;
  status: string;
}

export enum Salutation {
  Male = 'male',
  Female = 'female',
  Other = 'other',
}

export const getPersonFormValues = (person?: Person): PersonFormValues => ({
  salutation: person?.salutation || Salutation.Female,
  firstName: person?.firstName || '',
  lastName: person?.lastName || '',
  titleBefore: person?.titleBefore || '',
  titleAfter: person?.titleAfter || '',
  salutationPosition: person?.salutationPosition || '',
  email: person?.email || '',
  phone: person?.phone || '',
  mobilePhone: person?.mobilePhone || '',
  street: person?.street || '',
  zipCode: person?.zipCode || '',
  city: person?.city || '',
  country: person?.country || '',
  enabled: person ? person.enabled : true,
  comment: person?.comment || '',
  dateOfApproval: person?.dateOfApproval || null,
  dateOfEnd: person?.dateOfEnd || null,
  typeOfContact: person?.typeOfContact || 'business',
  companies:
    person?.companies?.map((companyPerson) => ({
      company: companyPerson.company.id || '',
      person: person?.id || '',
      position: companyPerson.position?.id || null,
      assistant: companyPerson.assistant?.id || null,
      descriptivePosition: companyPerson.descriptivePosition || '',
      email: companyPerson.email || '',
      phone: companyPerson.phone || '',
      defaultCompany: companyPerson.defaultCompany || false,
      companyPersonId: companyPerson.id || '',
      invoiceContactId: companyPerson.invoiceContactId || '',
      mobilePhone: companyPerson?.mobilePhone || '',
    })) || [],
  mailingLists:
    person?.mailingLists?.map((mailingList) => ({
      companyPerson: mailingList.companyPerson?.id || null,
      person: person?.id || '',
      mailingList: mailingList.mailingList.id || '',
      mailingListPersonId: mailingList.id || '',
    })) || [],
});

export const getPressPassPersonFormValues = (
  person?: PressPassPerson
): PressPassPersonFormValues => {
  return {
    id: person?.id || null,
    salutation: person?.salutation || Salutation.Female,
    firstName: person?.firstName || '',
    lastName: person?.lastName || '',
    titleBefore: person?.titleBefore || '',
    titleAfter: person?.titleAfter || '',
    salutationPosition: person?.salutationPosition || '',
    birthdate: person?.birthdate || '',
    birthplace: person?.birthplace || '',
    nationality: person?.nationality || '',
  };
};
