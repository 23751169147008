import { Add } from '@mui/icons-material';
import { Button, CardContent, Grid } from '@mui/material';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { config } from '../../config';
import { useFilteredPaginationApi } from '../../hooks';
import { apiRoutes } from '../../lib';
import { Credit, ID, Licensee } from '../../model';
import { StyledCardTitle } from '../globals';
import { PaginationProvider, PaginationTablePagination } from '../pagination';
import LicenseeCreditDialog from './credit-dialog';
import { CreditList } from './credit-list';

export const LicenseeCredits: FunctionComponent<{
  licensee: Licensee;
}> = ({ licensee }) => {
  const { t } = useTranslation();
  const [licenseeDialogOpen, setLicenseeDialogOpen] = useState(false);

  const { context } = useFilteredPaginationApi<{ licenseeId: ID }, Credit>(
    apiRoutes.licenseeCredits(licensee.id),
    { licenseeId: licensee.id },
    'createdAt',
    'desc',
    config.pageSize
  );

  return (
    <>
      <CardContent>
        <Grid container justifyContent="space-between">
          <Grid item md={6}>
            <StyledCardTitle variant="h6" color="secondary" gutterBottom>
              {t('Guthaben Historie')}
            </StyledCardTitle>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setLicenseeDialogOpen(true)}
              startIcon={<Add />}
            >
              {t('Guthaben')}
            </Button>
          </Grid>
        </Grid>
      </CardContent>
      <PaginationProvider context={context}>
        <CreditList licensee={licensee} />
        <PaginationTablePagination />
      </PaginationProvider>
      <LicenseeCreditDialog
        open={licenseeDialogOpen}
        onClose={() => setLicenseeDialogOpen(false)}
        licensee={licensee}
      />
    </>
  );
};
