import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

export const usePressPassApplicationValidation = () => {
  const { t } = useTranslation();
  return useMemo(
    () => ({
      salutation: yup.string(),
      firstName: yup.string().required(t('Vorname darf nicht leer sein')),
      lastName: yup.string().required(t('Nachname darf nicht leer sein')),
      email: yup
        .string()
        .email(t('Keine gültige E-Mail Adresse'))
        .required(t('E-Mail Adresse darf nicht leer sein')),
      titleBefore: yup.string(),
      titleAfter: yup.string(),
      salutationPosition: yup.string(),
      phone: yup.string().required(t('Telefon darf nicht leer sein')),
      birthdate: yup.string().nullable(),
      birthplace: yup.string(),
      nationality: yup.string(),
      street: yup.string(),
      zipCode: yup.string(),
      city: yup.string(),
      country: yup.string(),
      employmentStatus: yup.string(),
      medium: yup.string().required(t('Medium darf nicht leer sein')),
      mediumAddress: yup.string(),
      position: yup.string(),
      carBadge: yup.boolean(),
      confirmationPrivacy: yup
        .bool()
        .oneOf([true], t('Der Datenschutzerklärung muss zugestimmt werden')),
      confirmationMoral: yup
        .bool()
        .oneOf([true], t('Der Gewissensfrage muss zugestimmt werden')),
    }),
    []
  );
};

export const usePressPassApplicationValidationSchema = () => {
  const pressPassApplicationValidation = usePressPassApplicationValidation();
  return useMemo(() => {
    return yup.object({
      ...pressPassApplicationValidation,
    });
  }, [pressPassApplicationValidation]);
};

export const usePressPassApplicationDocumentsValidation = () => {
  const { t } = useTranslation();
  return useMemo(
    () => ({
      email: yup
        .string()
        .email(t('Keine gültige E-Mail Adresse.'))
        .required(t('E-Mail Adresse darf nicht leer sein')),
      confirmationPrivacy: yup
        .bool()
        .oneOf([true], t('Der Datenschutzerklärung muss zugestimmt werden')),
      confirmationMoral: yup
        .bool()
        .oneOf([true], t('Der Gewissensfrage muss zugestimmt werden')),
    }),
    []
  );
};

export const usePressPassApplicationDocumentsValidationSchema = () => {
  const pressPassApplicationValidation =
    usePressPassApplicationDocumentsValidation();
  return useMemo(() => {
    return yup.object({
      ...pressPassApplicationValidation,
    });
  }, [pressPassApplicationValidation]);
};

export const usePressPassApplicationUpdateValidation = () => {
  const { t } = useTranslation();
  return useMemo(
    () => ({
      salutation: yup.string(),
      passNumber: yup
        .string()
        .required(t('Ausweisnummer darf nicht leer sein.')),
      birthdate: yup
        .string()
        .required(t('Datum darf nicht leer sein.')),
      confirmationPrivacy: yup
        .bool()
        .oneOf([true], t('Der Datenschutzerklärung muss zugestimmt werden')),
      confirmationMoral: yup
        .bool()
        .oneOf([true], t('Der Gewissensfrage muss zugestimmt werden')),
    }),
    []
  );
};

export const usePressPassApplicationUpdateValidationSchema = () => {
  const pressPassApplicationValidation =
    usePressPassApplicationUpdateValidation();
  return useMemo(() => {
    return yup.object({
      ...pressPassApplicationValidation,
    });
  }, [pressPassApplicationValidation]);
};
