import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Card, CardActions, Container, Divider } from '@mui/material';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router';
import { Link as RouterLink, useParams } from 'react-router-dom';
import Header from '../../components/header';
import HttpError from '../../components/http-error';
import LoadingButton from '../../components/loading-button';
import LoadingContainer from '../../components/loading-container';
import { SignatureFormFields } from '../../components/signature';
import { handleHookFormErrors } from '../../helpers';
import { useSignatureValidationSchema, useTitle } from '../../hooks';
import { apiRoutes, request, routes, useSignatureDetailsApi } from '../../lib';
import {
  Signature,
  SignatureFormValues,
  getSignatureFormValues,
} from '../../model';

const EditSignature = () => {
  const { t } = useTranslation();
  const { signatureId } = useParams<{ signatureId: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const { isLoading, error, data } = useSignatureDetailsApi(+signatureId);

  useTitle(data?.name || t('Signatur bearbeiten'));

  const validationSchema = useSignatureValidationSchema();

  const {
    control,
    handleSubmit,
    setError,
    reset,
    formState: { isValid, isSubmitting },
  } = useForm<SignatureFormValues>({
    mode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues: getSignatureFormValues(data),
  });

  useEffect(() => reset(getSignatureFormValues(data)), [reset, data]);

  const mutation = useMutation(
    async (values: SignatureFormValues) => {
      if (!data) {
        return;
      }
      return await request<Signature>(
        apiRoutes.signature(data.id),
        'put',
        values
      );
    },
    {
      onSuccess: (res) => {
        if (!res) {
          return;
        }
        enqueueSnackbar(t('Signatur wurde erfolgreich aktualisiert.'), {
          variant: 'success',
        });
        history.push(routes.signature(res.data.id));
      },
      onError: (err: AxiosError) => {
        enqueueSnackbar(err.response?.data.message || 'Error', {
          variant: 'error',
        });
        handleHookFormErrors(err, setError);
      },
    }
  );

  if (error) {
    return (
      <HttpError
        error={error}
        actions={
          <Button component={RouterLink} to={routes.signatures}>
            {t('Zurück zu Signaturen')}
          </Button>
        }
      />
    );
  }

  if (isLoading || !data) {
    return <LoadingContainer />;
  }

  const detailBreadcrumbs = [
    { label: t('Home'), link: routes.dashboard },
    { label: t('Signaturen'), link: routes.signatures },
    { label: data.name, link: routes.signature(data.id) },
    { label: t('Bearbeiten') },
  ];

  return (
    <Container maxWidth="md">
      <Header title={data.name} breadcrumbs={detailBreadcrumbs} />
      <form onSubmit={handleSubmit((values) => mutation.mutateAsync(values))}>
        <Card>
          <SignatureFormFields control={control} signature={data} />
          <Divider />
          <CardActions>
            <LoadingButton
              type="submit"
              size="medium"
              color="primary"
              disabled={!isValid}
              variant="contained"
              loading={isSubmitting}
            >
              {t('Speichern')}
            </LoadingButton>
          </CardActions>
        </Card>
      </form>
    </Container>
  );
};

export default EditSignature;
