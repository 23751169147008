import { Alert, Autocomplete, TextField, TextFieldProps } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { config } from '../../config';
import { createOptionsFromEntities } from '../../helpers';
import {
  useDebounceState,
  useFilters,
  usePaginationApi,
  usePrevious,
} from '../../hooks';
import { Entity, ID, Option } from '../../model';

interface PaginatedAutocompleteProps<TEntity> {
  name: string;
  label: string;
  value: any;
  textFieldProps?: TextFieldProps;
  onChange: (entities: ID[]) => void;
  initialData?: TEntity[];
  multiple?: boolean;
  disabled?: boolean;
  loading?: boolean;
  optionLabel: keyof TEntity | ((entity: TEntity) => string);
  dataUrl: string;
  pageSize?: number;
  filters?: Record<string, unknown>;
}

const PaginatedAutocomplete = <TEntity extends Entity>({
  name,
  label,
  value,
  textFieldProps,
  initialData,
  multiple = false,
  disabled = false,
  loading,
  onChange,
  dataUrl,
  optionLabel,
  pageSize = config.pageSize,
  filters = {},
}: PaginatedAutocompleteProps<TEntity>) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const [, setInputSearch] = useDebounceState(search, setSearch);
  const { params } = useFilters<{ search: string } & typeof filters, TEntity>(
    {
      search,
      ...filters,
    },
    'id',
    'desc',
    pageSize
  );
  const [optionsCache, setOptionsCache] = useState<Option[]>([]);
  const { data, isLoading, isFetching, error } = usePaginationApi<
    { search: string } & typeof filters,
    TEntity
  >(dataUrl, params);

  // Everytime the search changes, we need to update the options
  useEffect(() => {
    if (!data) {
      return;
    }
    // Unique options based on new search and previously cached items
    setOptionsCache([
      ...new Map(
        [
          ...optionsCache,
          ...createOptionsFromEntities(data.results, optionLabel, 'id'),
        ].map((option) => [option.value, option])
      ).values(),
    ]);
  }, [data]);

  /**
   * Available options are built from different sources:
   * 1. `data.results` - fetched from API
   * 2. `preselectedValues` - already selected values on the entity (e.g. Company#media)
   * 3. `optionsCache` - cached options from previous search
   */
  const options = useMemo(() => {
    const options = [
      ...createOptionsFromEntities(
        [...(data?.results || []), ...(initialData || [])],
        optionLabel,
        'id'
      ),
      ...optionsCache,
    ];
    // Fancy way of removing duplicates
    return [
      ...new Map(options.map((option) => [option.value, option])).values(),
    ];
  }, [data, initialData, optionsCache]);

  // Unfortunately MUI Autocomplete doesn't take care of properly handling values in case of
  // multiple selection. So we need to handle it manually.
  // Without this the inputValue will be reset on every change.
  const autocompleteValue = useMemo(() => {
    if (!value) {
      return null;
    }
    if (!Array.isArray(value)) {
      value = [+value];
    }
    return value
      .map((v: any) => options.find((o) => o.value === v))
      .filter((v: any) => !!v);
  }, [value, options]);

  const [computedValue, setComputedValue] = useState(() => autocompleteValue);
  const prevValue = usePrevious(autocompleteValue);
  useEffect(() => {
    if (JSON.stringify(prevValue) !== JSON.stringify(autocompleteValue)) {
      setComputedValue(autocompleteValue);
    }
  }, [autocompleteValue]);

  // MUI Autocomplete uses labels as keys which can cause problems. In case duplicated entries are found
  // we simply attach IDs to the labels to make keys unique again.
  const addIdsToLabels =
    new Set(options.map((o) => o.label)).size < options.length &&
    typeof optionLabel !== 'function';

  if (error) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  return (
    <>
      <Autocomplete
        id={name}
        onInputChange={(event, v) => setInputSearch(v)}
        // While this may seem weird, it's needed to make sure that the inputValue is not reset if multiple is true and text field is
        // empty. Otherwise the text field will be reset on every keystroke.
        // See https://github.com/mui/material-ui/blob/master/packages/mui-base/src/AutocompleteUnstyled/useAutocomplete.js#L159
        clearOnBlur={(Array.isArray(value) && value.length !== 0) || !value}
        loading={isLoading || isFetching || loading}
        disabled={disabled}
        options={options.map((option) => {
          if (addIdsToLabels) {
            option.label = `${option.label} (${option.value})`;
          }
          return option;
        })}
        noOptionsText={t('Keine Ergebnisse gefunden')}
        loadingText={t('Lädt...')}
        multiple={multiple}
        onChange={(event, v) => {
          if (!v) {
            return onChange([]);
          }
          if (Array.isArray(v)) {
            return onChange(v.map((v) => v.value as unknown as number));
          }
          return onChange([v.value as unknown as number]);
        }}
        value={
          computedValue && Array.isArray(computedValue) && computedValue.length
            ? computedValue
            : multiple
            ? []
            : null
        }
        isOptionEqualToValue={(option, v) => {
          if (Array.isArray(v)) {
            return v.some((v) => option.value === v.value);
          }
          return option.value === v.value;
        }}
        getOptionLabel={(option) => {
          if (Array.isArray(option) && option.length > 0) {
            return option[0].label;
          }
          return option.label || '';
        }}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              label={label}
              disabled={disabled}
              {...textFieldProps}
            />
          );
        }}
      />
    </>
  );
};

export default PaginatedAutocomplete;
