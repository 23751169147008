import { CircularProgress, Container } from '@mui/material';
import { VoidFunctionComponent } from 'react';

const LoadingContainer: VoidFunctionComponent = () => (
  <Container maxWidth="sm" style={{ textAlign: 'center' }}>
    <CircularProgress />
  </Container>
);

export default LoadingContainer;
