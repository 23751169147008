import React from 'react';
import ReactDOM from 'react-dom';
import { getClient } from './hooks/use-client';
import './i18n';
import { getUser } from './lib';
import { Client } from './model';
import reportWebVitals from './reportWebVitals';
import App from './views/app';

(async () => {
  const client = getClient();
  const user = client === Client.Engine ? await getUser() : null;
  ReactDOM.render(
    <React.StrictMode>
      <App user={user} />
    </React.StrictMode>,
    document.getElementById('root')
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
})();
