import { Check } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Card,
  CardContent,
  Divider,
  FormControl,
  FormHelperText,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React, {
  VoidFunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { apiRoutes, request } from '../../lib';
import { Nullable } from '../../model';
import { Import, WorksheetData } from '../../model/import';
import WorksheetPreview from './worksheet-preview';


const WorksheetSelector: VoidFunctionComponent<{ importEntity: Import }> = ({
  importEntity,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [worksheetData, setWorksheetData] =
    useState<Nullable<WorksheetData>>(null);
  const [selectedWorksheet, setSelectedWorksheet] = useState<number | string>(
    ''
  );
  const queryClient = useQueryClient();

  const getWorksheetInfo = useCallback(
    (worksheet?: WorksheetData) => {
      if (!worksheet || !worksheet.valid) {
        return t('Ungültig');
      }
      const invalidRows = worksheet.rows.reduce(
        (acc, row) => acc + (row.violations.length > 0 ? 1 : 0),
        0
      );
      return t(
        `${worksheet.rows.length} Zeilen (${
          worksheet.rows.length - invalidRows
        } valide, ${invalidRows} fehlerhaft)`
      );
    },
    [importEntity]
  );

  const validSelection = useMemo(() => {
    const worksheet = importEntity.data.find(
      (worksheet) => worksheet.index === selectedWorksheet
    );
    if (!worksheet || !worksheet.valid) {
      return false;
    }
    return (
      worksheet.rows.reduce(
        (acc, row) => acc + (row.violations.length > 0 ? 1 : 0),
        0
      ) !== worksheet.rows.length
    );
  }, [selectedWorksheet, importEntity]);

  const importMutation = useMutation(
    async () => {
      return await request(apiRoutes.importProcess(importEntity.id), 'put', {
        worksheet: selectedWorksheet,
      });
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(apiRoutes.importCurrent);
        enqueueSnackbar(t('Daten wurden erfolgreich importiert'), {
          variant: 'success',
        });
      },
      onError: () => {
        enqueueSnackbar(t('Fehler beim Importieren der Daten'), {
          variant: 'error',
        });
      },
    }
  );

  const revalidateMutation = useMutation(
    async () => {
      return await request(apiRoutes.importRevalidate(importEntity.id));
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(apiRoutes.importCurrent);
        enqueueSnackbar(t('Daten wurden erfolgreich geprüft'), {
          variant: 'success',
        });
      },
      onError: () => {
        enqueueSnackbar(t('Fehler beim Prüfen der Daten'), {
          variant: 'error',
        });
      },
    }
  );

  useEffect(() => {
    const preselected = importEntity.data.find(
      (worksheetData) => worksheetData.valid
    );
    setWorksheetData(preselected || null);
    setSelectedWorksheet(preselected?.index || '');
  }, [importEntity]);

  return (
    <Card sx={{ mt: 3 }}>
      <CardContent sx={{ borderBottom: 1, borderColor: 'grey.200' }}>
        2. {t('Tabellenblatt wählen')}
      </CardContent>
      <CardContent>
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ flex: '1 auto' }}>
            <Typography sx={{ mb: 1 }}>
              {t('Wähle ein gültiges Tabellenblatt')}
            </Typography>
            <Box>
              <FormControl sx={{ width: 300 }}>
                <Select
                  variant="outlined"
                  size="small"
                  value={selectedWorksheet}
                  onChange={(e) => {
                    const worksheet = importEntity.data.find(
                      (w) => w.index === (e.target.value as number)
                    );
                    setWorksheetData(worksheet || null);
                    setSelectedWorksheet(worksheet ? worksheet.index : '');
                  }}
                >
                  <MenuItem value={''} disabled={true}>
                    {t('Tabellenblatt')}
                  </MenuItem>
                  {importEntity.data.map((worksheetData, index) => (
                    <MenuItem
                      key={index}
                      value={worksheetData.index}
                      disabled={
                        !importEntity.data.find(
                          (w) => w.name === worksheetData.name
                        )?.valid
                      }
                    >
                      <ListItemText
                        primary={worksheetData.name}
                        secondary={getWorksheetInfo(
                          importEntity.data.find(
                            (w) => w.name === worksheetData.name
                          )
                        )}
                      />
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{importEntity.filename}</FormHelperText>
              </FormControl>
            </Box>
          </Box>
          <Box sx={{ alignSelf: 'center' }}>
            <LoadingButton
              variant="outlined"
              onClick={() => revalidateMutation.mutateAsync()}
              color="secondary"
              loading={revalidateMutation.isLoading}
              sx={{ mr: 1 }}
            >
              {t('Erneut prüfen')}
            </LoadingButton>
            <LoadingButton
              variant="contained"
              startIcon={<Check />}
              type="submit"
              loading={importMutation.isLoading}
              disabled={!validSelection}
              onClick={() => importMutation.mutateAsync()}
            >
              {t('Jetzt importieren')}
            </LoadingButton>
          </Box>
        </Box>
      </CardContent>
      <Box sx={{ overflow: 'hidden' }}>
        {worksheetData && (
          <>
            <Divider />
            <CardContent>
              <Typography variant="overline">
                {t('Tabellenvorschau')}
              </Typography>
            </CardContent>
            <WorksheetPreview worksheetData={worksheetData} />
          </>
        )}
      </Box>
    </Card>
  );
};

export default WorksheetSelector;
