import { Chip } from '@mui/material';
import React, { VoidFunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Membership, Nullable } from '../../model';

export const MembershipChip: VoidFunctionComponent<{
  membership: Nullable<Membership | string>;
}> = ({ membership }) => {
  const { t } = useTranslation();

  switch (membership) {
    case Membership.Voez:
      return (
        <Chip
          label={t(`membership.${membership}`)}
          size="small"
          color="primary"
        />
      );
    case Membership.Oezv:
      return (
        <Chip label={t(`membership.${membership}`)} size="small" color="info" />
      );
    default:
      return <></>;
  }
};
