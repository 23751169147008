import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import { Tooltip } from '@mui/material';
import React, { FunctionComponent } from 'react';

const LoadingIconButton: FunctionComponent<
  LoadingButtonProps & { tooltip?: string }
> = ({ children, tooltip, ...props }) => {
  const button = (
    <LoadingButton {...props} sx={{ minWidth: 0, borderRadius: '50%' }}>
      {children}
    </LoadingButton>
  );
  return <>{tooltip ? <Tooltip title={tooltip}>{button}</Tooltip> : button}</>;
};

export default LoadingIconButton;
