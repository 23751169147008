import {
  Blameable,
  Commentable,
  ID,
  LegacyEntity,
  Nullable,
  Timestampable,
} from './index';

export interface Position
  extends Blameable,
    Commentable,
    Timestampable,
    LegacyEntity {
  id: ID;
  title: string;
}

export interface PositionFilters {
  search: string;
}

export interface PositionFormValues {
  title: string;
  comment: Nullable<string>;
}

export const getPositionFormValues = (
  position?: Position
): PositionFormValues => ({
  title: position?.title || '',
  comment: position?.comment || '',
});
