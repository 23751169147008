import { useMemo } from 'react';
import * as yup from 'yup';
import {useTranslation} from 'react-i18next';

export const usePositionValidation = () => {
  const { t } = useTranslation();

  return useMemo(
    () => ({
      title: yup.string().required(t('Titel darf nicht leer sein.')),
      comment: yup.string().nullable(),
    }),
    []
  );
};

export const usePositionValidationSchema = () => {
  const positionValidation = usePositionValidation();

  return useMemo(() => {
    return yup.object({
      ...positionValidation,
    });
  }, [positionValidation]);
};
