import { Add } from '@mui/icons-material';
import { Button } from '@mui/material';
import React, { VoidFunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FilterInputSearch } from '../../components/filter';
import { StyledContainer } from '../../components/globals';
import Guarded from '../../components/guarded';
import Header from '../../components/header';
import { PaginationCard } from '../../components/pagination';
import { TariffList } from '../../components/tariff';
import { config } from '../../config';
import {
  UseQueryStateOptions,
  useDebounceState,
  useFilteredPaginationApi,
  useQueryState,
  useTitle,
} from '../../hooks';
import { apiRoutes, routes } from '../../lib';
import { PayRate, PayRateFilters, Role } from '../../model';

const TariffsOverview: VoidFunctionComponent = () => {
  const { t } = useTranslation();
  useTitle(t('Tariflisten'));

  const breadcrumbs = [
    { label: t('Home'), link: routes.dashboard },
    { label: t('Tariflisten') },
  ];

  const queryStateOptions: UseQueryStateOptions = { action: 'replace' };
  const [search, setFilterSearch] = useQueryState(
    '',
    'search',
    queryStateOptions
  );
  const [inputSearch, setInputSearch] = useDebounceState(
    search,
    setFilterSearch
  );

  const { context } = useFilteredPaginationApi<PayRateFilters, PayRate>(
    apiRoutes.tariffs,
    { search },
    'name',
    'asc',
    config.pageSize
  );

  return (
    <StyledContainer data-test="media-content">
      <Header
        title={t('Tariflisten')}
        breadcrumbs={breadcrumbs}
        actions={
          <Guarded requiredRole={Role.PDN}>
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to={routes.tariffCreate}
              startIcon={<Add />}
            >
              {t('Neue Tarifliste')}
            </Button>
          </Guarded>
        }
      />
      <PaginationCard
        context={context}
        hasFilters={!!search}
        resetFilters={() => {
          setFilterSearch('');
        }}
        filters={
          <>
            <FilterInputSearch
              value={inputSearch}
              label={t('Suche')}
              onChange={setInputSearch}
            />
          </>
        }
      >
        <TariffList />
      </PaginationCard>
    </StyledContainer>
  );
};

export default TariffsOverview;
