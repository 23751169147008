import axios, {
  AxiosError,
  AxiosResponse,
  CancelToken,
  Method,
  ResponseType,
} from 'axios';
import { config } from '../config';
import { Dict, HttpStatus } from '../model';
import { apiRoutes } from './api';
import { routes } from './routes';

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (err: AxiosError) => {
    if (err.response?.status !== HttpStatus.Unauthorized) {
      throw err;
    }
    const api = process.env.REACT_APP_API_URL;
    if (
      [`${api}/${apiRoutes.profile.me}`, `${api}/${apiRoutes.login}`].includes(
        err.response.config.url || ''
      )
    ) {
      return;
    }
    window.location.href = routes.dashboard;
  }
);

export async function request<TResponse>(
  url: string,
  method: Method = 'get',
  data?: unknown,
  params?: unknown,
  headers: Dict = {},
  cancelToken?: CancelToken,
  responseType: ResponseType = 'json'
): Promise<AxiosResponse<TResponse>> {
  if (config.locale.sendAcceptLanguageHeader) {
    headers = {
      ...headers,
      'Accept-Language': config.locale.defaultLocale,
    };
  }
  return axios.request<TResponse>({
    method,
    url: `${process.env.REACT_APP_API_URL}/${url}`,
    data,
    params,
    headers,
    cancelToken,
    withCredentials: true,
    responseType,
  });
}
