import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Card,
  CardActions,
  Container,
  Divider,
} from '@mui/material';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { UseFormSetError, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Link as RouterLink, useParams } from 'react-router-dom';
import Header from '../../components/header';
import HttpError from '../../components/http-error';
import LoadingButton from '../../components/loading-button';
import LoadingContainer from '../../components/loading-container';
import { MediumFormFields } from '../../components/medium';
import { handleHookFormErrors } from '../../helpers';
import { useTitle } from '../../hooks';
import { useMediumValidationSchema } from '../../hooks/validation/medium';
import { apiRoutes, request, routes, useMediumDetailsApi } from '../../lib';
import {
  ApiError,
  Medium,
  MediumFormValues,
  getMediumFormValues,
} from '../../model';

const EditMedium = () => {
  const { t } = useTranslation();
  const { mediumId } = useParams<{ mediumId: string | undefined }>();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const { isLoading, error, data } = useMediumDetailsApi(
    mediumId as unknown as number
  );

  useTitle(data?.name || t('Medium bearbeiten'));

  const validationSchema = useMediumValidationSchema();

  const {
    control,
    handleSubmit,
    setError,
    formState: { isValid, isSubmitting },
    reset,
  } = useForm<MediumFormValues>({
    mode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues: getMediumFormValues(data),
  });

  useEffect(() => reset(getMediumFormValues(data)), [reset, data]);

  const onSubmit = async (
    values: MediumFormValues,
    setError: UseFormSetError<MediumFormValues>
  ) => {
    if (!data) {
      return;
    }
    await request<Medium>(apiRoutes.medium(data.id), 'put', values)
      .then(() => {
        enqueueSnackbar(t('Medium wurde erfolgreich bearbeitet.'), {
          variant: 'success',
        });
        history.push(routes.medium(data.id));
      })
      .catch((err: AxiosError<ApiError>) => {
        enqueueSnackbar(err.response?.data.message, { variant: 'error' });
        handleHookFormErrors(err, setError);
      });
  };

  if (error) {
    return (
      <HttpError
        error={error}
        actions={
          <Button component={RouterLink} to={routes.media}>
            {t('Zurück zu Medien')}
          </Button>
        }
      />
    );
  }

  if (isLoading || !data) {
    return <LoadingContainer />;
  }

  const detailBreadcrumbs = [
    { label: t('Home'), link: routes.dashboard },
    { label: t('Medien'), link: routes.media },
    { label: data.name, link: routes.medium(data.id) },
    { label: t('Bearbeiten') },
  ];

  return (
    <Container>
      <Header title={data.name} breadcrumbs={detailBreadcrumbs} />
      <form onSubmit={handleSubmit((values) => onSubmit(values, setError))}>
        <Card>
          <MediumFormFields control={control} medium={data} />
          <Divider />
          <CardActions>
            <LoadingButton
              type="submit"
              size="medium"
              color="primary"
              disabled={!isValid}
              variant="contained"
              loading={isSubmitting}
            >
              {t('Speichern')}
            </LoadingButton>
          </CardActions>
        </Card>
      </form>
    </Container>
  );
};

export default EditMedium;
