import { useMemo } from 'react';
import { config } from '../config';
import { Client } from '../model';

// Non-hook useClient implementation
export const getClient = () => {
  const location = window.location;
  // Set localStorage "client" to "voez" or "oezv" for dev purposes
  const localClient = localStorage.getItem('client');
  if (localClient === 'voez' || location.hostname === config.host.voez) {
    return Client.Voez;
  }
  if (localClient === 'oezv' || location.hostname === config.host.oezv) {
    return Client.Oezv;
  }
  return Client.Engine;
};

export const useClient = () => {
  return useMemo(getClient, [window, localStorage]);
};
