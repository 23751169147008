import {
  CardContent,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  TextField,
} from '@mui/material';
import React, { VoidFunctionComponent } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { apiRoutes } from '../../lib';
import { Medium, MediumFormValues } from '../../model';
import { StyledCardTitle } from '../globals';
import PaginatedAutocomplete from '../paginated-autocomplete';

export const MediumFormFields: VoidFunctionComponent<{
  control: Control<MediumFormValues>;
  medium?: Medium;
}> = ({ control, medium }) => {
  const { t } = useTranslation();

  return (
    <>
      <CardContent>
        <StyledCardTitle variant="h6" color="secondary" gutterBottom>
          {t('Informationen')}
        </StyledCardTitle>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="name"
              render={({ field, fieldState }) => (
                <TextField
                  label={t('Name')}
                  fullWidth
                  required
                  {...field}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="company"
              render={({ field, fieldState }) => (
                <PaginatedAutocomplete
                  name="company"
                  label={t('Unternehmen')}
                  value={field.value}
                  onChange={(ids) => field.onChange(ids?.[0] || null)}
                  optionLabel={(option) =>
                    `${option.name} ${option.term || ''} (${option.id})`
                  }
                  dataUrl={apiRoutes.companies}
                  initialData={medium && medium.company ? [medium.company] : []}
                  textFieldProps={{
                    error: fieldState.isTouched && fieldState.invalid,
                    helperText: fieldState.error?.message,
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="responsiblePerson"
              render={({ field, fieldState }) => (
                <PaginatedAutocomplete
                  name="responsiblePerson"
                  label={t('PA-Veranwortliche/r')}
                  value={field.value}
                  onChange={(ids) => field.onChange(ids?.[0] || null)}
                  optionLabel="displayName"
                  dataUrl={apiRoutes.persons}
                  initialData={medium ? [medium.responsiblePerson] : []}
                  textFieldProps={{
                    error: fieldState.isTouched && fieldState.invalid,
                    helperText: fieldState.error?.message,
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="licenseResponsiblePerson"
              render={({ field, fieldState }) => (
                <PaginatedAutocomplete
                  name="licenseResponsiblePerson"
                  label={t('PDN-Veranwortliche/r')}
                  value={field.value}
                  onChange={(ids) => field.onChange(ids?.[0] || null)}
                  optionLabel="displayName"
                  dataUrl={apiRoutes.persons}
                  initialData={medium ? [medium.responsiblePerson] : []}
                  textFieldProps={{
                    error: fieldState.isTouched && fieldState.invalid,
                    helperText: fieldState.error?.message,
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="phone"
              render={({ field, fieldState }) => (
                <TextField
                  label={t('Telefon')}
                  fullWidth
                  {...field}
                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="website"
              render={({ field, fieldState }) => (
                <TextField
                  label={t('Webseite')}
                  fullWidth
                  {...field}
                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="referenceId"
              render={({ field, fieldState }) => (
                <TextField
                  label={t('PDN Titel ID')}
                  fullWidth
                  {...field}
                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="pressPass"
              render={({ field }) => (
                <FormControlLabel
                  defaultChecked={field.value}
                  control={
                    <Checkbox
                      checked={field.value}
                      onChange={field.onChange}
                      id="pressPass"
                      color="primary"
                    />
                  }
                  label={t<string>('Presseausweis Medium')}
                />
              )}
            />
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="comment"
              render={({ field, fieldState }) => (
                <TextField
                  label={t('Interner Kommentar')}
                  fullWidth
                  multiline={true}
                  {...field}
                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
        </Grid>
      </CardContent>
    </>
  );
};
