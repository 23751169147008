import { Route, Switch, useRouteMatch } from 'react-router-dom';
import GuardedRoute from '../../components/guarded-route';
import { routes } from '../../lib';
import { Role } from '../../model';
import CreateMedium from './create';
import MediumDetails from './details';
import EditMedium from './edit';
import MediaOverview from './overview';

const Media = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <MediaOverview />
      </Route>
      <GuardedRoute requiredRole={Role.General} path={routes.mediumCreate}>
        <CreateMedium />
      </GuardedRoute>
      <GuardedRoute
        requiredRole={Role.General}
        path={routes.mediumEdit(':mediumId')}
      >
        <EditMedium />
      </GuardedRoute>
      <Route path={routes.medium(':mediumId')}>
        <MediumDetails />
      </Route>
    </Switch>
  );
};

export default Media;
