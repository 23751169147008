import { Avatar } from '@mui/material';
import { VoidFunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import VoezLogo from '../../images/logo.png';
import { routes } from '../../lib';

const StyledLink = styled(Link)`
  display: flex;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: 2.5em;
  font-weight: 500;
  align-items: center;
  text-decoration: none;
  color: ${(props) => props.theme.palette.text.primary};
`;

const StyledAvatar = styled(Avatar)`
  height: 2.2em;
  width: 2.2em;
  margin: 0 6px;
  border: 2px rgba(255, 255, 255, 0.2) solid;
`;

const Logo: VoidFunctionComponent = () => {
  return (
    <StyledLink to={routes.dashboard}>
      <StyledAvatar src={VoezLogo}/> PRESSE
    </StyledLink>
  );
};

export default Logo;
