import { DatePicker } from '@mui/lab';
import {
  Autocomplete,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import React, { VoidFunctionComponent } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useUnlimitedPaginationApi } from '../../hooks';
import { apiRoutes } from '../../lib';
import {
  DateFormat,
  Dict,
  LicenseInterval,
  Licensee,
  LicenseeFormValues,
  PayRate,
} from '../../model';
import Error from '../error';
import { StyledCardTitle } from '../globals';
import PaginatedAutocomplete from '../paginated-autocomplete';

export const LicenseeFormFields: VoidFunctionComponent<{
  control: Control<LicenseeFormValues>;
  licensee?: Licensee;
}> = ({ control, licensee }) => {
  const { t } = useTranslation();

  const {
    data: payRates,
    isLoading: payRatesLoading,
    error: payRatesError,
  } = useUnlimitedPaginationApi<Dict, PayRate>(apiRoutes.payRates);

  if (payRatesError) {
    return (
      <CardContent>
        <Error title={'Error'} />
      </CardContent>
    );
  }

  return (
    <CardContent>
      <StyledCardTitle variant="h6" color="secondary" gutterBottom>
        {t('PDN Vertragsdaten')}
      </StyledCardTitle>
      <Grid container spacing={3}>
        <Grid item md={6}>
          <Controller
            control={control}
            name="license"
            render={({ field, fieldState }) => (
              <TextField
                label={t('Lizenznummer')}
                fullWidth
                {...field}
                required
                error={fieldState.isTouched && fieldState.invalid}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="mbo"
            render={({ field, fieldState }) => (
              <PaginatedAutocomplete
                name="mbo"
                label={t('MBO')}
                value={field.value}
                onChange={(ids) => field.onChange(ids?.[0] || null)}
                multiple={false}
                optionLabel={(option) =>
                  `${option.name} ${option.term || ''} (${option.id})`
                }
                dataUrl={apiRoutes.companies}
                initialData={licensee && licensee.mbo ? [licensee.mbo] : []}
                textFieldProps={{
                  error: fieldState.isTouched && fieldState.invalid,
                  helperText: fieldState.error?.message,
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="contractStart"
            render={({ field, fieldState }) => (
              <DatePicker
                value={field.value}
                inputFormat={DateFormat.Default}
                mask={DateFormat.Mask}
                onChange={field.onChange}
                label={t('Vertragsbeginn')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    fullWidth
                    required
                    helperText={fieldState.error?.message}
                    error={fieldState.invalid}
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="contractEnd"
            render={({ field, fieldState }) => (
              <DatePicker
                value={field.value}
                inputFormat={DateFormat.Default}
                mask={DateFormat.Mask}
                onChange={field.onChange}
                label={t('Vertragsende')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    fullWidth
                    required
                    helperText={fieldState.error?.message}
                    error={fieldState.invalid}
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel>{t('Meldeintervall')}</InputLabel>
            <Controller
              control={control}
              name="licenseInterval"
              render={({ field }) => (
                <Select
                  fullWidth
                  {...field}
                  label={t('Meldeintervall')}
                  value={field.value}
                >
                  <MenuItem value={LicenseInterval.Monthly}>
                    {t('Monat')}
                  </MenuItem>
                  <MenuItem value={LicenseInterval.Quarterly}>
                    {t('Quartal')}
                  </MenuItem>
                  <MenuItem value={LicenseInterval.Yearly}>
                    {t('Jahr')}
                  </MenuItem>
                </Select>
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="payRate"
            render={({ field, fieldState }) => (
              <Autocomplete
                id="payRate"
                noOptionsText={t('Keine Lizenzpakete vorhanden')}
                options={payRates}
                loading={payRatesLoading}
                getOptionLabel={(option) => option.name}
                value={payRates.find((c) => c.id === field.value) || null}
                onChange={(event, value) => field.onChange(value?.id)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('Rechnungstyp / Lizenzpaket')}
                    error={fieldState.isTouched && fieldState.invalid}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="debtorAccount"
            render={({ field, fieldState }) => (
              <TextField
                label={t('Debitorennummer')}
                fullWidth
                {...field}
                error={fieldState.isTouched && fieldState.invalid}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="supplierAccount"
            render={({ field, fieldState }) => (
              <TextField
                label={t('Lieferantennummer')}
                fullWidth
                {...field}
                error={fieldState.isTouched && fieldState.invalid}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Grid>
      </Grid>
    </CardContent>
  );
};
