import { Button, Card, CardContent } from '@mui/material';
import React, { VoidFunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledCardTitle } from '../../components/globals';
import { config } from '../../config';
import { useClient } from '../../hooks/use-client';
import { Client } from '../../model';


export const SuccessCard: VoidFunctionComponent<{ text: string }> = ({
  text,
}) => {
  const { t } = useTranslation();
  const client = useClient();

  return (
    <Card>
      <CardContent>
        <StyledCardTitle variant="h6" color="secondary" sx={{ mb: 3 }}>
          {t('Vielen Dank!')}
        </StyledCardTitle>
        {text}
      </CardContent>
      <CardContent sx={{ textAlign: 'center' }}>
        <Button
          variant="contained"
          color="primary"
          href={
            client === Client.Oezv
              ? config.originLinks.oezv
              : config.originLinks.voez
          }
        >
          {t('Zurück zur Hauptseite')}
        </Button>
      </CardContent>
    </Card>
  );
};

export default SuccessCard;
