import { yupResolver } from '@hookform/resolvers/yup';
import { Check, Description } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Card,
  CardContent,
  FormHelperText,
  Typography,
} from '@mui/material';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import React, { VoidFunctionComponent } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { buildFormData } from '../../helpers';
import { useImportFileValidationSchema } from '../../hooks/validation/import';
import { apiRoutes, request } from '../../lib';

interface FormValues {
  file: File;
}

const FileUpload: VoidFunctionComponent<{ schema: string[] }> = ({
  schema,
}) => {
  const { t } = useTranslation();

  const validationSchema = useImportFileValidationSchema();

  const {
    control,
    handleSubmit,
    formState: { isValid, isSubmitting },
  } = useForm<FormValues>({
    mode: 'all',
    resolver: yupResolver(validationSchema),
  });

  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const uploadMutation = useMutation(
    async (values: FormValues) => {
      return await request(
        apiRoutes.importUpload,
        'post',
        buildFormData(values, (formData) => {
          formData.append('file', values.file);
        })
      );
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(apiRoutes.importCurrent);
        enqueueSnackbar(t('Datei erfolgreich hochgeladen.'), {
          variant: 'success',
        });
      },
      onError: (e) => {
        let message = t('Datei konnte nicht hochgeladen werden.');
        if (axios.isAxiosError(e)) {
          message = e.response?.data?.message || message;
        }
        enqueueSnackbar(message, {
          variant: 'error',
        });
      },
    }
  );

  return (
    <Card>
      <form
        onSubmit={handleSubmit((values) => uploadMutation.mutateAsync(values))}
      >
        <CardContent sx={{ borderBottom: 1, borderColor: 'grey.200' }}>
          1. {t('Datei hochladen')}
        </CardContent>
        <CardContent>
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ flex: '1 auto' }}>
              <Typography sx={{ mb: 1 }}>
                {t(
                  'Wähle eine passende Excel Datei. Die Tabelle muss exakt folgende Spalten enthalten: '
                )}{' '}
                {schema.join(', ')}
              </Typography>
              <Controller
                control={control}
                name="file"
                render={({ field, fieldState }) => (
                  <>
                    <input
                      value=""
                      id="fileUpload"
                      hidden
                      type="file"
                      onChange={(e) =>
                        field.onChange(e.currentTarget.files![0])
                      }
                    />
                    <label htmlFor="fileUpload">
                      <Button
                        variant="contained"
                        component="span"
                        color="primary"
                      >
                        <Description /> {t('Datei auswählen')}
                      </Button>
                    </label>
                    <Box m={1} display="inline">
                      <Typography
                        display="block"
                        variant="caption"
                      ></Typography>
                    </Box>
                    <Typography display="block" variant="caption">
                      {field.value?.name}
                    </Typography>
                    {fieldState.error && (
                      <FormHelperText error={true}>
                        {fieldState.error?.message}
                      </FormHelperText>
                    )}
                  </>
                )}
              />
            </Box>
            <Box sx={{ alignSelf: 'center', width: 300 }}>
              <LoadingButton
                variant="contained"
                startIcon={<Check />}
                type="submit"
                loading={isSubmitting}
                disabled={isSubmitting || !isValid}
              >
                {t('Import starten')}
              </LoadingButton>
            </Box>
          </Box>
        </CardContent>
      </form>
    </Card>
  );
};

export default FileUpload;
