import { yupResolver } from '@hookform/resolvers/yup';
import { Card, CardActions, Container, Divider } from '@mui/material';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router';
import Header from '../../components/header';
import LoadingButton from '../../components/loading-button';
import { PayRateFormFields } from '../../components/pay-rate';
import { handleHookFormErrors } from '../../helpers';
import { useTitle } from '../../hooks';
import { usePayRateValidationSchema } from '../../hooks/validation/pay-rate';
import { apiRoutes, request, routes } from '../../lib';
import { PayRate, PayRateFormValues, getPayRateFormValues } from '../../model';

const CreatePayRate = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  useTitle(t('Neues Lizenzpaket'));

  const validationSchema = usePayRateValidationSchema();

  const {
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting },
  } = useForm<PayRateFormValues>({
    mode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues: getPayRateFormValues(),
  });

  const submitMutation = useMutation(
    async (values: PayRateFormValues) => {
      return await request<PayRate>(apiRoutes.payRateCreate, 'post', values);
    },
    {
      onSuccess: (res) => {
        enqueueSnackbar(t('Lizenzpaket wurde erfolgreich erstellt.'), {
          variant: 'success',
        });
        history.push(routes.payRate(res.data.id));
      },
      onError: (err: AxiosError) => {
        enqueueSnackbar(
          err.response?.data.message || t('Leider ist etwas schief gelaufen.'),
          { variant: 'error' }
        );
        handleHookFormErrors(err, setError);
      },
    }
  );

  const detailBreadcrumbs = [
    { label: t('Home'), link: routes.dashboard },
    { label: t('Lizenzpaket'), link: routes.positions },
  ];

  return (
    <Container maxWidth="md">
      <Header title={t('Neu')} breadcrumbs={detailBreadcrumbs} />
      <form
        onSubmit={handleSubmit((values) => submitMutation.mutateAsync(values))}
        noValidate
      >
        <Card>
          <PayRateFormFields control={control} />
          <Divider />
          <CardActions>
            <LoadingButton
              type="submit"
              size="medium"
              color="primary"
              variant="contained"
              loading={isSubmitting}
            >
              {t('Speichern')}
            </LoadingButton>
          </CardActions>
        </Card>
      </form>
    </Container>
  );
};

export default CreatePayRate;
