import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { routes } from '../../lib';
import LicenseeDetails from './details';
import EditLicensee from './edit';
import LicenseeOverview from './overview';
import LicenseeExport from "./export";

const Licensees = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <LicenseeOverview />
      </Route>
      <Route path={routes.licenseesExport}>
        <LicenseeExport />
      </Route>
      <Route path={routes.licenseeEdit(':licenseeId')}>
        <EditLicensee />
      </Route>
      <Route path={routes.licensee(':licenseeId')}>
        <LicenseeDetails />
      </Route>
    </Switch>
  );
};

export default Licensees;
