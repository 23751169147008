import { useMemo } from 'react';
import * as yup from 'yup';
import {useTranslation} from 'react-i18next';

export const useMediumValidation = () => {
  const { t } = useTranslation();

  return useMemo(
    () => ({
      name: yup.string().required(t('Name darf nicht leer sein.')),
      company: yup.number().nullable(),
      phone: yup.string().nullable(),
      website: yup.string().nullable(),
      comment: yup.string().nullable(),
      responsiblePerson: yup.number().nullable(),
    }),
    []
  );
};

export const useMediumValidationSchema = () => {
  const mediumValidation = useMediumValidation();

  return useMemo(() => {
    return yup.object({
      ...mediumValidation,
    });
  }, [mediumValidation]);
};
