import { Add, Archive, BadgeOutlined } from '@mui/icons-material';
import { Button, CardContent, Grid, MenuItem, TextField, Typography } from '@mui/material';
import React, { VoidFunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FilterSelect } from '../../components/filter';
import {
  FilterFormControl,
  StyledContainer,
  StyledFilterFormControl,
} from '../../components/globals';
import Guarded from '../../components/guarded';
import Header from '../../components/header';
import PaginatedAutocomplete from '../../components/paginated-autocomplete';
import { PaginationCard } from '../../components/pagination';
import PressPassList from '../../components/press-pass/list';
import PressPassMarkDialog from '../../components/press-pass/mark-dialog';
import { config } from '../../config';
import {
  UseQueryStateOptions,
  useDebounceState,
  useFilteredPaginationApi,
  useQueryState,
  useTitle,
} from '../../hooks';
import { apiRoutes, routes } from '../../lib';
import {
  BillTo,
  Company,
  CompanyFilters,
  ID,
  Medium,
  MediumFilters,
  Membership,
  PressPass,
  PressPassFilters,
  Role,
} from '../../model';
import PressPassExtensionDialog from "../../components/press-pass/extension-dialog";

const PressPassesOverview: VoidFunctionComponent = () => {
  const { t } = useTranslation();
  useTitle(t('Presseausweise'));
  const [selected, setSelected] = useState<ID[]>([]);
  const [markDialogOpen, setMarkDialogOpen] = useState(false);
  const [extensionDialogOpen, setExtensionDialogOpen] = useState(false);
  const breadcrumbs = [
    { label: t('Home'), link: routes.dashboard },
    { label: t('Presseausweise') },
  ];

  const queryStateOptions: UseQueryStateOptions = { action: 'replace' };
  const [search, setFilterSearch] = useQueryState(
    '',
    'search',
    queryStateOptions
  );

  const [inputSearch, setInputSearch] = useDebounceState(
    search,
    setFilterSearch
  );

  const [medium, setFilterMedium] = useQueryState(
    '',
    'medium',
    queryStateOptions
  );

  const [inputMedium, setInputMedium] = useDebounceState(
    medium,
    setFilterMedium
  );

  const [company, setFilterCompany] = useQueryState(
    '',
    'company',
    queryStateOptions
  );
  const [inputCompany, setInputCompany] = useDebounceState(
    company,
    setFilterCompany
  );

  const [noMark, setFilterNoMark] = useQueryState(
    '',
    'noMark',
    queryStateOptions
  );

  const [inputNoMark, setInputNoMark] = useDebounceState(
    noMark,
    setFilterNoMark
  );

  const [membership, setFilterMembership] = useQueryState(
    '',
    'membership',
    queryStateOptions
  );

  const [status, setFilterStatus] = useQueryState(
    '',
    'status',
    queryStateOptions
  );

  const [enabled, setFilterEnabled] = useQueryState(
    'enabled',
    'enabled',
    queryStateOptions
  );

  const [billTo, setFilterBillTo] = useQueryState(
    '',
    'billTo',
    queryStateOptions
  );

  const { context } = useFilteredPaginationApi<PressPassFilters, PressPass>(
    apiRoutes.pressPasses,
    { search, medium, noMark, membership, company, enabled, status, billTo },
    'lastName',
    'asc',
    config.pageSize
  );

  const {
    data: media,
    isFetching: mediaIsFetching,
    isLoading: mediaIsLoading,
  } = useFilteredPaginationApi<MediumFilters, Medium>(
    apiRoutes.media,
    { id: +medium },
    'name',
    'desc',
    1
  );

  const {
    data: companies,
    isFetching: companiesIsFetching,
    isLoading: companiesIsLoading,
  } = useFilteredPaginationApi<CompanyFilters, Company>(
    apiRoutes.companies,
    { id: +inputCompany },
    'name',
    'desc',
    1
  );

  useEffect(() => {
    setSelected([]);
  }, [inputNoMark, inputMedium, inputSearch, inputCompany]);

  return (
    <StyledContainer data-test="press-pass-content">
      <Header
        title={t('Presseausweise')}
        breadcrumbs={breadcrumbs}
        actions={
          <Guarded requiredRole={Role.PressPass}>
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to={routes.pressPassCreate}
              startIcon={<Add/>}
            >
              {t('Neuer Presseausweis')}
            </Button>
          </Guarded>
        }
      />
      <PaginationCard
        context={context}
        hasFilters={
          !!(search || medium || noMark || membership || company || enabled || status || billTo)
        }
        resetFilters={() => {
          setFilterSearch('');
          setFilterMedium('');
          setFilterNoMark('');
          setFilterCompany('');
          setFilterMembership('');
          setFilterStatus('');
          setFilterEnabled('');
          setFilterBillTo('');
        }}
        filters={
          <>
            <FilterFormControl>
              <TextField
                label={t('Suche')}
                variant="outlined"
                placeholder={t('zB: Name, E-Mail')}
                value={inputSearch}
                onChange={(e) => setInputSearch(e.target.value)}
                size="small"
              />
            </FilterFormControl>
            <StyledFilterFormControl>
              <PaginatedAutocomplete
                name="media"
                label={t('Medien')}
                value={medium}
                onChange={(ids) =>
                  ids.length === 0
                    ? setInputMedium('')
                    : setInputMedium(ids[0].toString())
                }
                initialData={media?.results}
                multiple={false}
                loading={mediaIsLoading || mediaIsFetching}
                optionLabel="name"
                dataUrl={apiRoutes.media}
                textFieldProps={{
                  variant: 'outlined',
                  size: 'small',
                }}
              />
            </StyledFilterFormControl>
            <StyledFilterFormControl>
              <PaginatedAutocomplete
                name="companies"
                label={t('Unternehmen *')}
                value={company}
                onChange={(ids) =>
                  ids.length === 0
                    ? setInputCompany('')
                    : setInputCompany(ids[0].toString())
                }
                initialData={companies?.results}
                multiple={false}
                loading={companiesIsLoading || companiesIsFetching}
                optionLabel={(company) =>
                  `${company.name} ${company.term || ''} (${company.id})`
                }
                dataUrl={apiRoutes.companiesWithPressPasses}
                textFieldProps={{
                  variant: 'outlined',
                  size: 'small',
                }}
              />
            </StyledFilterFormControl>
            <FilterFormControl>
              <TextField
                label={t('Keine Marke für')}
                variant="outlined"
                placeholder={t('zB: 2022, 2021, ...')}
                value={inputNoMark}
                onChange={(e) => setInputNoMark(e.target.value)}
                size="small"
              />
            </FilterFormControl>
            <FilterSelect
              size="small"
              label={t('Mitglied')}
              value={membership}
              onChange={setFilterMembership}
            >
              <MenuItem value="">
                <em>{t('Alle')}</em>
              </MenuItem>
              <MenuItem value={Membership.Voez}>
                {t(`membership.${Membership.Voez}`)}
              </MenuItem>
              <MenuItem value={Membership.Oezv}>
                {t(`membership.${Membership.Oezv}`)}
              </MenuItem>
              <MenuItem value="null">{t('Keine')}</MenuItem>
            </FilterSelect>
            <FilterSelect
              size="small"
              label={t('Status')}
              value={enabled}
              onChange={setFilterEnabled}
            >
              <MenuItem value="">
                <em>{t('Alle')}</em>
              </MenuItem>
              <MenuItem value="enabled">{t('Aktiv')}</MenuItem>
              <MenuItem value="disabled">{t('Inaktiv')}</MenuItem>
            </FilterSelect>
            <FilterSelect
              size="small"
              label={t('Bearbeitungsstatus')}
              value={status}
              onChange={setFilterStatus}
            >
              <MenuItem value="">
                <em>{t('Alle')}</em>
              </MenuItem>
              <MenuItem value="created">{t('Erstellt')}</MenuItem>
              <MenuItem value="photo_updated">{t('Foto aktualisiert')}</MenuItem>
              <MenuItem value="ready_for_export">{t('Bereit für Export')}</MenuItem>
            </FilterSelect>
            <FilterSelect
              size="small"
              label={t('Bezahlt von')}
              value={billTo}
              onChange={setFilterBillTo}
            >
              <MenuItem value="">
                <em>{t('Alle')}</em>
              </MenuItem>
              <MenuItem value={BillTo.Company}>
                {t(`pressPass.billTo.${BillTo.Company}`)}
              </MenuItem>
              <MenuItem value={BillTo.Person}>
                {t(`pressPass.billTo.${BillTo.Person}`)}
              </MenuItem>
            </FilterSelect>
          </>
        }
      >
        <CardContent>
          <Grid container spacing={3} alignItems="center"
                justifyContent="flex-start">
            <Grid item>
              <Button
                startIcon={<BadgeOutlined/>}
                size={"small"}
                onClick={() => setMarkDialogOpen(true)}
                disabled={selected.length < 1}
              >
                {t('Marken verlängern ({{ selected }})', {
                  selected: selected.length,
                })}
              </Button>

            </Grid>
            <Grid item>
              <Button
                variant="text"
                size={"small"}
                title={t('Erstellt eine Zip-Datei die alle Verlängerungsformulare für alle ausgewählten Personen als PDF enthält.')}
                color="primary"
                startIcon={<Archive/>}
                onClick={() => setExtensionDialogOpen(true)}
                disabled={selected.length < 1}
              >
                {t('Zip-Datei erstellen ({{ selected }})', {
                  selected: selected.length,
                })}
              </Button>
            </Grid>
            <Grid item>
              <Typography variant="caption" color="textSecondary" fontSize={'x-small'}>
                {t('* Es werden nur Unternehmen mit Presseausweisen angezeigt')}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="caption" color="red" fontSize={'x-small'}>
                {' '}{t('¹ Gesperrte Ausweisnummer')}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
        <PressPassList
          selected={selected}
          setSelected={(selected: ID[]) => setSelected(selected)}
        />
      </PaginationCard>
      <PressPassMarkDialog
        open={markDialogOpen}
        onClose={() => setMarkDialogOpen(false)}
        selected={selected}
      />

      <PressPassExtensionDialog
        open={extensionDialogOpen}
        onClose={() => setExtensionDialogOpen(false)}
        selected={selected}
        company={company}
        membership={membership}
      />
    </StyledContainer>
  );
};

export default PressPassesOverview;
