import {
  Blameable,
  Commentable,
  CompanyPerson,
  ID,
  Nullable,
  Person,
  Timestampable,
} from './index';

export interface MailingList extends Blameable, Commentable, Timestampable {
  id: ID;
  name: string;
  enabled: boolean;
  persons: MailingListPerson[];
}

export interface MailingListPerson
  extends Blameable,
    Commentable,
    Timestampable {
  id: ID;
  mailingList: MailingList;
  person: Person;
  companyPerson: Nullable<CompanyPerson>;
  isDefault: boolean;
}

export interface MailingListFilters {
  search: string;
  enabled: boolean;
  id: ID;
}

export interface MailingListFormValues {
  name: string;
  enabled: boolean;
  mailingLists: ID[];
  comment: Nullable<string>;
}

export interface MailingListPersonFormValues {
  mailingListPersonId: ID | string;
  mailingList: ID | string;
  person: Nullable<ID | string>;
  companyPerson?: Nullable<ID | string>;
}

export const getMailingListFormValues = (
  mailingList?: MailingList
): MailingListFormValues => ({
  name: mailingList?.name || '',
  enabled: mailingList?.enabled || false,
  mailingLists: [],
  comment: mailingList?.comment || '',
});
