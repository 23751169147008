import { Box } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { CompanyPersonList } from '../../components/company/person-list';
import HttpError from '../../components/http-error';
import LoadingContainer from '../../components/loading-container';
import { useApi } from '../../hooks';
import { apiRoutes } from '../../lib';
import { Company, CompanyPerson } from '../../model';

const Persons: FunctionComponent<{
  company: Company;
}> = ({ company }) => {
  const { data, isLoading, error } = useApi<CompanyPerson[]>(
    apiRoutes.companyPersons(company.id)
  );

  if (!data || isLoading) {
    return (
      <Box padding={3}>
        <LoadingContainer />
      </Box>
    );
  }

  if (error) {
    return <HttpError error={error} />;
  }

  return (
    <CompanyPersonList
      persons={data}
      company={company}
    />
  );
};

export default Persons;
