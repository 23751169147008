import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import {
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import React, { VoidFunctionComponent } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import Header from '../../components/header';
import { handleHookFormErrors, hasRole } from '../../helpers';
import {
  useCurrentUser,
  useTitle,
  useUserManagementValidationSchema,
} from '../../hooks';
import { apiRoutes, request, routes } from '../../lib';
import {
  ApiError,
  Role,
  User,
  UserFormValues,
  getUserFormValues,
} from '../../model';

const CreateUser: VoidFunctionComponent = () => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const currentUser = useCurrentUser();
  const validation = useUserManagementValidationSchema();
  const { t } = useTranslation();
  useTitle(t('Neuer Benutzer'));

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    setError,
  } = useForm<UserFormValues>({
    mode: 'all',
    resolver: yupResolver(validation),
    defaultValues: getUserFormValues(),
  });

  const selectableRoles = Object.keys(Role).filter((role) =>
    hasRole(currentUser, Role[role as keyof typeof Role])
  );

  const onSubmit = async (values: UserFormValues) => {
    await request<User>(apiRoutes.userCreate, 'post', values)
      .then((res) => {
        enqueueSnackbar(t('Benutzer wurde erfolgreich angelegt.'), {
          variant: 'success',
        });
        history.push(routes.user(res.data.id));
      })
      .catch((err: AxiosError<ApiError>) => {
        enqueueSnackbar(t('Benutzer konnte nicht gespeichert werden.'), {
          variant: 'error',
        });
        handleHookFormErrors(err, setError);
      });
  };

  const breadcrumbs = [
    { label: 'Home', link: routes.dashboard },
    { label: t('Benutzer'), link: routes.users },
  ];

  return (
    <Container maxWidth="md">
      <Header title={t('Neu')} breadcrumbs={breadcrumbs} />
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Controller
                  control={control}
                  name="username"
                  render={({ field, fieldState }) => (
                    <TextField
                      id="username"
                      label={t('Username')}
                      fullWidth
                      required
                      {...field}
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  control={control}
                  name="fullName"
                  render={({ field, fieldState }) => (
                    <TextField
                      id="fullName"
                      label={t('Name')}
                      fullWidth
                      {...field}
                      error={fieldState.isTouched && fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  control={control}
                  name="email"
                  render={({ field, fieldState }) => (
                    <TextField
                      id="email"
                      label={t('E-Mail')}
                      fullWidth
                      required
                      {...field}
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel>Roles</InputLabel>
                  <Controller
                    control={control}
                    name="roles"
                    render={({ field }) => (
                      <Select
                        id="roles"
                        fullWidth
                        {...field}
                        label={t('Rollen')}
                        value={field.value}
                        multiple={true}
                      >
                        {selectableRoles.map((role, key) => (
                          <MenuItem
                            key={key}
                            value={Role[role as keyof typeof Role]}
                          >
                            {role}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <Controller
                  control={control}
                  name="enabled"
                  render={({ field }) => (
                    <FormControlLabel
                      defaultChecked={field.value}
                      control={
                        <Checkbox
                          onChange={field.onChange}
                          id="enabled"
                          color="primary"
                        />
                      }
                      label={t<string>('Aktiv')}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Controller
                  control={control}
                  name="plainPassword"
                  render={({ field, fieldState }) => (
                    <TextField
                      id="plainPassword"
                      type="password"
                      label={t('Passwort')}
                      fullWidth
                      required
                      {...field}
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  control={control}
                  name="passwordRepeat"
                  render={({ field, fieldState }) => (
                    <TextField
                      id="passwordRepeat"
                      type="password"
                      label={t('Passwort wiederholen')}
                      fullWidth
                      required
                      {...field}
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions>
            <LoadingButton
              type="submit"
              size="medium"
              color="primary"
              loading={isSubmitting}
            >
              {t('Speichern')}
            </LoadingButton>
          </CardActions>
        </Card>
      </form>
    </Container>
  );
};

export default CreateUser;
