import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Card, CardActions, Container, Divider } from '@mui/material';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router';
import Header from '../../components/header';
import { PersonFormFields } from '../../components/person';
import { handleHookFormErrors } from '../../helpers';
import { useTitle } from '../../hooks';
import { usePersonValidationSchema } from '../../hooks/validation/person';
import { apiRoutes, request, routes } from '../../lib';
import { Person, PersonFormValues, getPersonFormValues } from '../../model';

const CreatePerson = () => {
  const { t } = useTranslation();
  useTitle(t('Neue Person'));
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const breadcrumbs = [
    { label: t('Home'), link: routes.dashboard },
    { label: t('Personen'), link: routes.persons },
    { label: t('Neu') },
  ];

  const validationSchema = usePersonValidationSchema();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    setError,
  } = useForm<PersonFormValues>({
    mode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues: getPersonFormValues(),
  });

  const mutation = useMutation(
    async (values: PersonFormValues) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { mailingLists, companies, ...payload } = values;
      return await request<Person>(apiRoutes.personCreate, 'post', payload);
    },
    {
      onSuccess: (res) => {
        enqueueSnackbar(t('Person wurde erfolgreich erstellt.'), {
          variant: 'success',
        });
        history.push(routes.person(res.data.id));
      },
      onError: (err: AxiosError) => {
        enqueueSnackbar(
          err.response?.data.message ||
            t('Person konnte nicht erstellt werden.'),
          {
            variant: 'error',
          }
        );
        handleHookFormErrors(err, setError);
      },
    }
  );

  return (
    <Container maxWidth="md">
      <form
        onSubmit={handleSubmit((values) => mutation.mutateAsync(values))}
        noValidate
      >
        <Header
          title={t('Personen')}
          breadcrumbs={breadcrumbs}
          actions={
            <LoadingButton
              type="submit"
              size="medium"
              color="primary"
              variant="contained"
              loading={isSubmitting}
            >
              {t('Speichern')}
            </LoadingButton>
          }
        />
        <Card>
          <PersonFormFields control={control} />
          <Divider />
          <CardActions>
            <LoadingButton
              type="submit"
              size="medium"
              color="primary"
              variant="contained"
              loading={isSubmitting}
            >
              {t('Speichern')}
            </LoadingButton>
          </CardActions>
        </Card>
      </form>
    </Container>
  );
};

export default CreatePerson;
