import { Edit, Visibility } from '@mui/icons-material';
import { Link, TableCell, TableRow } from '@mui/material';
import React, { VoidFunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { routes } from '../../lib';
import { Person } from '../../model';
import { CompactTableCell } from '../globals';
import { IconLink } from '../icon-link';
import { PaginationTable } from '../pagination';
import PaginationTableHeadCell from '../pagination/table-head-cell';

export const PersonList: VoidFunctionComponent<{ persons?: Person[] }> = ({
                                                                            persons,
                                                                          }) => {
  const { t } = useTranslation();

  return (
    <PaginationTable
      items={persons}
      renderRow={(person) => (
        <TableRow key={person.id} hover={true}>
          <CompactTableCell>{person.id}</CompactTableCell>
          <CompactTableCell>{person.salutationPosition}</CompactTableCell>
          <CompactTableCell>{person.titleBefore}</CompactTableCell>
          <CompactTableCell>{person.firstName}</CompactTableCell>
          <TableCell>
            <Link to={routes.person(person.id)} component={RouterLink}>
              {person.lastName}
            </Link>
          </TableCell>
          <TableCell>
            {person.companies && person.companies.length > 0 && (
              person.companies.map((personCompany, index) => {
                return (<p key={index}>{personCompany.email ? personCompany.email : ""}</p>)
              }))}
          </TableCell>
          <TableCell>
            {person.companies &&
              person.companies.map((personCompany) => (
                <Link
                  style={{ display: 'block' }}
                  to={routes.company(personCompany.company.id)}
                  key={personCompany.id}
                  component={RouterLink}
                >
                  {personCompany.company.name}{' '}
                  {personCompany.company.term || ''}
                </Link>
              ))}
          </TableCell>
          <CompactTableCell align={'right'}>
            <IconLink
              to={routes.person(person.id)}
              tooltip={t('Ansehen')}
              icon={<Visibility/>}
            />
            <IconLink
              to={routes.personEdit(person.id)}
              tooltip={t('Bearbeiten')}
              icon={<Edit/>}
            />
          </CompactTableCell>
        </TableRow>
      )}
    >
      <TableRow>
        <PaginationTableHeadCell sort="id">{t('ID')}</PaginationTableHeadCell>
        <PaginationTableHeadCell>
          {t('Anrede Funktion')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell>
          {t('Titel')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell sort="firstName">
          {t('Vorname')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell sort="lastName">
          {t('Nachname')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell>
          {t('E-Mail')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell>{t('Unternehmen')}</PaginationTableHeadCell>
        <TableCell width={110}/>
      </TableRow>
    </PaginationTable>
  );
};
