import { Add } from '@mui/icons-material';
import { Button } from '@mui/material';
import React, { VoidFunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { StyledContainer } from '../../components/globals';
import Guarded from '../../components/guarded';
import Header from '../../components/header';
import { ImportList } from '../../components/import';
import { PaginationCard } from '../../components/pagination';
import { config } from '../../config';
import { useDebounceState, useFilteredPaginationApi, useQueryState, UseQueryStateOptions, useTitle } from '../../hooks';
import { apiRoutes, routes } from '../../lib';
import { Dict, Role } from '../../model';
import { Import } from '../../model/import';
import { FilterInputSearch } from "../../components/filter";

const ImportOverview: VoidFunctionComponent = () => {
  const { t } = useTranslation();
  useTitle(t('Imports'));

  const breadcrumbs = [
    { label: t('Home'), link: routes.dashboard },
    { label: t('Imports') },
  ];

  const queryStateOptions: UseQueryStateOptions = { action: 'replace' };
  const [search, setFilterSearch] = useQueryState(
    '',
    'search',
    queryStateOptions
  );
  const [inputSearch, setInputSearch] = useDebounceState(
    search,
    setFilterSearch
  );

  const { context } = useFilteredPaginationApi<Dict, Import>(
    apiRoutes.imports,
    { search },
    'id',
    'desc',
    config.pageSize
  );

  return (
    <StyledContainer data-test="import-content">
      <Header
        title={t('Imports')}
        breadcrumbs={breadcrumbs}
        actions={
          <Guarded requiredRole={Role.PDN}>
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to={routes.importCreate}
              startIcon={<Add/>}
            >
              {t('Neuer Import')}
            </Button>
          </Guarded>
        }
      />
      <PaginationCard
        context={context}
        hasFilters={!!search}
        resetFilters={() => {
          setFilterSearch('');
        }}
        filters={
          <>
            <FilterInputSearch
              value={inputSearch}
              placeholder={t('nach Dateiname')}
              label={t('Suche')}
              onChange={setInputSearch}
            />
          </>
        }
      >
        <ImportList/>
      </PaginationCard>
    </StyledContainer>
  );
};

export default ImportOverview;
