import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Card, CardActions, Container, Divider } from '@mui/material';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import React, { VoidFunctionComponent, useEffect } from 'react';
import { UseFormSetError, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import { Link, useParams } from 'react-router-dom';
import Header from '../../components/header';
import HttpError from '../../components/http-error';
import LoadingButton from '../../components/loading-button';
import LoadingContainer from '../../components/loading-container';
import {
  MailingListFormFields,
  MailingListRecipientList,
} from '../../components/mailing-list';
import { handleHookFormErrors } from '../../helpers';
import { useTitle } from '../../hooks';
import { useMailingListValidationSchema } from '../../hooks/validation/mailing-list';
import {
  apiRoutes,
  request,
  routes,
  useMailingListDetailsApi,
} from '../../lib';
import {
  ApiError,
  MailingList,
  MailingListFormValues,
  getMailingListFormValues,
} from '../../model';

const EditMailingList: VoidFunctionComponent = () => {
  const { t } = useTranslation();
  const { mailingListId } = useParams<{ mailingListId: string }>();
  const { isLoading, error, data } = useMailingListDetailsApi(+mailingListId);
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  useTitle(data?.name || t('Verteiler bearbeiten'));
  const history = useHistory();

  const validationSchema = useMailingListValidationSchema();

  const {
    handleSubmit,
    reset,
    setError,
    control,
    formState: { isSubmitting, isValid },
    setValue,
  } = useForm<MailingListFormValues>({
    mode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues: getMailingListFormValues(data),
  });

  useEffect(() => reset(getMailingListFormValues(data)), [reset, data]);

  const onSubmit = async (
    values: MailingListFormValues,
    setError: UseFormSetError<MailingListFormValues>
  ) => {
    if (!data) {
      return;
    }
    await request<MailingList>(apiRoutes.mailingList(data.id), 'put', values)
      .then(() => {
        queryClient.invalidateQueries(apiRoutes.mailingListPersons(data.id));
        setValue('mailingLists', []);
        enqueueSnackbar(t('Verteiler wurde erfolgreich bearbeitet.'), {
          variant: 'success',
        });
        history.push(routes.mailingList(data.id));
      })
      .catch((err: AxiosError<ApiError>) => {
        enqueueSnackbar(err.response?.data.message, { variant: 'error' });
        handleHookFormErrors(err, setError);
      });
  };

  if (error) {
    return (
      <HttpError
        error={error}
        actions={
          <Button component={Link} to={routes.mailingLists}>
            {t('Zurück zu Verteilern')}
          </Button>
        }
      />
    );
  }

  if (isLoading || !data) {
    return <LoadingContainer />;
  }

  const breadcrumbs = [
    { label: t('Home'), link: routes.dashboard },
    { label: t('Verteiler'), link: routes.mailingLists },
    { label: data.name, link: routes.mailingList(data.id) },
    { label: t('Bearbeiten') },
  ];

  return (
    <Container maxWidth="xl">
      <Header title="" breadcrumbs={breadcrumbs} />
      <form onSubmit={handleSubmit((values) => onSubmit(values, setError))}>
        <Card style={{ marginBottom: '2em' }}>
          <MailingListFormFields control={control} />
          <Divider />
          <CardActions>
            <LoadingButton
              type="submit"
              size="medium"
              color="primary"
              disabled={!isValid}
              variant="contained"
              loading={isSubmitting}
            >
              {t('Speichern')}
            </LoadingButton>
          </CardActions>
        </Card>
      </form>
      <MailingListRecipientList mailingList={data} edit={true} />
    </Container>
  );
};

export default EditMailingList;
