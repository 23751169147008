import { yupResolver } from '@hookform/resolvers/yup';
import { Description } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import React, { VoidFunctionComponent, useContext, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import PromptIfDirty from '../../components/prompt-if-dirty';
import AppContext from '../../context/app';
import { buildFormData, handleHookFormErrors } from '../../helpers';
import { useCredentialValidationSchema, useCurrentUser } from '../../hooks';
import { apiRoutes, request } from '../../lib';
import { Nullable, User } from '../../model';
import {useTranslation} from "react-i18next";

interface FormValues {
  avatarUpload: Nullable<File>;
  fullName: string;
  email: string;
  deleteAvatar: boolean;
}

const GeneralProfileSettings: VoidFunctionComponent = () => {
  const { setUser } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const user = useCurrentUser();
  const avatarRef = useRef<Nullable<HTMLInputElement>>(null);
  const validation = useCredentialValidationSchema();
  const {t} = useTranslation();
  const { control, handleSubmit, reset, formState, setError } =
    useForm<FormValues>({
      mode: 'all',
      resolver: yupResolver(validation),
      defaultValues: {
        avatarUpload: null,
        fullName: user.fullName ? user.fullName : '',
        email: user.email,
        deleteAvatar: false,
      },
    });

  const resetForm = () => {
    reset({
      avatarUpload: null,
      fullName: user.fullName ? user.fullName : '',
      email: user.email,
      deleteAvatar: false,
    });
    if (!avatarRef.current) {
      return;
    }
    avatarRef.current.value = '';
  };

  const onSubmit = async (values: FormValues) => {
    const formData = buildFormData<FormValues>(
      {
        fullName: values.fullName,
        email: values.email,
      },
      (formData) => {
        if (!values.deleteAvatar && values.avatarUpload) {
          formData.append('avatarUpload', values.avatarUpload);
        }
      }
    );
    await request<User>(apiRoutes.profile.me, 'post', formData)
      .then((res) => {
        enqueueSnackbar('Profile successfully updated', { variant: 'success' });
        setUser(res.data);
        resetForm();
      })
      .catch((err: AxiosError) => {
        enqueueSnackbar('Profile update failed', { variant: 'error' });
        handleHookFormErrors(err, setError);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <PromptIfDirty formState={formState} />
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={8}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="username"
                    name="username"
                    label={t('Benutzername')}
                    value={user.username}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="fullName"
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        id="fullName"
                        label={t('Name')}
                        fullWidth
                        error={fieldState.isTouched && fieldState.invalid}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="email"
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        id="email"
                        label={t('E-Mail')}
                        fullWidth
                        error={fieldState.isTouched && fieldState.invalid}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Card variant="outlined">
                <CardHeader subheader={t('Avatar')} />
                {user.avatar && (
                  <CardMedia
                    image={user.avatar}
                    style={{ paddingTop: '56.25%' }} // 16:9
                  />
                )}
                <CardContent>
                  <Controller
                    control={control}
                    name="avatarUpload"
                    render={({ field, fieldState }) => (
                      <>
                        <input
                          {...field}
                          value=""
                          accept="image/*"
                          id="avatarUpload"
                          hidden
                          ref={avatarRef}
                          type="file"
                          onChange={(e) =>
                            field.onChange(e.currentTarget.files![0])
                          }
                        />
                        <label htmlFor="avatarUpload">
                          <Button
                            variant="contained"
                            component="span"
                            color="primary"
                          >
                            <Description /> Choose
                          </Button>
                        </label>
                        <Box m={1} display="inline">
                          <Typography display="block" variant="caption">
                            {field.value?.name}
                          </Typography>
                        </Box>
                        {fieldState.error && (
                          <FormHelperText error={true}>
                            {fieldState.error?.message}
                          </FormHelperText>
                        )}
                      </>
                    )}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <LoadingButton
            type="submit"
            size="medium"
            color="primary"
            disabled={!formState.isValid}
            loading={formState.isSubmitting}
          >
            {t('Speichern')}
          </LoadingButton>
          <Button
            type="reset"
            size="medium"
            onClick={resetForm}
            disabled={!formState.isDirty || formState.isSubmitting}
          >
            {t('Zurücksetzen')}
          </Button>
        </CardActions>
      </Card>
    </form>
  );
};

export default GeneralProfileSettings;
