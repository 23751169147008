import { CopyAll, Delete, Edit } from '@mui/icons-material';
import {
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  Link,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router';
import { Link as RouterLink, useParams } from 'react-router-dom';
import FormattedDate from '../../components/formatted-date';
import { StyledCardTitle } from '../../components/globals';
import { GridData } from '../../components/grid-data';
import Guarded from '../../components/guarded';
import Header from '../../components/header';
import HttpError from '../../components/http-error';
import LoadingButton from '../../components/loading-button';
import LoadingContainer from '../../components/loading-container';
import { ModifiedEntity } from '../../components/modified-entity';
import { useTitle } from '../../hooks';
import { apiRoutes, request, routes, useTariffDetailsApi } from '../../lib';
import { DateFormat, Role, Tariff } from '../../model';

const TariffDetails = () => {
  const { t } = useTranslation();
  const { tariffId } = useParams<{ tariffId: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, error, data } = useTariffDetailsApi(+tariffId);
  const history = useHistory();
  useTitle(data?.name || t('Tariflisten Details'));

  const deleteMutation = useMutation(
    async () => {
      if (!data) {
        return;
      }

      return await request(apiRoutes.tariff(data.id), 'delete');
    },
    {
      onSuccess: () => {
        enqueueSnackbar(t('Tarifliste wurde erfolgreich gelöscht.'), {
          variant: 'success',
        });
        history.push(routes.tariffs);
      },
      onError: () => {
        enqueueSnackbar(t('Tarifliste konnte nicht gelöscht werden.'), {
          variant: 'error',
        });
      },
    }
  );

  const copyMutation = useMutation(
    async () => {
      if (!data) {
        return;
      }

      return await request<Tariff>(apiRoutes.tariffDuplicate(data.id), 'post');
    },
    {
      onSuccess: (res) => {
        enqueueSnackbar(t('Tarifliste wurde erfolgreich kopiert.'), {
          variant: 'success',
        });
        if (!res) {
          return;
        }
        history.push(routes.tariff(res.data.id));
      },
      onError: () => {
        enqueueSnackbar(t('Tarifliste konnte nicht gelöscht werden.'), {
          variant: 'error',
        });
      },
    }
  );

  if (error) {
    return (
      <HttpError
        error={error}
        actions={
          <Button component={RouterLink} to={routes.tariffs}>
            {t('Zurück zu Tariflisten')}
          </Button>
        }
      />
    );
  }

  if (isLoading || !data) {
    return <LoadingContainer />;
  }

  const detailBreadcrumbs = [
    { label: t('Home'), link: routes.dashboard },
    { label: t('Tariflisten'), link: routes.tariffs },
  ];

  return (
    <Container maxWidth="md">
      <Header
        title={data.name}
        breadcrumbs={detailBreadcrumbs}
        search={{ route: routes.tariffs, param: 'search' }}
        actions={
          <>
            <Guarded requiredRole={Role.PDN}>
              <LoadingButton
                variant="outlined"
                color="primary"
                loading={copyMutation.isLoading}
                onClick={() => copyMutation.mutateAsync()}
                startIcon={<CopyAll />}
              >
                {t('Kopieren')}
              </LoadingButton>{' '}
              <Button
                variant="contained"
                color="primary"
                component={RouterLink}
                to={routes.tariffEdit(data.id)}
                startIcon={<Edit />}
              >
                {t('Bearbeiten')}
              </Button>{' '}
              <LoadingButton
                variant="contained"
                color="secondary"
                onClick={() => {
                  if (
                    !window.confirm(
                      t(
                        `Wollen Sie die Tarifliste {{name}} wirklich löschen?`,
                        {
                          name: data.name,
                        }
                      )
                    )
                  ) {
                    return;
                  }
                  deleteMutation.mutateAsync();
                }}
                startIcon={<Delete />}
                loading={deleteMutation.isLoading}
              >
                {t('Löschen')}
              </LoadingButton>
            </Guarded>
            <ModifiedEntity entity={data} />
          </>
        }
      />
      <Card>
        <Grid container>
          <Grid item xs={12} md={6}>
            <CardContent>
              <StyledCardTitle variant="h6" color="secondary" gutterBottom>
                {t('Stammdaten')}
              </StyledCardTitle>
              <Grid container spacing={3}>
                <GridData xs={12} label={t<string>('Name')}>
                  {data.name}
                </GridData>
                <GridData xs={12} md={6} label={t<string>('Gültig von')}>
                  <FormattedDate
                    date={data.validFrom}
                    format={DateFormat.Default}
                  />
                </GridData>
                <GridData xs={12} md={6} label={t<string>('Gültig bis')}>
                  <FormattedDate
                    date={data.validTo}
                    format={DateFormat.Default}
                  />
                </GridData>
                <GridData xs={12} label={t<string>('APA Abrechnungsmodus')}>
                  {data.apa} %
                </GridData>
              </Grid>
            </CardContent>
            <Divider />
            <CardContent>
              <StyledCardTitle variant="h6" color="secondary" gutterBottom>
                {t('Tarifgruppen')}
              </StyledCardTitle>
              {data.tariffGroups.map((tariffGroup) => (
                <Grid container spacing={3} key={tariffGroup.id}>
                  <GridData xs={4}>{tariffGroup.groupKey}</GridData>
                  <GridData xs={4}>{tariffGroup.label}</GridData>
                  <GridData xs={4} textAlign="right">
                    {tariffGroup.price} €
                  </GridData>
                </Grid>
              ))}
            </CardContent>
          </Grid>
          <Divider
            orientation={'vertical'}
            flexItem
            style={{ marginRight: '-1px' }}
          />
          <Grid item xs={12} md={6}>
            <CardContent>
              <StyledCardTitle variant="h6" color="secondary" gutterBottom>
                {t('Medientitel')}
              </StyledCardTitle>
              <Grid container spacing={3}>
                <GridData xs={12}>
                  {data.media.map((medium) => (
                    <Link
                      component={RouterLink}
                      to={routes.medium(medium.id)}
                      key={medium.id}
                      style={{ display: 'block' }}
                    >
                      {medium.name}
                    </Link>
                  ))}
                </GridData>
              </Grid>
            </CardContent>
            <Divider />
            <CardContent>
              <StyledCardTitle variant="h6" color="secondary" gutterBottom>
                {t('Sonstiges')}
              </StyledCardTitle>
              <Grid container spacing={3}>
                <GridData xs={12} label={t<string>('Interner Kommentar')}>
                  {data.comment}
                </GridData>
              </Grid>
            </CardContent>
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
};

export default TariffDetails;
