import {
  Addressable,
  Attachment,
  Blameable,
  Commentable,
  DateTimeString,
  EmploymentStatus,
  ID,
  Nullable, PressPass,
  Salutation,
  Timestampable,
} from './index';

export enum PressPassApplicationStatus {
  New = 'new',
  UpdatedPhoto = 'updated_photo',
  UpdatedPhotoDone = 'updated_photo_done',
  Done = 'done',
  Denied = 'denied',
}

export interface PressPassApplication
  extends Blameable,
    Commentable,
    Timestampable,
    Addressable {
  id: ID;
  salutation: string;
  firstName: string;
  lastName: string;
  titleAfter: Nullable<string>;
  titleBefore: Nullable<string>;
  email: string;
  phone: Nullable<string>;
  birthdate: DateTimeString;
  birthplace: string;
  nationality: string;
  medium: string;
  mediumAddress: string;
  position: string;
  passNumber: string;
  pressPassId: number;
  employmentStatus: string;
  carBadge: boolean;
  confirmationPrivacy: boolean;
  confirmationMoral: boolean;
  comment: string;
  status: PressPassApplicationStatus;
  type: string;
  attachments: Attachment[];
  membership: Nullable<string>;
  token: Nullable<string>;
}

export interface PressPassApplicationWithPressPass extends PressPassApplication {
  pressPass: PressPass;
}

export interface PressPassApplicationFilters {
  search: string;
  filterStatus: string;
  filterType: string;
  membership: string;
}

export interface PressPassApplicationFormValues
  extends Omit<
    PressPassApplication,
    | 'id'
    | 'attachments'
    | 'status'
    | 'token'
    | keyof Timestampable
    | keyof Blameable
  > {
  attachments: Array<{
    documentId?: number;
    name: string;
    size: number;
    file?: File;
  }>;
}

export const getPressPassApplicationFormValues = (
  host: string
): PressPassApplicationFormValues => ({
  salutation: Salutation.Female,
  firstName: '',
  lastName: '',
  titleAfter: '',
  titleBefore: '',
  email: '',
  phone: '',
  birthdate: '',
  birthplace: '',
  nationality: '',
  medium: '',
  mediumAddress: '',
  position: '',
  employmentStatus: EmploymentStatus.Employed,
  comment: '',
  carBadge: false,
  confirmationPrivacy: false,
  confirmationMoral: false,
  attachments: [],
  membership: host,
  passNumber: '',
  pressPassId: 0,
  type: '',
});

export interface PressPassApplicationDocumentsFormValues {
  membership: string;
  email: string;
  attachments: Array<{
    documentId?: number;
    name: string;
    size: number;
    file?: File;
  }>;
  confirmationPrivacy: boolean;
  confirmationMoral: boolean;
}

export const getPressPassApplicationDocumentsFormValues = (
  host: string
): PressPassApplicationDocumentsFormValues => ({
  membership: host,
  email: '',
  attachments: [],
  confirmationPrivacy: false,
  confirmationMoral: false,
});

export interface PressPassApplicationUpdateFormValues {
  salutation: string;
  firstName: string;
  lastName: string;
  email: string;
  comment: string;
  membership: string;
  passNumber: string;
  birthdate: string;
  attachments: Array<{
    documentId?: number;
    name: string;
    size: number;
    file?: File;
  }>;
  confirmationPrivacy: boolean;
  confirmationMoral: boolean;
}

export const getPressPassApplicationUpdateFormValues = (
  host: string
): PressPassApplicationUpdateFormValues => ({
  salutation: Salutation.Female,
  firstName: '',
  lastName: '',
  email: '',
  comment: '',
  membership: host,
  passNumber: '',
  birthdate: '',
  attachments: [],
  confirmationPrivacy: false,
  confirmationMoral: false,
});
