import { Chip, Grid, Link } from '@mui/material';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';
import { useCurrentUser } from '../../hooks';
import { routes } from '../../lib';
import { Role, User as UserModel } from '../../model/user';
import UserAvatar from '../user/avatar';

const StyledChip = styled(Chip)`
  margin-left: 0.25em;
`;

const UserBadge: FunctionComponent<{ user: UserModel }> = ({ user }) => {
  const currentUser = useCurrentUser();
  const isAdmin =
    user.roles.includes(Role.Admin) || user.roles.includes(Role.SuperAdmin);
  const { t } = useTranslation();

  return (
    <Grid container alignItems="center" spacing={1}>
      <Grid item>
        <UserAvatar user={user} />
      </Grid>
      <Grid item>
        <Link to={routes.user(user.id)} component={RouterLink}>
          {user.username}
        </Link>
        {` `}
        {isAdmin && (
          <StyledChip label={t('Admin')} color={`primary`} size={`small`} />
        )}
        {!user.enabled && (
          <StyledChip label={t('Inaktiv')} color={`secondary`} size={`small`} />
        )}
        {currentUser.id === user.id && (
          <StyledChip label={t('Du!')} color={`default`} size={`small`} />
        )}
      </Grid>
    </Grid>
  );
};

export default UserBadge;
