import { Delete, Edit } from '@mui/icons-material';
import {
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { StyledCardTitle } from '../../components/globals';
import { GridData } from '../../components/grid-data';
import Guarded from '../../components/guarded';
import Header from '../../components/header';
import HttpError from '../../components/http-error';
import LoadingContainer from '../../components/loading-container';
import { ModifiedEntity } from '../../components/modified-entity';
import { useTitle } from '../../hooks';
import { apiRoutes, request, routes, usePositionDetailsApi } from '../../lib';
import { Role } from '../../model';

const PositionDetails = () => {
  const { t } = useTranslation();
  const { positionId } = useParams<{ positionId: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, error, data } = usePositionDetailsApi(+positionId);
  const history = useHistory();
  useTitle(data?.title || t('Funktionen Details'));

  if (error) {
    return (
      <HttpError
        error={error}
        actions={
          <Button component={RouterLink} to={routes.positions}>
            {t('Zurück zu Funktionen')}
          </Button>
        }
      />
    );
  }

  if (isLoading || !data) {
    return <LoadingContainer />;
  }

  const detailBreadcrumbs = [
    { label: t('Home'), link: routes.dashboard },
    { label: t('Funktionen'), link: routes.positions },
  ];

  const handleDelete = () => {
    const confirm = window.confirm(
      t(`Wollen Sie die Funktion {{name}} wirklich löschen?`, {
        name: data.title,
      })
    );
    if (!confirm) {
      return;
    }
    request(apiRoutes.position(data.id), 'delete')
      .then(() => {
        enqueueSnackbar(t('Funktion wurde erfolgreich gelöscht.'), {
          variant: 'success',
        });
        history.push(routes.positions);
      })
      .catch(() =>
        enqueueSnackbar(t('Funktion konnte nicht gelöscht werden.'), {
          variant: 'error',
        })
      );
  };

  return (
    <Container maxWidth="md">
      <Header
        title={data.title}
        breadcrumbs={detailBreadcrumbs}
        search={{ route: routes.positions, param: 'search' }}
        actions={
          <>
            <Guarded requiredRole={Role.Admin}>
              <Button
                variant="contained"
                color="primary"
                component={RouterLink}
                to={routes.positionEdit(data.id)}
                startIcon={<Edit />}
              >
                {t('Bearbeiten')}
              </Button>{' '}
              <Button
                variant="contained"
                color="secondary"
                onClick={handleDelete}
                startIcon={<Delete />}
              >
                {t('Löschen')}
              </Button>
            </Guarded>
            <ModifiedEntity entity={data} />
          </>
        }
      />
      <Card>
        <CardContent>
          <StyledCardTitle variant="h6" color="secondary" gutterBottom>
            {t('Informationen')}
          </StyledCardTitle>
          <Grid container spacing={3}>
            <GridData xs={12} label={t<string>('Titel')}>
              {data.title}
            </GridData>
          </Grid>
        </CardContent>
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <GridData xs={12} label={t<string>('Interner Kommentar')}>
              {data.comment}
            </GridData>
          </Grid>
        </CardContent>
      </Card>
    </Container>
  );
};

export default PositionDetails;
