import { Delete, IndeterminateCheckBox } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import React, { useState, VoidFunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useApi } from '../../hooks';
import { apiRoutes, request } from '../../lib';
import { Badge, DateFormat, ID, PressPass } from '../../model';
import FormattedDate from '../formatted-date';
import { StyledCheckbox } from '../globals';
import HttpError from '../http-error';
import LoadingContainer from '../loading-container';
import { useMutation, useQueryClient } from 'react-query';
import LoadingIconButton from "../loading-icon-button";
import { useSnackbar } from "notistack";

const PressPassMarkHistoryDialog: VoidFunctionComponent<{
  open: boolean;
  onClose: () => void;
  pressPass: PressPass;
}> = ({ open, onClose, pressPass }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const [deletingBadge, setDeletingBadge] = useState<ID[]>([]);
  const { isLoading, error, data } = useApi<Badge[]>(
    apiRoutes.pressPassBadges(pressPass.id)
  );

  const deletePressPassBadgeMutation = useMutation(
    async (badge: Badge) => {
      setDeletingBadge([...deletingBadge, badge.id]);
      return {
        id: badge.id,
        response: await request<Badge>(
          apiRoutes.pressPassBadgeDelete(badge.id),
          'delete'
        ),
      };
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(apiRoutes.pressPassBadges(pressPass.id));
        enqueueSnackbar(t('Marke wurde erfolgreich entfernt..'), {
          variant: 'success',
        });
      },
      onError: () => {
        enqueueSnackbar(t('Marke konnte nicht entfernt werden.'), {
          variant: 'error',
        });
      },
      onSettled: (res) => {
        if (!res) {
          return;
        }
        setDeletingBadge([
          ...deletingBadge.filter((badge) => badge !== res.id),
        ]);
      },
    }
  );

  return (
    <Dialog open={open} onClose={() => onClose()}>
      <DialogTitle>{t('Marken Historie')}</DialogTitle>
      <DialogContent>
        {error && <HttpError error={error}/>}
        {isLoading && <LoadingContainer/>}
        {data && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('Jahr')}</TableCell>
                <TableCell>{t('Autoschild')}</TableCell>
                <TableCell>{t('Erstellt am')}</TableCell>
                <TableCell>{t('Erstellt von')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((badge) => (
                <TableRow key={badge.id}>
                  <TableCell component="th" scope="row">
                    {badge.year}
                  </TableCell>
                  <TableCell>
                    <StyledCheckbox
                      readOnly={true}
                      name="carBadge"
                      color="primary"
                      checked={badge.carBadge}
                      disableRipple={true}
                      style={{ cursor: 'auto' }}
                      icon={
                        !badge.carBadge ? <IndeterminateCheckBox/> : undefined
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <FormattedDate
                      date={badge.createdAt}
                      format={DateFormat.Default}
                    />
                  </TableCell>
                  <TableCell>{badge.createdBy}</TableCell>
                  <TableCell>
                    <LoadingIconButton
                      type="button"
                      size="small"
                      loading={
                        deletePressPassBadgeMutation.isLoading &&
                        !!deletingBadge.find(
                          (review) => review === badge.id
                        )
                      }
                      onClick={() => {
                        if (
                          !window.confirm(
                            t(`Wollen Sie wirklich die Marke für das Jahr ${badge.year} wirklich löschen?`)
                          )
                        ) {
                          return;
                        }
                        deletePressPassBadgeMutation.mutate(badge);
                      }}
                    >
                      <Delete/>
                    </LoadingIconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()}>{t('Schließen')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default PressPassMarkHistoryDialog;
