import { Clear } from '@mui/icons-material';
import { Card, CardContent, IconButton } from '@mui/material';
import React, { FunctionComponent, PropsWithChildren, ReactNode } from 'react';
import { FilterFormGroup } from '../globals';
import { PaginationContextValue } from './context';
import { PaginationProvider } from './provider';
import { PaginationTablePagination } from './table-pagination';

interface Props {
  context: PaginationContextValue<any, any>;
  filters: ReactNode;
  hasFilters: boolean;
  resetFilters: () => void;
  header?: ReactNode;
  footer?: ReactNode;
}

export const PaginationCard: FunctionComponent<PropsWithChildren<Props>> = ({
  context,
  filters,
  hasFilters,
  resetFilters,
  header,
  footer,
  children
}) => {
  return (
    <PaginationProvider context={context}>
      <Card>
        {header}
        <CardContent>
          <FilterFormGroup row>
            {filters}
            {hasFilters && (
              <IconButton size="small" onClick={resetFilters}>
                <Clear />
              </IconButton>
            )}
          </FilterFormGroup>
        </CardContent>
        {children}
        <PaginationTablePagination />
        {footer}
      </Card>
    </PaginationProvider>
  );
};
