import { Delete, Edit, Visibility, VisibilityOff } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  IconButton,
} from '@mui/material';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { StyledCardTitle } from '../../components/globals';
import { GridData } from '../../components/grid-data';
import Guarded from '../../components/guarded';
import Header from '../../components/header';
import HttpError from '../../components/http-error';
import LoadingContainer from '../../components/loading-container';
import { ModifiedEntity } from '../../components/modified-entity';
import { useTitle } from '../../hooks';
import { apiRoutes, request, routes, useSignatureDetailsApi } from '../../lib';
import { Role } from '../../model';

const SignatureDetails = () => {
  const { t } = useTranslation();
  const { signatureId } = useParams<{ signatureId: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, error, data } = useSignatureDetailsApi(+signatureId);
  const history = useHistory();
  const [renderHtml, setRenderHtml] = useState(true);

  useTitle(data?.name || t('Signatur Details'));

  const detailBreadcrumbs = [
    { label: t('Home'), link: routes.dashboard },
    { label: t('Signaturen'), link: routes.signatures },
  ];

  const mutation = useMutation(
    async () => {
      if (!data) {
        return;
      }
      return await request(apiRoutes.signature(data.id), 'delete');
    },
    {
      onSuccess: () => {
        enqueueSnackbar(t('Signatur wurde erfolgreich gelöscht.'), {
          variant: 'success',
        });
        history.push(routes.signatures);
      },
      onError: (err: AxiosError) => {
        enqueueSnackbar(
          err.response?.data.message ||
            t('Signatur konnte nicht gelöscht werden.'),
          {
            variant: 'error',
          }
        );
      },
    }
  );

  if (error) {
    return (
      <HttpError
        error={error}
        actions={
          <Button component={RouterLink} to={routes.signatures}>
            {t('Zurück zu Signaturen')}
          </Button>
        }
      />
    );
  }

  if (isLoading || !data) {
    return <LoadingContainer />;
  }

  return (
    <Container maxWidth="md">
      <Header
        title={data.name}
        breadcrumbs={detailBreadcrumbs}
        actions={
          <>
            <Guarded requiredRole={Role.Admin}>
              <Button
                variant="contained"
                color="primary"
                component={RouterLink}
                to={routes.signatureEdit(data.id)}
                startIcon={<Edit />}
              >
                {t('Bearbeiten')}
              </Button>{' '}
              <LoadingButton
                variant="contained"
                color="secondary"
                loading={mutation.isLoading}
                onClick={() => {
                  const confirm = window.confirm(
                    t(`Wollen Sie die Signatur {{name}} wirklich löschen?`, {
                      name: data.name,
                    })
                  );
                  if (!confirm) {
                    return;
                  }
                  mutation.mutateAsync();
                }}
                startIcon={<Delete />}
              >
                {t('Löschen')}
              </LoadingButton>
            </Guarded>
            <ModifiedEntity entity={data} />
          </>
        }
      />
      <Card>
        <CardContent>
          <StyledCardTitle variant="h6" color="secondary" gutterBottom>
            {t('Signatur')}
          </StyledCardTitle>
          <Grid container spacing={3}>
            <GridData xs={12} md={6} label={t<string>('Name')}>
              {data.name}
            </GridData>
            <GridData xs={12} md={6} label={t<string>('E-Mail')}>
              {data.email}
            </GridData>
            <GridData
              xs={12}
              mode="richtext"
              label={
                <>
                  {t<string>('HTML')}
                  <IconButton onClick={() => setRenderHtml(!renderHtml)}>
                    {renderHtml ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </>
              }
            >
              <div style={{ overflow: 'auto', maxHeight: '300px' }}>
                {renderHtml ? (
                  <div dangerouslySetInnerHTML={{ __html: data.html }} />
                ) : (
                  <pre>{data.html}</pre>
                )}
              </div>
            </GridData>
          </Grid>
        </CardContent>
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <GridData xs={12} label={t<string>('Interner Kommentar')}>
              {data.comment}
            </GridData>
          </Grid>
        </CardContent>
      </Card>
    </Container>
  );
};

export default SignatureDetails;
