import { Flare, NightsStay, PersonPin } from '@mui/icons-material';
import {
  AppBar,
  Badge,
  IconButton,
  LinearProgress,
  Toolbar,
  Tooltip,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { VoidFunctionComponent, useContext } from 'react';
import { useIsFetching } from 'react-query';
import styled from 'styled-components';
import AppContext from '../../context/app';
import { apiRoutes, request } from '../../lib';
import Search from '../search';
import UserDropdown from '../user/dropdown';

const StyledToolbar = styled(Toolbar)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`;

const StyledMenuContainer = styled.div`
  text-align: right;
`;

const TopBar: VoidFunctionComponent = () => {
  const appContext = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const fetching = useIsFetching();

  const exitImpersonate = () => {
    request(apiRoutes.profile.me, 'get', {}, { _switch_user: '_exit' })
      .then(() => window.location.reload())
      .catch(() =>
        enqueueSnackbar('Failed to exit impersonation', { variant: 'error' })
      );
  };

  return (
    <>
      <AppBar position="static">
        <StyledToolbar>
          <div>
            <Search />
          </div>
          <StyledMenuContainer>
            {appContext.user?.impersonator && (
              <Tooltip
                title={
                  <>
                    Impersonating <strong>{appContext.user.username}</strong> as{' '}
                    <strong>{appContext.user.impersonator.username}</strong>.
                    Click to exit impersonation.
                  </>
                }
              >
                <IconButton
                  color="inherit"
                  onClick={exitImpersonate}
                  size="large"
                >
                  <Badge badgeContent="!" color="secondary">
                    <PersonPin />
                  </Badge>
                </IconButton>
              </Tooltip>
            )}
            {appContext.materialTheme === 'light' ? (
              <IconButton
                aria-label="darkness awaits"
                color={`inherit`}
                onClick={() => appContext.setMaterialTheme('dark')}
                size="large"
              >
                <NightsStay />
              </IconButton>
            ) : (
              <IconButton
                aria-label="let there be light"
                color={`inherit`}
                onClick={() => appContext.setMaterialTheme('light')}
                size="large"
              >
                <Flare />
              </IconButton>
            )}
            <UserDropdown />
          </StyledMenuContainer>
        </StyledToolbar>
      </AppBar>
      {fetching ? <LinearProgress /> : <div style={{ height: '4px' }} />}
    </>
  );
};

export default TopBar;
