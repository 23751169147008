import { Add } from '@mui/icons-material';
import { Button } from '@mui/material';
import React, { VoidFunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FilterInputSearch } from '../../components/filter';
import { StyledContainer } from '../../components/globals';
import Header from '../../components/header';
import { PaginationCard } from '../../components/pagination';
import { PositionList } from '../../components/position';
import { config } from '../../config';
import {
  UseQueryStateOptions,
  useDebounceState,
  useFilteredPaginationApi,
  useQueryState,
  useTitle,
} from '../../hooks';
import { apiRoutes, routes } from '../../lib';
import { Position, PositionFilters } from '../../model/position';

const PositionOverview: VoidFunctionComponent = () => {
  const { t } = useTranslation();
  useTitle(t('Funktionen'));

  const breadcrumbs = [
    { label: t('Home'), link: routes.dashboard },
    { label: t('Funktionen') },
  ];

  const queryStateOptions: UseQueryStateOptions = { action: 'replace' };
  const [search, setFilterSearch] = useQueryState(
    '',
    'search',
    queryStateOptions
  );
  const [inputSearch, setInputSearch] = useDebounceState(
    search,
    setFilterSearch
  );

  const { context } = useFilteredPaginationApi<PositionFilters, Position>(
    apiRoutes.positions,
    { search },
    'title',
    'asc',
    config.pageSize
  );

  return (
    <StyledContainer data-test="position-content">
      <Header
        title={t('Funktionen')}
        breadcrumbs={breadcrumbs}
        actions={
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to={routes.positionCreate}
            startIcon={<Add />}
          >
            {t('Neue Funktion')}
          </Button>
        }
      />
      <PaginationCard
        context={context}
        hasFilters={!!search}
        resetFilters={() => {
          setFilterSearch('');
        }}
        filters={
          <FilterInputSearch
            value={inputSearch}
            label={t('Suche')}
            onChange={setInputSearch}
          />
        }
      >
        <PositionList />
      </PaginationCard>
    </StyledContainer>
  );
};

export default PositionOverview;
