import { Chip } from '@mui/material';
import { green } from '@mui/material/colors';
import React, { VoidFunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Company } from '../../model';

const StyledChip = styled(Chip)`
  margin: 1px;
`;

export const CompanyType: VoidFunctionComponent<{ company: Company }> = ({
  company,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {company.customer && (
        <StyledChip label={t('Kunde')} size="small" color="primary" />
      )}
      {company.publisher && (
        <StyledChip label={t('Verlag')} size="small" color="secondary" />
      )}
      {company.advocacy && (
        <StyledChip
          label={t('Interessensvertretung')}
          style={{ backgroundColor: green[500], color: 'white' }}
          size="small"
        />
      )}
      {company.partner && <StyledChip label={t('Partner')} size="small" />}
    </>
  );
};
