import { AttachFile, Delete, Edit } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  FormControlLabel,
  Grid,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { StyledCardTitle, StyledCheckbox, StyledContainer } from 'components/globals';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import { Link as RouterLink, useParams } from 'react-router-dom';
import Filesize from '../../components/filesize';
import { GridData } from '../../components/grid-data';
import Guarded from '../../components/guarded';
import Header from '../../components/header';
import HttpError from '../../components/http-error';
import LoadingContainer from '../../components/loading-container';
import { MailingDeliveryList } from '../../components/mailing/delivery-list';
import { ModifiedEntity } from '../../components/modified-entity';
import { useCurrentUser, useTitle } from '../../hooks';
import { apiRoutes, request, routes, useMailingDetailsApi } from '../../lib';
import { Attachment, Mailing, MailingDelivery, Role } from '../../model';
import LoadingIconButton from "../../components/loading-icon-button";

const MailingDetails = () => {
  const { t } = useTranslation();
  const { mailingId } = useParams<{ mailingId: string }>();
  const { isLoading, error, data } = useMailingDetailsApi(+mailingId);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const user = useCurrentUser();
  const [testRecipient, setTestRecipient] = useState(user.email);
  const queryClient = useQueryClient();

  useTitle(data?.name || t('Mailing Details'));

  const testMutation = useMutation(
    async (recipients: string[]) => {
      if (!data) {
        return;
      }
      return await request<MailingDelivery>(
        apiRoutes.mailingSend(data.id),
        'post',
        {
          recipients,
        }
      );
    },
    {
      onSuccess: async (res) => {
        if (!res || !data) {
          return;
        }
        const stats = res.data.stats;
        enqueueSnackbar(
          t(
            `Testversand durchgeführt. (Gesamt: ${stats.total}, Erfolgreich: ${stats.success}, Fehlgeschlagen: ${stats.error})`
          ),
          {
            variant: 'success',
          }
        );
        await queryClient.invalidateQueries(apiRoutes.mailing(data.id));
      },
      onError: () => {
        enqueueSnackbar(t('Testversand fehlgeschlagen.'));
      },
    }
  );

  const sendMutation = useMutation(
    async () => {
      if (!data) {
        return;
      }
      return await request<MailingDelivery>(
        apiRoutes.mailingSend(data.id),
        'post',
        {}
      );
    },
    {
      onSuccess: async (res) => {
        if (!res || !data) {
          return;
        }
        const stats = res.data.stats;
        enqueueSnackbar(
          t(
            `Gesendet. (Gesamt: ${stats.total}, Erfolgreich: ${stats.success}, Fehlgeschlagen: ${stats.error})`
          ),
          {
            variant: 'success',
          }
        );
        await queryClient.invalidateQueries(apiRoutes.mailing(data.id));
      },
      onError: () => {
        enqueueSnackbar(t('Senden fehlgeschlagen.'));
      },
    }
  );

  const deleteMutation = useMutation(
    async () => {
      if (!data) {
        return;
      }
      return await request(apiRoutes.mailing(data.id), 'delete');
    },
    {
      onSuccess: () => {
        enqueueSnackbar(t('Mailing wurde erfolgreich gelöscht.'), {
          variant: 'success',
        });
        history.push(routes.mailings);
      },
      onError: () => {
        enqueueSnackbar(t('Mailing konnte nicht gelöscht werden.'), {
          variant: 'error',
        });
      },
    }
  );

  const deleteAttachmentMutation = useMutation(
    async (attachment: Attachment) =>
      await request<Mailing>(apiRoutes.attachment(attachment.id), 'delete'),
    {
      onSuccess: async (res) => {
        enqueueSnackbar(t('Anhang wurde erfolgreich gelöscht.'), {
          variant: 'success',
        });
        await queryClient.invalidateQueries(apiRoutes.mailing(res.data.id));
      },
      onError: () => {
        enqueueSnackbar(t('Anhang konnte nicht gelöscht werden.'), {
          variant: 'error',
        });
      },
    }
  );

  if (error) {
    return (
      <HttpError
        error={error}
        actions={
          <Button component={RouterLink} to={routes.mailings}>
            {t('Zurück zu Mailings')}
          </Button>
        }
      />
    );
  }

  if (isLoading || !data) {
    return <LoadingContainer/>;
  }

  const detailBreadcrumbs = [
    { label: t('Home'), link: routes.dashboard },
    { label: t('Mailing'), link: routes.mailings },
  ];

  return (
    <StyledContainer>
      <Header
        title={data.name}
        breadcrumbs={detailBreadcrumbs}
        search={{ route: routes.mailings, param: 'search' }}
        actions={
          <>
            <Guarded requiredRole={Role.General}>
              <Button
                variant="contained"
                color="primary"
                component={RouterLink}
                to={routes.mailingEdit(data.id)}
              >
                <Edit/> {t('Bearbeiten')}
              </Button>{' '}
              <LoadingButton
                variant="contained"
                color="secondary"
                loading={deleteMutation.isLoading}
                onClick={() => {
                  if (
                    !window.confirm(
                      t(`Wollen Sie Mailing {{name}} wirklich löschen?`, {
                        name: data.name,
                      })
                    )
                  ) {
                    return;
                  }
                  deleteMutation.mutateAsync();
                }}
              >
                <Delete/> {t('Löschen')}
              </LoadingButton>
            </Guarded>
            <ModifiedEntity entity={data}/>
          </>
        }
      />
      <Card>
        <CardContent>
          <StyledCardTitle variant="h6" color="secondary" gutterBottom>
            {t('Mailing')}
          </StyledCardTitle>
          <Grid container spacing={3}>
            <Grid item md={6}>
              <GridData label={t<string>('Name')}>{data.name}</GridData>
            </Grid>
            <Grid item md={6}>
              <GridData
                label={t<string>('Empfänger (Verteiler)')}
                mode="richtext"
              >
                <List dense={true} sx={{ mt: 0, pt: 0 }}>
                  {data.mailingLists.map((ml, index) => (
                    <ListItem key={index} disableGutters={true}>
                      <Link href={routes.mailingList(ml.id)}>
                        {ml.name} (
                        {ml.persons.filter((mlp) => mlp.person.enabled).length})
                      </Link>
                    </ListItem>
                  ))}
                </List>
              </GridData>
            </Grid>
            <Grid item md={6}>
              <GridData label={t<string>('Betreff')}>{data.subject}</GridData>
            </Grid>
            <Grid item md={6}>
              <GridData label={t<string>('Interner Kommentar')}>
                {data.comment}
              </GridData>
            </Grid>
          </Grid>
        </CardContent>
        <Divider/>
        <CardContent>
          <Typography variant="overline" color="textSecondary">
            {t('Inhalt')}
          </Typography>
          <div dangerouslySetInnerHTML={{ __html: data.content }}/>
        </CardContent>
        <Divider/>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6}>
              <GridData label={t<string>('Signatur')}>
                {data.signature.name} ({data.signature.email})
              </GridData>
              <Box mt={3}>
                <Paper variant="outlined" sx={{ padding: 1 }}>
                  <div
                    dangerouslySetInnerHTML={{ __html: data.signature.html }}
                  />
                </Paper>
              </Box>
            </Grid>
            <Grid item md={6}>
              <FormControlLabel
                control={
                  <StyledCheckbox
                    disableRipple
                    readOnly={true}
                    name="includeNameList"
                    color="primary"
                    checked={!!data.includeNameList}
                  />
                }
                label={t<string>('Verteiler in E-Mail anzeigen')}
              />
            </Grid>
            <Grid item md={6}>
              <GridData label={t<string>('Anhänge')} mode="richtext">
                <List>
                  {data.attachments.map((attachment, index) => (
                    <ListItem key={index}>
                      <ListItemIcon>
                        <AttachFile/>
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Link href={attachment.file} target="_blank">
                            {attachment.name}
                          </Link>
                        }
                        secondary={<Filesize sizeInBytes={attachment.size}/>}
                      />
                      <LoadingIconButton
                        color="primary"
                        onClick={() => {
                          if (
                            !window.confirm(
                              t(
                                `Wollen Sie den Anhang {{name}} wirklich löschen?`,
                                {
                                  name: attachment.name,
                                }
                              )
                            )
                          ) {
                            return;
                          }
                          deleteAttachmentMutation.mutateAsync(attachment);
                        }}
                        loading={deleteAttachmentMutation.isLoading}
                      ><Delete/></LoadingIconButton>
                    </ListItem>
                  ))}
                </List>
              </GridData>
            </Grid>
          </Grid>
        </CardContent>
        <Divider/>
        <CardActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box>
            <LoadingButton
              color="secondary"
              variant="contained"
              disabled={testRecipient === ''}
              loading={testMutation.isLoading}
              onClick={async () => {
                const confirm = window.confirm(t('Testversand ausführen?'));
                if (!confirm) {
                  return;
                }
                await testMutation.mutateAsync(
                  testRecipient.split(',').map((r) => r.trim())
                );
              }}
            >
              {t('Testversand')}
            </LoadingButton>
            <Box sx={{ display: 'inline', mx: 2 }}>an</Box>
            <TextField
              type="email"
              value={testRecipient}
              onChange={(e) => setTestRecipient(e.currentTarget.value)}
              helperText={t('Mehrere Empfänger durch Komma trennen')}
              placeholder="max@voez.at, susanne@voez.at"
            />
          </Box>
          <LoadingButton
            color="primary"
            variant="contained"
            loading={sendMutation.isLoading}
            onClick={async () => {
              const confirm = window.prompt(
                t(
                  `Zum Senden bitte "senden" eingeben und bestätigen um an ${data.mailingLists.reduce(
                    (i, ml) => ml.persons.length + i,
                    0
                  )} Empfänger zu senden`
                )
              );
              if (confirm !== 'senden') {
                return;
              }
              await sendMutation.mutateAsync();
            }}
          >
            {data.deliveries.filter((d) => !d.test).length === 0
              ? t('Senden')
              : t('Erneut Senden')}
          </LoadingButton>
        </CardActions>
      </Card>
      {data.deliveries.length > 0 && (
        <Card style={{ marginTop: '1em' }}>
          <CardContent>
            <StyledCardTitle variant="h6" color="secondary" gutterBottom>
              {t('Versand Historie')}
            </StyledCardTitle>
          </CardContent>
          <MailingDeliveryList deliveries={data.deliveries}/>
        </Card>
      )}
    </StyledContainer>
  );
};

export default MailingDetails;
