import {
  Button,
  Card,
  CardHeader,
  Container,
  Grid,
  Typography,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { CompanyList } from '../../components/company';
import Header from '../../components/header';
import HttpError from '../../components/http-error';
import LoadingContainer from '../../components/loading-container';
import { MediumList } from '../../components/medium';
import { PersonList } from '../../components/person';
import UserList from '../../components/user/list';
import { useTitle } from '../../hooks';
import { routes, useSearchApi } from '../../lib';

const breadcrumbs = [{ label: 'Home', link: routes.dashboard }];

const StyledNotFound = styled(Typography)`
  margin-top: 2em;
`;

const Search = () => {
  const location = useLocation();
  const search = new URLSearchParams(location.search).get('q') || '';
  const { isLoading, error, data } = useSearchApi(search);
  const { t } = useTranslation();
  useTitle(t('Suche'));

  if (error) {
    return (
      <HttpError
        error={error}
        actions={
          <Button component={Link} to={routes.dashboard}>
            {t('Zurück zum Dashboard')}
          </Button>
        }
      />
    );
  }

  if (isLoading || !data) {
    return <LoadingContainer />;
  }

  const results = data.filter((result) => result.results.length);

  return (
    <Container maxWidth="lg">
      <Header
        title={t('Suche für ') + `"${search}"`}
        breadcrumbs={breadcrumbs}
      />
      <Grid container spacing={3} direction="row">
        {results.map((result, key) => (
          <Grid item key={key} xs={12}>
            <Card>
              <CardHeader
                title={`${result.results.length} ${
                  result.results.length === 1
                    ? t(`search.label.${result.labelSingular}`)
                    : t(`search.label.${result.label}`)
                }`}
              />
              {result.label === 'users' && <UserList users={result.results} />}
              {result.label === 'companies' && (
                <CompanyList companies={result.results} />
              )}
              {result.label === 'persons' && (
                <PersonList persons={result.results} />
              )}
              {result.label === 'media' && (
                <MediumList media={result.results} />
              )}
            </Card>
          </Grid>
        ))}
      </Grid>
      {!results.length && (
        <StyledNotFound variant="body1">
          No search results found.
        </StyledNotFound>
      )}
    </Container>
  );
};

export default Search;
