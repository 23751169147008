export const config = {
  locale: {
    sendAcceptLanguageHeader: true,
    defaultLocale: process.env.REACT_APP_DEFAULT_LOCALE || 'en',
  },
  pageSize: 50,
  pageSizes: [10, 25, 50],
  host: {
    voez: 'presseausweis.voez.at',
    oezv: 'presseausweis.oezv.or.at',
  },
  originLinks: {
    voez: 'https://voez.at/',
    oezv: 'https://www.oezv.or.at/'
  }
};
