import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { CompanyFormValues } from '../../../model';

type ValidationSchema = { [key in keyof CompanyFormValues]: any };

export const useCompanyValidation = () => {
  const { t } = useTranslation();

  return useMemo(
    (): ValidationSchema => ({
      name: yup.string().required(t('Name darf nicht leer sein.')),
      term: yup.string(),
      street: yup.string().required(t('Straße darf nicht leer sein.')),
      zipCode: yup.string().required(t('PLZ darf nicht leer sein.')),
      country: yup.string(),
      city: yup.string().required(t('Ort darf nicht leer sein.')),
      website: yup.string(),
      phone: yup.string(),
      email: yup.string(),
      media: yup.mixed(),
      parent: yup.mixed(),
      comment: yup.string(),
      responsiblePerson: yup.number().nullable(),
    }),
    [t]
  );
};
