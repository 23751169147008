import { TextField } from '@mui/material';
import React, { Dispatch, SetStateAction, VoidFunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterFormControl } from '../globals';
import { FilterWidth, getWidth } from './index';

const defaultLabel = 'Suche...';
export const FilterInputSearch: VoidFunctionComponent<{
  label?: string;
  placeholder?: string;
  value: string;
  onChange: Dispatch<SetStateAction<string>> | ((value: string) => void);
  width?: FilterWidth;
}> = ({
  label = defaultLabel,
  placeholder,
  value,
  onChange,
  width = 'medium',
}) => {
  const { t } = useTranslation();
  label = label === defaultLabel ? t(defaultLabel) : label;

  return (
    <FilterFormControl style={{ width: getWidth(width) }}>
      <TextField
        label={label}
        fullWidth
        variant="outlined"
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        size="small"
      />
    </FilterFormControl>
  );
};
