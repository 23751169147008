import { CalendarToday, Delete, Edit } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  Link,
} from '@mui/material';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import { Link as RouterLink, useParams } from 'react-router-dom';
import FormattedDate from '../../components/formatted-date';
import { StyledCardTitle, StyledCheckbox } from '../../components/globals';
import { GridData } from '../../components/grid-data';
import Guarded from '../../components/guarded';
import Header from '../../components/header';
import HttpError from '../../components/http-error';
import LoadingContainer from '../../components/loading-container';
import { ModifiedEntity } from '../../components/modified-entity';
import { useTitle } from '../../hooks';
import { apiRoutes, request, routes, useMediumDetailsApi } from '../../lib';
import { DateFormat, Medium, Role } from '../../model';

const MediumDetails = () => {
  const { t } = useTranslation();
  const { mediumId } = useParams<{ mediumId: string | undefined }>();
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, error, data } = useMediumDetailsApi(
    mediumId as unknown as number
  );
  const history = useHistory();
  useTitle(data?.name || t('Medium Details'));
  const queryClient = useQueryClient();

  const billingMutation = useMutation(
    async (data: Medium) => {
      return await request<Medium>(apiRoutes.mediumBilled(data.id), 'PUT');
    },
    {
      onSuccess: async (res) => {
        await queryClient.invalidateQueries(
          apiRoutes.mediumBilled(res.data.id)
        );
        enqueueSnackbar(t('Das Abrechnungsdatum wurde erfolgreich gesetzt.'), {
          variant: 'success',
        });
      },
      onError: (err: AxiosError) => {
        enqueueSnackbar(
          err.response?.data.message || t('Leider ist etwas schief gelaufen.'),
          { variant: 'error' }
        );
      },
    }
  );

  if (error) {
    return (
      <HttpError
        error={error}
        actions={
          <Button component={RouterLink} to={routes.media}>
            {t('Zurück zu Medien')}
          </Button>
        }
      />
    );
  }

  if (isLoading || !data) {
    return <LoadingContainer />;
  }

  const detailBreadcrumbs = [
    { label: t('Home'), link: routes.dashboard },
    { label: t('Medien'), link: routes.media },
  ];

  const handleDelete = () => {
    const confirm = window.confirm(
      t(`Wollen Sie die Medium {{name}} wirklich löschen?`, {
        name: data.name,
      })
    );
    if (!confirm) {
      return;
    }
    request(apiRoutes.medium(data.id), 'delete')
      .then(() => {
        enqueueSnackbar(t('Medium wurde erfolgreich gelöscht.'), {
          variant: 'success',
        });
        history.push(routes.media);
      })
      .catch(() =>
        enqueueSnackbar(t('Medium konnte nicht gelöscht werden.'), {
          variant: 'error',
        })
      );
  };

  return (
    <Container>
      <Header
        title={data.name}
        breadcrumbs={detailBreadcrumbs}
        search={{ route: routes.media, param: 'search' }}
        actions={
          <>
            <Guarded requiredRole={Role.PDN}>
              <LoadingButton
                variant="outlined"
                color="primary"
                onClick={() => {
                  if (
                    !window.confirm(
                      t(
                        `Wollen Sie das Abrechnungsdatum der Nutzungsdaten mit dem heutigen Tag setzen?`
                      )
                    )
                  ) {
                    return;
                  }
                  billingMutation.mutate(data);
                }}
                loading={billingMutation.isLoading}
                startIcon={<CalendarToday />}
              >
                {t('Abr LG setzen')}
              </LoadingButton>{' '}
            </Guarded>
            <Guarded requiredRole={Role.General}>
              <Button
                variant="contained"
                color="primary"
                component={RouterLink}
                to={routes.mediumEdit(data.id)}
                startIcon={<Edit />}
              >
                {t('Bearbeiten')}
              </Button>{' '}
              <Button
                variant="contained"
                color="secondary"
                onClick={handleDelete}
                startIcon={<Delete />}
              >
                {t('Löschen')}
              </Button>
            </Guarded>
            <ModifiedEntity entity={data} />
          </>
        }
      />
      <Card>
        <CardContent>
          <StyledCardTitle variant="h6" color="secondary" gutterBottom>
            {t('Informationen')}
          </StyledCardTitle>
          <Grid container spacing={3}>
            <GridData xs={12} md={6} label={t<string>('Name')}>
              {data.name}
            </GridData>
            {data.company && (
              <GridData
                xs={12}
                md={6}
                label={t<string>('Zugehöriges Unternehmen')}
              >
                <Link
                  component={RouterLink}
                  to={routes.company(data.company.id)}
                >
                  {data.company.name}
                </Link>
              </GridData>
            )}
            <GridData xs={12} md={6} label={t<string>('PA-Verantwortliche/r')}>
              {data.responsiblePerson && (
                <Link
                  component={RouterLink}
                  to={routes.person(data.responsiblePerson.id)}
                >
                  {data.responsiblePerson.displayName}
                </Link>
              )}
            </GridData>
            <GridData xs={12} md={6} label={t<string>('PDN-Verantwortliche/r')}>
              {data.licenseResponsiblePerson && (
                <Link
                  component={RouterLink}
                  to={routes.person(data.licenseResponsiblePerson.id)}
                >
                  {data.licenseResponsiblePerson.displayName}
                </Link>
              )}
            </GridData>
            <GridData xs={12} md={6} label={t<string>('Telefon')}>
              {data.phone}
            </GridData>
            <GridData xs={12} md={6} label={t<string>('Website')}>
              {data.website}
            </GridData>
            <GridData xs={12} md={6} label={t<string>('PDN Titel-ID')}>
              {data.referenceId}
            </GridData>
            <Guarded requiredRole={Role.PDN}>
              <GridData
                xs={12}
                md={6}
                label={t<string>('Letztes Abr LG Datum')}
              >
                <FormattedDate
                  date={data.lastBillingDate || ''}
                  format={DateFormat.DateTime}
                />
              </GridData>
            </Guarded>
            <Grid item xs={12} md={6}>
              <FormControlLabel
                control={
                  <StyledCheckbox
                    disableRipple
                    readOnly={true}
                    name="pressPass"
                    color="primary"
                    checked={!!data.pressPass}
                  />
                }
                label={t<string>('Presseausweis Medium')}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <GridData xs={12} label={t<string>('Interner Kommentar')}>
              {data.comment}
            </GridData>
          </Grid>
        </CardContent>
        <Divider />
      </Card>
    </Container>
  );
};

export default MediumDetails;
