import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { routes } from '../../lib';
import CreateTariff from './create';
import TariffDetails from './details';
import EditTariff from './edit';
import TariffsOverview from './overview';

const Tariffs = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <TariffsOverview />
      </Route>
      <Route path={routes.tariffCreate}>
        <CreateTariff />
      </Route>
      <Route path={routes.tariffEdit(':tariffId')}>
        <EditTariff />
      </Route>
      <Route path={routes.tariff(':tariffId')}>
        <TariffDetails />
      </Route>
    </Switch>
  );
};

export default Tariffs;
