import { AxiosError } from 'axios';
import React, { ReactChild, VoidFunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { HttpStatus } from '../../model';
import Error, { NotFound } from '../error';


const HttpError: VoidFunctionComponent<{
  error: AxiosError | Array<AxiosError | undefined> | null | undefined;
  actions?: ReactChild;
}> = ({ error, actions }) => {
  if (!error) {
    return null;
  }
  const { t } = useTranslation();

  if (!error) {
    return null;
  }
  const errors = (Array.isArray(error) ? error : [error]).filter(
    (item) => !!item
  ) as AxiosError[];
  if (!errors.length) {
    return null;
  }
  const firstError = errors[0];
  switch (firstError.response?.status) {
    case HttpStatus.NotFound:
      return <NotFound actions={actions} />;
  }
  return (
    <Error
      title={t('Fehler')}
      description={
        firstError?.response?.data.message ||
        t('Leider ist etwas schief gelaufen.')
      }
      actions={actions}
    />
  );
};

export default HttpError;
