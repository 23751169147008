import { useMemo } from 'react';
import * as yup from 'yup';
import {useTranslation} from 'react-i18next';

export const useMailingValidation = () => {
  const { t } = useTranslation();

  return useMemo(
    () => ({
      name: yup.string().required(t('Name darf nicht leer sein.')),
      subject: yup.string().required(t('Betreff darf nicht leer sein.')),
      content: yup.string().required(t('Inhalt darf nicht leer sein.')),
      signature: yup.number().required(t('Signatur darf nicht leer sein.'))
    }),
    []
  );
};

export const useMailingValidationSchema = () => {
  const mailingValidation = useMailingValidation();

  return useMemo(() => {
    return yup.object({
      ...mailingValidation,
    });
  }, [mailingValidation]);
};
