import { Delete, Edit } from '@mui/icons-material';
import {
  CardContent,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { apiRoutes, request } from '../../lib';
import {
  DateFormat,
  ID,
  LicensePressReview,
  Licensee,
  Nullable,
  TariffGroupDefaultLabels,
} from '../../model';
import FormattedDate from '../formatted-date';
import { GridData } from '../grid-data';
import LoadingIconButton from '../loading-icon-button';
import LicenseePressReviewDialog from './press-review-dialog';

export const LicensePressReviewList: FunctionComponent<{
  licensee: Licensee;
}> = ({ licensee }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const [deletingReviews, setDeletingReviews] = useState<ID[]>([]);
  const [pressReviewDialogOpen, setPressReviewDialogOpen] = useState(false);
  const [editingPressReview, setEditingPressReview] =
    useState<Nullable<LicensePressReview>>(null);

  const deletePressReviewMutation = useMutation(
    async (pressReview: LicensePressReview) => {
      setDeletingReviews([...deletingReviews, pressReview.id]);
      return {
        id: pressReview.id,
        response: await request<LicensePressReview>(
          apiRoutes.licensePressReview(pressReview.id),
          'delete'
        ),
      };
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(apiRoutes.licensee(licensee.id));
        enqueueSnackbar(t('Pressespiegel wurde erfolgreich entfernt.'), {
          variant: 'success',
        });
      },
      onError: () => {
        enqueueSnackbar(t('Pressespiegel konnte nicht entfernt werden.'), {
          variant: 'error',
        });
      },
      onSettled: (res) => {
        if (!res) {
          return;
        }
        setDeletingReviews([
          ...deletingReviews.filter((review) => review !== res.id),
        ]);
      },
    }
  );

  if (
    !licensee.licensePressReviews ||
    licensee.licensePressReviews.length <= 0
  ) {
    return (
      <CardContent>
        <GridData xs={12}>
          {t('Es wurde noch kein Pressespiegel hinzugefügt.')}
        </GridData>
      </CardContent>
    );
  }

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('Von')}</TableCell>
            <TableCell>{t('Bis')}</TableCell>
            <TableCell>{t('Art')}</TableCell>
            <TableCell>{t('Verwendung')}</TableCell>
            <TableCell>{t('Tarifgruppe')}</TableCell>
            <TableCell>{t('Zuschlag Archiv')}</TableCell>
            <TableCell>{t('Rabatt')}</TableCell>
            <TableCell>{t('Nutzungsdauer')}</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {licensee.licensePressReviews.map((pressReview) => (
            <TableRow key={pressReview.id}>
              <TableCell>
                <FormattedDate
                  date={pressReview.from}
                  format={DateFormat.Default}
                />
              </TableCell>
              <TableCell>
                <FormattedDate
                  date={pressReview.to}
                  format={DateFormat.Default}
                />
              </TableCell>
              <TableCell>
                {t(`licensee.pressReview.type.${pressReview.type}`)}
              </TableCell>
              <TableCell>
                {t(`licensee.pressReview.usage.${pressReview.usage}`)}
              </TableCell>
              <TableCell>
                {
                  TariffGroupDefaultLabels[
                    pressReview.tariffGroup as keyof typeof TariffGroupDefaultLabels
                  ]
                }
              </TableCell>
              <TableCell>{pressReview.archiveCharge || 0} %</TableCell>
              <TableCell>{pressReview.discount || 0} %</TableCell>
              <TableCell>
                {t(`licensee.pressReview.lifespan.${pressReview.lifetime}`)}
              </TableCell>
              <TableCell>
                <Tooltip title={t<string>('Pressespiegel bearbeiten')}>
                  <IconButton
                    onClick={() => {
                      setEditingPressReview(pressReview);
                      setPressReviewDialogOpen(true);
                    }}
                  >
                    <Edit />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t<string>('Pressespiegel entfernen')}>
                  <span>
                    <LoadingIconButton
                      type="button"
                      size="small"
                      loading={
                        deletePressReviewMutation.isLoading &&
                        !!deletingReviews.find(
                          (review) => review === pressReview.id
                        )
                      }
                      onClick={() => {
                        if (
                          !window.confirm(
                            t(
                              `Wollen Sie den Pressespiegel von {{from}} - {{to}} wirklich entfernen?`,
                              {
                                from: pressReview.from,
                                to: pressReview.to,
                              }
                            )
                          )
                        ) {
                          return;
                        }

                        deletePressReviewMutation.mutate(pressReview);
                      }}
                    >
                      <Delete />
                    </LoadingIconButton>
                  </span>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {editingPressReview && (
        <LicenseePressReviewDialog
          open={pressReviewDialogOpen}
          onClose={() => setPressReviewDialogOpen(false)}
          pressReview={editingPressReview}
          licenseeId={licensee.id}
        />
      )}
    </>
  );
};
