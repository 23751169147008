import { DateTimeString, Dict, ID, Nullable } from './types';

export interface Entity {
  id: ID;
}

export interface Timestampable {
  createdAt: DateTimeString;
  updatedAt: DateTimeString;
}

export interface Blameable {
  createdBy: string | null;
  updatedBy: string | null;
}

export interface Commentable {
  comment: string | null;
}

export interface Addressable {
  street?: string;
  zipCode?: string;
  city?: string;
  country?: string;
  state?: string;
}

export interface LegacyEntity {
  legacyId: Nullable<ID>;
  legacyTable: Nullable<string>;
}

export function isTimestampable(object: Dict): object is Timestampable {
  return 'createdAt' in object;
}

export function isBlameable(object: Dict): object is Blameable {
  return 'createdBy' in object;
}

export function isLegacy(object: Dict): object is LegacyEntity {
  return 'legacyId' in object;
}

export enum DateFormat {
  Default = 'dd.MM.yyyy',
  DateTime = 'dd.MM.yyyy H:mm',
  Mask = '__.__.____',
}

export interface Option {
  value: string | null;
  label: string;
}

export enum Origin {
  Voez = 'voez',
  Oezv = 'oezv',
}

export enum Membership {
  Voez = 'voez',
  Oezv = 'oezv',
}

export enum Client {
  Engine,
  Voez,
  Oezv,
}

export * from './api';
export * from './http-status';
export * from './table';
export * from './types';
export * from './user';
export * from './search';
export * from './log';
export * from './company';
export * from './person';
export * from './medium';
export * from './press-pass';
export * from './location';
export * from './signature';
export * from './mailing';
export * from './mailing-list';
export * from './press-pass-application';
export * from './pay-rates';
export * from './tariff';
export * from './licensee';
export * from './license-press-review';
