import { Tooltip } from '@mui/material';
import {
  format as dateFormat,
  formatDistance,
  isValid,
  parseISO,
} from 'date-fns';
import { de } from 'date-fns/locale';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

const FormattedDate: FunctionComponent<{ date: string; format?: string }> = ({
  date,
  format,
}) => {
  const { t } = useTranslation();

  if (!isValid(parseISO(date))) {
    return t('Kein Datum');
  }
  return (
    <Tooltip title={date}>
      {format ? (
        <time>{dateFormat(parseISO(date), format, { locale: de })}</time>
      ) : (
        <time>
          {formatDistance(parseISO(date), new Date(), {
            addSuffix: true,
            locale: de,
          })}
        </time>
      )}
    </Tooltip>
  );
};

export default FormattedDate;
