import { Delete } from '@mui/icons-material';
import { Link, TableCell, TableRow } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';
import { apiRoutes, request, routes } from '../../lib';
import { DateFormat, ID, Role, Transaction } from '../../model';
import FormattedDate from '../formatted-date';
import Guarded from '../guarded';
import LoadingIconButton from '../loading-icon-button';
import { PaginationTable } from '../pagination';
import PaginationTableHeadCell from '../pagination/table-head-cell';

export const TransactionImportList: FunctionComponent<{
  transactions?: Transaction[];
}> = ({ transactions }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const [deleting, setDeleting] = useState<ID[]>([]);

  const deleteMutation = useMutation(
    async (transaction: Transaction) => {
      setDeleting([...deleting, transaction.id]);
      return {
        id: transaction.id,
        response: await request<Transaction>(
          apiRoutes.transaction(transaction.id),
          'delete'
        ),
      };
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([apiRoutes.transactions]);
        enqueueSnackbar(t('Nutzungsdaten wurde erfolgreich entfernt.'), {
          variant: 'success',
        });
      },
      onError: () => {
        enqueueSnackbar(t('Nutzungsdaten konnten nicht entfernt werden.'), {
          variant: 'error',
        });
      },
      onSettled: (res) => {
        if (!res) {
          return;
        }
        setDeleting([...deleting.filter((id) => id !== res.id)]);
      },
    }
  );

  return (
    <PaginationTable
      items={transactions}
      size="small"
      renderRow={(transaction) => (
        <TableRow key={transaction.id} hover={true}>
          <TableCell>
            <Link
              to={routes.licensee(transaction.company.id)}
              component={RouterLink}
            >
              {transaction.company.name}
            </Link>
          </TableCell>
          <TableCell>{transaction.license}</TableCell>
          <TableCell>
            <Link
              to={routes.medium(transaction.medium.id)}
              component={RouterLink}
            >
              {transaction.medium.name}
            </Link>
          </TableCell>
          <TableCell>{transaction.referenceId}</TableCell>
          <TableCell>
            <FormattedDate
              date={transaction.usageDate}
              format={DateFormat.Default}
            />
          </TableCell>
          <TableCell>{transaction.units}</TableCell>
          <TableCell>
            {t(`licensee.pressReview.type.${transaction.type}`)}
          </TableCell>
          <TableCell>
            {t(`licensee.pressReview.usage.${transaction.usage}`)}
          </TableCell>
          <TableCell>{transaction.tariffGroupImport}</TableCell>
          <TableCell>
            <FormattedDate
              date={transaction.importDate}
              format={DateFormat.Default}
            />
          </TableCell>
          <TableCell align="right">{transaction.unitPrice}</TableCell>
          <TableCell align="right">{transaction.archiveCharge}</TableCell>
          <TableCell align="right">{transaction.discount}</TableCell>
          <TableCell align="right">{transaction.apa}</TableCell>
          <TableCell>
            {transaction.billingDateLicensee && (
              <FormattedDate
                date={transaction.billingDateLicensee}
                format={DateFormat.Default}
              />
            )}
          </TableCell>
          <TableCell>
            {transaction.billingDateMedium && (
              <FormattedDate
                date={transaction.billingDateMedium}
                format={DateFormat.Default}
              />
            )}
          </TableCell>
          <TableCell>
            <Guarded requiredRole={Role.PDN}>
              <LoadingIconButton
                type="button"
                size="small"
                loading={
                  deleteMutation.isLoading &&
                  !!deleting.find((id) => id === transaction.id)
                }
                onClick={() => {
                  if (
                    !window.confirm(
                      t(`Wollen Sie den Eintrag wirklich entfernen?`)
                    )
                  ) {
                    return;
                  }
                  deleteMutation.mutate(transaction);
                }}
              >
                <Delete />
              </LoadingIconButton>
            </Guarded>
          </TableCell>
        </TableRow>
      )}
    >
      <TableRow>
        <PaginationTableHeadCell sort="company">
          {t('Unternehmen')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell sort="license">
          {t('LizenzNr')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell sort="medium">
          {t('Titel')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell sort="medium.referenceId">
          {t('TitelID')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell sort="usageDate">
          {t('Nutzungsdatum')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell sort="units">
          {t('Anzahl')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell sort="type">
          {t('Art')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell sort="usage">
          {t('Verwendung')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell sort="tariffGroup">
          {t('TG')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell sort="importDate">
          {t('Importdatum')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell sort="unitPrice">
          {t('Artikelpreis')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell sort="archiveCharge">
          {t('Archiv')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell sort="discount">
          {t('Rabatt')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell sort="apa">{t('Apa')}</PaginationTableHeadCell>
        <PaginationTableHeadCell sort="billingDateLicensee">
          {t('Abr LN')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell sort="billingDateMedium">
          {t('Abr LG')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell />
      </TableRow>
    </PaginationTable>
  );
};
