import { Visibility } from '@mui/icons-material';
import { Container, MenuItem, TableCell, TableRow } from '@mui/material';
import React, { VoidFunctionComponent } from 'react';
import {
  FilterDatePicker,
  FilterInputSearch,
  FilterSelect,
} from '../../components/filter';
import FormattedDate from '../../components/formatted-date';
import { CompactTableCell } from '../../components/globals';
import Header from '../../components/header';
import { IconLink } from '../../components/icon-link';
import LogEntityLink from '../../components/log-entity-link';
import { PaginationCard, PaginationTable } from '../../components/pagination';
import PaginationTableHeadCell from '../../components/pagination/table-head-cell';
import {
  UseQueryStateOptions,
  useDebounceState,
  useFilteredPaginationApi,
  useQueryState,
  useTitle,
} from '../../hooks';
import { apiRoutes, routes } from '../../lib';
import { Log } from '../../model';

const breadcrumbs = [{ label: 'Home', link: routes.dashboard }];

interface Filters {
  search?: string;
  action?: string;
  from?: Date | null;
  to?: Date | null;
}

const LogsOverview: VoidFunctionComponent = () => {
  useTitle('Logs');

  const queryStateOptions: UseQueryStateOptions = { action: 'replace' };
  const [search, setFilterSearch] = useQueryState(
    '',
    'search',
    queryStateOptions
  );
  const [inputSearch, setInputSearch] = useDebounceState(
    search,
    setFilterSearch
  );
  const [action, setFilterAction] = useQueryState(
    '',
    'action',
    queryStateOptions
  );
  const [from, setFilterFromDate] = useQueryState<Date | null>(null, 'from', {
    ...queryStateOptions,
    valueType: 'Date',
  });
  const [to, setFilterToDate] = useQueryState<Date | null>(null, 'to', {
    ...queryStateOptions,
    valueType: 'Date',
  });

  const { context } = useFilteredPaginationApi<Filters, Log>(
    apiRoutes.logs,
    { search, action, from, to },
    'id',
    'desc',
    10
  );

  return (
    <Container maxWidth="md">
      <Header title={`Logs`} breadcrumbs={breadcrumbs} />
      <PaginationCard
        context={context}
        hasFilters={
          search !== '' || action !== '' || from !== null || to !== null
        }
        resetFilters={() => {
          setFilterSearch('');
          setFilterAction('');
          setFilterFromDate(null);
          setFilterToDate(null);
        }}
        filters={
          <>
            <FilterInputSearch
              placeholder="e.g. User#1 or admin"
              value={inputSearch}
              onChange={setInputSearch}
            />
            <FilterSelect
              label="Action"
              value={action}
              onChange={setFilterAction}
            >
              <MenuItem value="">
                <em>All</em>
              </MenuItem>
              <MenuItem value="create">Create</MenuItem>
              <MenuItem value="update">Update</MenuItem>
              <MenuItem value="remove">Remove</MenuItem>
            </FilterSelect>
            <FilterDatePicker
              label="From"
              value={from}
              onChange={setFilterFromDate}
            />
            <FilterDatePicker
              label="To"
              value={to}
              onChange={setFilterToDate}
            />
          </>
        }
      >
        <PaginationTable
          renderRow={(log: Log) => (
            <TableRow key={log.id} hover={true}>
              <CompactTableCell align="center">{log.id}</CompactTableCell>
              <TableCell>
                <LogEntityLink
                  entityClass={log.objectClass}
                  entityId={log.objectId}
                  label={log.label}
                />
              </TableCell>
              <CompactTableCell>{log.action}</CompactTableCell>
              <TableCell>
                <FormattedDate date={log.loggedAt} />
              </TableCell>
              <TableCell>{log.username}</TableCell>
              <CompactTableCell>{log.version}</CompactTableCell>
              <CompactTableCell>
                <IconLink
                  to={routes.log(log.id)}
                  tooltip="View Log"
                  icon={<Visibility />}
                />
              </CompactTableCell>
            </TableRow>
          )}
        >
          <TableRow>
            <PaginationTableHeadCell sort="id" align="center">
              ID
            </PaginationTableHeadCell>
            <PaginationTableHeadCell sort="objectId" width="30%">
              Subject
            </PaginationTableHeadCell>
            <PaginationTableHeadCell sort="action">
              Action
            </PaginationTableHeadCell>
            <PaginationTableHeadCell sort="loggedAt" width="30%">
              Logged
            </PaginationTableHeadCell>
            <PaginationTableHeadCell sort="username" width="10%">
              User
            </PaginationTableHeadCell>
            <PaginationTableHeadCell sort="version" align="center">
              Version
            </PaginationTableHeadCell>
            <TableCell />
          </TableRow>
        </PaginationTable>
      </PaginationCard>
    </Container>
  );
};

export default LogsOverview;
