import { Edit, Visibility } from '@mui/icons-material';
import { Link, TableCell, TableRow } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { routes } from '../../lib';
import { Role, Tariff } from '../../model';
import { CompactTableCell } from '../globals';
import Guarded from '../guarded';
import { IconLink } from '../icon-link';
import { PaginationTable } from '../pagination';
import PaginationTableHeadCell from '../pagination/table-head-cell';

export const TariffList: FunctionComponent<{ tariffs?: Tariff[] }> = ({
  tariffs,
}) => {
  const { t } = useTranslation();

  return (
    <PaginationTable
      items={tariffs}
      renderRow={(tariff) => (
        <TableRow key={tariff.id} hover={true}>
          <CompactTableCell>
            <Link to={routes.tariff(tariff.id)} component={RouterLink}>
              {tariff.name}
            </Link>
          </CompactTableCell>
          <CompactTableCell align={'right'}>
            <Guarded requiredRole={Role.PDN}>
              <IconLink
                to={routes.tariff(tariff.id)}
                tooltip={t('Ansehen')}
                icon={<Visibility />}
              />
            </Guarded>
            <Guarded requiredRole={Role.PDN}>
              <IconLink
                to={routes.tariffEdit(tariff.id)}
                tooltip={t('Bearbeiten')}
                icon={<Edit />}
              />
            </Guarded>
          </CompactTableCell>
        </TableRow>
      )}
    >
      <TableRow>
        <PaginationTableHeadCell sort="name">
          {t('Name')}
        </PaginationTableHeadCell>

        <TableCell width={110} />
      </TableRow>
    </PaginationTable>
  );
};
