import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Card,
  CardActions,
  Container,
  Divider,
  Grid,
} from '@mui/material';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import React, { VoidFunctionComponent, useEffect } from 'react';
import { UseFormSetError, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import { Link, useParams } from 'react-router-dom';
import Header from '../../components/header';
import HttpError from '../../components/http-error';
import LoadingButton from '../../components/loading-button';
import LoadingContainer from '../../components/loading-container';
import {
  PersonCompanyFormFields,
  PersonFormFields,
  PersonMailingListFormFields,
} from '../../components/person';
import { handleHookFormErrors } from '../../helpers';
import { useTitle } from '../../hooks';
import { usePersonValidationSchema } from '../../hooks/validation/person';
import { apiRoutes, request, routes, usePersonDetailsApi } from '../../lib';
import {
  ApiError,
  Person,
  PersonFormValues,
  getPersonFormValues,
} from '../../model';

const EditPerson: VoidFunctionComponent = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { personId } = useParams<{ personId: string }>();
  const { isLoading, error, data } = usePersonDetailsApi(+personId);
  const { enqueueSnackbar } = useSnackbar();
  useTitle(data?.lastName || t('Person bearbeiten'));
  const validationSchema = usePersonValidationSchema();
  const queryClient = useQueryClient();

  const {
    control,
    handleSubmit,
    setError,
    formState: { isValid, isSubmitting },
    reset,
  } = useForm<PersonFormValues>({
    mode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues: getPersonFormValues(data),
  });

  useEffect(() => reset(getPersonFormValues(data)), [reset, data]);

  const onSubmit = async (
    values: PersonFormValues,
    setError: UseFormSetError<PersonFormValues>
  ) => {
    if (!data) {
      return;
    }

    if (
      values.companies &&
      values.companies?.filter((company) => company.defaultCompany).length > 1
    ) {
      enqueueSnackbar(
        t(
          'Person konnte nicht gespeichert werden. Es kann nur ein primäres Unternehmen gewählt werden.'
        ),
        {
          variant: 'warning',
        }
      );
      return;
    }

    await request<Person>(apiRoutes.person(data.id), 'put', values)
      .then((res) => {
        enqueueSnackbar(t('Person wurde erfolgreich bearbeitet.'), {
          variant: 'success',
        });
        queryClient.invalidateQueries(apiRoutes.person(data.id));
        queryClient.invalidateQueries(apiRoutes.personEmailAddresses(data.id));
        history.push(routes.person(res.data.id));
      })
      .catch((err: AxiosError<ApiError>) => {
        enqueueSnackbar(
          err.response?.data.message ||
            t('Person konnte nicht gepeichert werden.'),
          { variant: 'error' }
        );
        handleHookFormErrors(err, setError);
      });
  };

  if (error) {
    return (
      <HttpError
        error={error}
        actions={
          <Button component={Link} to={routes.persons}>
            {t('Zurück zu Personen')}
          </Button>
        }
      />
    );
  }

  if (isLoading || !data) {
    return <LoadingContainer />;
  }

  const breadcrumbs = [
    { label: t('Home'), link: routes.dashboard },
    { label: t('Personen'), link: routes.persons },
    { label: data.displayName, link: routes.person(data.id) },
    { label: t('Bearbeiten') },
  ];

  return (
    <Container maxWidth="xl">
      <form onSubmit={handleSubmit((values) => onSubmit(values, setError))}>
        <Header
          title={data.displayName}
          breadcrumbs={breadcrumbs}
          actions={
            <LoadingButton
              type="submit"
              size="medium"
              color="primary"
              disabled={!isValid}
              variant="contained"
              loading={isSubmitting}
            >
              {t('Speichern')}
            </LoadingButton>
          }
        />
        <Card>
          <Grid container>
            <Grid item xs={12} md={4}>
              <PersonFormFields control={control} />
            </Grid>
            <Divider
              orientation={'vertical'}
              flexItem
              style={{ marginRight: '-1px' }}
            />
            <Grid item xs={12} md={4}>
              <PersonCompanyFormFields control={control} person={data} />
            </Grid>
            <Divider
              orientation={'vertical'}
              flexItem
              style={{ marginRight: '-1px' }}
            />
            <Grid item xs={12} md={4}>
              <PersonMailingListFormFields person={data} control={control} />
            </Grid>
            <Grid item xs={12}>
              <Divider />
              <CardActions>
                <LoadingButton
                  type="submit"
                  size="medium"
                  color="primary"
                  disabled={!isValid}
                  variant="contained"
                  loading={isSubmitting}
                >
                  {t('Speichern')}
                </LoadingButton>
              </CardActions>
            </Grid>
          </Grid>
        </Card>
      </form>
    </Container>
  );
};

export default EditPerson;
