import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import React, { PropsWithChildren, ReactNode } from 'react';
import { Entity } from '../../model';
import HttpError from '../http-error';
import { usePaginationContext } from './context';
import {TableTypeMap} from '@mui/material/Table/Table';

interface PaginationTableProps<TEntity extends Entity> {
  items?: TEntity[];
  renderRow: (item: TEntity) => ReactNode;
  size?: TableTypeMap['props']['size'];
}

export const PaginationTable = <TEntity extends Entity>({
  items,
  renderRow,
  children,
  size = 'medium'
}: PropsWithChildren<PaginationTableProps<TEntity>>) => {
  const { data, isLoading, error, isError } = usePaginationContext();

  if (items) {
    return (
      <TableContainer>
        <Table>
          <TableHead>{children}</TableHead>
          <TableBody>{items.map((item) => renderRow(item as any))}</TableBody>
        </Table>
      </TableContainer>
    );
  }

  if (isLoading === undefined) {
    return null;
  }

  return (
    <TableContainer>
      <Table size={size}>
        <TableHead>{children}</TableHead>
        {!isError ? (
          !isLoading && data ? (
            <TableBody>
              {data.results.map((item) => renderRow(item as any))}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell align="center" colSpan={100}>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            </TableBody>
          )
        ) : (
          <TableBody>
            <TableRow>
              <TableCell align="center" colSpan={100}>
                <HttpError error={error} />
              </TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};
