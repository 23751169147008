import { IconButton, Tooltip } from '@mui/material';
import React, { ReactChild, VoidFunctionComponent } from 'react';
import { Link, LinkProps } from 'react-router-dom';

interface IconLinkProps extends LinkProps {
  icon: ReactChild;
  tooltip?: string;
  size?: 'small' | 'medium';
}

export const IconLink: VoidFunctionComponent<IconLinkProps> = ({
  icon,
  tooltip,
  size = 'small',
  ...props
}) => {
  const button = (
    <IconButton aria-label={tooltip} size={size}>
      {icon}
    </IconButton>
  );
  return (
    <Link {...props}>
      {tooltip ? <Tooltip title={tooltip}>{button}</Tooltip> : button}
    </Link>
  );
};
