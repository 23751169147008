import { CalendarToday, FileDownload, PictureAsPdf } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, CardContent, Grid } from '@mui/material';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { config } from '../../config';
import { useFilteredPaginationApi } from '../../hooks';
import { apiRoutes, request } from '../../lib';
import { ID, Licensee, Transaction } from '../../model';
import { StyledCardTitle } from '../globals';
import { PaginationProvider, PaginationTablePagination } from '../pagination';
import { TransactionList } from '../transaction';
import LicenseeReportDialog from './report-dialog';

export const LicenseeJournal: FunctionComponent<{
  licensee: Licensee;
}> = ({ licensee }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const [reportDialogOpen, setReportDialogOpen] = useState(false);

  const { context } = useFilteredPaginationApi<{ licenseeId: ID }, Transaction>(
    apiRoutes.transactions,
    { licenseeId: licensee.id },
    'createdAt',
    'desc',
    config.pageSize
  );

  const billingMutation = useMutation(
    async () =>
      await request<Licensee>(apiRoutes.licenseeBilled(licensee.id), 'PUT'),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([apiRoutes.transactions]);
        enqueueSnackbar(t('Das Abrechnungsdatum wurde erfolgreich gesetzt.'), {
          variant: 'success',
        });
      },
      onError: (err: AxiosError) => {
        enqueueSnackbar(
          err.response?.data.message || t('Leider ist etwas schief gelaufen.'),
          { variant: 'error' }
        );
      },
    }
  );

  return (
    <>
      <CardContent>
        <Grid container justifyContent="space-between">
          <Grid item md={6}>
            <StyledCardTitle variant="h6" color="secondary" gutterBottom>
              {t('Importierte Nutzungsdaten')}
            </StyledCardTitle>
          </Grid>
          <Grid item>
            <LoadingButton
              variant="outlined"
              color="primary"
              onClick={() => {
                if (
                  !window.confirm(
                    t(
                      `Wollen Sie das Abrechnungsdatum des Lizenznehmers für alle offenen Einträge setzen?`
                    )
                  )
                ) {
                  return;
                }
                billingMutation.mutateAsync();
              }}
              loading={billingMutation.isLoading}
              startIcon={<CalendarToday />}
            >
              {t('Abr LN setzen')}
            </LoadingButton>{' '}
            <Button
              variant="contained"
              color="secondary"
              startIcon={<PictureAsPdf />}
              onClick={() => setReportDialogOpen(true)}
            >
              {t('Report')}
            </Button>{' '}
            <Button
              variant="contained"
              color="primary"
              startIcon={<FileDownload />}
              target="_blank"
              href={`${
                process.env.REACT_APP_API_URL
              }/${apiRoutes.licenseeBmdExport(licensee.id)}`}
            >
              {t('BMD Export')}
            </Button>
          </Grid>
        </Grid>
      </CardContent>
      <PaginationProvider context={context}>
        <TransactionList />
        <PaginationTablePagination />
      </PaginationProvider>
      <LicenseeReportDialog
        open={reportDialogOpen}
        onClose={() => setReportDialogOpen(false)}
        licensee={licensee}
      />
    </>
  );
};
