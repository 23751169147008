import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { routes } from '../../lib';
import CreateMailingList from './create';
import MailingListDetails from './details';
import EditMailingList from './edit';
import MailingListOverview from './overview';

const MailingLists = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <MailingListOverview />
      </Route>
      <Route path={routes.mailingListEdit(':mailingListId')}>
        <EditMailingList />
      </Route>
      <Route path={routes.mailingListCreate}>
        <CreateMailingList />
      </Route>
      <Route path={routes.mailingList(':mailingListId')}>
        <MailingListDetails />
      </Route>
    </Switch>
  );
};

export default MailingLists;
