import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { ID, Nullable } from '../../../model';
import { usePersonValidation } from '../person';

export const usePressPassValidation = () => {
  const { t } = useTranslation();
  const personValidation = usePersonValidation();
  return useMemo(
    () => ({
      person: yup.object(personValidation),
      employmentStatus: yup.string(),
      position: yup.number().nullable(),
      carBadge: yup.boolean(),
      carBadgeDate: yup.string().nullable(),
      mark: yup.string().nullable(),
      badgeDate: yup.string().nullable(),
      passDate: yup.string().nullable(),
      passNumber: yup.string().nullable(),
      passWithdrawal: yup.boolean(),
      passWithdrawalDate: yup.string().nullable(),
      billTo: yup.string().nullable(),
      enabled: yup.boolean(),
      medium: yup.mixed().when('company', {
        is: (company: Nullable<ID>) => !company,
        then: yup.string().required(t('Medium darf nicht leer sein.')),
      }),
      badge: yup.object({
        year: yup.string(),
        carBadge: yup.boolean(),
      }),
    }),
    [personValidation]
  );
};

export const usePressPassValidationSchema = () => {
  const pressPassValidation = usePressPassValidation();
  return useMemo(() => {
    return yup.object({
      ...pressPassValidation,
    });
  }, [pressPassValidation]);
};


export const usePressPassSuspensionValidation = () => {
  const { t } = useTranslation();
  return useMemo(
    () => ({
      passNumberNew: yup
        .string()
        .required(t('Presseausweisnummer darf nicht leer sein.')),
      date: yup.string().required(t('Datum darf nicht leer sein.')),
    }),
    [t]
  );
};

export const usePressPassSuspensionValidationSchema = () => {
  const validation = usePressPassSuspensionValidation();
  return useMemo(() => {
    return yup.object({
      ...validation,
    });
  }, [validation]);
};
