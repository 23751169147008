import {FunctionComponent} from 'react';
import {Role} from '../../model';
import {useCurrentUser} from '../../hooks';
import {Redirect, Route, RouteProps} from 'react-router-dom';
import {hasRole} from "../../helpers";
import {routes} from "../../lib";

const GuardedRoute: FunctionComponent<RouteProps & { requiredRole: Role }> = ({requiredRole, ...props}) => {
  const user = useCurrentUser();
  if (!user || !hasRole(user, requiredRole)) {
    return <Redirect to={routes.dashboard}/>;
  }
  return <Route{...props}/>
};

export default GuardedRoute;
