import { InputLabel, Select, SelectProps } from '@mui/material';
import React, {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useRef,
} from 'react';
import { uniqueId } from '../../helpers';
import { FilterFormControl } from '../globals';
import { FilterWidth, getWidth } from './index';

export const FilterSelect: FunctionComponent<
  Omit<SelectProps, 'onChange' | 'label' | 'value'> & {
    label: string;
    value: any;
    onChange: Dispatch<SetStateAction<any>> | ((value: any) => void);
    width?: FilterWidth;
  }
> = ({ label, value, onChange, children, width = 'medium', ...props }) => {
  const { current: id } = useRef(uniqueId('filterSelect'));
  return (
    <FilterFormControl
      variant="outlined"
      size="small"
      style={{ width: getWidth(width) }}
    >
      <InputLabel id={id}>{label}</InputLabel>
      <Select
        labelId={id}
        fullWidth
        value={value}
        variant="outlined"
        onChange={(e) => onChange(e.target.value)}
        label={label}
        {...props}
      >
        {children}
      </Select>
    </FilterFormControl>
  );
};
