import { ThemeOptions } from '@mui/material/styles';
import {darken, lighten} from "@mui/material";
import {grey, orange, pink} from "@mui/material/colors";

const MaterialCommonTheme: ThemeOptions = {
  typography: {
    h1: {
      fontSize: '3rem',
    },
    h2: {
      fontSize: '2.5rem',
    },
    h3: {
      fontSize: '2rem',
      fontWeight: 300,
    },
    h4: {
      fontSize: '1.5rem',
    },
    h5: {
      fontSize: '1.25rem',
    },
    h6: {
      fontSize: '1rem',
    },
  },
  components: {
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiFormControl: {
      defaultProps: {
        variant: 'standard',
      },
    },
  },
};

export const MaterialLightTheme: ThemeOptions = {
  ...MaterialCommonTheme,
  palette: {
    background: {
      default: grey[50]
    },
    mode: 'light',
    primary: {
      main: '#cc0000',
    },
    secondary: {
      main: '#104f55',
    },
  },
};

export const VoezTheme: ThemeOptions = {
  ...MaterialCommonTheme,
  palette: {
    background: {
      default: grey[50]
    },
    mode: 'light',
    primary: {
      main: '#cc0000',
    },
    secondary: {
      main: '#000',
    },
  },
};

export const OezvTheme: ThemeOptions = {
  ...MaterialCommonTheme,
  palette: {
    background: {
      default: grey[50]
    },
    mode: 'light',
    primary: {
      main: '#135c9a',
    },
    secondary: {
      main: '#000',
    },
  },
};

export const MaterialDarkTheme: ThemeOptions = {
  ...MaterialCommonTheme,
  palette: {
    mode: 'dark',
    background: {
      default: grey[900]
    },
    primary: {
      main: pink[500],
    },
    secondary: {
      main: orange[500],
    },
  },
};

export const CustomTheme = {
  navigation: {
    width: 260,
  },
  width: {
    full: '100%',
    0: '0',
    '1/2': '50%',
    '1/3': '33.33%',
    '2/3': '66.67%',
    '1/4': '25%',
    '2/4': '50%',
    '3/4': '75%',
    '1/5': '20%',
    '2/5': '40%',
    '3/5': '60%',
    '4/5': '80%',
  },
  color: {
    main: {
      900: darken('#cc0000', .6),
      800: darken('#cc0000', .5),
      700: darken('#cc0000', .4),
      600: darken('#cc0000', .2),
      500: '#cc0000',
      400: lighten('#cc0000', .25),
      300: lighten('#cc0000', .5),
      200: lighten('#cc0000', .75),
      100: lighten('#cc0000', .95),
    }
  }
};
