import { AttachFile, Delete } from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { FunctionComponent, useRef } from 'react';
import { Control, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Nullable } from '../../model';
import Filesize from '../filesize';

interface FormValues {
  attachments: Array<{ name: string; size: number; file: File }>;
}

const MultiUpload: FunctionComponent<{
  control: Control<any>;
  textAlign?: 'right' | 'left' | 'center';
  allowedTypes?: string[];
}> = ({ control, textAlign = 'right', allowedTypes }) => {
  const { t } = useTranslation();
  const attachmentRef = useRef<Nullable<HTMLInputElement>>(null);
  const { enqueueSnackbar } = useSnackbar();
  const { fields, append, remove } = useFieldArray<
    FormValues,
    'attachments',
    'id'
  >({
    control,
    name: 'attachments',
  });

  return (
    <>
      <Box sx={{ textAlign: textAlign }}>
        <input
          value=""
          id="attachment"
          hidden
          ref={attachmentRef}
          type="file"
          onChange={(e) => {
            const file = e.currentTarget.files![0];
            if (!file) {
              return;
            }
            if (file.size >= 3145728) {
              enqueueSnackbar(t('Datei zu groß'), { variant: 'error' });
              return;
            }
            if (allowedTypes && !allowedTypes.includes(file.type)) {
              enqueueSnackbar(
                t('Dateiformat ist ungültig. Erlaubte Formate: ') +
                  allowedTypes.map((type) => t(`file.type.${type}`)).join(', '),
                { variant: 'error' }
              );
              return;
            }

            append({
              name: file.name,
              size: file.size,
              file,
            });
          }}
        />
        <label htmlFor="attachment">
          <Button variant="contained" component="span" color="secondary">
            <AttachFile /> {t('Datei wählen')}
          </Button>
        </label>
      </Box>
      <List>
        {fields.map((field, index) => (
          <ListItem
            key={field.id}
            secondaryAction={
              <IconButton edge="end" onClick={() => remove(index)}>
                <Delete />
              </IconButton>
            }
          >
            <ListItemIcon>
              <AttachFile />
            </ListItemIcon>
            <ListItemText
              primary={field.name}
              secondary={<Filesize sizeInBytes={field.size} />}
              sx={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            />
          </ListItem>
        ))}
      </List>
    </>
  );
};

export default MultiUpload;
