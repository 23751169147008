import {
  Autocomplete,
  CardContent,
  Divider,
  Grid,
  TextField,
} from '@mui/material';
import React, { VoidFunctionComponent } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { apiRoutes } from '../../lib';
import { Company, CompanyFormValues, CountryList } from '../../model';
import { StyledCardTitle } from '../globals';
import PaginatedAutocomplete from '../paginated-autocomplete';

export const CompanyFormFields: VoidFunctionComponent<{
  control: Control<CompanyFormValues>;
  company?: Company;
}> = ({ control, company }) => {
  const { t } = useTranslation();

  return (
    <>
      {/*Not used by now, but maybe later*/}
      {/*<CardContent>*/}
      {/*  <StyledCardTitle variant="h6" color="secondary" gutterBottom>*/}
      {/*    {t('Typ')}*/}
      {/*  </StyledCardTitle>*/}
      {/*  <Grid container spacing={3}>*/}
      {/*    <Grid item xs={6} md={3}>*/}
      {/*      <Controller*/}
      {/*        control={control}*/}
      {/*        name={'customer'}*/}
      {/*        render={({ field }) => (*/}
      {/*          <FormControlLabel*/}
      {/*            control={*/}
      {/*              <Checkbox*/}
      {/*                checked={field.value}*/}
      {/*                onChange={field.onChange}*/}
      {/*                name="customer"*/}
      {/*                color="primary"*/}
      {/*              />*/}
      {/*            }*/}
      {/*            label={t<string>('Kunde')}*/}
      {/*          />*/}
      {/*        )}*/}
      {/*      />*/}
      {/*    </Grid>*/}
      {/*    <Grid item xs={6} md={3}>*/}
      {/*      <Controller*/}
      {/*        control={control}*/}
      {/*        name={'publisher'}*/}
      {/*        render={({ field }) => (*/}
      {/*          <FormControlLabel*/}
      {/*            control={*/}
      {/*              <Checkbox*/}
      {/*                checked={field.value}*/}
      {/*                onChange={field.onChange}*/}
      {/*                name="publisher"*/}
      {/*                color="primary"*/}
      {/*              />*/}
      {/*            }*/}
      {/*            label={t<string>('Verlag')}*/}
      {/*          />*/}
      {/*        )}*/}
      {/*      />*/}
      {/*    </Grid>*/}
      {/*    <Grid item xs={6} md={3}>*/}
      {/*      <Controller*/}
      {/*        control={control}*/}
      {/*        name={'advocacy'}*/}
      {/*        render={({ field }) => (*/}
      {/*          <FormControlLabel*/}
      {/*            control={*/}
      {/*              <Checkbox*/}
      {/*                checked={field.value}*/}
      {/*                onChange={field.onChange}*/}
      {/*                name="advocacy"*/}
      {/*                color="primary"*/}
      {/*              />*/}
      {/*            }*/}
      {/*            label={t<string>('Interessensvertreter')}*/}
      {/*          />*/}
      {/*        )}*/}
      {/*      />*/}
      {/*    </Grid>*/}
      {/*    <Grid item xs={6} md={3}>*/}
      {/*      <Controller*/}
      {/*        control={control}*/}
      {/*        name={'partner'}*/}
      {/*        render={({ field }) => (*/}
      {/*          <FormControlLabel*/}
      {/*            control={*/}
      {/*              <Checkbox*/}
      {/*                checked={field.value}*/}
      {/*                onChange={field.onChange}*/}
      {/*                name="partner"*/}
      {/*                color="primary"*/}
      {/*              />*/}
      {/*            }*/}
      {/*            label={t<string>('Vertriebspartner')}*/}
      {/*          />*/}
      {/*        )}*/}
      {/*      />*/}
      {/*    </Grid>*/}
      {/*  </Grid>*/}
      {/*</CardContent>*/}
      <Divider/>
      <CardContent>
        <StyledCardTitle variant="h6" color="secondary" gutterBottom>
          {t('Unternehmen Stammdaten')}
        </StyledCardTitle>
        <Grid container spacing={3}>
          <Grid item md={6}>
            <Controller
              control={control}
              name={'name'}
              render={({ field, fieldState }) => (
                <TextField
                  label={t('Name')}
                  fullWidth
                  {...field}
                  required
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item md={6}>
            <Controller
              control={control}
              name={'term'}
              render={({ field, fieldState }) => (
                <TextField
                  label={t('Name Zusatz')}
                  fullWidth
                  {...field}
                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          {/*Not used by now, but maybe later*/}
          {/*<Grid item md={12}>*/}
          {/*  <Controller*/}
          {/*    control={control}*/}
          {/*    name={'parent'}*/}
          {/*    render={({ field, fieldState }) => (*/}
          {/*      <PaginatedAutocomplete*/}
          {/*        name="parent"*/}
          {/*        label={t('Übergeordnetes Unternehmen')}*/}
          {/*        value={field.value}*/}
          {/*        onChange={(ids) => field.onChange(ids?.[0])}*/}
          {/*        multiple={false}*/}
          {/*        optionLabel={(option) =>*/}
          {/*          `${option.name} ${option.term} (${option.id})`*/}
          {/*        }*/}
          {/*        dataUrl={apiRoutes.companies}*/}
          {/*        initialData={*/}
          {/*          company && company.parent ? [company.parent] : []*/}
          {/*        }*/}
          {/*        textFieldProps={{*/}
          {/*          error: fieldState.isTouched && fieldState.invalid,*/}
          {/*          helperText: fieldState.error?.message,*/}
          {/*        }}*/}
          {/*      />*/}
          {/*    )}*/}
          {/*  />*/}
          {/*</Grid>*/}
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name={'website'}
              render={({ field, fieldState }) => (
                <TextField
                  label={t('Website')}
                  fullWidth
                  {...field}
                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name={'phone'}
              render={({ field, fieldState }) => (
                <TextField
                  label={t('Telefon')}
                  fullWidth
                  {...field}
                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name={'email'}
              render={({ field, fieldState }) => (
                <TextField
                  label={t('Office E-Mail')}
                  fullWidth
                  {...field}
                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
        </Grid>
      </CardContent>
      <Divider/>
      <CardContent>
        <StyledCardTitle variant="h6" color="secondary" gutterBottom>
          {t('Adresse')}
        </StyledCardTitle>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Controller
              control={control}
              name={'street'}
              render={({ field, fieldState }) => (
                <TextField
                  label={t('Straße')}
                  fullWidth
                  {...field}
                  required={true}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <Controller
              control={control}
              name={'zipCode'}
              render={({ field, fieldState }) => (
                <TextField
                  label={t('PLZ')}
                  fullWidth
                  {...field}
                  required={true}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <Controller
              control={control}
              name={'city'}
              render={({ field, fieldState }) => (
                <TextField
                  label={t('Ort')}
                  fullWidth
                  {...field}
                  required={true}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item md={6}>
            <Controller
              control={control}
              name={'country'}
              render={({ field, fieldState }) => (
                <Autocomplete
                  id="country"
                  noOptionsText={t('Kein Land verfügbar')}
                  options={CountryList}
                  getOptionLabel={(option) => `${option.name} (${option.code})`}
                  onChange={(event, value) => field.onChange(value?.code)}
                  value={
                    CountryList.find(
                      (country) => country.code === field.value
                    ) || null
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t('Land')}
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              )}
            />
          </Grid>
        </Grid>
      </CardContent>
      <Divider/>
      <CardContent>
        <StyledCardTitle variant="h6" color="secondary" gutterBottom>
          {t('Medien')}
        </StyledCardTitle>
        <Controller
          control={control}
          name={'media'}
          render={({ field, fieldState }) => (
            <PaginatedAutocomplete
              name="media"
              label={t('Medien')}
              value={field.value}
              onChange={(ids) => field.onChange(ids)}
              multiple={true}
              initialData={company?.media}
              optionLabel="name"
              dataUrl={apiRoutes.media}
              textFieldProps={{
                error: fieldState.isTouched && fieldState.invalid,
                helperText: fieldState.error?.message,
              }}
            />
          )}
        />
      </CardContent>
      <Divider/>
      <CardContent>
        <StyledCardTitle variant="h6" color="secondary" gutterBottom>
          {t('Sonstiges')}
        </StyledCardTitle>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="responsiblePerson"
              render={({ field, fieldState }) => (
                <PaginatedAutocomplete
                  name="responsiblePerson"
                  label={t('PA-Veranwortliche/r')}
                  value={field.value}
                  onChange={(ids) => field.onChange(ids?.[0] || null)}
                  optionLabel="displayName"
                  dataUrl={apiRoutes.persons}
                  initialData={company ? [company.responsiblePerson] : []}
                  textFieldProps={{
                    error: fieldState.isTouched && fieldState.invalid,
                    helperText: fieldState.error?.message,
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name={'comment'}
              render={({ field, fieldState }) => (
                <TextField
                  label={t('Kommentar')}
                  fullWidth
                  {...field}
                  multiline={true}
                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
        </Grid>
      </CardContent>
    </>
  );
};
