import {Add, Delete, Edit, LinkOff, Visibility} from '@mui/icons-material';
import {Button, Link, TableCell, TableRow} from '@mui/material';
import {useSnackbar} from 'notistack';
import React, {VoidFunctionComponent, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useMutation, useQueryClient} from 'react-query';
import {Link as RouterLink} from 'react-router-dom';
import {apiRoutes, request, routes} from '../../lib';
import {Company, CompanyPerson, ID, Person, Role} from '../../model';
import {CompactTableCell} from '../globals';
import Guarded from '../guarded';
import {IconLink} from '../icon-link';
import LoadingIconButton from '../loading-icon-button';
import {PaginationTable} from '../pagination';
import PaginationTableHeadCell from '../pagination/table-head-cell';

export const CompanyPersonList: VoidFunctionComponent<{
  persons: CompanyPerson[];
  company: Company;
}> = ({persons, company}) => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const [deletingPersons, setDeletingPersons] = useState<ID[]>([]);
  const [deletingCompanyPersons, setDeletingCompanyPersons] = useState<ID[]>(
    []
  );
  const queryClient = useQueryClient();
  const deleteCompanyPersonMutation = useMutation(
    async (companyPerson: CompanyPerson) => {
      setDeletingCompanyPersons([...deletingCompanyPersons, companyPerson.id]);
      const {data} = await request<CompanyPerson>(
        apiRoutes.companyPersonDelete(companyPerson.id),
        'delete'
      );
      return data;
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([
          apiRoutes.companyPersons(company.id),
        ]);
        enqueueSnackbar(t('Verknüpfung wurde erfolgreich aufgehoben.'), {
          variant: 'success',
        });
      },
      onError: () => {
        enqueueSnackbar(t('Verknüpfung konnte nicht aufgehoben werden.'), {
          variant: 'error',
        });
      },
      onSettled: (res) => {
        if (!res) {
          return;
        }
        setDeletingCompanyPersons([
          ...deletingPersons.filter((person) => person !== res.id),
        ]);
      },
    }
  );

  const deletePersonMutation = useMutation(
    async (person: Person) => {
      setDeletingPersons([...deletingPersons, person.id]);
      const {data} = await request<Person>(
        apiRoutes.person(person.id),
        'delete'
      );
      return data;
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([
          apiRoutes.companyPersons(company.id),
        ]);
        enqueueSnackbar(t('Person wurde erfolgreich gelöscht.'), {
          variant: 'success',
        });
      },
      onError: () => {
        enqueueSnackbar(t('Person konnte nicht gelöscht werden.'), {
          variant: 'error',
        });
      },
      onSettled: (res) => {
        if (!res) {
          return;
        }
        setDeletingPersons([
          ...deletingPersons.filter((person) => person !== res.id),
        ]);
      },
    }
  );

  return (
    <PaginationTable
      items={persons.sort((a, b) =>
        a.person.lastName >= b.person.lastName ? 1 : -1
      )}
      renderRow={(companyPerson) => (
        <TableRow key={companyPerson.id} hover={true}>
          <CompactTableCell>{companyPerson.id}</CompactTableCell>
          <CompactTableCell>{companyPerson.person.titleBefore}</CompactTableCell>
          <CompactTableCell>{companyPerson.person.titleAfter}</CompactTableCell>
          <CompactTableCell>{companyPerson.person.firstName}</CompactTableCell>
          <CompactTableCell>
            <Link
              to={routes.person(companyPerson.person.id)}
              component={RouterLink}
            >
              {companyPerson.person.lastName}
            </Link>
          </CompactTableCell>
          <CompactTableCell>{companyPerson.position ? companyPerson.position.title : '-'}</CompactTableCell>
          <TableCell>{companyPerson.email}</TableCell>
          <CompactTableCell align={'right'}>
            <IconLink
              to={routes.person(companyPerson.person.id)}
              tooltip={t('Ansehen')}
              icon={<Visibility/>}
            />
            <IconLink
              to={routes.personEdit(companyPerson.person.id)}
              tooltip={t('Bearbeiten')}
              icon={<Edit/>}
            />
            <LoadingIconButton
              type="button"
              tooltip={t('Verknüpfung aufheben')}
              size="small"
              color="secondary"
              loading={
                deleteCompanyPersonMutation.isLoading &&
                !!deletingCompanyPersons.find(
                  (person) => person === companyPerson.id
                )
              }
              onClick={() => {
                if (
                  !window.confirm(
                    t(
                      `Wollen Sie die Person {{name}} von dem Unternehmen {{company}} wirklich entfernen?`,
                      {
                        name: companyPerson.person.displayName,
                        company: company.name,
                      }
                    )
                  )
                ) {
                  return;
                }
                deleteCompanyPersonMutation.mutate(companyPerson);
              }}
            >
              <LinkOff/>
            </LoadingIconButton>
            <Guarded requiredRole={Role.Admin}>
              <LoadingIconButton
                type="button"
                tooltip={t('Person löschen')}
                size="small"
                loading={
                  deletePersonMutation.isLoading &&
                  !!deletingPersons.find(
                    (person) => person === companyPerson.person.id
                  )
                }
                onClick={() => {
                  if (
                    !window.confirm(
                      t(
                        `Wollen Sie die Person {{name}} wirklich dauerhaft löschen?`,
                        {
                          name: companyPerson.person.displayName,
                        }
                      )
                    )
                  ) {
                    return;
                  }
                  deletePersonMutation.mutate(companyPerson.person);
                }}
              >
                <Delete/>
              </LoadingIconButton>
            </Guarded>
          </CompactTableCell>
        </TableRow>
      )}
    >
      <TableRow>
        <PaginationTableHeadCell sort="id">{t('ID')}</PaginationTableHeadCell>
        <PaginationTableHeadCell>
          {t('Titel')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell>
          {t('Nach. Titel')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell sort="name">
          {t('Vorname')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell sort="lastName">
          {t('Nachname')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell>
          {t('Funktion')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell sort="email">
          {t('E-Mail')}
        </PaginationTableHeadCell>
        <TableCell width={200} align={'right'}>
          <Button
            component={RouterLink}
            to={routes.personCreate}
            variant="contained"
            color="primary"
            startIcon={<Add/>}
          >
            {t('Neue Person')}
          </Button>
        </TableCell>
      </TableRow>
    </PaginationTable>
  );
};
