import {
  Delete,
  Edit,
  MarkEmailUnread,
  Visibility,
  Warning,
} from '@mui/icons-material';
import {
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  Icon,
  Link,
  Tooltip,
} from '@mui/material';
import { red } from '@mui/material/colors';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Link as RouterLink, useParams } from 'react-router-dom';
import FormattedDate from '../../components/formatted-date';
import {
  SpanBlock,
  StyledCardTitle,
  StyledCheckbox,
} from '../../components/globals';
import { GridData } from '../../components/grid-data';
import Header from '../../components/header';
import HttpError from '../../components/http-error';
import LoadingContainer from '../../components/loading-container';
import { ModifiedEntity } from '../../components/modified-entity';
import { PersonCompanyAccordion } from '../../components/person';
import { useCurrentUser, useTitle } from '../../hooks';
import { apiRoutes, request, routes, usePersonDetailsApi } from '../../lib';
import { Role, Salutation } from '../../model';
import { hasRole } from "../../helpers";

const PersonDetails = () => {
  const { t } = useTranslation();
  const { personId } = useParams<{ personId: string | undefined }>();
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, error, data } = usePersonDetailsApi(
    personId as unknown as number
  );
  const history = useHistory();
  const user = useCurrentUser();
  useTitle(data?.lastName || t('Kontakt Details'));

  if (error) {
    return (
      <HttpError
        error={error}
        actions={
          <Button component={RouterLink} to={routes.pressPasses}>
            {t('Zurück zu Personen')}
          </Button>
        }
      />
    );
  }

  if (isLoading || !data) {
    return <LoadingContainer/>;
  }

  const detailBreadcrumbs = [
    { label: t('Home'), link: routes.dashboard },
    { label: t('Personen'), link: routes.persons },
  ];

  const handleDelete = () => {
    const confirm = window.confirm(
      t(`Wollen Sie die Person {{firstName}} {{lastName}} wirklich löschen?`, {
        firstName: data.firstName,
        lastName: data.lastName,
      })
    );
    if (!confirm) {
      return;
    }
    request(apiRoutes.person(data.id), 'delete')
      .then(() => {
        enqueueSnackbar(t('Person wurde erfolgreich gelöscht.'), {
          variant: 'success',
        });
        history.push(routes.persons);
      })
      .catch((err) =>
        enqueueSnackbar(
          err.response?.data.message ||
          t('Person konnte nicht gelöscht werden.'),
          { variant: 'error' }
        )
      );
  };

  return (
    <Container maxWidth="xl">
      <Header
        title={
          (data.salutation && data.salutation !== Salutation.Other
            ? t(`person.salutation.${data.salutation}`) + ' ' + (data.salutationPosition != null ? data.salutationPosition + ' ' : '')
            : '') + data.displayName
        }
        search={{ route: routes.persons, param: 'search' }}
        breadcrumbs={detailBreadcrumbs}
        actions={
          <>
            {data.pressPassId && (
              <>
                <Button
                  variant="outlined"
                  color="primary"
                  component={RouterLink}
                  disabled={!hasRole(user, Role.PressPass)}
                  to={routes.pressPass(data.pressPassId)}
                  startIcon={<Visibility/>}
                >
                  {t('Zum Presseausweis')}
                </Button>{' '}
              </>
            )}
            <Button
              variant="contained"
              color="primary"
              component={RouterLink}
              to={routes.personEdit(data.id)}
              startIcon={<Edit/>}
            >
              {t('Bearbeiten')}
            </Button>{' '}
            <Button
              variant="contained"
              color="secondary"
              onClick={handleDelete}
              startIcon={<Delete/>}
            >
              {t('Löschen')}
            </Button>
            <ModifiedEntity entity={data}/>
          </>
        }
      />
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Card>
            {(data.street || data.zipCode || data.city || data.country) && (
              <CardContent>
                <StyledCardTitle variant="h6" color="secondary" gutterBottom>
                  {t('Informationen zur Person')}
                </StyledCardTitle>
                <Grid container spacing={3}>
                  <GridData xs={12} md={6} label={t<string>('Privatanschrift')}>
                    <SpanBlock>{data.street}</SpanBlock>
                    <SpanBlock>
                      {data.zipCode} {data.city}
                    </SpanBlock>
                    <SpanBlock>{data.country}</SpanBlock>
                  </GridData>
                </Grid>
              </CardContent>
            )}
            <Divider/>
            <CardContent>
              <StyledCardTitle variant="h6" color="secondary" gutterBottom>
                {t('Private Kontaktdaten')}
              </StyledCardTitle>
              <Grid container spacing={3}>
                <GridData xs={12} md={6} label={t<string>('Telefonnummer')}>
                  {data.phone}
                </GridData>
                <GridData xs={12} md={6} label={t<string>('Mobiltelefon')}>
                  {data.mobilePhone}
                </GridData>
                <GridData xs={12} md={6} label={t<string>('E-Mail Adresse')}>
                  {data.email}
                </GridData>
              </Grid>
            </CardContent>
            <Divider/>
            <CardContent>
              <StyledCardTitle variant="h6" color="secondary" gutterBottom>
                {t('Sonstiges')}
              </StyledCardTitle>
              <Grid container spacing={3}>
                <GridData xs={6} md={6} label={t<string>('Anrede Funktion')}>
                  {data.salutationPosition}
                </GridData>
                <GridData xs={6} label={t<string>('Art des Kontakts')}>
                  {data.typeOfContact
                    ? t(`person.typeOfContact.${data.typeOfContact}`)
                    : null}
                </GridData>
                <GridData xs={6} label={t<string>('Einwilligung am')}>
                  <FormattedDate
                    date={data.dateOfApproval || ''}
                    format="dd.mm.Y"
                  />
                </GridData>
                {data.dateOfEnd && (
                  <GridData
                    xs={6}
                    label={t<string>('Ende der Geschäftsbeziehung')}
                  >
                    <FormattedDate date={data.dateOfEnd} format="dd.mm.Y"/>
                  </GridData>
                )}
                <GridData xs={12} label={t<string>('Interner Kommentar')}>
                  {data.comment}
                </GridData>
                <Grid item xs={12} md={6}>
                  <FormControlLabel
                    control={
                      <StyledCheckbox
                        disableRipple
                        readOnly={true}
                        name="enabled"
                        color="primary"
                        checked={data.enabled}
                      />
                    }
                    label={
                      data.enabled ? t<string>('Aktiv') : t<string>('Inaktiv')
                    }
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <StyledCardTitle variant="h6" color="secondary" gutterBottom>
                {t('Verknüpfungen')}
              </StyledCardTitle>
              {data.companies.length > 0
                ? data.companies.map((companyPerson) => (
                  <PersonCompanyAccordion
                    companyPerson={companyPerson}
                    isExpanded={companyPerson.defaultCompany || data.companies.length === 1}
                    key={companyPerson.id}
                  />
                ))
                : t('Keine Verknüpfung angelegt')}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <StyledCardTitle variant="h6" color="secondary" gutterBottom>
                {t('Aktive Verteiler')}
              </StyledCardTitle>
              {data.mailingLists.length > 0
                ? data.mailingLists.map((mailingListPerson) => (
                  <Grid
                    display={mailingListPerson.mailingList.enabled ? 'flex' : 'none'}
                    container
                    spacing={1}
                    key={mailingListPerson.id}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item xs={10}>
                      <Link
                        to={routes.mailingList(
                          mailingListPerson.mailingList.id
                        )}
                        component={RouterLink}
                      >
                        {mailingListPerson.mailingList.name}
                      </Link>
                    </Grid>
                    <Grid item xs={2} textAlign="center">
                      <>
                        {((mailingListPerson.companyPerson &&
                            !mailingListPerson.companyPerson.email) ||
                          (!mailingListPerson.companyPerson &&
                            !data.email)) && (
                          <Tooltip
                            title={t<string>(
                              'Keine E-Mail Adresse zugewiesen'
                            )}
                          >
                            <Icon>
                              <Warning
                                style={{ color: red[500] }}
                                fontSize="small"
                              />
                            </Icon>
                          </Tooltip>
                        )}
                        {!mailingListPerson.isDefault &&
                          (mailingListPerson.companyPerson?.email ||
                            data.email) && (
                            <Tooltip
                              title={
                                mailingListPerson.companyPerson
                                  ? `${mailingListPerson.companyPerson.company.name} (${mailingListPerson.companyPerson.email})`
                                  : `Privat (${data.email})`
                              }
                            >
                              <Icon>
                                <MarkEmailUnread
                                  color="secondary"
                                  fontSize="small"
                                />
                              </Icon>
                            </Tooltip>
                          )}
                      </>
                    </Grid>
                  </Grid>
                ))
                : t('Keine Verteiler verknüpft')}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PersonDetails;
