import { useApi } from '../hooks';
import {
  Company,
  DateTimeString,
  ID,
  Licensee,
  Log,
  Mailing,
  MailingList,
  Medium,
  PayRate,
  Person,
  PressPass,
  PressPassApplication, PressPassApplicationWithPressPass,
  SearchResult,
  Signature,
  Tariff,
  User,
} from '../model';
import { Import } from '../model/import';
import { Position } from '../model/position';
import { request } from './request';

export const apiRoutes = {
  login: 'login',
  logout: 'logout',
  search: (query: string) => `search?q=${query}`,

  profile: {
    me: 'profile/me',
    changePassword: 'profile/password',
  },

  users: 'users',
  user: (id: ID) => `users/${id}`,
  userCreate: 'users/create',

  logs: 'logs',
  log: (id: ID) => `logs/${id}`,

  companies: 'companies',
  companiesWithPressPasses: 'companies/press-passes',
  company: (id: ID) => `companies/${id}`,
  companyCreate: 'companies/create',
  availableCompanies: (id: ID) => `companies/${id}/available`,
  companyPersons: (id: ID) => `companies/${id}/persons`,
  companyMedia: (id: ID) => `companies/${id}/media`,
  companyPersonDelete: (id: ID) => `company-person/${id}`,

  persons: 'persons',
  person: (id: ID) => `persons/${id}`,
  personCreate: 'persons/create',
  personEmailAddresses: (id: ID) => `persons/${id}/email-addresses`,
  personAssistantOptions: (id: ID, companyId: ID) =>
    `persons/${id}/assistants/${companyId}`,

  media: 'media',
  mediaBilled: 'media/billed',
  medium: (id: ID) => `media/${id}`,
  mediumCreate: 'media/create',
  mediumBilled: (id: ID) => `media/${id}/billed`,

  positions: 'positions',
  position: (id: ID) => `positions/${id}`,
  positionCreate: 'positions/create',

  pressPasses: 'press-passes',
  pressPass: (id: ID) => `press-passes/${id}`,
  pressPassPdf: (id: ID) => `press-passes/${id}/pdf`,
  pressPassSuspend: (id: ID) => `press-passes/${id}/suspend`,
  pressPassSuspendDelete: (id: ID) => `press-passes/suspended/${id}`,
  pressPassStatus: (id: ID) =>
    `press-passes/${id}/status`,
  pressPassCreate: 'press-passes/create',
  pressPassMarks: 'press-passes/marks',
  pressPassExportNameList: 'press-passes/exportNameList',
  pressPassBadges: (id: ID) => `press-passes/${id}/badges`,
  pressPassBadgeDelete: (id: ID) => `press-passes/badges/${id}`,
  pressPassNumberNext: 'press-passes/next-pass-number',

  mailingLists: 'mailing-lists',
  mailingListsEnabled: 'mailing-lists/enabled',
  mailingList: (id: ID) => `mailing-lists/${id}`,
  mailingListCreate: 'mailing-lists/create',
  mailingListPersons: (id: ID) => `mailing-lists/${id}/persons`,

  mailingListPersonCreate: `mailing-list-persons/create`,
  mailingListPerson: (id: ID) => `mailing-list-persons/${id}`,

  signatures: 'signatures',
  signature: (id: ID) => `signatures/${id}`,
  signatureCreate: 'signatures/create',

  mailings: 'mailings',
  mailing: (id: ID) => `mailings/${id}`,
  mailingUpdate: (id: ID) => `mailings/${id}/update`,
  mailingCreate: 'mailings/create',
  mailingSend: (id: ID) => `mailings/${id}/send`,

  pressPassApplications: 'press-pass-applications/?filterType=new',
  pressPassUpdateApplications: 'press-pass-applications/?filterType=update',
  pressPassApplication: (id: ID) => `press-pass-applications/${id}`,
  pressPassApplicationPress: (id: ID) => `press-pass-applications/${id}/?context=updateApplication`,
  pressPassApplicationStatus: (id: ID) =>
    `press-pass-applications/${id}/status`,
  pressPassApplicationCreate: 'press-pass-applications/create',
  pressPassApplicationUpdate: 'press-pass-applications/update',
  pressPassApplicationDocumentUpdate: (token: string) =>
    `press-pass-applications/${token}/update`,
  pressPassApplicationNewCount: 'press-pass-applications/new-count',
  pressPassApplicationNewUpdateCount: 'press-pass-applications/new-update-count',
  pressPassApplicationAttachmentDelete: (id: ID, attachmentId: ID) =>
    `press-pass-applications/${id}/attachment/${attachmentId}`,
  pressPassApplicationAttachmentUpdate: (id: ID, attachmentId: ID) =>
    `press-pass-applications/${id}/attachment/${attachmentId}`,
  pressPassApplicationAttachmentUpdateUploadCroppedImage: (id: ID) =>
    `press-pass-applications/${id}/attachment/upload-cropped-image`,

  payRates: 'pay-rates',
  payRate: (id: ID) => `pay-rates/${id}`,
  payRateCreate: 'pay-rates/create',

  tariffs: 'tariffs',
  tariff: (id: ID) => `tariffs/${id}`,
  tariffDuplicate: (id: ID) => `tariffs/${id}/duplicate`,
  tariffCreate: 'tariffs/create',

  licensees: 'licensees',
  licenseesBilled: 'licensees/billed',
  licensee: (id: ID) => `licensees/${id}`,
  licenseeContactPersonCreate: (id: ID) => `licensees/${id}/contact-person`,
  licenseContactPerson: (id: ID, contactPersonId: ID) =>
    `licensees/${id}/contact-person/${contactPersonId}`,
  nextLicense: 'licensees/next',
  licenseeCreditCreate: (id: ID) => `licensees/${id}/credit`,
  licenseeCredit: (id: ID, creditId: ID) =>
    `licensees/${id}/credit/${creditId}`,
  licenseeCredits: (id: ID) => `licensees/${id}/credits`,
  licenseeBmdExport: (id: ID) => `licensees/${id}/bmd-export`,
  licenseeReport: (id: ID, from: DateTimeString, to: DateTimeString) =>
    `licensees/${id}/report?from=${from}&to=${to}`,
  licenseeBilled: (id: ID) => `licensees/${id}/billed`,

  licenseePressReviewCreate: `license-press-reviews/create`,
  licensePressReview: (id: ID) => `license-press-reviews/${id}`,

  imports: 'imports',
  importUpload: 'imports/upload',
  importCurrent: 'imports/current',
  import: (id: ID) => `imports/${id}`,
  importProcess: (id: ID) => `imports/${id}/process`,
  importRevalidate: (id: ID) => `imports/${id}/revalidate`,

  transactions: 'transactions',
  transactionsExport: 'transactions/export',
  transaction: (id: ID) => `transactions/${id}`,

  attachment: (id: ID) => `attachments/${id}`,
};

export async function getUser(): Promise<User | null> {
  try {
    const user = await request<User>(apiRoutes.profile.me);
    return user.data;
  } catch (e) {
    return null;
  }
}

export async function logout(): Promise<unknown> {
  return await request(apiRoutes.logout);
}

export const useUserApi = () => useApi<User[]>(apiRoutes.users);
export const useUserDetailsApi = (id: ID) => useApi<User>(apiRoutes.user(id));
export const useLogDetailsApi = (id: ID) => useApi<Log[]>(apiRoutes.log(id));
export const useSearchApi = (query: string) =>
  useApi<SearchResult[]>(apiRoutes.search(query));
export const useCompanyApi = () => useApi<Company[]>(apiRoutes.companies);
export const useCompanyDetailsApi = (id: ID) =>
  useApi<Company>(apiRoutes.company(id));
export const usePersonDetailsApi = (id: ID) =>
  useApi<Person>(apiRoutes.person(id));
export const usePressPassDetailsApi = (id: ID) =>
  useApi<PressPass>(apiRoutes.pressPass(id));
export const useMediumDetailsApi = (id: ID) =>
  useApi<Medium>(apiRoutes.medium(id));
export const usePositionDetailsApi = (id: ID) =>
  useApi<Position>(apiRoutes.position(id));
export const useSignatureDetailsApi = (id: ID) =>
  useApi<Signature>(apiRoutes.signature(id));
export const useMailingListDetailsApi = (id: ID) =>
  useApi<MailingList>(apiRoutes.mailingList(id));
export const useMailingDetailsApi = (id: ID) =>
  useApi<Mailing>(apiRoutes.mailing(id));
export const usePressPassApplicationDetailsApi = (id: ID) =>
  useApi<PressPassApplication>(apiRoutes.pressPassApplication(id))
;export const usePressPassApplicationUpdateDetailsApi = (id: ID) =>
  useApi<PressPassApplicationWithPressPass>(apiRoutes.pressPassApplicationPress(id));
export const usePayRateDetailsApi = (id: ID) =>
  useApi<PayRate>(apiRoutes.payRate(id));
export const useTariffDetailsApi = (id: ID) =>
  useApi<Tariff>(apiRoutes.tariff(id));
export const useLicenseeDetailsApi = (id: ID) =>
  useApi<Licensee>(apiRoutes.licensee(id));
export const useImportDetailsApi = (id: ID) =>
  useApi<Import>(apiRoutes.import(id));
