import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import React, { VoidFunctionComponent, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useParams } from 'react-router-dom';
import Header from '../../components/header';
import HttpError from '../../components/http-error';
import LoadingContainer from '../../components/loading-container';
import { handleHookFormErrors, hasRole } from '../../helpers';
import {
  useCurrentUser,
  useTitle,
  useUserManagementValidationSchema,
} from '../../hooks';
import { apiRoutes, request, routes, useUserDetailsApi } from '../../lib';
import {
  ApiError,
  Role,
  User,
  UserFormValues,
  getUserFormValues,
} from '../../model';

const EditUser: VoidFunctionComponent = () => {
  const { t } = useTranslation();
  const currentUser = useCurrentUser();
  const { userId } = useParams<{ userId: string | undefined }>();
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, error, data } = useUserDetailsApi(
    userId as unknown as number
  );
  const history = useHistory();
  const validation = useUserManagementValidationSchema(false);
  const {
    control,
    handleSubmit,
    formState: { isValid, isSubmitting },
    setError,
    reset,
  } = useForm<UserFormValues>({
    mode: 'all',
    resolver: yupResolver(validation),
    defaultValues: getUserFormValues(data),
  });

  // Used in order to update the form when the request data changes
  useEffect(() => reset(getUserFormValues(data)), [reset, data]);

  useTitle(data?.username || t('Benutzer bearbeiten'));

  if (error) {
    return (
      <HttpError
        error={error}
        actions={
          <Button component={Link} to={routes.users}>
            Back to User overview
          </Button>
        }
      />
    );
  }

  if (isLoading || !data) {
    return <LoadingContainer />;
  }

  const editBreadcrumbs = [
    { label: 'Home', link: routes.dashboard },
    { label: t('Benutzer'), link: routes.users },
    { label: data.username, link: routes.user(data.id) },
    { label: t('Bearbeiten') },
  ];
  const selectableRoles = Object.keys(Role).filter((role) =>
    hasRole(currentUser, Role[role as keyof typeof Role])
  );

  const onSubmit = async (values: UserFormValues) => {
    await request<User>(apiRoutes.user(data.id), 'put', values)
      .then((res) => {
        enqueueSnackbar(t('Benutzer wurde aktualisiert'), {
          variant: 'success',
        });
        history.push(routes.user(res.data.id));
      })
      .catch((err: AxiosError<ApiError>) => {
        enqueueSnackbar(
          err.response?.data.message || t('Leider ist etwas schief gelaufen'),
          { variant: 'error' }
        );
        handleHookFormErrors(err, setError);
      });
  };

  return (
    <Container maxWidth="md">
      <Header title={t('Bearbeiten')} breadcrumbs={editBreadcrumbs} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Controller
                  control={control}
                  name="username"
                  render={({ field, fieldState }) => (
                    <TextField
                      id="username"
                      label={t('Username')}
                      fullWidth
                      {...field}
                      error={fieldState.isTouched && fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  control={control}
                  name="fullName"
                  render={({ field, fieldState }) => (
                    <TextField
                      id="fullName"
                      label={t('Name')}
                      fullWidth
                      {...field}
                      error={fieldState.isTouched && fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  control={control}
                  name="email"
                  render={({ field, fieldState }) => (
                    <TextField
                      id="email"
                      label={t('E-Mail')}
                      fullWidth
                      {...field}
                      error={fieldState.isTouched && fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel>Roles</InputLabel>
                  <Controller
                    control={control}
                    name="roles"
                    render={({ field }) => (
                      <Select
                        id="roles"
                        fullWidth
                        {...field}
                        label={t('Rollen')}
                        value={field.value}
                        multiple={true}
                      >
                        {selectableRoles.map((role, key) => (
                          <MenuItem
                            key={key}
                            value={Role[role as keyof typeof Role]}
                          >
                            {role}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <Controller
                  control={control}
                  name="enabled"
                  render={({ field }) => (
                    <FormControlLabel
                      defaultChecked={field.value}
                      control={
                        <Checkbox
                          checked={field.value}
                          onChange={field.onChange}
                          id="enabled"
                          color="primary"
                        />
                      }
                      label={t<string>('Aktiv')}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Controller
                  control={control}
                  name="plainPassword"
                  render={({ field, fieldState }) => (
                    <>
                      <TextField
                        id="plainPassword"
                        type="password"
                        label={t('Passwort')}
                        fullWidth
                        {...field}
                        error={fieldState.isTouched && fieldState.invalid}
                        helperText={fieldState.error?.message}
                      />
                      <FormHelperText>
                        {t(
                          'Passwort leer lassen, wenn kein neues gesetzt werden soll'
                        )}
                      </FormHelperText>
                    </>
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  control={control}
                  name="passwordRepeat"
                  render={({ field, fieldState }) => (
                    <TextField
                      id="passwordRepeat"
                      type="password"
                      label={t('Passwort wiederholen')}
                      fullWidth
                      {...field}
                      error={fieldState.isTouched && fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions>
            <LoadingButton
              type="submit"
              size="medium"
              color="primary"
              disabled={!isValid}
              loading={isSubmitting}
            >
              {t('Speichern')}
            </LoadingButton>
          </CardActions>
        </Card>
      </form>
    </Container>
  );
};

export default EditUser;
