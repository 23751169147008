import { Edit, Visibility } from '@mui/icons-material';
import { Link, TableCell, TableRow } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { routes } from '../../lib';
import { PayRate, Role } from '../../model';
import { CompactTableCell } from '../globals';
import Guarded from '../guarded';
import { IconLink } from '../icon-link';
import { PaginationTable } from '../pagination';
import PaginationTableHeadCell from '../pagination/table-head-cell';

export const PayRateList: FunctionComponent<{ payRates?: PayRate[] }> = ({
  payRates,
}) => {
  const { t } = useTranslation();

  return (
    <PaginationTable
      items={payRates}
      renderRow={(payRate) => (
        <TableRow key={payRate.id} hover={true}>
          <CompactTableCell>
            <Link to={routes.payRate(payRate.id)} component={RouterLink}>
              {payRate.name}
            </Link>
          </CompactTableCell>
          <TableCell>{t(`payRate.type.${payRate.type}`)}</TableCell>
          <TableCell align={'right'}>{payRate.price} €</TableCell>
          <CompactTableCell align={'right'}>
            <Guarded requiredRole={Role.PDN}>
              <IconLink
                to={routes.payRate(payRate.id)}
                tooltip={t('Ansehen')}
                icon={<Visibility />}
              />
            </Guarded>
            <Guarded requiredRole={Role.PDN}>
              <IconLink
                to={routes.payRateEdit(payRate.id)}
                tooltip={t('Bearbeiten')}
                icon={<Edit />}
              />
            </Guarded>
          </CompactTableCell>
        </TableRow>
      )}
    >
      <TableRow>
        <PaginationTableHeadCell sort="name">
          {t('Name')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell sort="rateType">
          {t('Typ')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell sort="price" align={'right'}>
          {t('Preis')}
        </PaginationTableHeadCell>
        <TableCell width={110} />
      </TableRow>
    </PaginationTable>
  );
};
