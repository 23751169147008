import { Add, Cancel, InfoOutlined } from '@mui/icons-material';
import {
  Autocomplete,
  Card,
  CardContent,
  Fab,
  Grid, Icon,
  IconButton,
  TextField,
  Tooltip,
} from '@mui/material';
import React, { VoidFunctionComponent } from 'react';
import { Control, Controller, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useApi } from '../../hooks';
import { apiRoutes } from '../../lib';
import {
  MailingList,
  Person,
  PersonEmailOptions,
  PersonFormValues,
} from '../../model';
import Error from '../error';
import { StyledCardTitle, StyledColoredCardContent } from '../globals';
import LoadingContainer from '../loading-container';
import PaginatedAutocomplete from '../paginated-autocomplete';

export const PersonMailingListFormFields: VoidFunctionComponent<{
  person: Person;
  control: Control<PersonFormValues>;
}> = ({ person, control }) => {
  const { t } = useTranslation();

  const {
    data: emailOptions,
    isLoading: emailOptionsLoading,
    error: emailOptionsError,
  } = useApi<PersonEmailOptions[]>(apiRoutes.personEmailAddresses(person.id));

  if (emailOptionsError) {
    return (
      <CardContent>
        <Error title={'Error'}/>
      </CardContent>
    );
  }

  const { fields, remove, append } = useFieldArray({
    control,
    name: 'mailingLists',
  });

  return (
    <CardContent>
      <StyledCardTitle variant="h6" color="secondary" gutterBottom>
        {t('Verteiler')}<Tooltip
        title={t<string>(
          'Es werden nur aktive Verteiler angezeigt'
        )}
      >
        <Icon><InfoOutlined/></Icon>
      </Tooltip>
      </StyledCardTitle>
      {emailOptionsLoading && <LoadingContainer/>}
      {fields.map((field, index) => (
        <Card key={field.id} style={{ marginBottom: '1em' }}>
          <StyledColoredCardContent>
            <Grid
              container
              spacing={1}
              justifyContent="space-between"
              alignContent="center"
              alignItems="center"
            >
              <Grid item xs={12} md={10}>
                <Grid container spacing={1}>
                  <Grid item style={{ display: 'none' }}>
                    <Controller
                      control={control}
                      name={
                        `mailingLists.${index}.mailingListPersonId` as const
                      }
                      defaultValue={field.mailingListPersonId as any}
                      render={({ field }) => (
                        <TextField type="hidden" value={field.value}/>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      name={`mailingLists.${index}.mailingList` as const}
                      defaultValue={field.mailingList as any}
                      render={({ field, fieldState }) => (
                        <PaginatedAutocomplete
                          name={'mailingList'}
                          label={t('Verteiler')}
                          value={field.value}
                          onChange={(ids) => field.onChange(ids?.[0] || null)}
                          optionLabel={(option: MailingList) => option.name}
                          dataUrl={apiRoutes.mailingListsEnabled}
                          textFieldProps={{
                            required: true,
                            error: fieldState.isTouched && fieldState.invalid,
                            helperText: fieldState.error?.message,
                          }}
                          initialData={person.mailingLists.map(mlp => mlp.mailingList)}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      name={`mailingLists.${index}.companyPerson` as const}
                      defaultValue={field.companyPerson as any}
                      render={({ field, fieldState }) => (
                        <Autocomplete
                          options={emailOptions || []}
                          loading={emailOptionsLoading}
                          loadingText={t('Lädt...')}
                          noOptionsText={t('Keine Person verfügbar')}
                          getOptionLabel={(option) =>
                            `${option.name ? option.name : t('Privat')} ${
                              option.email ? `(${option.email})` : ''
                            }`
                          }
                          value={
                            emailOptions?.find(
                              (emailOptions) =>
                                emailOptions.companyPerson === field.value
                            ) || null
                          }
                          onChange={(event, value) =>
                            field.onChange(value ? value.companyPerson : null)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={t('E-Mail Adresse')}
                              error={fieldState.isTouched && fieldState.invalid}
                              helperText={fieldState.error?.message}
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={2} textAlign="right">
                <Tooltip title={t<string>('Aus Verteiler löschen')}>
                  <IconButton type="button" onClick={() => remove(index)}>
                    <Cancel/>
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </StyledColoredCardContent>
        </Card>
      ))}
      <Grid container spacing={5} justifyContent="space-around">
        <Grid item>
          <Tooltip title={t<string>('Weitere Verknüpfung')}>
            <Fab
              type="button"
              color="primary"
              size="small"
              onClick={() =>
                append({
                  companyPerson:
                    emailOptions?.find((option) => option.default)
                      ?.companyPerson || '',
                  person: person.id,
                  mailingList: '',
                  mailingListPersonId: '',
                })
              }
            >
              <Add/>
            </Fab>
          </Tooltip>
        </Grid>
      </Grid>
    </CardContent>
  );
};
