import { DatePicker } from '@mui/lab';
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import React, { VoidFunctionComponent } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  DateFormat,
  LicensePressReviewFormValues,
  LicensePressReviewLifespan,
  LicensePressReviewType,
  LicensePressReviewUsage,
  TariffGroupDefaultLabels,
} from '../../model';

export const PressReviewFormFields: VoidFunctionComponent<{
  control: Control<LicensePressReviewFormValues>;
  inline?: boolean;
}> = ({ control, inline = false }) => {
  const { t } = useTranslation();

  return (
    <>
      <Grid item xs={12} md={inline ? 2 : 6}>
        <Controller
          control={control}
          name={'from'}
          render={({ field, fieldState }) => (
            <DatePicker
              value={field.value}
              inputFormat={DateFormat.Default}
              mask={DateFormat.Mask}
              onChange={field.onChange}
              label={t('Von')}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  fullWidth
                  required
                  helperText={fieldState.error?.message}
                  error={fieldState.invalid}
                />
              )}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={inline ? 2 : 6}>
        <Controller
          control={control}
          name={'to'}
          render={({ field, fieldState }) => (
            <DatePicker
              value={field.value}
              inputFormat={DateFormat.Default}
              mask={DateFormat.Mask}
              onChange={field.onChange}
              label={t('Bis')}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  fullWidth
                  required
                  helperText={fieldState.error?.message}
                  error={fieldState.invalid}
                />
              )}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={inline ? 1 : 6}>
        <FormControl fullWidth>
          <InputLabel>{t('Art')}</InputLabel>
          <Controller
            control={control}
            name={'type'}
            render={({ field }) => (
              <Select
                fullWidth
                {...field}
                label={t('Art')}
                value={field.value}
                required
              >
                {Object.keys(LicensePressReviewType).map((type, key) => (
                  <MenuItem
                    value={
                      LicensePressReviewType[
                        type as keyof typeof LicensePressReviewType
                      ]
                    }
                    key={key}
                  >
                    {t(
                      `licensee.pressReview.type.${
                        LicensePressReviewType[
                          type as keyof typeof LicensePressReviewType
                        ]
                      }`
                    )}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={inline ? 1 : 6}>
        <FormControl fullWidth>
          <InputLabel>{t('Verwendung')}</InputLabel>
          <Controller
            control={control}
            name={'usage'}
            render={({ field }) => (
              <Select
                fullWidth
                {...field}
                label={t('Verwendung')}
                value={field.value}
                required
              >
                {Object.keys(LicensePressReviewUsage).map((usage, key) => (
                  <MenuItem
                    value={
                      LicensePressReviewUsage[
                        usage as keyof typeof LicensePressReviewUsage
                      ]
                    }
                    key={key}
                  >
                    {t(
                      `licensee.pressReview.usage.${
                        LicensePressReviewUsage[
                          usage as keyof typeof LicensePressReviewUsage
                        ]
                      }`
                    )}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={inline ? 1 : 6}>
        <FormControl fullWidth>
          <InputLabel>{t('Tarifgruppe')}</InputLabel>
          <Controller
            control={control}
            name={'tariffGroup'}
            render={({ field }) => (
              <Select
                fullWidth
                {...field}
                label={t('Tarifgruppe')}
                value={field.value}
                required
              >
                {Object.keys(TariffGroupDefaultLabels).map(
                  (tariffGroup, key) => (
                    <MenuItem value={tariffGroup} key={key}>
                      {
                        TariffGroupDefaultLabels[
                          tariffGroup as keyof typeof TariffGroupDefaultLabels
                        ]
                      }
                    </MenuItem>
                  )
                )}
              </Select>
            )}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={inline ? 1 : 6}>
        <Controller
          control={control}
          name={'archiveCharge'}
          render={({ field, fieldState }) => (
            <TextField
              label={t('Zuschlag Archiv %')}
              fullWidth
              {...field}
              error={fieldState.isTouched && fieldState.invalid}
              helperText={fieldState.error?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={inline ? 1 : 6}>
        <Controller
          control={control}
          name={'discount'}
          render={({ field, fieldState }) => (
            <TextField
              label={t('Rabatt %')}
              fullWidth
              {...field}
              error={fieldState.isTouched && fieldState.invalid}
              helperText={fieldState.error?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={inline ? 2 : 6}>
        <FormControl fullWidth>
          <InputLabel>{t('Nutzungsdauer')}</InputLabel>
          <Controller
            control={control}
            name={'lifetime'}
            render={({ field }) => (
              <Select
                fullWidth
                {...field}
                label={t('Nutzungsdauer')}
                value={field.value}
                required
              >
                {Object.keys(LicensePressReviewLifespan).map(
                  (lifespan, key) => (
                    <MenuItem
                      value={
                        LicensePressReviewLifespan[
                          lifespan as keyof typeof LicensePressReviewLifespan
                        ]
                      }
                      key={key}
                    >
                      {t(
                        `licensee.pressReview.lifespan.${
                          LicensePressReviewLifespan[
                            lifespan as keyof typeof LicensePressReviewLifespan
                          ]
                        }`
                      )}
                    </MenuItem>
                  )
                )}
              </Select>
            )}
          />
        </FormControl>
      </Grid>
    </>
  );
};
