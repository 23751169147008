import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

export const usePayRateValidation = () => {
  const { t } = useTranslation();

  return useMemo(
    () => ({
      name: yup.string().required(t('Name darf nicht leer sein.')),
      price: yup
        .string()
        .matches(
          /^(\d+(?:[,]\d+)?)$/,
          t('Muss eine Komma-getrennte Dezimalzahl sein.')
        ),
      type: yup.string().required(t('Typ darf nicht leer sein.')),
      comment: yup.string().nullable(),
    }),
    []
  );
};

export const usePayRateValidationSchema = () => {
  const validation = usePayRateValidation();

  return useMemo(() => {
    return yup.object({
      ...validation,
    });
  }, [validation]);
};
