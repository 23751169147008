import { AxiosError, Method } from 'axios';
import { useQuery } from 'react-query';
import { request } from '../lib';
import { Dict } from '../model';

export const useApi = <TData>(
  url: string,
  method: Method = 'get',
  requestData?: unknown,
  params?: unknown,
  headers?: Dict
) => {
  return useQuery<TData, AxiosError>([url, params], async () => {
    const { data } = await request<TData>(
      url,
      method,
      requestData,
      params,
      headers
    );
    return data;
  });
};
