import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

export const usePersonValidation = () => {
  const { t } = useTranslation();

  return useMemo(
    () => ({
      salutation: yup.string(),
      firstName: yup.string().required(t('Vorname darf nicht leer sein')),
      lastName: yup.string().required(t('Nachname darf nicht leer sein.')),
      email: yup.string().email(t('Keine gültige E-Mail Adresse.')),
      titleBefore: yup.string(),
      titleAfter: yup.string(),
      salutationPosition: yup.string(),
      phone: yup.string(),
      mobilePhone: yup.string(),
      externalCode: yup.string(),
      birthdate: yup.string().nullable(),
      birthplace: yup.string().nullable(),
      nationality: yup.string().nullable(),
      street: yup.string().nullable(),
      zipCode: yup.string().nullable(),
      city: yup.string().nullable(),
      country: yup.string().nullable(),
      typeOfContact: yup.string(),
      companies: yup.array().of(
        yup.object().shape({
          company: yup
            .string()
            .required(t('Unternehmen darf nicht leer sein.')),
        })
      ),
      mailingLists: yup.array().of(
        yup.object().shape({
          mailingList: yup
            .string()
            .required(t('Verteiler darf nicht leer sein.')),
        })
      ),
    }),
    []
  );
};

export const usePersonValidationSchema = () => {
  const personValidation = usePersonValidation();

  return useMemo(() => {
    return yup.object({
      ...personValidation,
    });
  }, [personValidation]);
};
