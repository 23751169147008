import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { routes } from '../../lib';
import CreateMailing from './create';
import EditMailing from './edit';
import MailingOverview from './overview';
import MailingDetails from './details';

const Mailings = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <MailingOverview />
      </Route>
      <Route path={routes.mailingCreate}>
        <CreateMailing />
      </Route>
      <Route path={routes.mailingEdit(':mailingId')}>
        <EditMailing />
      </Route>
      <Route path={routes.mailing(':mailingId')}>
        <MailingDetails />
      </Route>
    </Switch>
  );
};

export default Mailings;
