import {
  Addressable,
  Blameable,
  Commentable,
  ID,
  LegacyEntity,
  Medium,
  Nullable,
  Person,
  Timestampable,
} from './';
import { Position } from './position';

export interface PressPassFile {
  date: string,
  filename: string
}

export interface Company
  extends Timestampable,
    Blameable,
    Commentable,
    Addressable,
    LegacyEntity {
  id: ID;
  term: string;
  name: string;
  customer: boolean;
  publisher: boolean;
  advocacy: boolean;
  partner: boolean;
  website: string;
  phone: string;
  email: string;
  parent: Nullable<Company>;
  companies: Company[];
  media: Medium[];
  hasLicense: boolean;
  pressPassFiles: PressPassFile[];
  responsiblePerson: Person;
}

export interface CompanyPerson extends Timestampable, Blameable, Commentable {
  id: ID;
  company: Company;
  person: Person;
  position: Nullable<Position>;
  descriptivePosition: Nullable<string>;
  email: Nullable<string>;
  phone: Nullable<string>;
  mobilePhone: Nullable<string>;
  assistant: Nullable<CompanyPerson>;
  defaultCompany: boolean;
  invoiceContactId: Nullable<string>;
}

export interface CompanyPersonFormValues
  extends Omit<
    CompanyPerson,
    | 'id'
    | 'company'
    | 'person'
    | 'position'
    | 'assistant'
    | keyof Timestampable
    | keyof Blameable
    | keyof Commentable
  > {
  companyPersonId: ID | string;
  company: ID | string;
  person: ID | string;
  position: Nullable<ID>;
  assistant: Nullable<ID>;
}

export enum CompanyType {
  Customer = 'customer',
  Publisher = 'publisher',
  Advocacy = 'advocacy',
  Partner = 'partner',
}

export interface CompanyFormValues
  extends Omit<
    Company,
    | 'id'
    | 'parent'
    | 'media'
    | 'companies'
    | 'state'
    | 'hasLicense'
    | 'advocacy'
    | 'customer'
    | 'publisher'
    | 'partner'
    | 'pressPassFiles'
    | 'responsiblePerson'
    | keyof Timestampable
    | keyof Blameable
    | keyof LegacyEntity
  > {
  media: ID[];
  parent: Nullable<ID>;
  responsiblePerson: Nullable<ID>;
}

export interface CompanyFilters {
  id: ID;
  search: string;
  type: string;
  medium: string;
  zipCode: string;
  city: string;
  country: string;
  state: string;
}

export const getCompanyFormValues = (company?: Company): CompanyFormValues => ({
  name: company?.name || '',
  term: company?.term || '',
  street: company?.street || '',
  zipCode: company?.zipCode || '',
  city: company?.city || '',
  country: company?.country || '',
  comment: company?.comment || '',
  parent: company?.parent?.id || null,
  website: company?.website || '',
  phone: company?.phone || '',
  email: company?.email || '',
  media: company?.media.map((m) => m.id) || [],
  responsiblePerson: company?.responsiblePerson?.id || null,
});

export const getCompanyDisplayName = (company: Company) =>
  `${company.name} ${company.term ? company.term : ''} (${company.id})`;
