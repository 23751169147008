import { Search } from '@mui/icons-material';
import { Breadcrumbs, Grid, Link, TextField, Typography } from '@mui/material';
import React, {
  FunctionComponent,
  KeyboardEvent,
  ReactNode,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { LinkProps, Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';
import { IconLink } from '../icon-link';

const StyledHeader = styled.header`
  margin-bottom: 1em;
`;

const StyledActions = styled.div`
  text-align: right;
`;

interface LinkRouterProps extends LinkProps {
  to: string;
  replace?: boolean;
}

interface SearchProps {
  route: string;
  param: string;
}

// Link proxy for breadcrumb links to React Router
// @ts-ignore
const LinkRouter = (props: LinkRouterProps) => (
  <Link {...props} component={RouterLink as any} />
);

const Header: FunctionComponent<{
  title: ReactNode;
  breadcrumbs?: Array<{
    label: string;
    link?: string;
  }>;
  actions?: ReactNode;
  search?: SearchProps;
}> = ({ title, breadcrumbs = [], actions, search }) => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');
  const history = useHistory();

  const handleKeyPress = (event: KeyboardEvent<HTMLDivElement>) => {
    if (!search || event.key !== 'Enter') {
      return;
    }

    history.push(searchLink);
  };

  const searchLink = search
    ? `${search.route}?${search.param}=${searchTerm}`
    : '';

  return (
    <StyledHeader>
      <Grid container justifyContent={`space-between`}>
        <Grid item xs={12} md={6}>
          <Breadcrumbs>
            {breadcrumbs &&
              breadcrumbs.map((link, key) =>
                link.link ? (
                  <LinkRouter to={link.link} key={key}>
                    {link.label}
                  </LinkRouter>
                ) : (
                  <Typography color="textPrimary" key={key}>
                    {link.label}
                  </Typography>
                )
              )}
          </Breadcrumbs>
          <Typography variant="h2" component="h1" gutterBottom>
            {title}
          </Typography>
          {search && (
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <TextField
                  label="Suche"
                  variant="outlined"
                  size="small"
                  value={searchTerm}
                  onKeyPress={(event) => handleKeyPress(event)}
                  onChange={(event) => setSearchTerm(event.target.value)}
                />
              </Grid>
              <Grid item>
                <IconLink
                  to={searchLink}
                  tooltip={t('Suchen')}
                  icon={<Search color={searchTerm ? 'primary' : 'inherit'} />}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <StyledActions>{actions}</StyledActions>
        </Grid>
      </Grid>
    </StyledHeader>
  );
};

export default Header;
