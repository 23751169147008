import {
  Blameable,
  Commentable,
  Company,
  CompanyFormValues,
  CompanyPerson,
  LicensePressReview,
  Medium,
  Person,
  TariffGroup,
  Timestampable,
  getCompanyFormValues,
} from './index';
import { PayRate } from './pay-rates';
import { DateTimeString, ID, Nullable } from './types';

export interface Licensee extends Company {
  debtorAccount?: Nullable<string>;
  supplierAccount?: Nullable<string>;
  license?: Nullable<number>;
  mbo?: Nullable<Company>;
  contractStart?: Nullable<DateTimeString>;
  contractEnd?: Nullable<DateTimeString>;
  licenseInterval?: Nullable<LicenseInterval>;
  payRate?: Nullable<PayRate>;
  licenseContactPersons?: LicenseContactPerson[];
  licensePressReviews?: LicensePressReview[];
  lastBillingDate?: DateTimeString;
  balance?: {
    transactionUnitSum: number;
    purchaseNet: number;
    licenseRevenueNet: number;
    balanceNet: string;
    balanceNetFloat: number;
  };
}

export interface LicenseContactPerson
  extends Blameable,
    Commentable,
    Timestampable {
  id: ID;
  company: Company;
  person: Person;
  companyPerson: Nullable<CompanyPerson>;
  type: string;
}

export interface LicenseeFormValues extends CompanyFormValues {
  debtorAccount?: Nullable<string>;
  supplierAccount?: Nullable<string>;
  license?: Nullable<number>;
  mbo?: Nullable<ID>;
  contractStart?: Nullable<DateTimeString>;
  contractEnd?: Nullable<DateTimeString>;
  licenseInterval?: Nullable<LicenseInterval>;
  payRate?: Nullable<ID>;
}

export interface LicenseeEvaluationFormValues {
  from: Nullable<DateTimeString>;
  to: Nullable<DateTimeString>;
}

export enum LicenseInterval {
  Monthly = 1,
  Quarterly = 3,
  Yearly = 12,
}

export enum LicenseContactType {
  Invoice = 'invoice',
  Contract = 'contract',
  Report = 'report',
  Announcement = 'announcement',
}

export interface LicenseeFilters {
  search: string;
  hasLicense: boolean;
  status: string;
  payRate: string;
  from: Nullable<Date>;
  to: Nullable<Date>;
}

export const getLicenseeFormValues = (
  company?: Licensee,
  nextLicense?: number
): LicenseeFormValues => {
  const companyFormValues = getCompanyFormValues(company);
  return {
    ...companyFormValues,
    debtorAccount: company?.debtorAccount || '',
    supplierAccount: company?.supplierAccount || '',
    license: company?.license || nextLicense || 0,
    mbo: company?.mbo?.id || null,
    contractStart: company?.contractStart || '',
    contractEnd: company?.contractEnd || '',
    licenseInterval: company?.licenseInterval || 1,
    payRate: company?.payRate?.id || null,
  };
};

export interface LicenseContactPersonFormValues {
  type: LicenseContactType;
  person: Nullable<ID>;
  companyPerson: Nullable<ID>;
}

export const getLicenseContactPersonFormValues =
  (): LicenseContactPersonFormValues => {
    return {
      type: LicenseContactType.Invoice,
      person: null,
      companyPerson: null,
    };
  };

export interface Credit extends Blameable, Commentable, Timestampable {
  id: ID;
  creditDate: DateTimeString;
  netValue: number;
  grossValue: number;
}

export interface Transaction extends Blameable, Commentable, Timestampable {
  id: ID;
  company: Pick<Licensee, 'id' | 'name' | 'license'>;
  license: number;
  medium: Pick<Medium, 'id' | 'name' | 'referenceId'>;
  referenceId: string;
  type: string;
  usage: string;
  usageDate: DateTimeString;
  importDate: DateTimeString;
  tariffGroup: TariffGroup;
  tariffGroupImport: string;
  units: number;
  unitPrice: number;
  archiveCharge: Nullable<number>;
  apa: Nullable<number>;
  discount: Nullable<number>;
  grossValue: number;
  netValue: number;
  billingDateLicensee: Nullable<DateTimeString>;
  billingDateMedium: Nullable<DateTimeString>;
}
