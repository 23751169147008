import { Delete, Edit, PersonPin } from '@mui/icons-material';
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Tooltip,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { VoidFunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Link, useParams } from 'react-router-dom';
import { GridData } from '../../components/grid-data';
import Guarded from '../../components/guarded';
import Header from '../../components/header';
import HttpError from '../../components/http-error';
import LoadingContainer from '../../components/loading-container';
import { ModifiedEntity } from '../../components/modified-entity';
import { useCurrentUser, useTitle } from '../../hooks';
import { apiRoutes, request, routes, useUserDetailsApi } from '../../lib';
import { Role } from '../../model';

const UserDetails: VoidFunctionComponent = () => {
  const { t } = useTranslation();
  const { userId } = useParams<{ userId: string | undefined }>();
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, error, data } = useUserDetailsApi(
    userId as unknown as number
  );
  const history = useHistory();
  const currentUser = useCurrentUser();
  useTitle(data?.username || t('Benutzer Details'));

  if (error) {
    return (
      <HttpError
        error={error}
        actions={
          <Button component={Link} to={`/users`}>
            Back to User overview
          </Button>
        }
      />
    );
  }

  if (isLoading || !data) {
    return <LoadingContainer />;
  }

  const detailBreadcrumbs = [
    { label: 'Home', link: routes.dashboard },
    { label: t('Benutzer'), link: routes.users },
  ];

  const handleDelete = () => {
    const confirm = window.confirm(
      t('Benutzer {{username}} wirklich löschen?', { username: data.username })
    );
    if (!confirm) {
      return;
    }
    request(apiRoutes.user(data.id), 'delete')
      .then(() => {
        enqueueSnackbar(t('Benutzer wurde erfolgreich gelöscht'), {
          variant: 'success',
        });
        history.push('/users');
      })
      .catch(() =>
        enqueueSnackbar(t('Benutzer konnte nicht gelöscht werden'), {
          variant: 'error',
        })
      );
  };

  const impersonate = () => {
    request(apiRoutes.profile.me, 'get', {}, { _switch_user: data.email })
      .then(() => window.location.reload())
      .catch(() =>
        enqueueSnackbar(t('Benutzer konnte nicht impersonated werden'), { variant: 'error' })
      );
  };

  return (
    <Container maxWidth="md">
      <Header
        title={data.username}
        breadcrumbs={detailBreadcrumbs}
        actions={
          <>
            <Guarded requiredRole={Role.AllowedToSwitch}>
              {currentUser.id !== data.id &&
                !data.roles.includes(Role.SuperAdmin) && (
                  <Tooltip title={t<string>('Impersonate')}>
                    <IconButton
                      color="inherit"
                      disabled={!!currentUser.impersonator}
                      onClick={impersonate}
                      size="large"
                    >
                      <PersonPin />
                    </IconButton>
                  </Tooltip>
                )}{' '}
            </Guarded>
            {!data.roles.includes(Role.SuperAdmin) && (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  component={Link}
                  to={routes.userEdit(data.id)}
                  startIcon={<Edit />}
                >
                  {t('Bearbeiten')}
                </Button>{' '}
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleDelete}
                  startIcon={<Delete />}
                >
                  {t('Löschen')}
                </Button>
              </>
            )}
            <ModifiedEntity entity={data} />
          </>
        }
      />
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <GridData xs={6} label={t<string>('Username')}>
              {data.username}
            </GridData>
            <GridData xs={6} label={t<string>('Name')}>
              {data.fullName ? data.fullName : '-'}
            </GridData>
            <GridData xs={12} label={t<string>('E-Mail')}>
              {data.email}
            </GridData>
          </Grid>
        </CardContent>
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <GridData xs={6} label={t<string>('Rollen')}>
              {data.roles.join(', ')}
            </GridData>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={data.enabled}
                    readOnly={true}
                    name="enabled"
                    color="primary"
                  />
                }
                label={t<string>('Aktiv')}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Container>
  );
};

export default UserDetails;
