import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { routes } from '../../lib';
import PositionDetails from './details';
import EditPosition from './edit';
import PositionOverview from './overview';
import CreatePosition from "./create";

const Positions = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <PositionOverview />
      </Route>
      <Route path={routes.positionCreate}>
        <CreatePosition />
      </Route>
      <Route path={routes.positionEdit(':positionId')}>
        <EditPosition />
      </Route>
      <Route path={routes.position(':positionId')}>
        <PositionDetails />
      </Route>
    </Switch>
  );
};

export default Positions;
