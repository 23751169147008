import { ID } from '../model';

export const routes = {
  dashboard: '/',
  search: '/search',

  profile: '/profile',
  profilePassword: '/profile/password',

  users: '/users',
  user: (id: ID | string) => `/users/${id}`,
  userEdit: (id: ID | string) => `/users/${id}/edit`,
  userCreate: '/users/create',

  logs: '/logs',
  log: (id: ID | string) => `/logs/${id}`,

  companies: '/companies',
  company: (id: ID | string) => `/companies/${id}`,
  companyFiles: (id: ID | string) => `/companies/${id}/files`,
  companyEdit: (id: ID | string) => `/companies/${id}/edit`,
  companyCreate: '/companies/create',

  persons: '/persons',
  person: (id: ID | string) => `/persons/${id}`,
  personEdit: (id: ID | string) => `/persons/${id}/edit`,
  personCreate: '/persons/create',

  pressPasses: '/press-passes',
  pressPass: (id: ID | string) => `/press-passes/${id}`,
  pressPassEdit: (id: ID | string) => `/press-passes/${id}/edit`,
  pressPassCreate: '/press-passes/create',

  media: '/media',
  medium: (id: ID | string) => `/media/${id}`,
  mediumEdit: (id: ID | string) => `/media/${id}/edit`,
  mediumCreate: '/media/create',

  positions: '/positions',
  position: (id: ID | string) => `/positions/${id}`,
  positionEdit: (id: ID | string) => `/positions/${id}/edit`,
  positionCreate: '/positions/create',

  mailingLists: '/mailing-lists',
  mailingList: (id: ID | string) => `/mailing-lists/${id}`,
  mailingListEdit: (id: ID | string) => `/mailing-lists/${id}/edit`,
  mailingListCreate: '/mailing-lists/create',
  mailingListExport: (id: ID | string) => `mailing-lists/${id}/export`,

  signatures: '/signatures',
  signature: (id: ID | string) => `/signatures/${id}`,
  signatureEdit: (id: ID | string) => `/signatures/${id}/edit`,
  signatureCreate: '/signatures/create',

  mailings: '/mailings',
  mailing: (id: ID | string) => `/mailings/${id}`,
  mailingEdit: (id: ID | string) => `/mailings/${id}/edit`,
  mailingCreate: '/mailings/create',

  pressPassApplications: '/press-pass-applications',
  pressPassApplication: (id: ID | string) => `/press-pass-applications/${id}`,
  pressPassUpdateApplications: '/press-pass-update-applications',
  pressPassUpdateApplication: (id: ID | string) => `/press-pass-update-applications/${id}`,

  payRates: '/pay-rates',
  payRate: (id: ID | string) => `/pay-rates/${id}`,
  payRateEdit: (id: ID | string) => `/pay-rates/${id}/edit`,
  payRateCreate: '/pay-rates/create',

  tariffs: '/tariffs',
  tariff: (id: ID | string) => `/tariffs/${id}`,
  tariffEdit: (id: ID | string) => `/tariffs/${id}/edit`,
  tariffCreate: '/tariffs/create',

  licensees: '/licensees',
  licenseesExport: '/licensees/export',
  licensee: (id: ID | string) => `/licensees/${id}`,
  licenseeEdit: (id: ID | string) => `/licensees/${id}/edit`,
  licenseeCreate: '/licensees/create',

  bmdExportAll: `licensees/bmd-export`,

  imports: '/imports',
  import: (id: ID | string) => `/imports/${id}`,
  importCreate: '/imports/create',
};
