import { Grid, TextField } from '@mui/material';
import React, { VoidFunctionComponent } from 'react';
import { Control, Controller, useFieldArray } from 'react-hook-form';
import { TariffFormValues } from '../../model';
import {useTranslation} from "react-i18next";

export const TariffGroupFormFields: VoidFunctionComponent<{
  control: Control<TariffFormValues>;
}> = ({ control }) => {
  const { fields } = useFieldArray<TariffFormValues>({
    control,
    name: 'tariffGroups',
  });
  const {t} = useTranslation();

  return (
    <>
      {fields.map((field, index) => (
        <Grid container spacing={3} key={field.id}>
          <Grid item style={{ display: 'none' }}>
            <Controller
              control={control}
              name={`tariffGroups.${index}.groupNumber` as const}
              render={({ field }) => (
                <TextField type="hidden" value={field.value} />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              control={control}
              name={`tariffGroups.${index}.groupKey` as const}
              render={({ field }) => <TextField disabled value={field.value} />}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              control={control}
              name={`tariffGroups.${index}.label` as const}
              render={({ field, fieldState }) => (
                <TextField
                  fullWidth
                  {...field}
                  placeholder={t('Anzahl')}

                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              control={control}
              name={`tariffGroups.${index}.price` as const}
              render={({ field, fieldState }) => (
                <TextField
                  fullWidth
                  {...field}
                  placeholder={t('Preis')}
                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
        </Grid>
      ))}
    </>
  );
};
