import { Description } from '@mui/icons-material';
import { DatePicker } from '@mui/lab';
import {
  Autocomplete,
  Button,
  CardContent,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { VoidFunctionComponent } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getYearOptions } from '../../helpers';
import { useUnlimitedPaginationApi } from '../../hooks';
import { apiRoutes } from '../../lib';
import {
  BillTo,
  DateFormat,
  EmploymentStatus,
  ID,
  Membership,
  Nullable,
  PressPass,
  PressPassFormValues, PressPassStatus,
} from '../../model';
import { Position } from '../../model/position';
import Error from '../error';
import { StyledCardTitle } from '../globals';
import { GridData } from '../grid-data';
import PaginatedAutocomplete from '../paginated-autocomplete';
import PressPassPhoto from './photo';

export const PressPassFormFields: VoidFunctionComponent<{
  control: Control<PressPassFormValues>;
  pressPass?: PressPass;
  medium: Nullable<ID>;
}> = ({ control, pressPass, medium }) => {
  const { t } = useTranslation();

  const {
    data: positions,
    isLoading: positionsLoading,
    error: positionsError,
  } = useUnlimitedPaginationApi<Record<string, never>, Position>(
    apiRoutes.positions
  );

  if (positionsError) {
    return (
      <CardContent>
        <Error title={'Error'}/>
      </CardContent>
    );
  }

  return (
    <>
      <CardContent>
        <StyledCardTitle variant="h6" color="secondary" gutterBottom>
          {t('Mitgliedschaft')}
        </StyledCardTitle>
        <Grid item xs={12} md={6}>
          <FormControl variant="standard" fullWidth>
            <InputLabel>{t('Mitgliedschaft')}</InputLabel>
            <Controller
              control={control}
              name="membership"
              render={({ field }) => (
                <Select {...field} displayEmpty defaultValue={''}>
                  <MenuItem value={Membership.Voez}>
                    {t(`membership.${Membership.Voez}`)}
                  </MenuItem>
                  <MenuItem value={Membership.Oezv}>
                    {t(`membership.${Membership.Oezv}`)}
                  </MenuItem>
                  <MenuItem value="">{t('Keine')}</MenuItem>
                </Select>
              )}
            />
          </FormControl>
        </Grid>
      </CardContent>
      <Divider/>
      <CardContent>
        <StyledCardTitle variant="h6" color="secondary" gutterBottom>
          {t('Medium')}
        </StyledCardTitle>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="medium"
              render={({ field, fieldState }) => (
                <PaginatedAutocomplete
                  name="media"
                  label={t('Medium')}
                  value={field.value}
                  onChange={(ids) => field.onChange(ids?.[0] || null)}
                  initialData={
                    pressPass && pressPass.medium ? [pressPass.medium] : []
                  }
                  optionLabel="name"
                  dataUrl={apiRoutes.media}
                  textFieldProps={{
                    error: fieldState.invalid,
                    helperText: fieldState.error?.message,
                    required: !pressPass?.company,
                  }}
                  filters={{ pressPass: true }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="company"
              render={({ field, fieldState }) => (
                <PaginatedAutocomplete
                  name="company"
                  label={t('Unternehmen')}
                  value={field.value}
                  onChange={(ids) => field.onChange(ids?.[0] || null)}
                  multiple={false}
                  optionLabel={(option) =>
                    `${option.name} ${option.term || ''} (${option.id})`
                  }
                  dataUrl={apiRoutes.companies}
                  disabled={!!medium}
                  initialData={
                    pressPass && pressPass.company ? [pressPass.company] : []
                  }
                  textFieldProps={{
                    error: fieldState.isTouched && fieldState.invalid,
                    helperText: fieldState.error?.message,
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl variant="standard" fullWidth>
              <InputLabel>{t('Dienstverhältnis')}</InputLabel>
              <Controller
                control={control}
                name="employmentStatus"
                render={({ field }) => (
                  <Select {...field}>
                    <MenuItem value={EmploymentStatus.Employed}>
                      {t(
                        `pressPass.employmentStatus.${EmploymentStatus.Employed}`
                      )}
                    </MenuItem>
                    <MenuItem value={EmploymentStatus.Selfemployed}>
                      {t(
                        `pressPass.employmentStatus.${EmploymentStatus.Selfemployed}`
                      )}
                    </MenuItem>
                    <MenuItem value={EmploymentStatus.Freelance}>
                      {t(
                        `pressPass.employmentStatus.${EmploymentStatus.Freelance}`
                      )}
                    </MenuItem>
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <Controller
              control={control}
              name="position"
              render={({ field, fieldState }) => (
                <Autocomplete
                  options={positions}
                  loading={positionsLoading}
                  loadingText={t('Lädt...')}
                  noOptionsText={t('Keine Funktion verfügbar')}
                  getOptionLabel={(option) => option.title}
                  value={
                    positions.find((position) => position.id === field.value) ||
                    null
                  }
                  onChange={(event, value) => field.onChange(value?.id)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t('Funktion')}
                      error={fieldState.isTouched && fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              )}
            />
          </Grid>
        </Grid>
      </CardContent>
      <Divider/>
      <CardContent>
        <StyledCardTitle variant="h6" color="secondary" gutterBottom>
          {t('Verwaltung')}
        </StyledCardTitle>
        <Grid container spacing={3}>
          <Grid item md={6}>
            <Grid container spacing={3}>
              <GridData xs={12} label={t<string>('Foto')} mode="richtext">
                <>
                  <PressPassPhoto src={pressPass?.photo || ''}/>
                  <Controller
                    control={control}
                    name="photoUpload"
                    render={({ field, fieldState }) => (
                      <>
                        <input
                          {...field}
                          value=""
                          accept="image/*"
                          id="photoUpload"
                          hidden
                          type="file"
                          onChange={(e) =>
                            field.onChange(e.currentTarget.files![0])
                          }
                        />
                        <label htmlFor="photoUpload">
                          <Button
                            variant="contained"
                            component="span"
                            color="primary"
                          >
                            <Description/> {t('Datei auswählen')}
                          </Button>
                        </label>
                        <Typography display="block" variant="caption">
                          {field.value?.name}
                        </Typography>
                        {fieldState.error && (
                          <FormHelperText error={true}>
                            {fieldState.error?.message}
                          </FormHelperText>
                        )}
                      </>
                    )}
                  />
                </>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="deletePhoto"
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <Tooltip title={t<string>('Foto löschen')}>
                            <Checkbox
                              checked={field.value}
                              onChange={(e) => field.onChange(e.target.checked)}
                            />
                          </Tooltip>
                        }
                        label={
                          field.value
                            ? t<string>('Foto wird entfernt')
                            : t<string>('Foto entfernen')
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="photoUpdatedAt"
                    render={({ field, fieldState }) => (
                      <DatePicker
                        value={field.value}
                        inputFormat={DateFormat.Default}
                        mask={DateFormat.Mask}
                        onChange={field.onChange}
                        label={t('Foto aktualisiert am')}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            fullWidth
                            helperText={fieldState.error?.message}
                            error={fieldState.isTouched && fieldState.invalid}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
              </GridData>
              <Grid item xs={12} style={{ marginTop: '4px' }}>
                <FormControl fullWidth variant="standard">
                  <InputLabel>{t('Rechnungsempfänger')}</InputLabel>
                  <Controller
                    control={control}
                    name="billTo"
                    render={({ field }) => (
                      <Select {...field}>
                        <MenuItem value={BillTo.Company}>
                          {t(`pressPass.billTo.${BillTo.Company}`)}
                        </MenuItem>
                        <MenuItem value={BillTo.Person}>
                          {t(`pressPass.billTo.${BillTo.Person}`)}
                        </MenuItem>
                        <MenuItem value={BillTo.Unknown}>
                          {t(`pressPass.billTo.${BillTo.Unknown}`)}
                        </MenuItem>
                      </Select>
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={6}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="passNumber"
                  render={({ field, fieldState }) => (
                    <TextField
                      fullWidth
                      label={t('Ausweisnummer')}
                      {...field}
                      error={fieldState.isTouched && fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="passDate"
                  render={({ field, fieldState }) => (
                    <DatePicker
                      value={field.value}
                      inputFormat={DateFormat.Default}
                      mask={DateFormat.Mask}
                      onChange={field.onChange}
                      label={t('Ausweis ausgefolgt am')}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          fullWidth
                          helperText={fieldState.error?.message}
                          error={fieldState.isTouched && fieldState.invalid}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="passWithdrawalDate"
                  render={({ field, fieldState }) => (
                    <DatePicker
                      value={field.value}
                      inputFormat={DateFormat.Default}
                      mask={DateFormat.Mask}
                      onChange={field.onChange}
                      label={t('Ausweis eingezogen am')}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          fullWidth
                          helperText={fieldState.error?.message}
                          error={fieldState.isTouched && fieldState.invalid}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="passPrintDate"
                  render={({ field, fieldState }) => (
                    <DatePicker
                      value={field.value}
                      inputFormat={DateFormat.Default}
                      mask={DateFormat.Mask}
                      onChange={field.onChange}
                      label={t('Druckdatum')}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          fullWidth
                          helperText={fieldState.error?.message}
                          error={fieldState.isTouched && fieldState.invalid}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth variant="standard">
                  <InputLabel>{t('Marke')}</InputLabel>
                  <Controller
                    control={control}
                    name="badge.year"
                    render={({ field }) => (
                      <Select {...field}>
                        <MenuItem value="">{t('Keine')}</MenuItem>
                        {getYearOptions().map((year, index) => (
                          <MenuItem key={index} value={year}>
                            {year}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="badgeDate"
                  render={({ field, fieldState }) => (
                    <DatePicker
                      value={field.value}
                      inputFormat={DateFormat.Default}
                      mask={DateFormat.Mask}
                      onChange={field.onChange}
                      label={t('Marke ausgefolgt am')}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          fullWidth
                          helperText={fieldState.error?.message}
                          error={fieldState.isTouched && fieldState.invalid}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="badge.carBadge"
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={field.value}
                      onChange={field.onChange}
                      color="primary"
                    />
                  }
                  label={t<string>('Autoschild')}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl variant="standard" fullWidth>
              <InputLabel>{t('Status')}</InputLabel>
              <Controller
                control={control}
                name="status"
                render={({ field }) => (
                  <Select {...field}>
                    <MenuItem value={PressPassStatus.Created}>
                      {t(
                        `pressPass.status.${PressPassStatus.Created}`
                      )}
                    </MenuItem>
                    <MenuItem value={PressPassStatus.PhotoUpdated}>
                      {t(
                        `pressPass.status.${PressPassStatus.PhotoUpdated}`
                      )}
                    </MenuItem>
                    <MenuItem value={PressPassStatus.ReadyForExport}>
                      {t(
                        `pressPass.status.${PressPassStatus.ReadyForExport}`
                      )}
                    </MenuItem>
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="enabled"
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={field.value}
                      onChange={field.onChange}
                      color="primary"
                    />
                  }
                  label={t<string>('Aktiver Presseausweis')}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="carBadgeDate"
              render={({ field, fieldState }) => (
                <DatePicker
                  value={field.value}
                  inputFormat={DateFormat.Default}
                  mask={DateFormat.Mask}
                  onChange={field.onChange}
                  label={t('Ausgabe Autoschild am')}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      fullWidth
                      helperText={fieldState.error?.message}
                      error={fieldState.isTouched && fieldState.invalid}
                    />
                  )}
                />
              )}
            />
          </Grid>
        </Grid>
      </CardContent>
    </>
  );
};
