import { Blameable, DateTimeString, ID, Timestampable, User } from './';

export enum ImportStatus {
  Draft = 'draft',
  Done = 'done',
}

export interface WorksheetData {
  name: string;
  valid: boolean;
  index: number;
  rows: Array<{
    row: number;
    violations: Array<{
      column: number;
      message: string;
    }>;
    data: (string | number)[];
  }>;
  schema: string[];
}

export type ImportData = WorksheetData[];

export interface Import extends Timestampable, Blameable {
  id: ID;
  status: ImportStatus;
  data: ImportData;
  user: User;
  filename: string;
  schema: string[];
  processDate: DateTimeString;
  stats: {
    total: number;
  };
}

export function isSchemaResponse(response: any): response is string[] {
  return (
    typeof response.schema !== undefined && Object.keys(response).length === 1
  );
}
