import { Add, Delete, Edit, Visibility } from '@mui/icons-material';
import { LoadingButton, TabContext } from '@mui/lab';
import { Button, Card, Divider, Paper, Tab, Tabs } from '@mui/material';
import { AxiosError } from 'axios';
import { StyledContainer, StyledTabPanel } from 'components/globals';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router';
import { Link as RouterLink, useParams, useRouteMatch } from 'react-router-dom';
import { CompanyBaseData } from '../../components/company';
import Guarded from '../../components/guarded';
import Header from '../../components/header';
import HttpError from '../../components/http-error';
import LoadingContainer from '../../components/loading-container';
import { ModifiedEntity } from '../../components/modified-entity';
import { hasRole } from '../../helpers';
import { useCurrentUser, useTitle } from '../../hooks';
import { apiRoutes, request, routes, useCompanyDetailsApi } from '../../lib';
import { Role, getCompanyDisplayName } from '../../model';
import CompanyLicensee from './licensee';
import Media from './media';
import Persons from './persons';
import { PressPassFiles } from "./files";

const CompanyDetails = () => {
  const { t } = useTranslation();
  const { companyId } = useParams<{ companyId: string }>();
  const { isLoading, error, data } = useCompanyDetailsApi(+companyId);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const user = useCurrentUser();

  useTitle(data?.name || t('Unternehmen Details'));

  const base = routes.company(companyId);
  const tabRoutes = {
    persons: `${base}/persons`,
    pdn: `${base}/pdn`,
    media: `${base}/media`,
    files: `${base}/files`,
    // It's important to keep routes in descendant order (or, in other words, keep the `/` root at the end)
    details: `${base}/`,
  };
  const routeMatch = useRouteMatch(Object.values(tabRoutes));
  const currentTab = routeMatch?.path || tabRoutes.details;

  const mutation = useMutation(
    async () => {
      if (!data) {
        return;
      }

      return await request(apiRoutes.company(data.id), 'delete');
    },
    {
      onSuccess: () => {
        enqueueSnackbar(t('Unternehmen wurde erfolgreich gelöscht.'), {
          variant: 'success',
        });
        history.push(routes.companies);
      },
      onError: (err: AxiosError) => {
        enqueueSnackbar(
          err.response?.data.message ||
            t('Unternehmen konnte nicht gelöscht werden.'),
          {
            variant: 'error',
          }
        );
      },
    }
  );

  if (error) {
    return (
      <HttpError
        error={error}
        actions={
          <Button component={RouterLink} to={routes.companies}>
            {t('Zurück zu Unternehmen')}
          </Button>
        }
      />
    );
  }

  if (isLoading || !data) {
    return <LoadingContainer />;
  }

  const detailBreadcrumbs = [
    { label: t('Home'), link: routes.dashboard },
    { label: t('Unternehmen'), link: routes.companies },
  ];

  return (
    <StyledContainer>
      <Header
        title={getCompanyDisplayName(data)}
        breadcrumbs={detailBreadcrumbs}
        search={{ route: routes.companies, param: 'search' }}
        actions={
          <>
            <Guarded requiredRole={Role.PDN}>
              {data.hasLicense ? (
                <Button
                  variant="outlined"
                  color="primary"
                  component={RouterLink}
                  to={routes.licensee(data.id)}
                >
                  <Visibility /> {t('Lizenznehmer')}
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  color="primary"
                  component={RouterLink}
                  to={routes.licenseeEdit(data.id)}
                >
                  <Add /> {t('Lizenznehmer')}
                </Button>
              )}{' '}
            </Guarded>
            <Guarded requiredRole={Role.General}>
              <Button
                variant="contained"
                color="primary"
                component={RouterLink}
                to={routes.companyEdit(data.id)}
              >
                <Edit /> {t('Bearbeiten')}
              </Button>{' '}
              <LoadingButton
                variant="contained"
                color="secondary"
                loading={mutation.isLoading}
                onClick={() => {
                  if (
                    !window.confirm(
                      t(
                        `Wollen Sie das Unternehmen {{name}} wirklich löschen?`,
                        {
                          name: data.name,
                        }
                      )
                    )
                  ) {
                    return;
                  }
                  mutation.mutateAsync();
                }}
              >
                <Delete /> {t('Löschen')}
              </LoadingButton>
            </Guarded>
            <ModifiedEntity entity={data} />
          </>
        }
      />
      <Card>
        <Paper square={true}>
          <TabContext value={currentTab}>
            <Tabs
              value={currentTab}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab
                label={t('Unternehmen')}
                component={RouterLink}
                to={tabRoutes.details}
                value={tabRoutes.details}
              />
              <Tab
                label={t('Personen')}
                component={RouterLink}
                to={tabRoutes.persons}
                value={tabRoutes.persons}
              />
              <Tab
                label={t('Medien')}
                component={RouterLink}
                to={tabRoutes.media}
                value={tabRoutes.media}
              />
              {hasRole(user, Role.PDN) && (
                <Tab
                  label={t('PDN')}
                  component={RouterLink}
                  to={tabRoutes.pdn}
                  value={tabRoutes.pdn}
                />
              )}
              <Tab
                label={t('Dateien')}
                component={RouterLink}
                to={tabRoutes.files}
                value={tabRoutes.files}
              />

            </Tabs>
            <Divider />
            <StyledTabPanel value={tabRoutes.details}>
              <CompanyBaseData company={data} />
            </StyledTabPanel>
            <StyledTabPanel value={tabRoutes.persons}>
              <Persons company={data} />
            </StyledTabPanel>
            <StyledTabPanel value={tabRoutes.media}>
              <Media company={data} />
            </StyledTabPanel>

            <StyledTabPanel value={tabRoutes.files}>
              <PressPassFiles company={data} />
            </StyledTabPanel>

            {hasRole(user, Role.PDN) && (
              <StyledTabPanel value={tabRoutes.pdn}>
                <CompanyLicensee company={data} />
              </StyledTabPanel>
            )}
          </TabContext>
        </Paper>
      </Card>
    </StyledContainer>
  );
};

export default CompanyDetails;
