import {
  CardContent,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import React, { VoidFunctionComponent } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PayRateFormValues, PayRateType } from '../../model';
import { StyledCardTitle } from '../globals';

export const PayRateFormFields: VoidFunctionComponent<{
  control: Control<PayRateFormValues>;
}> = ({ control }) => {
  const { t } = useTranslation();

  return (
    <>
      <CardContent>
        <StyledCardTitle variant="h6" color="secondary" gutterBottom>
          {t('Informationen')}
        </StyledCardTitle>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Controller
              control={control}
              name={'name'}
              render={({ field, fieldState }) => (
                <TextField
                  label={t('Name')}
                  fullWidth
                  required
                  {...field}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth required>
              <InputLabel>{t('Typ')}</InputLabel>
              <Controller
                control={control}
                name={'type'}
                render={({ field, fieldState }) => (
                  <>
                    <Select
                      fullWidth
                      {...field}
                      label={t('Typ')}
                      value={field.value}
                      error={fieldState.invalid}
                    >
                      {Object.keys(PayRateType).map((type, key) => (
                        <MenuItem
                          value={PayRateType[type as keyof typeof PayRateType]}
                          key={key}
                        >
                          {t(`payRate.type.${type.toLowerCase()}`)}
                        </MenuItem>
                      ))}
                    </Select>
                    {fieldState.error ? (
                      <FormHelperText error>
                        {fieldState.error?.message}
                      </FormHelperText>
                    ) : null}
                  </>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name={'price'}
              render={({ field, fieldState }) => (
                <TextField
                  label={t('Preis')}
                  fullWidth
                  {...field}
                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Controller
              control={control}
              name={'comment'}
              render={({ field, fieldState }) => (
                <TextField
                  label={t('Interner Kommentar')}
                  fullWidth
                  multiline={true}
                  {...field}
                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
        </Grid>
      </CardContent>
    </>
  );
};
