import { CardContent, Divider, Grid, Link } from '@mui/material';
import React, { VoidFunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Company } from '../../model';
import { StyledCardTitle } from '../globals';
import { GridData } from '../grid-data';
import { Link as RouterLink } from "react-router-dom";
import { routes } from "../../lib";

export const CompanyBaseData: VoidFunctionComponent<{ company: Company }> = ({
                                                                               company,
                                                                             }) => {
  const { t } = useTranslation();

  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <CardContent>
          <Grid container spacing={3}>
            <GridData xs={12} md={6} label={t<string>('Name')}>
              {company.name}
            </GridData>
            <GridData xs={12} md={6} label={t<string>('Namenszusatz')}>
              {company.term}
            </GridData>
          </Grid>
        </CardContent>
        <Divider/>
        <CardContent>
          <StyledCardTitle variant="h6" color="secondary" gutterBottom>
            {t('Adresse')}
          </StyledCardTitle>
          <Grid container spacing={3}>
            <GridData xs={12} md={6} label={t<string>('Straße')}>
              {company.street}
            </GridData>
            <GridData xs={12} md={6} label={t<string>('Ort')}>
              {company.zipCode + ' ' + company.city}
            </GridData>
            <GridData xs={12} md={6} label={t<string>('Bundesland')}>
              {company.state}
            </GridData>
            <GridData xs={12} md={6} label={t<string>('Land')}>
              {company.country}
            </GridData>
          </Grid>
        </CardContent>
      </Grid>
      <Divider
        orientation="vertical"
        flexItem
        style={{ marginRight: '-1px' }}
      />
      <Grid item xs={12} md={6}>
        <CardContent>
          <StyledCardTitle variant="h6" color="secondary" gutterBottom>
            {t('Kontakt')}
          </StyledCardTitle>
          <Grid container spacing={3}>
            <GridData xs={12} md={6} label={t<string>('Telefon')}>
              {company.phone}
            </GridData>
            <GridData xs={12} md={6} label={t<string>('Office Email')}>
              {company.email}
            </GridData>
            <GridData xs={12} md={6} label={t<string>('Website')}>
              {company.website && (
                <Link href={company.website} rel="noopener" target="_blank">
                  {company.website}
                </Link>
              )}
            </GridData>
          </Grid>
        </CardContent>
        <Divider/>
        <CardContent>
          <StyledCardTitle variant="h6" color="secondary" gutterBottom>
            {t('Sonstiges')}
          </StyledCardTitle>
          <Grid container spacing={3}>
            <GridData xs={12} label={t<string>('PA-Veranwortliche/r')}>
              {company.responsiblePerson && (
                <Link
                  component={RouterLink}
                  to={routes.person(company.responsiblePerson.id)}
                >
                  {company.responsiblePerson?.displayName}
                </Link>
              )}
              {company.responsiblePerson == null && t('Es wurde noch keine Person zugeordnet.')}
            </GridData>
            <GridData xs={12} label={t<string>('Interne Notiz')}>
              {company.comment ||
                t('Es wurde noch kein Kommentar hinzugefügt.')}
            </GridData>
          </Grid>
        </CardContent>
      </Grid>
    </Grid>
  );
};
