import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { routes } from '../../lib';
import CreatePayRate from './create';
import PayRateDetails from './details';
import EditPayRate from './edit';
import PayRatesOverview from './overview';

const PayRates = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <PayRatesOverview />
      </Route>
      <Route path={routes.payRateCreate}>
        <CreatePayRate />
      </Route>
      <Route path={routes.payRateEdit(':payRateId')}>
        <EditPayRate />
      </Route>
      <Route path={routes.payRate(':payRateId')}>
        <PayRateDetails />
      </Route>
    </Switch>
  );
};

export default PayRates;
