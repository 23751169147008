import { DatePicker } from '@mui/lab';
import {
  Autocomplete,
  CardContent,
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import React, { VoidFunctionComponent } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  CountryList,
  DateFormat,
  PressPassFormValues,
  Salutation,
} from '../../model';
import { StyledCardTitle } from '../globals';

export const PressPassPersonFormFields: VoidFunctionComponent<{
  control: Control<PressPassFormValues>;
}> = ({ control }) => {
  const { t } = useTranslation();

  return (
    <>
      <CardContent>
        <StyledCardTitle variant="h6" color="secondary" gutterBottom>
          {t('Informationen zur Person')}
        </StyledCardTitle>
        <Grid container spacing={3}>
          <Grid item style={{ display: 'none' }}>
            <Controller
              control={control}
              name="person.id"
              render={({ field }) => (
                <TextField type="hidden" value={field.value || ''} />
              )}
            />
          </Grid>
          <Grid item md={12}>
            <Controller
              control={control}
              name="person.salutation"
              render={({ field }) => (
                <RadioGroup
                  value={field.value}
                  onChange={field.onChange}
                  sx={{ flexDirection: 'row' }}
                >
                  <FormControlLabel
                    value={Salutation.Female}
                    control={<Radio />}
                    label={t<string>(`person.salutation.${Salutation.Female}`)}
                  />
                  <FormControlLabel
                    value={Salutation.Male}
                    control={<Radio />}
                    label={t<string>(`person.salutation.${Salutation.Male}`)}
                  />
                  <FormControlLabel
                    value={Salutation.Other}
                    control={<Radio />}
                    label={t<string>(`person.salutation.${Salutation.Other}`)}
                  />
                </RadioGroup>
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name={'person.firstName'}
              render={({ field, fieldState }) => (
                <TextField
                  label={t('Vorname')}
                  fullWidth
                  required
                  {...field}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name={'person.lastName'}
              render={({ field, fieldState }) => (
                <TextField
                  label={t('Nachname')}
                  fullWidth
                  required
                  {...field}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name={'person.titleBefore'}
              render={({ field, fieldState }) => (
                <TextField
                  label={t('Voranstehender Titel')}
                  fullWidth
                  {...field}
                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name={'person.titleAfter'}
              render={({ field, fieldState }) => (
                <TextField
                  label={t('Nachstehender Titel')}
                  fullWidth
                  {...field}
                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name={'person.salutationPosition'}
              render={({ field, fieldState }) => (
                <TextField
                  label={t('Anrede Funktion')}
                  fullWidth
                  {...field}
                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name={'person.nationality'}
              render={({ field, fieldState }) => (
                <TextField
                  label={t('Nationalität')}
                  fullWidth
                  {...field}
                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name={'person.birthplace'}
              render={({ field, fieldState }) => (
                <TextField
                  label={t('Geburtsort')}
                  fullWidth
                  {...field}
                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name={'person.birthdate'}
              render={({ field, fieldState }) => (
                <DatePicker
                  value={field.value}
                  inputFormat={DateFormat.Default}
                  mask={DateFormat.Mask}
                  onChange={field.onChange}
                  label={t('Geburtsdatum')}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      fullWidth
                      helperText={fieldState.error?.message}
                      error={fieldState.isTouched && fieldState.invalid}
                    />
                  )}
                />
              )}
            />
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardContent>
        <StyledCardTitle variant="h6" color="secondary" gutterBottom>
          {t('Kontaktdaten PA')}
        </StyledCardTitle>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name={'phone'}
              render={({ field, fieldState }) => (
                <TextField
                  label={t('Telefonnummer')}
                  fullWidth
                  {...field}
                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name={'mobilePhone'}
              render={({ field, fieldState }) => (
                <TextField
                  label={t('Mobiltelefon')}
                  fullWidth
                  {...field}
                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name={'email'}
              render={({ field, fieldState }) => (
                <TextField
                  label={t('E-Mail Adresse')}
                  fullWidth
                  {...field}
                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardContent>
        <StyledCardTitle variant="h6" color="secondary" gutterBottom>
          {t('Anschrift PA')}
        </StyledCardTitle>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Controller
              control={control}
              name={'street'}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t('Straße und Hausnummer')}
                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <Controller
              control={control}
              name={'zipCode'}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t('PLZ')}
                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <Controller
              control={control}
              name={'city'}
              render={({ field, fieldState }) => (
                <TextField
                  fullWidth
                  label={t('Stadt')}
                  {...field}
                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name={'country'}
              render={({ field, fieldState }) => (
                <Autocomplete
                  id="country"
                  options={CountryList}
                  noOptionsText={t('Kein Land verfügbar')}
                  getOptionLabel={(option) => `${option.name} (${option.code})`}
                  onChange={(event, value) => field.onChange(value?.code)}
                  value={
                    CountryList.find(
                      (country) => country.code === field.value
                    ) || null
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t('Land')}
                      error={fieldState.isTouched && fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              )}
            />
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardContent>
        <StyledCardTitle variant="h6" color="secondary" gutterBottom>
          {t('Sonstiges')}
        </StyledCardTitle>
        <Grid container spacing={3}>
          <Grid item md={12}>
            <Controller
              control={control}
              name={'comment'}
              render={({ field, fieldState }) => (
                <TextField
                  label={t('Interner Kommentar')}
                  fullWidth
                  {...field}
                  multiline={true}
                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
        </Grid>
      </CardContent>
    </>
  );
};
