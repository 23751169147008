import {useMemo} from 'react';
import * as yup from 'yup';
import {useCompanyValidation} from './use-company-validation';

export const useCompanyValidationSchema = () => {
  const companyValidation = useCompanyValidation();

  return useMemo(() => {
    return yup.object({
      ...companyValidation
    });
  }, [companyValidation]);
};
