import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import {Nullable} from '../../../model';

export const useImportFileValidation = () => {
  const { t } = useTranslation();

  return useMemo(
    () => ({
      file: yup
        .mixed()
        .required(t('Datei ist erforderlich'))
        .test('fileExtension', t('Ungültiges Dateiformat (erlaubt ist: Excel)'), (value: Nullable<File>) => {
          if (! value) {
            return false;
          }
          return [
            'application/vnd.ms-excel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          ].includes(value.type);
        }),
    }),
    []
  );
};

export const useImportFileValidationSchema = () => {
  const fileValidation = useImportFileValidation();

  return useMemo(() => {
    return yup.object({
      ...fileValidation,
    });
  }, [fileValidation]);
};
