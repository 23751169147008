import {
  AttachFile,
  Check,
  Clear,
  CopyAll,
  Delete,
  RestartAlt,
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  Chip,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { Link as RouterLink, useParams } from 'react-router-dom';
import Filesize from '../../components/filesize';
import FormattedDate from '../../components/formatted-date';
import { SpanBlock, StyledCardTitle } from '../../components/globals';
import { GridData } from '../../components/grid-data';
import Header from '../../components/header';
import HttpError from '../../components/http-error';
import LoadingContainer from '../../components/loading-container';
import LoadingIconButton from '../../components/loading-icon-button';
import { ModifiedEntity } from '../../components/modified-entity';
import { config } from '../../config';
import { useTitle } from '../../hooks';
import {
  apiRoutes,
  request,
  routes,
  usePressPassApplicationDetailsApi,
} from '../../lib';
import {
  Attachment,
  ID,
  Origin,
  PressPassApplicationStatus,
} from '../../model';


function copyToClipboard(text: string) {
  if (navigator.clipboard) {
    return navigator.clipboard.writeText(text);
  }
  const textArea = document.createElement('textarea');
  textArea.value = text;
  textArea.style.position = 'fixed';
  textArea.style.left = '-999999px';
  textArea.style.top = '-999999px';
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  return new Promise((resolve, reject) =>
    document.execCommand('copy') ? resolve(text) : reject()
  ).then(() => textArea.remove());
}

const PressPassApplicationDetails = () => {
  const { t } = useTranslation();
  const { pressPassApplicationId } =
    useParams<{ pressPassApplicationId: string | undefined }>();
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, error, data } = usePressPassApplicationDetailsApi(
    pressPassApplicationId as unknown as number
  );
  const queryClient = useQueryClient();
  const [deletingAttachments, setDeletingAttachments] = useState<ID[]>([]);

  useTitle(t('Antrag Details'));

  if (error) {
    return (
      <HttpError
        error={error}
        actions={
          <Button component={RouterLink} to={routes.pressPassApplications}>
            {t('Zurück zu Anträgen')}
          </Button>
        }
      />
    );
  }

  const statusMutation = useMutation(
    async (value: PressPassApplicationStatus) => {
      if (!data) {
        return;
      }
      return await request(
        apiRoutes.pressPassApplicationStatus(data.id),
        'put',
        {
          status: value,
        }
      );
    },
    {
      onSuccess: async () => {
        if (!data) {
          return;
        }
        await queryClient.invalidateQueries(
          apiRoutes.pressPassApplication(data.id)
        );
        enqueueSnackbar(t('Antrag wurde erfolgreich aktualisiert.'), {
          variant: 'success',
        });
      },
      onError: () => {
        enqueueSnackbar(t('Antrag konnte nicht aktualisiert werden.'), {
          variant: 'error',
        });
      },
    }
  );

  const deleteAttachmentMutation = useMutation(
    async (attachment: Attachment) => {
      setDeletingAttachments([...deletingAttachments, attachment.id]);
      return await request<Attachment>(
        apiRoutes.pressPassApplicationAttachmentDelete(
          pressPassApplicationId as unknown as number,
          attachment.id
        ),
        'delete'
      );
    },
    {
      onSuccess: async () => {
        if (!data) {
          return;
        }
        await queryClient.invalidateQueries(
          apiRoutes.pressPassApplication(data.id)
        );
        enqueueSnackbar(t('Anhang wurde erfolgreich gelöscht.'), {
          variant: 'success',
        });
      },
      onError: () => {
        enqueueSnackbar(t('Anhang konnte nicht gelöscht werden.'), {
          variant: 'error',
        });
      },
      onSettled: (res) => {
        if (!res) {
          return;
        }
        setDeletingAttachments([
          ...deletingAttachments.filter(
            (attachment) => attachment !== res.data.id
          ),
        ]);
      },
    }
  );

  if (isLoading || !data) {
    return <LoadingContainer />;
  }

  const detailBreadcrumbs = [
    { label: t('Home'), link: routes.dashboard },
    { label: t('Anträge'), link: routes.pressPassApplications },
  ];

  return (
    <Container maxWidth="xl">
      <Header
        title={
          <>
            {(data.salutation
              ? t(`person.salutation.${data.salutation}`) + ' '
              : '') +
              data.firstName +
              ' ' +
              data.lastName}
            {data.status === PressPassApplicationStatus.New && (
              <Chip label={t('Neu')} size="small" color="primary" />
            )}
          </>
        }
        search={{ route: routes.pressPassApplications, param: 'search' }}
        breadcrumbs={detailBreadcrumbs}
        actions={
          <>
            <Button
              color="secondary"
              onClick={() => {
                copyToClipboard(
                  (data.membership === Origin.Oezv
                    ? config.host.oezv
                    : config.host.voez) +
                    '/' +
                    data.token
                )
                  .then(() => {
                    enqueueSnackbar(
                      t('Link wurde in die Zwischenablage kopiert.'),
                      {
                        variant: 'info',
                      }
                    );
                  })
                  .catch(() => console.error('Failed to copy to clipboard'));
              }}
              startIcon={<CopyAll />}
            >
              {t('Link Datenanreicherung')}
            </Button>{' '}
            {data.status === PressPassApplicationStatus.Done ||
            data.status === PressPassApplicationStatus.Denied ? (
              <LoadingButton
                variant="contained"
                color="secondary"
                loading={statusMutation.isLoading}
                onClick={() =>
                  statusMutation.mutateAsync(PressPassApplicationStatus.New)
                }
                startIcon={<RestartAlt />}
              >
                {t('Antrag erneut öffnen')}
              </LoadingButton>
            ) : (
              <>
                <LoadingButton
                  variant="contained"
                  color="success"
                  loading={statusMutation.isLoading}
                  onClick={() =>
                    statusMutation.mutateAsync(PressPassApplicationStatus.Done)
                  }
                  startIcon={<Check />}
                >
                  {t('Antrag abschließen')}
                </LoadingButton>{' '}
                <LoadingButton
                  variant="contained"
                  color="error"
                  loading={statusMutation.isLoading}
                  onClick={() =>
                    statusMutation.mutateAsync(
                      PressPassApplicationStatus.Denied
                    )
                  }
                  startIcon={<Clear />}
                >
                  {t('Antrag ablehnen')}
                </LoadingButton>
              </>
            )}
            <ModifiedEntity entity={data} />
          </>
        }
      />
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <StyledCardTitle variant="h6" color="secondary" gutterBottom>
                {t('Informationen zur Person')}
              </StyledCardTitle>
              <Grid container spacing={3}>
                <GridData xs={12} md={6} label={t<string>('Vorname')}>
                  {data.firstName}
                </GridData>
                <GridData xs={12} md={6} label={t<string>('Nachname')}>
                  {data.lastName}
                </GridData>
                <GridData
                  xs={12}
                  md={6}
                  label={t<string>('Voranstehender Titel')}
                >
                  {data.titleBefore}
                </GridData>
                <GridData
                  xs={12}
                  md={6}
                  label={t<string>('Nachstehender Titel')}
                >
                  {data.titleAfter}
                </GridData>
                <GridData xs={12} md={6} label={t<string>('Geburtsdatum')}>
                  <FormattedDate
                    format={'d.MM.Y'}
                    date={data.birthdate || ''}
                  />
                </GridData>
                <GridData xs={12} md={6} label={t<string>('Geburtsort')}>
                  {data.birthplace}
                </GridData>
                <GridData xs={12} md={6} label={t<string>('Anschrift')}>
                  {data.street && <SpanBlock>{data.street}</SpanBlock>}
                  {(data.zipCode || data.city) && (
                    <SpanBlock>
                      {data.zipCode} {data.city}
                    </SpanBlock>
                  )}
                  {data.country && <SpanBlock>{data.country}</SpanBlock>}
                </GridData>
                <GridData xs={12} md={6} label={t<string>('Nationalität')}>
                  {data.nationality}
                </GridData>
              </Grid>
            </CardContent>
            <Divider />
            <CardContent>
              <StyledCardTitle variant="h6" color="secondary" gutterBottom>
                {t('Kontaktdaten')}
              </StyledCardTitle>
              <Grid container spacing={3}>
                <GridData xs={12} md={6} label={t<string>('Telefonnummer')}>
                  {data.phone}
                </GridData>
                <GridData xs={12} md={6} label={t<string>('E-Mail Adresse')}>
                  {data.email}
                </GridData>
              </Grid>
            </CardContent>
            <Divider />
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <StyledCardTitle variant="h6" color="secondary" gutterBottom>
                    {t('Medium')}
                  </StyledCardTitle>
                  <Grid container spacing={3}>
                    <GridData xs={12} md={12} label={t<string>('Medium')}>
                      {data.medium}
                    </GridData>
                    <GridData xs={12} md={6} label={t<string>('Funktion')}>
                      {data.position}
                    </GridData>
                    <GridData
                      xs={12}
                      md={6}
                      label={t<string>('Dienstverhältnis')}
                    >
                      {data.employmentStatus &&
                        t(
                          `pressPass.employmentStatus.${data.employmentStatus}`
                        )}
                    </GridData>
                    <GridData
                      xs={12}
                      md={12}
                      label={t<string>('Adresse des Mediums')}
                    >
                      {data.mediumAddress}
                    </GridData>
                  </Grid>
                </CardContent>
                <Divider />
                <CardContent>
                  <StyledCardTitle variant="h6" color="secondary" gutterBottom>
                    {t('Verwaltung')}
                  </StyledCardTitle>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={data.carBadge}
                            readOnly={true}
                            name="enabled"
                            color="primary"
                            disableRipple={true}
                            style={{ cursor: 'auto' }}
                          />
                        }
                        label={t<string>('Autoschild')}
                      />
                    </Grid>
                    <GridData
                      xs={12}
                      label={t<string>('Dateien')}
                      mode="richtext"
                    >
                      {data.attachments && (
                        <List>
                          {data.attachments.map((attachment, index) => (
                            <ListItem key={index}>
                              <ListItemIcon>
                                <AttachFile />
                              </ListItemIcon>
                              <ListItemText
                                style={{
                                  textOverflow: 'ellipsis',
                                  overflow: 'hidden',
                                  whiteSpace: 'nowrap',
                                }}
                                primary={
                                  <Link href={attachment.file} target="_blank">
                                    {attachment.name}
                                  </Link>
                                }
                                secondary={
                                  <Filesize sizeInBytes={attachment.size} />
                                }
                              />
                              <Tooltip title={t<string>('Datei löschen')}>
                                <LoadingIconButton
                                  onClick={() => {
                                    const confirm = window.confirm(
                                      t(
                                        `Wollen Sie die Datei {{name}} wirklich entfernen?`,
                                        {
                                          name: attachment.name,
                                        }
                                      )
                                    );
                                    if (!confirm) {
                                      return;
                                    }
                                    deleteAttachmentMutation.mutate(attachment);
                                  }}
                                  loading={
                                    deleteAttachmentMutation.isLoading &&
                                    !!deletingAttachments.find(
                                      (a) => a === attachment.id
                                    )
                                  }
                                >
                                  <Delete />
                                </LoadingIconButton>
                              </Tooltip>
                            </ListItem>
                          ))}
                        </List>
                      )}
                    </GridData>
                  </Grid>
                </CardContent>
                <Divider />
                <CardContent>
                  <Grid container spacing={3}>
                    <GridData
                      xs={12}
                      label={t<string>('Kommentar von Antragsteller')}
                    >
                      {data.comment}
                    </GridData>
                  </Grid>
                </CardContent>
                <Divider />
                <CardContent>
                  <StyledCardTitle variant="h6" color="secondary" gutterBottom>
                    {t('Datenschutz')}
                  </StyledCardTitle>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={data.confirmationPrivacy}
                            readOnly={true}
                            name="enabled"
                            color="primary"
                            disableRipple={true}
                            style={{ cursor: 'auto' }}
                          />
                        }
                        label={t<string>('Zustimmung Datenschutz')}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={data.confirmationMoral}
                            readOnly={true}
                            name="enabled"
                            color="primary"
                            disableRipple={true}
                            style={{ cursor: 'auto' }}
                          />
                        }
                        label={t<string>('Zustimmung Gewissensfrage')}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PressPassApplicationDetails;
