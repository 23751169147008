import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Card,
  CardActions,
  Container,
  Divider,
  Grid,
} from '@mui/material';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import React, { VoidFunctionComponent, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router';
import { Link, useParams } from 'react-router-dom';
import Header from '../../components/header';
import HttpError from '../../components/http-error';
import LoadingButton from '../../components/loading-button';
import LoadingContainer from '../../components/loading-container';
import {
  PressPassFormFields,
  PressPassPersonFormFields,
} from '../../components/press-pass';
import {
  buildFormData,
  formatPayloadDate,
  handleHookFormErrors,
} from '../../helpers';
import { useTitle } from '../../hooks';
import { usePressPassValidationSchema } from '../../hooks/validation/press-pass';
import { apiRoutes, request, routes, usePressPassDetailsApi } from '../../lib';
import {
  PressPass,
  PressPassFormValues,
  getPressPassFormValues,
} from '../../model';

const EditPressPass: VoidFunctionComponent = () => {
  const { t } = useTranslation();
  const { pressPassId } = useParams<{ pressPassId: string | undefined }>();

  const { isLoading, error, data } = usePressPassDetailsApi(
    pressPassId as unknown as number
  );
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  useTitle(data?.person.lastName || 'Presseausweis bearbeiten');

  const validationSchema = usePressPassValidationSchema();

  const {
    control,
    handleSubmit,
    setError,
    formState: { isValid, isSubmitting },
    reset,
    watch,
    setValue,
  } = useForm<PressPassFormValues>({
    mode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues: getPressPassFormValues(data),
  });

  const medium = watch('medium');
  const photoUpload = watch('photoUpload');

  useEffect(() => reset(getPressPassFormValues(data)), [reset, data]);

  useEffect(() => {
    setValue('deletePhoto', false);
  }, [photoUpload]);

  const submitMutation = useMutation(
    async (values: PressPassFormValues) => {
      if (!data) {
        return;
      }

      values.person.birthdate = formatPayloadDate(values.person.birthdate);
      values.passDate = formatPayloadDate(values.passDate);
      values.passWithdrawalDate = formatPayloadDate(values.passWithdrawalDate);
      values.badgeDate = formatPayloadDate(values.badgeDate);
      values.carBadgeDate = formatPayloadDate(values.carBadgeDate);
      values.photoUpdatedAt = formatPayloadDate(values.photoUpdatedAt);

      const formData = buildFormData<PressPassFormValues>(
        values,
        (formData) => {
          if (values.photoUpload) {
            formData.append('photoUpload', values.photoUpload);
          }
        }
      );

      return await request<PressPass>(
        apiRoutes.pressPass(data.id),
        'post',
        formData
      );
    },
    {
      onSuccess: () => {
        enqueueSnackbar(t('Presseausweis wurde erfolgreich bearbeitet.'), {
          variant: 'success',
        });
        if (!data) {
          return;
        }
        history.push(routes.pressPass(data.id));
      },
      onError: (err: AxiosError) => {
        enqueueSnackbar(err.response?.data.message, { variant: 'error' });
        handleHookFormErrors(err, setError);
      },
    }
  );

  if (error) {
    return (
      <HttpError
        error={error}
        actions={
          <Button component={Link} to={routes.pressPasses}>
            {t('Zurück zu Presseausweisen')}
          </Button>
        }
      />
    );
  }

  if (isLoading || !data) {
    return <LoadingContainer />;
  }

  const breadcrumbs = [
    { label: t('Home'), link: routes.dashboard },
    { label: t('Presseausweise'), link: routes.pressPasses },
    { label: data.person.displayName, link: routes.pressPass(data.id) },
    { label: t('Bearbeiten') },
  ];

  return (
    <Container maxWidth="xl">
      <form
        onSubmit={handleSubmit((values) => submitMutation.mutateAsync(values))}
      >
        <Header
          title=""
          breadcrumbs={breadcrumbs}
          actions={
            <LoadingButton
              type="submit"
              size="medium"
              color="primary"
              disabled={!isValid}
              variant="contained"
              loading={isSubmitting}
            >
              {t('Speichern')}
            </LoadingButton>
          }
        />
        <Card>
          <Grid container>
            <Grid item xs={12} md={6}>
              <PressPassPersonFormFields control={control} />
            </Grid>
            <Divider
              orientation={'vertical'}
              flexItem
              style={{ marginRight: '-1px' }}
            />
            <Grid item xs={12} md={6}>
              <PressPassFormFields
                control={control}
                pressPass={data}
                medium={medium}
              />
            </Grid>
          </Grid>
          <Divider />
          <CardActions>
            <LoadingButton
              type="submit"
              size="medium"
              color="primary"
              disabled={!isValid}
              variant="contained"
              loading={isSubmitting}
            >
              {t('Speichern')}
            </LoadingButton>
          </CardActions>
        </Card>
      </form>
    </Container>
  );
};

export default EditPressPass;
