import { yupResolver } from '@hookform/resolvers/yup';
import { FileDownload } from '@mui/icons-material';
import { DatePicker } from '@mui/lab';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Header from '../../components/header';
import { formatPayloadDate } from '../../helpers';
import { useTitle } from '../../hooks';
import { useLicenseeEvaluationValidationSchema } from '../../hooks/validation/licensee';
import { apiRoutes, routes } from '../../lib';
import { DateFormat, LicenseeEvaluationFormValues } from '../../model';

const LicenseeExport = () => {
  const { t } = useTranslation();
  useTitle(t('Auswertung'));

  const [inputFromDate, setInputFromDate] = useState<Date | string>('');
  const [inputToDate, setInputToDate] = useState<Date | string>('');

  const validationSchema = useLicenseeEvaluationValidationSchema();

  const breadcrumbs = [
    { label: t('Home'), link: routes.dashboard },
    { label: t('Lizenznehmer'), link: routes.licensees },
    { label: t('Auswertung') },
  ];

  const {
    control,
    formState: { isValid },
    setValue,
    watch,
  } = useForm<LicenseeEvaluationFormValues>({
    mode: 'all',
    defaultValues: {
      from: null,
      to: null,
    },
    resolver: yupResolver(validationSchema),
  });

  const from = watch('from');
  const to = watch('to');

  useEffect(() => {
    if (from) {
      setValue('from', null, { shouldValidate: true, shouldTouch: true });
    }
    if (
      inputFromDate instanceof Date &&
      !isNaN(inputFromDate.valueOf()) &&
      inputFromDate.getFullYear() >= 2000
    ) {
      setValue('from', inputFromDate as unknown as string, {
        shouldValidate: true,
        shouldTouch: true,
      });
    }
  }, [inputFromDate]);

  useEffect(() => {
    if (to) {
      setValue('to', null, { shouldValidate: true, shouldTouch: true });
    }
    if (
      inputToDate instanceof Date &&
      !isNaN(inputToDate.valueOf()) &&
      inputToDate.getFullYear() >= 2000
    ) {
      setValue('to', inputToDate as unknown as string, {
        shouldValidate: true,
        shouldTouch: true,
      });
    }
  }, [inputToDate]);

  return (
    <form>
      <Container maxWidth="md">
        <Header title={t('Auswertung')} breadcrumbs={breadcrumbs} />
        <Card>
          <CardHeader
            title={t('Auswertung Nutzungsdaten')}
            subheader={t(
              'Bitte einen Zeitraum wählen, für den alle Nutzungsdaten als Excel exportiert werden sollen.'
            )}
          />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  name="from"
                  render={({ field, fieldState }) => (
                    <DatePicker
                      value={field.value}
                      inputFormat={DateFormat.Default}
                      mask={DateFormat.Mask}
                      onChange={(date: any) => {
                        setInputFromDate(date);
                      }}
                      label={t('Von')}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          fullWidth
                          required
                          helperText={fieldState.error?.message}
                          error={fieldState.isTouched && fieldState.invalid}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  name="to"
                  render={({ field, fieldState }) => (
                    <DatePicker
                      value={field.value}
                      inputFormat={DateFormat.Default}
                      mask={DateFormat.Mask}
                      onChange={(date: any) => {
                        setInputToDate(date);
                      }}
                      label={t('Bis')}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          fullWidth
                          required
                          helperText={fieldState.error?.message}
                          error={fieldState.isTouched && fieldState.invalid}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={!isValid}
                  target="_blank"
                  href={`${process.env.REACT_APP_API_URL}/${
                    apiRoutes.transactionsExport
                  }?from=${formatPayloadDate(from)}&to=${formatPayloadDate(
                    to
                  )}`}
                  startIcon={<FileDownload />}
                >
                  {t('Exportieren')}
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </form>
  );
};

export default LicenseeExport;
