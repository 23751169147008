import {
  Blameable,
  Commentable,
  Company,
  DateTimeString,
  ID,
  Nullable,
  Person,
  Timestampable,
} from './';

export interface Medium extends Timestampable, Blameable, Commentable {
  id: ID;
  name: string;
  phone: string;
  website: string;
  company?: Company;
  responsiblePerson: Person;
  licenseResponsiblePerson: Person;
  pressPass: Nullable<boolean>;
  referenceId: Nullable<string>;
  lastBillingDate?: DateTimeString;
}

export interface MediumFilters {
  id: ID;
  search: string;
  company: string;
}

export interface MediumFormValues {
  name: string;
  company: Nullable<ID>;
  phone: Nullable<string>;
  website: Nullable<string>;
  comment: Nullable<string>;
  responsiblePerson: Nullable<ID>;
  licenseResponsiblePerson: Nullable<ID>;
  pressPass: boolean;
  referenceId: Nullable<string>;
}

export const getMediumFormValues = (medium?: Medium): MediumFormValues => ({
  name: medium?.name || '',
  company: medium?.company?.id || null,
  phone: medium?.phone || '',
  website: medium?.website || '',
  comment: medium?.comment || '',
  responsiblePerson: medium?.responsiblePerson?.id || null,
  licenseResponsiblePerson: medium?.licenseResponsiblePerson?.id || null,
  pressPass: medium?.pressPass || false,
  referenceId: medium?.referenceId || '',
});
