export enum ApiErrorType {
  ConstraintViolationList = 'ConstraintViolationList',
  Unknown = 'Unknown',
}

export type ApiErrorContext = {
  type: ApiErrorType.ConstraintViolationList;
  violations: {
    [property: string]: string[];
  };
};

export interface ApiError {
  identifier: string;
  message: string;
  context: ApiErrorContext;
}
