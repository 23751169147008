import { debounce } from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';

export const useDebounceState = <S>(
  value: S | (() => S),
  setter: (value: S) => void,
  delay = 500
): [S, Dispatch<SetStateAction<S>>] => {
  const [state, setState] = useState(value);

  const debouncer = useMemo<((value: S) => void) & { clear: () => void }>(
    () => debounce((value) => setter(value), delay),
    [delay, setter]
  );

  useEffect(() => {
    debouncer(state);
    return () => debouncer.clear();
  }, [state, debouncer]);

  useEffect(() => {
    if (value !== state) {
      setState(value);
    }
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  return [state, setState];
};
