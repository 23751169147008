import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Card, CardActions, Container, Divider } from '@mui/material';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router';
import { Link as RouterLink, useParams } from 'react-router-dom';
import Header from '../../components/header';
import HttpError from '../../components/http-error';
import LoadingButton from '../../components/loading-button';
import LoadingContainer from '../../components/loading-container';
import { TariffFormFields } from '../../components/tariff';
import { formatPayloadDate, handleHookFormErrors } from '../../helpers';
import { useTitle } from '../../hooks';
import { useTariffValidationSchema } from '../../hooks/validation/tariff';
import { apiRoutes, request, routes, useTariffDetailsApi } from '../../lib';
import { Tariff, TariffFormValues, getTariffFormValues } from '../../model';


const EditTariff = () => {
  const { t } = useTranslation();
  const { tariffId } = useParams<{ tariffId: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const { isLoading, error, data } = useTariffDetailsApi(+tariffId);

  useTitle(data?.name || t('Tarifliste bearbeiten'));

  const validationSchema = useTariffValidationSchema();

  const {
    control,
    handleSubmit,
    setError,
    formState: { isValid, isSubmitting },
    reset,
  } = useForm<TariffFormValues>({
    mode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues: getTariffFormValues(data),
  });

  useEffect(() => reset(getTariffFormValues(data)), [reset, data]);

  const submitMutation = useMutation(
    async (values: TariffFormValues) => {
      if (!data) {
        return;
      }
      values.validFrom = formatPayloadDate(values.validFrom);
      values.validTo = formatPayloadDate(values.validTo);
      return await request<Tariff>(apiRoutes.tariff(data.id), 'put', values);
    },
    {
      onSuccess: (res) => {
        if (!res) {
          return;
        }
        enqueueSnackbar(t('Tarifliste wurde erfolgreich bearbeitet.'), {
          variant: 'success',
        });
        history.push(routes.tariff(res.data.id));
      },
      onError: (err: AxiosError) => {
        enqueueSnackbar(
          err.response?.data.message || t('Leider ist etwas schief gelaufen.'),
          { variant: 'error' }
        );
        handleHookFormErrors(err, setError);
      },
    }
  );

  if (error) {
    return (
      <HttpError
        error={error}
        actions={
          <Button component={RouterLink} to={routes.tariffs}>
            {t('Zurück zu Tariflisten')}
          </Button>
        }
      />
    );
  }

  if (isLoading || !data) {
    return <LoadingContainer />;
  }

  const detailBreadcrumbs = [
    { label: t('Home'), link: routes.dashboard },
    { label: t('Tariflisten'), link: routes.tariffs },
    { label: data.name, link: routes.tariff(data.id) },
    { label: t('Bearbeiten') },
  ];

  return (
    <Container maxWidth="md">
      <Header title={data.name} breadcrumbs={detailBreadcrumbs} />
      <form
        onSubmit={handleSubmit((values) => submitMutation.mutateAsync(values))}
      >
        <Card>
          <TariffFormFields control={control} tariff={data} />
          <Divider />
          <CardActions>
            <LoadingButton
              type="submit"
              size="medium"
              color="primary"
              disabled={!isValid}
              variant="contained"
              loading={isSubmitting}
            >
              {t('Speichern')}
            </LoadingButton>
          </CardActions>
        </Card>
      </form>
    </Container>
  );
};

export default EditTariff;
