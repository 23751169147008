import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { routes } from '../../lib';
import CreateCompany from './create';
import CompanyDetails from './details';
import EditCompany from './edit';
import CompanyOverview from './overview';

const Companies = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <CompanyOverview />
      </Route>
      <Route path={routes.companyCreate}>
        <CreateCompany />
      </Route>
      <Route path={routes.companyEdit(':companyId')}>
        <EditCompany />
      </Route>
      <Route path={routes.company(':companyId')}>
        <CompanyDetails />
      </Route>
    </Switch>
  );
};

export default Companies;
