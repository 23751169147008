import { Edit, Visibility } from '@mui/icons-material';
import { Link, TableCell, TableRow } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { routes } from '../../lib';
import { Position } from '../../model/position';
import { CompactTableCell } from '../globals';
import { IconLink } from '../icon-link';
import { PaginationTable } from '../pagination';
import PaginationTableHeadCell from '../pagination/table-head-cell';

export const PositionList: FunctionComponent<{ positions?: Position[] }> = ({
  positions,
}) => {
  const { t } = useTranslation();

  return (
    <PaginationTable
      items={positions}
      renderRow={(position) => (
        <TableRow key={position.id} hover={true}>
          <CompactTableCell>
            <Link to={routes.position(position.id)} component={RouterLink}>
              {position.title}
            </Link>
          </CompactTableCell>
          <TableCell>{position.comment}</TableCell>
          <CompactTableCell align={'right'}>
            <IconLink
              to={routes.position(position.id)}
              tooltip={t('Ansehen')}
              icon={<Visibility />}
            />
            <IconLink
              to={routes.positionEdit(position.id)}
              tooltip={t('Bearbeiten')}
              icon={<Edit />}
            />
          </CompactTableCell>
        </TableRow>
      )}
    >
      <TableRow>
        <PaginationTableHeadCell sort="title">
          {t('Titel')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell>{t('Kommentar')}</PaginationTableHeadCell>
        <TableCell width={110} />
      </TableRow>
    </PaginationTable>
  );
};
