import { useMemo } from 'react';
import * as yup from 'yup';
import {useTranslation} from "react-i18next";

export const useUserValidation = (passwordRequired = true) => {
  const {t} = useTranslation();

  return useMemo(
    () => ({
      username: yup.string().required(t('Username darf nicht leer sein.')),
      fullName: yup.string().nullable(),
      email: yup
        .string()
        .email(t('Ungültige E-Mail Adresse'))
        .required(t('E-Mail darf nicht leer sein.')),
      currentPassword: yup.string().required(t('Aktuelles Passwort darf nicht leer sein.')),
      password: yup.string().when('$passwordRequired', {
        is: () => passwordRequired,
        then: yup.string().required(t('Passwort darf nicht leer sein.')),
      }),
      passwordRepeat: (ref = 'password') =>
        yup
          .string()
          .oneOf([yup.ref(ref), null], t('Passwörter müssen übereinstimmen.'))
          .when('$passwordRequired', {
            is: () => passwordRequired,
            then: yup.string().required(t('Dieses Feld darf nicht leer sein.')),
          }),
    }),
    [passwordRequired]
  );
};

export const useUserManagementValidationSchema = (passwordRequired = true) => {
  const userValidation = useUserValidation(passwordRequired);

  return useMemo(() => {
    return yup.object({
      username: userValidation.username,
      email: userValidation.email,
      plainPassword: userValidation.password,
      passwordRepeat: userValidation.passwordRepeat('plainPassword'),
    });
  }, [userValidation, passwordRequired]);
};

export const useLoginValidationSchema = () => {
  const userValidation = useUserValidation(true);

  return useMemo(() => {
    return yup.object({
      email: userValidation.email,
      password: userValidation.password
    });
  }, [userValidation]);
};
