import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import React, { VoidFunctionComponent, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { formatPayloadDate, handleHookFormErrors } from '../../helpers';
import { useLicensePressReviewValidationSchema } from '../../hooks/validation/licensee';
import { apiRoutes, request } from '../../lib';
import {
  ID,
  LicensePressReview,
  LicensePressReviewFormValues,
  Licensee,
  getLicensePressReviewFormValues,
} from '../../model';
import { PressReviewFormFields } from './press-review-form-fields';

const LicenseePressReviewDialog: VoidFunctionComponent<{
  pressReview: LicensePressReview;
  licenseeId: ID;
  onClose: () => void;
  open: boolean;
}> = ({ pressReview, licenseeId, open, onClose }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const validationSchema = useLicensePressReviewValidationSchema();

  const {
    handleSubmit,
    setError,
    control,
    formState: { isSubmitting, isValid },
    reset,
  } = useForm<LicensePressReviewFormValues>({
    mode: 'all',
    defaultValues: getLicensePressReviewFormValues(pressReview),
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    reset(getLicensePressReviewFormValues(pressReview));
  }, [pressReview]);

  const submitMutation = useMutation(
    async (values: LicensePressReviewFormValues) => {
      if (!values.from || !values.to) {
        throw new Error(t('Pressespiegel konnte nicht bearbeitet werden'));
      }
      values.from = formatPayloadDate(values.from) as string;
      values.to = formatPayloadDate(values.to) as string;
      values.licenseeId = licenseeId;
      return await request<Licensee>(
        apiRoutes.licensePressReview(pressReview.id),
        'put',
        values
      );
    },
    {
      onSuccess: async () => {
        enqueueSnackbar(t('Pressespiegel wurde erfolgreich bearbeitet.'), {
          variant: 'success',
        });
        await queryClient.invalidateQueries(apiRoutes.licensee(licenseeId));
        reset(getLicensePressReviewFormValues(pressReview));
        onClose();
      },
      onError: (err: AxiosError) => {
        enqueueSnackbar(
          err.response?.data.message ||
            t('Pressespiegel konnte nicht bearbeitet werden.'),
          {
            variant: 'error',
          }
        );
        handleHookFormErrors(err, setError);
      },
    }
  );

  return (
    <Dialog open={open} onClose={() => onClose()}>
      <form
        onSubmit={handleSubmit((values) => submitMutation.mutateAsync(values))}
      >
        <DialogTitle>{t('Pressespiegel bearbeiten')}</DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <PressReviewFormFields control={control} />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose()} disabled={isSubmitting}>
            {t('Abbrechen')}
          </Button>
          <LoadingButton
            type="submit"
            size="medium"
            color="primary"
            disabled={!isValid || isSubmitting}
            variant="contained"
            loading={isSubmitting || submitMutation.isLoading}
          >
            {t('Speichern')}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default LicenseePressReviewDialog;
