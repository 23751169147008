import { useEffect, useState } from 'react';
import { shallowEqual } from '../helpers';
import { Dict } from '../model';
import { useQueryState } from './use-query-state';

export type Order = 'asc' | 'desc';

export interface FilterParams<
  TFilters extends Dict,
  TContext extends Dict = Dict
> extends Params<TContext> {
  filters: Partial<TFilters>;
}

export interface Params<TContext extends Dict = Dict> {
  page: number;
  pageSize: number;
  orderBy: string;
  order: Order;
  context?: TContext;
}

export type SortHandler = (property: string) => () => void;

export const useFilters = <TFilters extends Dict, TContext extends Dict = Dict>(
  filterDeps: Partial<TFilters> = {},
  initialOrderBy: string,
  initialOrder: Order = 'asc',
  initialPageSize = 10,
  context?: TContext
): {
  params: FilterParams<TFilters, TContext>;
  changePage: (newPage: number) => void;
  changePageSize: (newPageSize: number) => void;
  sort: SortHandler;
} => {
  const [filters, setFilters] = useState<Partial<TFilters>>({ ...filterDeps });

  const [page, setPage] = useQueryState(0, 'page');
  const [pageSize, setPageSize] = useQueryState(initialPageSize, 'pageSize');

  const [orderBy, setOrderBy] = useQueryState<string>(initialOrderBy, 'order');
  const [order, setOrder] = useQueryState<Order>(initialOrder, 'orderBy');

  useEffect(() => {
    if (!shallowEqual(filters, filterDeps)) {
      setFilters({ ...filters, ...filterDeps });
      setPage(0);
    }
  }, Object.values(filterDeps)); // eslint-disable-line react-hooks/exhaustive-deps

  const changePage = (newPage: number) => {
    setPage(newPage);
  };

  const changePageSize = (newPageSize: number) => {
    setPageSize(newPageSize);
    setPage(0);
  };

  const sort = (property: string) => () => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    setPage(0);
  };

  return {
    params: {
      filters,
      page,
      pageSize,
      order,
      orderBy,
      context,
    },
    changePage,
    changePageSize,
    sort,
  };
};
