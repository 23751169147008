import { Add, CalendarToday } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import React, { VoidFunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import { FilterInputSearch } from '../../components/filter';
import {
  StyledContainer,
  StyledFilterFormControl,
} from '../../components/globals';
import Guarded from '../../components/guarded';
import Header from '../../components/header';
import { MediumList } from '../../components/medium';
import PaginatedAutocomplete from '../../components/paginated-autocomplete';
import { PaginationCard } from '../../components/pagination';
import { config } from '../../config';
import {
  UseQueryStateOptions,
  useDebounceState,
  useFilteredPaginationApi,
  useQueryState,
  useTitle,
} from '../../hooks';
import { apiRoutes, request, routes } from '../../lib';
import {
  Company,
  CompanyFilters,
  Medium,
  MediumFilters,
  Role,
} from '../../model';

const MediaOverview: VoidFunctionComponent = () => {
  const { t } = useTranslation();
  useTitle(t('Medien'));
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const breadcrumbs = [
    { label: t('Home'), link: routes.dashboard },
    { label: t('Medien') },
  ];

  const queryStateOptions: UseQueryStateOptions = { action: 'replace' };
  const [search, setFilterSearch] = useQueryState(
    '',
    'search',
    queryStateOptions
  );
  const [inputSearch, setInputSearch] = useDebounceState(
    search,
    setFilterSearch
  );

  const [company, setFilterCompany] = useQueryState(
    '',
    'company',
    queryStateOptions
  );
  const [inputCompany, setInputCompany] = useDebounceState(
    company,
    setFilterCompany
  );

  const { context } = useFilteredPaginationApi<MediumFilters, Medium>(
    apiRoutes.media,
    { search, company },
    'name',
    'asc',
    config.pageSize
  );

  const { data: companies } = useFilteredPaginationApi<CompanyFilters, Company>(
    apiRoutes.companies,
    { id: +inputCompany },
    'name',
    'desc',
    1
  );

  const billingMutation = useMutation(
    async () => await request<{ count: number }>(apiRoutes.mediaBilled, 'PUT'),
    {
      onSuccess: async (res) => {
        await queryClient.invalidateQueries([apiRoutes.media]);
        enqueueSnackbar(
          t(
            'Das Abrechnungsdatum wurde erfolgreich für {{count}} Nutzungsdaten gesetzt.',
            {
              count: res.data.count,
            }
          ),
          {
            variant: 'success',
          }
        );
      },
      onError: (err: AxiosError) => {
        enqueueSnackbar(
          err.response?.data.message || t('Leider ist etwas schief gelaufen.'),
          { variant: 'error' }
        );
      },
    }
  );

  return (
    <StyledContainer data-test="media-content">
      <Header
        title={t('Medien')}
        breadcrumbs={breadcrumbs}
        actions={
          <>
            <Guarded requiredRole={Role.PDN}>
              <LoadingButton
                variant="outlined"
                color="secondary"
                onClick={() => {
                  if (
                    !window.confirm(
                      t(
                        `Wollen Sie wirklich alle offenen Nutzungsdaten als abgerechnet setzen?`
                      )
                    )
                  ) {
                    return;
                  }
                  billingMutation.mutateAsync();
                }}
                loading={billingMutation.isLoading}
                startIcon={<CalendarToday />}
              >
                {t('Abr LG setzen')}
              </LoadingButton>{' '}
            </Guarded>
            <Guarded requiredRole={Role.General}>
              <Button
                variant="contained"
                color="primary"
                component={Link}
                to={routes.mediumCreate}
                startIcon={<Add />}
              >
                {t('Neues Medium')}
              </Button>{' '}
            </Guarded>
          </>
        }
      />
      <PaginationCard
        context={context}
        hasFilters={!!(search || company)}
        resetFilters={() => {
          setFilterSearch('');
          setFilterCompany('');
        }}
        filters={
          <>
            <FilterInputSearch
              value={inputSearch}
              label={t('Suche')}
              onChange={setInputSearch}
            />
            <StyledFilterFormControl>
              <PaginatedAutocomplete
                name={'company'}
                label={t('Unternehmen')}
                value={company}
                onChange={(ids) =>
                  ids.length === 0
                    ? setInputCompany('')
                    : setInputCompany(ids[0].toString())
                }
                multiple={false}
                optionLabel={(company) =>
                  `${company.name} ${company.term || ''} (${company.id})`
                }
                dataUrl={apiRoutes.companies}
                initialData={companies?.results}
                textFieldProps={{
                  variant: 'outlined',
                  size: 'small',
                }}
              />
            </StyledFilterFormControl>
          </>
        }
      >
        <MediumList />
      </PaginationCard>
    </StyledContainer>
  );
};

export default MediaOverview;
