import { Container, Tab, Tabs } from '@mui/material';
import React, { VoidFunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Route, Switch } from 'react-router-dom';
import Header from '../../components/header';
import { useTitle } from '../../hooks';
import { routes } from '../../lib';
import ChangePassword from './change-password';
import GeneralProfileSettings from './general';

const Profile: VoidFunctionComponent = () => {
  useTitle('Profil');
  const [tab, setTab] = useState(0);
  const { t } = useTranslation();

  const breadcrumbs = [
    { label: 'Home', link: routes.dashboard },
    { label: t('Profil') },
  ];

  return (
    <Container maxWidth="md">
      <Header title={t('Profil')} breadcrumbs={breadcrumbs} />
      <Tabs value={tab} onChange={(e, v) => setTab(v)}>
        <Tab label={t('Profil')} component={Link} to={routes.profile} />
        <Tab
          label={t('Passwort ändern')}
          component={Link}
          to={routes.profilePassword}
        />
      </Tabs>
      <Switch>
        <Route path={routes.profilePassword}>
          <ChangePassword />
        </Route>
        <Route exact path={routes.profile}>
          <GeneralProfileSettings />
        </Route>
      </Switch>
    </Container>
  );
};

export default Profile;
