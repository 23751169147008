import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { routes } from '../../lib';
import PressPassApplicationDetails from './details';
import Overview from './overview';
import React from "react";

const PressPassApplications = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <Overview/>
      </Route>
      <Route path={routes.pressPassApplication(':pressPassApplicationId')}>
        <PressPassApplicationDetails/>
      </Route>
    </Switch>
  );
};

export default PressPassApplications;
