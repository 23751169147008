import { Add } from '@mui/icons-material';
import { Button } from '@mui/material';
import React, { VoidFunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FilterInputSearch } from '../../components/filter';
import { StyledContainer } from '../../components/globals';
import Header from '../../components/header';
import { PaginationCard } from '../../components/pagination';
import { SignatureList } from '../../components/signature';
import { config } from '../../config';
import {
  UseQueryStateOptions,
  useDebounceState,
  useFilteredPaginationApi,
  useQueryState,
  useTitle,
} from '../../hooks';
import { apiRoutes, routes } from '../../lib';
import { Signature, SignatureFilters } from '../../model';

const SignatureOverview: VoidFunctionComponent = () => {
  const { t } = useTranslation();
  useTitle(t('Signaturen'));

  const breadcrumbs = [
    { label: t('Home'), link: routes.dashboard },
    { label: t('Signaturen') },
  ];

  const queryStateOptions: UseQueryStateOptions = { action: 'replace' };
  const [search, setFilterSearch] = useQueryState(
    '',
    'search',
    queryStateOptions
  );
  const [inputSearch, setInputSearch] = useDebounceState(
    search,
    setFilterSearch
  );

  const { context } = useFilteredPaginationApi<SignatureFilters, Signature>(
    apiRoutes.signatures,
    { search },
    'name',
    'asc',
    config.pageSize
  );

  return (
    <StyledContainer data-test="signature-content">
      <Header
        title={t('Signaturen')}
        breadcrumbs={breadcrumbs}
        actions={
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to={routes.signatureCreate}
            startIcon={<Add />}
          >
            {t('Neue Signatur')}
          </Button>
        }
      />
      <PaginationCard
        context={context}
        hasFilters={!!search}
        resetFilters={() => {
          setFilterSearch('');
        }}
        filters={
          <FilterInputSearch
            value={inputSearch}
            label={t('Suche')}
            onChange={setInputSearch}
          />
        }
      >
        <SignatureList />
      </PaginationCard>
    </StyledContainer>
  );
};

export default SignatureOverview;
