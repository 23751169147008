import { MenuItem } from '@mui/material';
import React, { VoidFunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterInputSearch, FilterSelect } from '../../components/filter';
import { StyledContainer } from '../../components/globals';
import Header from '../../components/header';
import { PaginationCard } from '../../components/pagination';
import { PressPassApplicationUpdateList } from '../../components/press-pass-application';
import { config } from '../../config';
import {
  UseQueryStateOptions,
  useDebounceState,
  useFilteredPaginationApi,
  useQueryState,
  useTitle,
} from '../../hooks';
import { apiRoutes, routes } from '../../lib';
import {
  Membership,
  PressPassApplication,
  PressPassApplicationFilters,
  PressPassApplicationStatus,
} from '../../model';

const PressPassUpdateApplicationsOverview: VoidFunctionComponent = () => {
  const { t } = useTranslation();
  useTitle(t('Aktualisierungen'));

  const breadcrumbs = [
    { label: t('Home'), link: routes.dashboard },
    { label: t('Aktualisierungen für Presseausweise') },
  ];

  const queryStateOptions: UseQueryStateOptions = { action: 'replace' };
  const [search, setFilterSearch] = useQueryState(
    '',
    'search',
    queryStateOptions
  );
  const [inputSearch, setInputSearch] = useDebounceState(
    search,
    setFilterSearch
  );

  const [filterStatus, setFilterStatus] = useQueryState(
    '',
    'filterStatus',
    queryStateOptions
  );

  const [filterType, setFilterType] = useQueryState(
    'update',
    'filterType',
    queryStateOptions
  );

  const [membership, setFilterMembership] = useQueryState(
    '',
    'membership',
    queryStateOptions
  );

  const { context } = useFilteredPaginationApi<
    PressPassApplicationFilters,
    PressPassApplication
  >(
    apiRoutes.pressPassUpdateApplications,
    { search, filterStatus, membership },
    'createdAt',
    'desc',
    config.pageSize
  );

  return (
    <StyledContainer data-test="position-content">
      <Header title={t('Aktualisierungen für Presseausweise')} breadcrumbs={breadcrumbs}/>
      <PaginationCard
        context={context}
        hasFilters={!!search || !!filterStatus || !!membership}
        resetFilters={() => {
          setFilterSearch('');
          setFilterStatus('');
          setFilterType('update');
          setFilterMembership('');
        }}
        filters={
          <>
            <FilterInputSearch
              value={inputSearch}
              label={t('Suche')}
              placeholder={t('Name, E-Mail')}
              onChange={setInputSearch}
            />
            <FilterSelect
              label={t('Status')}
              value={filterStatus}
              onChange={setFilterStatus}
            >
              <MenuItem value="">
                <em>{t('Alle')}</em>
              </MenuItem>
              <MenuItem value={PressPassApplicationStatus.New}>
                {t('Neu')}
              </MenuItem>
              <MenuItem value={PressPassApplicationStatus.UpdatedPhoto}>
                {t('Foto aktualisiert')}
              </MenuItem>
              <MenuItem value={PressPassApplicationStatus.UpdatedPhotoDone}>
                {t('Aktualisierung abgeschlossen')}
              </MenuItem>
              <MenuItem value={PressPassApplicationStatus.Done}>
                {t('Angelegt')}
              </MenuItem>
              <MenuItem value={PressPassApplicationStatus.Denied}>
                {t('Abgelehnt')}
              </MenuItem>
            </FilterSelect>
            <FilterSelect
              label={t('Mitglied')}
              value={membership}
              onChange={setFilterMembership}
            >
              <MenuItem value="">
                <em>{t('Alle')}</em>
              </MenuItem>
              <MenuItem value={Membership.Voez}>
                {t(`membership.${Membership.Voez}`)}
              </MenuItem>
              <MenuItem value={Membership.Oezv}>
                {t(`membership.${Membership.Oezv}`)}
              </MenuItem>
              <MenuItem value="null">{t('Keine')}</MenuItem>
            </FilterSelect>
            <StyledContainer
              style={{
                display: 'none',
              }}>
              <FilterInputSearch
                value={filterType}
                label={t('Typ')}
                placeholder={t('Typ')}
                onChange={setFilterType}
              />
            </StyledContainer>
          </>
        }
      >
        <PressPassApplicationUpdateList/>
      </PaginationCard>
    </StyledContainer>
  );
};

export default PressPassUpdateApplicationsOverview;
