import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { routes } from '../../lib';
import CreatePressPass from './create';
import PressPassDetails from './details';
import EditPressPass from './edit';
import PressPassesOverview from './overview';

const PressPasses = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <PressPassesOverview />
      </Route>
      <Route path={routes.pressPassCreate}>
        <CreatePressPass />
      </Route>
      <Route path={routes.pressPassEdit(':pressPassId')}>
        <EditPressPass />
      </Route>
      <Route path={routes.pressPass(':pressPassId')}>
        <PressPassDetails />
      </Route>
    </Switch>
  );
};

export default PressPasses;
