import { Card, CardContent, Container } from '@mui/material';
import { VoidFunctionComponent } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrentUser, useTitle } from '../../hooks';
import { Role } from '../../model';
import CompanyOverview from '../companies/overview';
import PressPassesOverview from '../press-passes/overview';

const Dashboard: VoidFunctionComponent = () => {
  const { t } = useTranslation();
  useTitle(t('Dashboard'));
  const user = useCurrentUser();

  if (!user) {
    return <></>;
  }

  const roles = user.inheritedRoles;
  if (roles.includes(Role.PressPass)) {
    return <PressPassesOverview />;
  }
  if (roles.includes(Role.General)) {
    return <CompanyOverview />;
  }

  return (
    <Container maxWidth="md">
      <Card>
        <CardContent data-test="dashboard-content">{t('Willkommen')}</CardContent>
      </Card>
    </Container>
  );
};

export default Dashboard;
