import { AxiosError } from 'axios';
import { UseQueryResult } from 'react-query';
import { PaginationContextValue } from '../components/pagination';
import { Dict, Entity } from '../model';
import { FilterParams, Order, SortHandler, useFilters } from './use-filters';
import { PaginationResponse, usePaginationApi } from './use-pagination-api';

export const useFilteredPaginationApi = <
  TFilters extends Dict,
  TEntity extends Entity,
  TContext extends Dict = Dict
>(
  url: string,
  filters: Partial<TFilters> = {},
  initialOrderBy = 'id',
  initialOrder: Order = 'asc',
  initialPageSize = 10,
  context?: TContext
): {
  params: FilterParams<TFilters, TContext>;
  changePage?: (newPage: number) => void;
  changePageSize?: (newPageSize: number) => void;
  sort?: SortHandler;
  context: PaginationContextValue<TFilters, TEntity, TContext>;
} & UseQueryResult<PaginationResponse<TEntity>, AxiosError> => {
  const { params, changePage, changePageSize, sort } = useFilters<
    TFilters,
    TContext
  >(filters, initialOrderBy, initialOrder, initialPageSize, context);

  const query = usePaginationApi<TFilters, TEntity>(url, params);

  return {
    ...query,
    params,
    changePage,
    changePageSize,
    sort,
    context: {
      ...query,
      params,
      changePage,
      changePageSize,
      sort,
    },
  };
};
