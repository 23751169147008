import { Edit, Visibility } from '@mui/icons-material';
import { Link, TableCell, TableRow } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { routes } from '../../lib';
import { Signature } from '../../model';
import { CompactTableCell } from '../globals';
import { IconLink } from '../icon-link';
import { PaginationTable } from '../pagination';
import PaginationTableHeadCell from '../pagination/table-head-cell';

export const SignatureList: FunctionComponent<{ signatures?: Signature[] }> = ({
  signatures,
}) => {
  const { t } = useTranslation();

  return (
    <PaginationTable
      items={signatures}
      renderRow={(signature) => (
        <TableRow key={signature.id} hover={true}>
          <CompactTableCell>
            <Link to={routes.signature(signature.id)} component={RouterLink}>
              {signature.name}
            </Link>
          </CompactTableCell>
          <CompactTableCell>{signature.email}</CompactTableCell>
          <TableCell>{signature.comment}</TableCell>
          <CompactTableCell align={'right'}>
            <IconLink
              to={routes.signature(signature.id)}
              tooltip={t('Ansehen')}
              icon={<Visibility />}
            />
            <IconLink
              to={routes.signatureEdit(signature.id)}
              tooltip={t('Bearbeiten')}
              icon={<Edit />}
            />
          </CompactTableCell>
        </TableRow>
      )}
    >
      <TableRow>
        <PaginationTableHeadCell sort="title">
          {t('Name')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell sort="email">
          {t('E-Mail')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell>{t('Kommentar')}</PaginationTableHeadCell>
        <TableCell width={110} />
      </TableRow>
    </PaginationTable>
  );
};
