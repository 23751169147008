import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Button, Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel,
  InputLabel, Link,
  MenuItem,
  Select, Typography,
} from '@mui/material';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import React, { useState, VoidFunctionComponent } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { getYearOptions, handleHookFormErrors } from '../../helpers';
import { apiRoutes, request, routes } from '../../lib';
import { ID, Membership, PressPassExtensionResponse } from '../../model';
import { Link as RouterLink } from "react-router-dom";

interface ExtensionFormValues {
  year: number;
  includeNameList: boolean;
  includeExtensionForms: boolean;
  pressPassIds: ID[];
  membership: string;
}

const PressPassExtensionDialog: VoidFunctionComponent<{
  open: boolean;
  onClose: () => void;
  selected: ID[];
  membership: string;
  company: string;
}> = ({ open, onClose, selected, membership, company }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const [status, setStatus] = useState<PressPassExtensionResponse | false>(false);

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isValid },
    setError,
  } = useForm<ExtensionFormValues>({
    mode: 'all',
    defaultValues: {
      includeNameList: true,
      includeExtensionForms: true,
      year: new Date().getFullYear(),
      pressPassIds: selected,
      membership: membership,
    }
  });

  const closeDialog = () => {
    setStatus(false);
    onClose();
  };

  const submitMutation = useMutation(
    async (values: ExtensionFormValues) => {
      values.pressPassIds = selected;
      values.membership = membership;
      return await request<PressPassExtensionResponse | false>(
        apiRoutes.pressPassExportNameList,
        'put',
        values
      );
    },
    {
      onSuccess: async (res) => {
        await queryClient.invalidateQueries(apiRoutes.pressPassExportNameList);
        setStatus(res.data);
        enqueueSnackbar(t('Verlängerungsformular-Link erfolgreich erstellt.'), {
          variant: 'success',
        });
      },
      onError: (err: AxiosError) => {
        enqueueSnackbar(
          err.response?.data.message || t('Leider ist etwas schief gelaufen.'),
          { variant: 'error' }
        );
        handleHookFormErrors(err, setError);
      }
    }
  );

  return (
    <Dialog open={open} onClose={() => onClose()}>
      {!status &&
        <>
          <form
            onSubmit={handleSubmit((values) => submitMutation.mutateAsync(values))}
          >
            <DialogTitle>{t(`Zip-Archiv für {{count}} Personen erzeugen`, {
              count: selected.length,
            })}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {!(membership === Membership.Oezv || membership === Membership.Voez) &&
                  <Alert severity="error">Bitte wählen Sie einen Verband aus!</Alert>
                }

                {!(company) &&
                  <Alert severity="error">Bitte wählen Sie ein Medienunternehmen aus!</Alert>
                }

              </DialogContentText>
              <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                <FormLabel component="legend">{t(`Folgende Dateien sollen erzeugt werden:`)}</FormLabel>
                <FormGroup>
                  <FormControl fullWidth style={{ marginTop: '1em' }}>
                    <Controller
                      control={control}
                      name={'includeNameList'}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={field.value}
                              onChange={field.onChange}
                              name="includeNameList"
                              color="primary"
                            />
                          }
                          label={t<string>('Namesliste.pdf')}
                        />
                      )}
                    />
                  </FormControl>
                  <FormControl fullWidth style={{ marginTop: '1em' }}>
                    <Controller
                      control={control}
                      name={'includeExtensionForms'}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={field.value}
                              onChange={field.onChange}
                              name="includeExtensionForms"
                              color="primary"
                            />
                          }
                          label={t<string>('Verlängerungsformulare.pdf')}
                        />
                      )}
                    />
                  </FormControl>
                </FormGroup>
                <FormGroup>
                  <FormControl fullWidth style={{ marginTop: '1em', marginBottom: '1em' }}>
                    <InputLabel>{t('Formulare für folgendes Jahr erzeugen')}</InputLabel>
                    <Controller
                      control={control}
                      name="year"
                      render={({ field }) => (
                        <Select {...field}>
                          {getYearOptions().map((year, index) => (
                            <MenuItem key={index} value={year}>
                              {year}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </FormControl>
                </FormGroup>
                <FormHelperText>Falls viele Personen ausgewählt wurden, kann dieser Vorgang einige Zeit in Anspruch nehmen!</FormHelperText>
              </FormControl>


            </DialogContent>
            <DialogActions>
              <Button onClick={() => onClose()} disabled={isSubmitting}>
                {t('Abbrechen')}
              </Button>
              <LoadingButton
                disabled={!company || (selected.length < 1 || !(membership === Membership.Oezv || membership === Membership.Voez) || !isValid || isSubmitting)}
                type="submit"
                autoFocus
                variant="contained"
                loading={submitMutation.isLoading}
              >
                {t('Erstellen')}
              </LoadingButton>
            </DialogActions>
          </form>
        </>}
      {status && status.status === 200 &&
        <>
          <DialogTitle>{t('Link generiert')}</DialogTitle>
          <DialogContent>
            <Alert severity={status.status === 200 ? "success" : "error"}>Download-Link wurde erfolgreich erstellt.</Alert>
            <br/>
            <Typography variant="caption" style={{ fontSize: 'small' }} color="text.primary">
              Die <strong>Zip-Datei</strong> enthält die <i>Namensliste</i> und <i>Verlängerungsformulare</i> für {selected.length} Personen. <br/>
              Teilen Sie folgenden Link zu der Zip-Datei (rechte Maustaste Link-Adresse kopieren):<br/><br/>
            </Typography>
            <Link href={`${process.env.REACT_APP_UPLOADS_URL}/${status.data}`} target="_blank" style={{ fontSize: 'small' }}>
              {`${process.env.REACT_APP_UPLOADS_URL}/${status.data}`}
            </Link>

            <Typography variant="caption" style={{ fontSize: 'small' }} color="text.primary">
              <br/><br/>
              Den Link ist für <strong>14 Tage gültig</strong> und kann jederzeit auf der Unternehmensseite unter dem Reiter <i>Dateien</i> abgerufen werden.
              <br/><br/>
              <strong>Dort kann auch direkt eine E-Mail an den/die PA-Verantwortliche(n) versendet werden.</strong> <br/>
              Die {status.companyId &&
              <Link to={routes.companyFiles(status.companyId)} component={RouterLink} target={"_blank"}>
                Unternehmensseite
              </Link>} in einem neuen Tab öffnen.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => closeDialog()} disabled={isSubmitting}>
              {t('Schliessen')}
            </Button>
          </DialogActions>
        </>

      }
    </Dialog>
  );
};

export default PressPassExtensionDialog;
