import * as queryString from 'query-string';
import { useCallback, useEffect, useReducer, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { isSame } from '../helpers';
import { Dict } from '../model';

type Action = 'push' | 'replace';

type Type = 'Date' | 'DateTime' | 'string' | 'array' | 'number' | 'boolean';

export type UseQueryStateOptions = {
  action?: Action;
  delay?: number; // WARNING: using multiple useQueryState with delay may result in unexpected behaviour with changes at the same time
  valueType?: Type;
};

const TYPE_EMPTY_VALUE_MAP: Dict = {
  string: '',
  number: 0,
  boolean: false,
  array: [],
  Date: null,
  DateTime: null,
};

const isValidType = (value: any, allowNullAndUndefined = false) => {
  if (
    !(
      Array.isArray(value) ||
      Object.keys(TYPE_EMPTY_VALUE_MAP).indexOf(typeof value) > -1 ||
      (allowNullAndUndefined && (value === null || value === undefined)) ||
      value instanceof Date
    )
  ) {
    console.error('isValidType', value);
    throw new Error('useQueryState: the type is not supported.');
  }
};

const convertToQueryParam = <S>(value: S, type?: Type) => {
  if (value instanceof Date) {
    switch (type) {
      case 'Date':
        return value.toISOString().split('T')[0];
      case 'DateTime':
        return value.toISOString();
    }
  }
  return value;
};

const convertFromQueryParam = (value: any, type?: Type) => {
  if (value === '' || value === undefined) {
    // fill in empty value based on type (i.e. ?a=)
    return type ? TYPE_EMPTY_VALUE_MAP[type] : '';
  }
  if (type === 'array' && !Array.isArray(value)) {
    // for single element: tranform to array type (i.e. ?a=1)
    return [value];
  }
  if (
    type &&
    typeof value === 'string' &&
    ['Date', 'DateTime'].includes(type)
  ) {
    return new Date(value);
  }
  if (type === 'number') {
    return +value;
  }
  return value;
};

const LOCATION_CHANGE = 'LOCATION_CHANGE';
const STATE_CHANGE = 'STATE_CHANGE';

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case LOCATION_CHANGE: {
      const { currentQueryValue, key } = action.payload;
      const queryValue = convertFromQueryParam(currentQueryValue, state.type);
      if (!isSame(queryValue, state[key])) {
        return {
          ...state,
          [key]: queryValue,
        };
      }
      return state;
    }
    case STATE_CHANGE: {
      const { newValue, key } = action.payload;
      return {
        ...state,
        [key]: newValue,
      };
    }
  }
};

function filterObject(obj: any, callback: (v: any, key: string) => boolean) {
  return Object.fromEntries(
    Object.entries(obj).filter(([key, val]) => callback(val, key))
  );
}

// WARNING: using multiple useQueryState with delay may result in unexpected behaviour with changes at the same time
export const useQueryState = <S>(
  defaultValue: S | (() => S),
  varName: string,
  { action = 'push', delay = 0, valueType }: UseQueryStateOptions = {}
): [S, (value: S) => void] => {
  const location = useLocation();
  const history = useHistory();

  isValidType(defaultValue, true);
  const type =
    valueType || (Array.isArray(defaultValue) ? 'array' : typeof defaultValue);
  const parsedQuery = queryString.parse(location.search, {
    arrayFormat: 'comma',
  });

  const [state, dispatch] = useReducer(reducer, {
    [varName]: convertFromQueryParam(
      parsedQuery[varName] || defaultValue,
      type as Type
    ),
    type,
  });
  const timer = useRef<ReturnType<typeof setTimeout>>();
  const isPending = useRef(false);
  const isChanged = useRef(false);

  const clearTimer = useCallback(() => {
    isPending.current = false;
    if (timer.current) {
      clearTimeout(timer.current);
    }
  }, []);

  // push or replace history with debounce
  const manipulateHistory = useCallback((func: any, delay: number) => {
    isPending.current = true;
    if (timer.current) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(() => {
      isPending.current = false;
      func();
    }, delay);
  }, []);

  // changing location: sync state
  useEffect(() => {
    if (!isPending.current) {
      const parsedQuery = queryString.parse(location.search, {
        arrayFormat: 'comma',
      });
      const queryValue: any = parsedQuery[varName];
      // if query value is not supplied or state value is never changed, preserve default value
      if (queryValue !== undefined || isChanged.current) {
        isChanged.current = true;
        dispatch({
          type: LOCATION_CHANGE,
          payload: {
            key: varName,
            currentQueryValue: queryValue,
          },
        });
      }
    }

    return clearTimer;
  }, [varName, location, clearTimer]);

  if (varName === 'type') {
    throw new Error("VarName 'type' is not allowed in useQueryState");
  }

  const setVar = useCallback(
    (newValue: S) => {
      isValidType(newValue, true);
      // changing state: sync location
      // access location from history to ensure it is the most updated one
      const { search, pathname } = history.location;
      const parsedQuery = queryString.parse(search, { arrayFormat: 'comma' });
      const queryVar = parsedQuery[varName];
      const newQueryVar = convertToQueryParam(newValue, valueType);
      if (!isSame(queryVar, newQueryVar)) {
        const newSearch = `?${queryString.stringify(
          filterObject(
            {
              ...parsedQuery,
              [varName]: newQueryVar,
            },
            (value) => !!value
          ),
          {
            arrayFormat: 'comma',
          }
        )}`;

        if (delay) {
          // mutate history object synchronously
          history.location.search = newSearch;
          // push or replace history state asynchronously
          manipulateHistory(
            history[action].bind(null, `${pathname}${newSearch}` as any),
            delay
          );
        } else {
          history[action](`${pathname}${newSearch}` as any);
        }
      }
      dispatch({
        type: STATE_CHANGE,
        payload: {
          key: varName,
          newValue,
        },
      });
    },
    [varName, action, delay, history, manipulateHistory, valueType]
  );

  return [state![varName], setVar];
};
