import { Add } from '@mui/icons-material';
import { Autocomplete, Grid, TextField, Tooltip } from '@mui/material';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import React, { VoidFunctionComponent, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { handleHookFormErrors } from '../../helpers';
import { apiRoutes, request } from '../../lib';
import {
  ApiError,
  ID,
  MailingList,
  MailingListPersonFormValues,
  Person,
  PersonEmailOptions,
} from '../../model';
import LoadingIconButton from '../loading-icon-button';
import PaginatedAutocomplete from '../paginated-autocomplete';

export const MailingListRecipientForm: VoidFunctionComponent<{
  mailingList: MailingList;
}> = ({ mailingList }) => {
  const { t } = useTranslation();
  const [emailOptions, setEmailOptions] = useState<PersonEmailOptions[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const {
    handleSubmit,
    watch,
    setError,
    control,
    formState: { isSubmitting },
    setValue,
    reset,
    getValues,
  } = useForm<MailingListPersonFormValues>({
    mode: 'all',
  });

  const person = watch('person');

  const loadEmailOptions = async (value: ID) => {
    setEmailOptions([]);
    setValue('companyPerson', null);

    await request<PersonEmailOptions[]>(
      apiRoutes.personEmailAddresses(value),
      'get'
    )
      .then((res) => {
        setEmailOptions(res.data);
        const defaultOption = res.data.find((option) => option.default);
        if (defaultOption) {
          setValue('companyPerson', defaultOption.companyPerson);
        }
      })
      .catch((err: AxiosError<ApiError>) => {
        enqueueSnackbar(err.response?.data.message, { variant: 'error' });
        handleHookFormErrors(err, setError);
      });
  };

  const onSubmit = async (values: MailingListPersonFormValues) => {
    values.mailingList = mailingList.id;
    await request<MailingList>(
      apiRoutes.mailingListPersonCreate,
      'post',
      values
    )
      .then(() => {
        reset({ companyPerson: null, person: null });
        queryClient.invalidateQueries(
          apiRoutes.mailingListPersons(mailingList.id)
        );
        enqueueSnackbar(t('Empfänger wurde erfolgreich hinzugefügt.'), {
          variant: 'success',
        });
      })
      .catch((err: AxiosError<ApiError>) => {
        enqueueSnackbar(err.response?.data.message, { variant: 'error' });
        handleHookFormErrors(err, setError);
      });
  };

  return (
    <form onSubmit={handleSubmit((values) => onSubmit(values))}>
      <Grid
        container
        spacing={1}
        style={{ width: '100%' }}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Grid item xs={5}>
          <Controller
            control={control}
            name={`person` as const}
            render={({ field, fieldState }) => (
              <PaginatedAutocomplete
                name="person"
                label={t('Person hinzufügen')}
                value={field.value}
                onChange={async (ids) => {
                  if (ids.length === 0) {
                    return;
                  }
                  field.onChange(ids?.[0]);
                  await loadEmailOptions(ids?.[0]);
                }}
                optionLabel={(option: Person) =>
                  `${option.displayName} (${option.id})`
                }
                dataUrl={apiRoutes.persons}
                textFieldProps={{
                  error: fieldState.isTouched && fieldState.invalid,
                  helperText: fieldState.error?.message,
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            control={control}
            name={`companyPerson` as const}
            render={({ field, fieldState }) => (
              <Autocomplete
                multiple={false}
                options={emailOptions}
                loading={emailOptions.length === 0}
                disabled={!person}
                noOptionsText={t('Keine E-Mail Adresse verfügbar')}
                loadingText={t('Lädt E-Mail Adressen...')}
                getOptionLabel={(option) =>
                  `${option.name ? option.name : t('Privat')} ${
                    option.email ? `(${option.email})` : ''
                  }`
                }
                value={
                  emailOptions.find(
                    (option) => option.companyPerson === field.value
                  ) || null
                }
                onChange={(event, value) =>
                  field.onChange(value?.companyPerson || null)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('Unternehmen (E-Mail) auswählen')}
                    error={fieldState.isTouched && fieldState.invalid}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item textAlign="right">
          <Tooltip title={t<string>('Empfänger hinzufügen')}>
            <span>
              <LoadingIconButton
                type="submit"
                color="primary"
                size="small"
                disabled={!getValues('person')}
                loading={isSubmitting}
              >
                <Add />
              </LoadingIconButton>
            </span>
          </Tooltip>
        </Grid>
      </Grid>
    </form>
  );
};
