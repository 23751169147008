import { SearchOutlined } from '@mui/icons-material';
import { InputBase, alpha } from '@mui/material';
import React, { FormEvent, VoidFunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';


const StyledSearchContainer = styled.div`
  position: relative;
  border-radius: ${(props) => props.theme.shape.borderRadius}px;
  background-color: ${(props) => alpha(props.theme.palette.common.white, 0.15)};

  &:hover {
    background-color: ${(props) =>
      alpha(props.theme.palette.common.white, 0.25)};
  }

  margin-right: ${(props) => props.theme.spacing(2)}px;
  max-width: 250px;
`;

const StyledSearchIconContainer = styled.div`
  padding: ${(props) => props.theme.spacing(0, 2)};
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledInput = styled(InputBase)`
  padding: ${(props) => props.theme.spacing(1, 1, 1, 0)}px;
  padding-left: calc(1em + ${(props) => props.theme.spacing(4)});
  transition: ${(props) => props.theme.transitions.create('width')};
  color: inherit;
`;

const Search: VoidFunctionComponent = () => {
  const location = useLocation();
  const [search, setSearch] = useState(
    new URLSearchParams(location.search).get('q') || ''
  );
  const history = useHistory();
  const { t } = useTranslation();

  const performSearch = (e: FormEvent) => {
    e.preventDefault();
    history.push({
      pathname: '/search',
      search: `?q=${search}`,
    });
  };

  return (
    <StyledSearchContainer>
      <StyledSearchIconContainer>
        <SearchOutlined />
      </StyledSearchIconContainer>
      <form onSubmit={performSearch}>
        <StyledInput
          defaultValue={search}
          placeholder={t('Suche...')}
          inputProps={{ 'aria-label': 'search' }}
          onChange={(e) => setSearch(e.target.value)}
        />
      </form>
    </StyledSearchContainer>
  );
};

export default Search;
