import { Delete, Edit } from '@mui/icons-material';
import {
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { StyledCardTitle } from '../../components/globals';
import { GridData } from '../../components/grid-data';
import Guarded from '../../components/guarded';
import Header from '../../components/header';
import HttpError from '../../components/http-error';
import LoadingButton from '../../components/loading-button';
import LoadingContainer from '../../components/loading-container';
import { ModifiedEntity } from '../../components/modified-entity';
import { useTitle } from '../../hooks';
import { apiRoutes, request, routes, usePayRateDetailsApi } from '../../lib';
import { Role } from '../../model';


const PayRateDetails = () => {
  const { t } = useTranslation();
  const { payRateId } = useParams<{ payRateId: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, error, data } = usePayRateDetailsApi(+payRateId);
  const history = useHistory();
  useTitle(data?.name || t('Lizenzpaket Details'));

  const deleteMutation = useMutation(
    async () => {
      if (!data) {
        return;
      }

      return await request(apiRoutes.payRate(data.id), 'delete');
    },
    {
      onSuccess: () => {
        enqueueSnackbar(t('Lizenzpaket wurde erfolgreich gelöscht.'), {
          variant: 'success',
        });
        history.push(routes.payRates);
      },
      onError: () => {
        enqueueSnackbar(t('Lizenzpaket konnte nicht gelöscht werden.'), {
          variant: 'error',
        });
      },
    }
  );

  if (error) {
    return (
      <HttpError
        error={error}
        actions={
          <Button component={RouterLink} to={routes.payRates}>
            {t('Zurück zu Lizenzpaketen')}
          </Button>
        }
      />
    );
  }

  if (isLoading || !data) {
    return <LoadingContainer />;
  }

  const detailBreadcrumbs = [
    { label: t('Home'), link: routes.dashboard },
    { label: t('Lizenzpakete'), link: routes.payRates },
  ];

  return (
    <Container maxWidth="md">
      <Header
        title={data.name}
        breadcrumbs={detailBreadcrumbs}
        search={{ route: routes.payRates, param: 'search' }}
        actions={
          <>
            <Guarded requiredRole={Role.PDN}>
              <Button
                variant="contained"
                color="primary"
                component={RouterLink}
                to={routes.payRateEdit(data.id)}
                startIcon={<Edit />}
              >
                {t('Bearbeiten')}
              </Button>{' '}
              <LoadingButton
                variant="contained"
                color="secondary"
                onClick={() => {
                  if (
                    !window.confirm(
                      t(
                        `Wollen Sie das Lizenzpaket {{name}} wirklich löschen?`,
                        {
                          name: data.name,
                        }
                      )
                    )
                  ) {
                    return;
                  }
                  deleteMutation.mutateAsync();
                }}
                startIcon={<Delete />}
                loading={deleteMutation.isLoading}
              >
                {t('Löschen')}
              </LoadingButton>
            </Guarded>
            <ModifiedEntity entity={data} />
          </>
        }
      />
      <Card>
        <CardContent>
          <StyledCardTitle variant="h6" color="secondary" gutterBottom>
            {t('Informationen')}
          </StyledCardTitle>
          <Grid container spacing={3}>
            <GridData xs={12} label={t<string>('Name')}>
              {data.name}
            </GridData>
            <GridData xs={12} md={6} label={t<string>('Typ')}>
              {t(`payRate.type.${data.type}`)}
            </GridData>
            <GridData xs={12} md={6} label={t<string>('Preis')}>
              {data.price} €
            </GridData>
          </Grid>
        </CardContent>
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <GridData xs={12} label={t<string>('Interner Kommentar')}>
              {data.comment}
            </GridData>
          </Grid>
        </CardContent>
      </Card>
    </Container>
  );
};

export default PayRateDetails;
