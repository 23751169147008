import { Typography } from '@mui/material';
import React, { VoidFunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  Blameable,
  LegacyEntity,
  Timestampable,
  isBlameable,
  isLegacy,
  isTimestampable,
} from '../../model';
import FormattedDate from '../formatted-date';

const StyledTypography = styled(Typography)`
  display: block;
  margin-top: 0.5em;
`;

const StyledSpan = styled.span<{ multiline: boolean }>`
  display: ${(props) => (props.multiline ? 'block' : 'inline')};
`;

interface Props {
  entity: Timestampable | Blameable | LegacyEntity;
  multiline?: boolean;
}

export const ModifiedEntity: VoidFunctionComponent<Props> = ({
  entity,
  multiline = true,
}) => {
  const { t } = useTranslation();

  const showUpdated =
    (isTimestampable(entity) && entity.createdAt !== entity.updatedAt) ||
    (isBlameable(entity) && entity.createdBy !== entity.updatedBy);

  return (
    <StyledTypography variant="caption">
      <StyledSpan multiline={multiline}>
        {t('erstellt')}{' '}
        {isTimestampable(entity) && <FormattedDate date={entity.createdAt} />}
        {isBlameable(entity) && entity.createdBy && (
          <>
            {' '}
            {t('von')} {entity.createdBy}
          </>
        )}
      </StyledSpan>
      {isLegacy(entity) && (
        <StyledSpan multiline={multiline}>
          {t('importiert von')} {entity.legacyTable} / {entity.legacyId}
        </StyledSpan>
      )}
      {showUpdated && (
        <StyledSpan multiline={multiline}>
          {!multiline && ','} {t('aktualisiert')}{' '}
          {isTimestampable(entity) && <FormattedDate date={entity.updatedAt} />}
          {isBlameable(entity) && entity.updatedBy && (
            <>
              {' '}
              {t('von')} {entity.updatedBy}
            </>
          )}
        </StyledSpan>
      )}
    </StyledTypography>
  );
};
