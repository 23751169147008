import { Delete } from '@mui/icons-material';
import {
  CardContent,
  Divider,
  Grid,
  Link,
  Tooltip,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { VoidFunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';
import { apiRoutes, request, routes } from '../../lib';
import { DateFormat, ID, LicenseContactPerson, Licensee } from '../../model';
import FormattedDate from '../formatted-date';
import { StyledCardTitle } from '../globals';
import { GridData } from '../grid-data';
import LoadingIconButton from '../loading-icon-button';
import { LicenseeContactPersonForm } from './contact-person-form';
import { LicenseePressReviewForm } from './press-review-form';
import { LicensePressReviewList } from './press-review-list';

export const LicenseeBaseData: VoidFunctionComponent<{ licensee: Licensee }> =
  ({ licensee }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();
    const [deletingContactPersons, setDeletingContactPersons] = useState<ID[]>(
      []
    );

    const deleteContactPersonMutation = useMutation(
      async (contactPerson: LicenseContactPerson) => {
        setDeletingContactPersons([
          ...deletingContactPersons,
          contactPerson.id,
        ]);
        return {
          id: contactPerson.id,
          response: await request(
            apiRoutes.licenseContactPerson(licensee.id, contactPerson.id),
            'delete'
          ),
        };
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries(apiRoutes.licensee(licensee.id));
          enqueueSnackbar(t('Kontaktperson wurde erfolgreich entfernt.'), {
            variant: 'success',
          });
        },
        onError: () => {
          enqueueSnackbar(t('Kontaktperson konnte nicht entfernt werden.'), {
            variant: 'error',
          });
        },
        onSettled: (res) => {
          if (!res) {
            return;
          }
          setDeletingContactPersons([
            ...deletingContactPersons.filter((person) => person !== res.id),
          ]);
        },
      }
    );

    return (
      <Grid container>
        <Grid item xs={12} md={6}>
          <CardContent>
            <StyledCardTitle variant="h6" color="secondary" gutterBottom>
              {t('Vertragsdaten')}
            </StyledCardTitle>
            <Grid container spacing={3}>
              <GridData xs={12} md={6} label={t<string>('Lizenznummer')}>
                {licensee.license}
              </GridData>
              <GridData xs={12} md={6} label={t<string>('MBO')}>
                {licensee.mbo && (
                  <Link
                    to={routes.company(licensee.mbo.id)}
                    component={RouterLink}
                  >
                    {licensee.mbo.name} {licensee.mbo.term}
                  </Link>
                )}
              </GridData>
              <GridData xs={12} md={6} label={t<string>('Vertragsbeginn')}>
                <FormattedDate
                  date={licensee.contractStart || ''}
                  format={DateFormat.Default}
                />
              </GridData>
              <GridData xs={12} md={6} label={t<string>('Vertragsende')}>
                <FormattedDate
                  date={licensee.contractEnd || ''}
                  format={DateFormat.Default}
                />
              </GridData>
              <GridData xs={12} md={6} label={t<string>('Meldeintervall')}>
                {t(`licensee.licenseInterval.${licensee.licenseInterval}`)}
              </GridData>
              <GridData
                xs={12}
                md={6}
                label={t<string>('Rechnungstyp / Lizenzpaket')}
              >
                {licensee.payRate && (
                  <Link
                    to={routes.payRate(licensee.payRate.id)}
                    component={RouterLink}
                  >
                    {licensee.payRate.name}
                  </Link>
                )}
              </GridData>
              <GridData xs={12} md={6} label={t<string>('Debitorennummer')}>
                {licensee.debtorAccount}
              </GridData>
              <GridData xs={12} md={6} label={t<string>('Lieferantennummer')}>
                {licensee.supplierAccount}
              </GridData>
            </Grid>
          </CardContent>
        </Grid>
        <Divider
          orientation="vertical"
          flexItem
          style={{ marginRight: '-1px' }}
        />
        <Grid item xs={12} md={6}>
          <CardContent>
            <StyledCardTitle variant="h6" color="secondary" gutterBottom>
              {t('Kontaktpersonen')}
            </StyledCardTitle>
            <Grid
              container
              spacing={3}
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={12}>
                {licensee.licenseContactPersons?.map((contactPerson) => (
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    key={contactPerson.id}
                  >
                    <GridData
                      item
                      xs={10}
                      label={t<string>(
                        `licensee.contactPerson.type.${contactPerson.type}`
                      )}
                      mode="richtext"
                    >
                      <Grid container spacing={1}>
                        <Grid item xs={12} md={6}>
                          <Link
                            to={routes.person(contactPerson.person.id)}
                            component={RouterLink}
                          >
                            {contactPerson.person.displayName}
                          </Link>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          {contactPerson.companyPerson && (
                            <Typography display="block">
                              {contactPerson.companyPerson.company.name}{' '}
                              {contactPerson.companyPerson.company.term} (
                              {contactPerson.companyPerson.company.id})
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                    </GridData>
                    <Grid item textAlign="right">
                      <Tooltip title={t<string>('Kontaktperson entfernen')}>
                        <span>
                          <LoadingIconButton
                            type="button"
                            size="small"
                            loading={
                              deleteContactPersonMutation.isLoading &&
                              !!deletingContactPersons.find(
                                (person) => person === contactPerson.id
                              )
                            }
                            onClick={() => {
                              if (
                                !window.confirm(
                                  t(
                                    `Wollen Sie die Kontaktperson {{displayName}} wirklich entfernen?`,
                                    {
                                      displayName:
                                        contactPerson.person.displayName,
                                    }
                                  )
                                )
                              ) {
                                return;
                              }
                              deleteContactPersonMutation.mutate(contactPerson);
                            }}
                          >
                            <Delete />
                          </LoadingIconButton>
                        </span>
                      </Tooltip>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
              <Grid item xs={12}>
                <LicenseeContactPersonForm licensee={licensee} />
              </Grid>
            </Grid>
          </CardContent>
        </Grid>
        <Grid item xs={12}>
          <Divider />
          <CardContent>
            <StyledCardTitle variant="h6" color="secondary" gutterBottom>
              {t('Pressespiegel')}
            </StyledCardTitle>
          </CardContent>
          <LicensePressReviewList licensee={licensee} />
          <CardContent>
            <Grid item xs={12}>
              <LicenseePressReviewForm licensee={licensee} />
            </Grid>
          </CardContent>
        </Grid>
      </Grid>
    );
  };
