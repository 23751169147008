import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Button, Card, CardActions, Container, Divider } from '@mui/material';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { CompanyFormFields } from '../../components/company';
import Header from '../../components/header';
import HttpError from '../../components/http-error';
import LoadingContainer from '../../components/loading-container';
import { handleHookFormErrors } from '../../helpers';
import { useTitle } from '../../hooks';
import { useCompanyValidationSchema } from '../../hooks/validation/company/use-company-validation-schema';
import { apiRoutes, request, routes, useCompanyDetailsApi } from '../../lib';
import { Company, CompanyFormValues, getCompanyFormValues } from '../../model';

const EditCompany = () => {
  const { t } = useTranslation();
  const { companyId } = useParams<{ companyId: string | undefined }>();

  const { isLoading, error, data } = useCompanyDetailsApi(
    companyId as unknown as number
  );
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  useTitle(data?.name || t('Unternehmen bearbeiten'));

  const validationSchema = useCompanyValidationSchema();

  const {
    control,
    handleSubmit,
    setError,
    formState: { isValid, isSubmitting },
    reset,
  } = useForm<CompanyFormValues>({
    mode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues: getCompanyFormValues(data),
  });

  useEffect(() => reset(getCompanyFormValues(data)), [reset, data]);

  const mutation = useMutation(
    async (values: CompanyFormValues) => {
      if (!data) {
        return;
      }
      return await request<Company>(apiRoutes.company(data.id), 'put', values);
    },
    {
      onSuccess: (res) => {
        if (!res) {
          return;
        }
        enqueueSnackbar(t('Unternehmen wurde erfolgreich aktualisiert.'), {
          variant: 'success',
        });
        history.push(routes.company(res.data.id));
      },
      onError: (err: AxiosError) => {
        enqueueSnackbar(err.response?.data.message || 'Error', {
          variant: 'error',
        });
        handleHookFormErrors(err, setError);
      },
    }
  );

  if (isLoading || !data) {
    return <LoadingContainer />;
  }

  if (error) {
    return (
      <HttpError
        error={error}
        actions={
          <Button component={RouterLink} to={routes.companies}>
            {t('Zurück zu Unternehmen')}
          </Button>
        }
      />
    );
  }

  const breadcrumbs = [
    { label: t('Home'), link: routes.dashboard },
    { label: t('Unternehmen'), link: routes.companies },
    { label: data.name, link: routes.company(data.id) },
    { label: t('Bearbeiten') },
  ];

  return (
    <Container maxWidth="md">
      <form onSubmit={handleSubmit((values) => mutation.mutateAsync(values))}>
        <Header
          title={data.name}
          breadcrumbs={breadcrumbs}
          actions={
            <LoadingButton
              type="submit"
              size="medium"
              color="primary"
              variant="contained"
              loading={isSubmitting}
            >
              {t('Speichern')}
            </LoadingButton>
          }
        />
        <Card>
          <CompanyFormFields control={control} company={data} />
          <Divider />
          <CardActions>
            <LoadingButton
              type="submit"
              size="medium"
              color="primary"
              disabled={!isValid}
              variant="contained"
              loading={isSubmitting}
            >
              {t('Speichern')}
            </LoadingButton>
          </CardActions>
        </Card>
      </form>
    </Container>
  );
};

export default EditCompany;
