import { Button, ButtonProps, CircularProgress } from '@mui/material';
import React, { FunctionComponent } from 'react';

const LoadingButton: FunctionComponent<{ loading: boolean } & ButtonProps> = ({
  children,
  loading,
  ...props
}) => {
  return (
    <Button {...props} disabled={loading || props.disabled}>
      {children} {loading && <CircularProgress size={12} />}
    </Button>
  );
};

export default LoadingButton;
