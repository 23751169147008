import { Container } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../../components/header';
import { ImportWizard } from '../../components/import/wizard';
import { useTitle } from '../../hooks';
import { routes } from '../../lib';

const CreateImport = () => {
  const { t } = useTranslation();

  useTitle(t('Neuer Import'));

  const detailBreadcrumbs = [
    { label: t('Home'), link: routes.dashboard },
    { label: t('Imports'), link: routes.imports },
  ];

  return (
    <Container maxWidth="md">
      <Header title={t('Neu')} breadcrumbs={detailBreadcrumbs} />
      <ImportWizard />
    </Container>
  );
};

export default CreateImport;
