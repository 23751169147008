import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import FormPageFullForm from './full-form';
import FormPageUploadForm from './upload-form';
import FormPageUpdateForm from "./update-form";

const FormPage = () => {
  return (
    <Router>
      <Switch>
        <Route path={`/`} exact>
          <FormPageFullForm />
        </Route>
        <Route path={`/update`}>
          <FormPageUpdateForm />
        </Route>
        <Route path={`/:token`}>
          <FormPageUploadForm />
        </Route>
      </Switch>
    </Router>
  );
};

export default FormPage;
