import { yupResolver } from '@hookform/resolvers/yup';
import { Card, CardActions, Container, Divider } from '@mui/material';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router';
import Header from '../../components/header';
import LoadingButton from '../../components/loading-button';
import { MailingFormFields } from '../../components/mailing';
import { buildFormData, handleHookFormErrors } from '../../helpers';
import { useMailingValidationSchema, useTitle } from '../../hooks';
import { apiRoutes, request, routes } from '../../lib';
import { Mailing, MailingFormValues, getMailingFormValues } from '../../model';

const CreateMailing = () => {
  const { t } = useTranslation();
  useTitle(t('Neues Mailing'));
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const breadcrumbs = [
    { label: t('Home'), link: routes.dashboard },
    { label: t('Mailings'), link: routes.mailings },
    { label: t('Neu') },
  ];

  const validationSchema = useMailingValidationSchema();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    setError,
  } = useForm<MailingFormValues>({
    mode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues: getMailingFormValues(),
  });

  const mutation = useMutation(
    async (values: MailingFormValues) => {
      return await request<Mailing>(
        apiRoutes.mailingCreate,
        'post',
        buildFormData<MailingFormValues>(
          {
            name: values.name,
            subject: values.subject,
            comment: values.comment,
            content: values.content,
            signature: values.signature,
            includeNameList: values.includeNameList,
            mailingLists: values.mailingLists,
            attachments: values.attachments.map((attachment) => ({
              entityId: attachment.attachmentId,
              name: attachment.name,
              size: attachment.size,
              file: attachment.file,
            })),
          },
          (formData) => {
            values.attachments.forEach((attachment, index) => {
              if (!attachment.file) {
                return;
              }
              formData.append(`attachment_${index}`, attachment.file);
            });
          }
        )
      );
    },
    {
      onSuccess: (res) => {
        enqueueSnackbar(t('Mailing wurde erfolgreich erstellt.'), {
          variant: 'success',
        });
        history.push(routes.mailing(res.data.id));
      },
      onError: (err: AxiosError) => {
        enqueueSnackbar(err.response?.data.message || 'Error', {
          variant: 'error',
        });
        handleHookFormErrors(err, setError);
      },
    }
  );

  return (
    <Container maxWidth="xl">
      <form
        onSubmit={handleSubmit((values) => mutation.mutateAsync(values))}
        noValidate
      >
        <Header
          title={t('Neu')}
          breadcrumbs={breadcrumbs}
          actions={
            <LoadingButton
              type="submit"
              size="medium"
              color="primary"
              variant="contained"
              loading={isSubmitting}
            >
              {t('Speichern')}
            </LoadingButton>
          }
        />
        <Card>
          <MailingFormFields control={control} />
          <Divider />
          <CardActions>
            <LoadingButton
              type="submit"
              size="medium"
              color="primary"
              variant="contained"
              loading={isSubmitting}
            >
              {t('Speichern')}
            </LoadingButton>
          </CardActions>
        </Card>
      </form>
    </Container>
  );
};

export default CreateMailing;
