import { yupResolver } from '@hookform/resolvers/yup';
import { FileDownload } from '@mui/icons-material';
import { DatePicker } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from '@mui/material';
import React, { VoidFunctionComponent } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { formatPayloadDate } from '../../helpers';
import { useLicenseeEvaluationValidationSchema } from '../../hooks/validation/licensee';
import { apiRoutes } from '../../lib';
import { DateFormat, DateTimeString, Licensee } from '../../model';

interface ReportFormValues {
  from: DateTimeString;
  to: DateTimeString;
}

const defaultValues = {
  from: '',
  to: '',
};

const LicenseeReportDialog: VoidFunctionComponent<{
  open: boolean;
  onClose: () => void;
  licensee: Licensee;
}> = ({ open, onClose, licensee }) => {
  const { t } = useTranslation();

  const validationSchema = useLicenseeEvaluationValidationSchema();

  const {
    control,
    watch,
    formState: { isSubmitting, isValid },
    handleSubmit,
    reset,
  } = useForm<ReportFormValues>({
    mode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues: defaultValues,
  });

  const from = watch('from');
  const to = watch('to');

  return (
    <Dialog open={open} onClose={() => onClose()}>
      <form
        onSubmit={handleSubmit(() => {
          window.open(
            `${process.env.REACT_APP_API_URL}/${apiRoutes.licenseeReport(
              licensee.id,
              formatPayloadDate(from) || '',
              formatPayloadDate(to) || ''
            )}`,
            '_blank'
          );
          onClose();
          reset(defaultValues);
        })}
      >
        <DialogTitle>{t('Report exportieren')}</DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name="from"
                render={({ field, fieldState }) => (
                  <DatePicker
                    value={field.value}
                    inputFormat={DateFormat.Default}
                    mask={DateFormat.Mask}
                    onChange={field.onChange}
                    label={t('Von')}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        required
                        fullWidth
                        helperText={fieldState.error?.message}
                        error={fieldState.isTouched && fieldState.invalid}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name="to"
                render={({ field, fieldState }) => (
                  <DatePicker
                    value={field.value}
                    inputFormat={DateFormat.Default}
                    mask={DateFormat.Mask}
                    onChange={field.onChange}
                    label={t('Bis')}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        required
                        fullWidth
                        helperText={fieldState.error?.message}
                        error={fieldState.isTouched && fieldState.invalid}
                      />
                    )}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => onClose()}
            disabled={isSubmitting}
            type="button"
          >
            {t('Abbrechen')}
          </Button>{' '}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            startIcon={<FileDownload />}
            disabled={!isValid}
          >
            {t('Download')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default LicenseeReportDialog;
