import {
  CardContent,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  TextField,
} from '@mui/material';
import React, { VoidFunctionComponent } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { apiRoutes } from '../../lib';
import { MailingList, MailingListFormValues } from '../../model';
import { StyledCardTitle } from '../globals';
import PaginatedAutocomplete from '../paginated-autocomplete';

export const MailingListFormFields: VoidFunctionComponent<{
  control: Control<MailingListFormValues>;
}> = ({ control }) => {
  const { t } = useTranslation();

  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <CardContent>
          <StyledCardTitle variant="h6" color="secondary" gutterBottom>
            {t('Stammdaten')}
          </StyledCardTitle>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name={'name'}
                render={({ field, fieldState }) => (
                  <TextField
                    label={t('Name')}
                    fullWidth
                    required
                    {...field}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item md={12}>
              <Controller
                control={control}
                name={'comment'}
                render={({ field, fieldState }) => (
                  <TextField
                    label={t('Interner Kommentar')}
                    fullWidth
                    {...field}
                    multiline={true}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="enabled"
                render={({ field }) => (
                  <FormControlLabel
                    defaultChecked={field.value}
                    control={
                      <Checkbox
                        checked={field.value}
                        onChange={field.onChange}
                        id="enabled"
                        color="primary"
                      />
                    }
                    label={t<string>('Aktiv')}
                  />
                )}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Grid>
      <Divider
        orientation={'vertical'}
        flexItem
        style={{ marginRight: '-1px' }}
      />
      <Grid item xs={12} md={6}>
        <CardContent>
          <StyledCardTitle variant="h6" color="secondary" gutterBottom>
            {t('Verteiler hinzufügen')}
          </StyledCardTitle>
          <Controller
            control={control}
            name={'mailingLists'}
            render={({ field, fieldState }) => (
              <PaginatedAutocomplete
                name="mailingLists"
                label={t('Verteiler')}
                value={field.value}
                onChange={(ids) => field.onChange(ids)}
                optionLabel={(option: MailingList) => option.name}
                dataUrl={apiRoutes.mailingLists}
                multiple={true}
                textFieldProps={{
                  error: fieldState.invalid,
                  helperText: fieldState.error?.message,
                }}
              />
            )}
          />
        </CardContent>
      </Grid>
    </Grid>
  );
};
