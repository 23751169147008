import { Edit, Visibility } from '@mui/icons-material';
import { Link, TableCell, TableRow } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { routes } from '../../lib';
import { DateFormat, Licensee } from '../../model';
import FormattedDate from '../formatted-date';
import { CompactTableCell } from '../globals';
import { IconLink } from '../icon-link';
import { PaginationTable } from '../pagination';
import PaginationTableHeadCell from '../pagination/table-head-cell';

export const LicenseeList: FunctionComponent<{ licensees?: Licensee[] }> = ({
  licensees,
}) => {
  const { t } = useTranslation();

  return (
    <PaginationTable
      items={licensees}
      renderRow={(licensee) => (
        <TableRow key={licensee.id} hover={true}>
          <TableCell>{licensee.license}</TableCell>
          <TableCell>{licensee.payRate?.name || '-'}</TableCell>
          <TableCell>
            <Link to={routes.licensee(licensee.id)} component={RouterLink}>
              {licensee.name}
            </Link>
          </TableCell>
          <TableCell>
            {' '}
            {licensee.mbo && (
              <Link component={RouterLink} to={routes.company(licensee.mbo.id)}>
                {licensee.mbo.name} {licensee.mbo.term || ''}
              </Link>
            )}
          </TableCell>
          <TableCell>
            {licensee.lastBillingDate ? (
              <FormattedDate
                date={licensee.lastBillingDate}
                format={DateFormat.Default}
              />
            ) : (
              '-'
            )}
          </TableCell>
          <TableCell>
            {licensee.balance ? licensee.balance.transactionUnitSum : 0}
          </TableCell>
          <TableCell>
            {licensee.balance ? licensee.balance.purchaseNet : 0}
          </TableCell>
          <TableCell>
            {licensee.balance ? licensee.balance.licenseRevenueNet : 0}
          </TableCell>
          <TableCell>
            {licensee.balance ? licensee.balance.balanceNet : 0}
          </TableCell>
          <CompactTableCell align={'right'}>
            <IconLink
              to={routes.licensee(licensee.id)}
              tooltip={t('Ansehen')}
              icon={<Visibility />}
            />
            <IconLink
              to={routes.licenseeEdit(licensee.id)}
              tooltip={t('Bearbeiten')}
              icon={<Edit />}
            />
          </CompactTableCell>
        </TableRow>
      )}
    >
      <TableRow>
        <PaginationTableHeadCell sort="license">
          {t('Lizenznummer')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell sort="licenseName">
          {t('LP')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell sort="name">
          {t('Kundenname')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell sort="mbo">
          {t('MBO / Agentur')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell>{t('Letzte Abr')}</PaginationTableHeadCell>
        <PaginationTableHeadCell>{t('Clippings')}</PaginationTableHeadCell>
        <PaginationTableHeadCell>{t('EK Netto')}</PaginationTableHeadCell>
        <PaginationTableHeadCell>{t('LU Netto')}</PaginationTableHeadCell>
        <PaginationTableHeadCell>{t('Saldo Netto')}</PaginationTableHeadCell>
        <TableCell width={110} />
      </TableRow>
    </PaginationTable>
  );
};
