import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Card,
  CardActions,
  Container,
  Divider,
  Grid,
} from '@mui/material';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { CompanyFormFields } from '../../components/company';
import Header from '../../components/header';
import HttpError from '../../components/http-error';
import { LicenseeFormFields } from '../../components/licensee';
import LoadingContainer from '../../components/loading-container';
import {
  formatPayloadDate,
  handleHookFormErrors,
  hasRole,
} from '../../helpers';
import { useApi, useCurrentUser, useTitle } from '../../hooks';
import { useLicenseeValidationSchema } from '../../hooks/validation/licensee';
import { apiRoutes, request, routes, useLicenseeDetailsApi } from '../../lib';
import {
  Licensee,
  LicenseeFormValues,
  Role,
  getLicenseeFormValues,
} from '../../model';

const EditLicensee = () => {
  const { t } = useTranslation();
  const { licenseeId } = useParams<{ licenseeId: string }>();
  const user = useCurrentUser();

  const { isLoading, error, data } = useLicenseeDetailsApi(+licenseeId);

  const {
    data: nextLicense,
    isLoading: nextLicenseLoading,
    error: nextLicenseError,
  } = useApi<{ number: number }>(apiRoutes.nextLicense);

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  useTitle(data?.name || t('Lizenznehmer bearbeiten'));

  const validationSchema = useLicenseeValidationSchema();

  const {
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting },
    reset,
  } = useForm<LicenseeFormValues>({
    mode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues: getLicenseeFormValues(data, nextLicense?.number),
  });

  useEffect(
    () => reset(getLicenseeFormValues(data, nextLicense?.number)),
    [reset, data, nextLicense]
  );

  const mutation = useMutation(
    async (values: LicenseeFormValues) => {
      if (!data) {
        return;
      }
      values.contractStart = formatPayloadDate(values.contractStart || null);
      values.contractEnd = formatPayloadDate(values.contractEnd || null);
      return await request<Licensee>(
        apiRoutes.licensee(data.id),
        'put',
        values
      );
    },
    {
      onSuccess: (res) => {
        if (!res) {
          return;
        }
        enqueueSnackbar(t('Lizenznehmer wurde erfolgreich aktualisiert.'), {
          variant: 'success',
        });
        history.push(routes.licensee(res.data.id));
      },
      onError: (err: AxiosError) => {
        enqueueSnackbar(err.response?.data.message || 'Error', {
          variant: 'error',
        });
        handleHookFormErrors(err, setError);
      },
    }
  );

  if (isLoading || !data || nextLicenseLoading) {
    return <LoadingContainer />;
  }

  if (error || nextLicenseError) {
    return (
      <HttpError
        error={error}
        actions={
          <Button component={RouterLink} to={routes.licensees}>
            {t('Zurück zu Lizenznehmern')}
          </Button>
        }
      />
    );
  }

  const breadcrumbs = [
    { label: t('Home'), link: routes.dashboard },
    { label: t('Unternehmen'), link: routes.companies },
    { label: data.name, link: routes.company(data.id) },
    { label: t('Lizenznehmer'), link: routes.licensee(data.id) },
    { label: t('Bearbeiten') },
  ];

  return (
    <Container maxWidth={hasRole(user, Role.PDN) ? 'xl' : 'md'}>
      <Header title={data.name} breadcrumbs={breadcrumbs} />
      <form
        noValidate
        onSubmit={handleSubmit((values) => mutation.mutateAsync(values))}
      >
        <Card>
          <Grid container>
            <Grid item xs={12} md={hasRole(user, Role.PDN) ? 6 : 12}>
              <CompanyFormFields control={control} />
            </Grid>
            <Divider
              orientation="vertical"
              flexItem
              style={{ marginRight: '-1px' }}
            />
            <Grid item xs={12} md={6}>
              <LicenseeFormFields control={control} licensee={data} />
            </Grid>
          </Grid>
          <Divider />
          <CardActions>
            <LoadingButton
              type="submit"
              size="medium"
              color="primary"
              variant="contained"
              loading={isSubmitting || mutation.isLoading}
            >
              {t('Speichern')}
            </LoadingButton>
          </CardActions>
        </Card>
      </form>
    </Container>
  );
};

export default EditLicensee;
