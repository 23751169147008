import {
  Blameable,
  Commentable,
  DateTimeString,
  ID,
  Nullable,
  Timestampable,
} from './index';

export interface LicensePressReview
  extends Blameable,
    Commentable,
    Timestampable {
  id: ID;
  from: DateTimeString;
  to: DateTimeString;
  type: LicensePressReviewType;
  usage: LicensePressReviewUsage;
  archiveCharge: number;
  discount: number;
  tariffGroup: string;
  lifetime: LicensePressReviewLifespan;
}

export enum LicensePressReviewType {
  Internal = 'internal',
  External = 'external',
}

export enum LicensePressReviewUsage {
  Digital = 'digital',
  Paper = 'paper',
}

export enum LicensePressReviewLifespan {
  Month = '1m',
  TwoMonths = '2m',
  Year = '1y',
  FiveYears = '5y',
  Unlimited = 'inf',
}

export interface LicensePressReviewFormValues
  extends Omit<
    LicensePressReview,
    | 'id'
    | 'tariffGroup'
    | 'to'
    | 'from'
    | keyof Timestampable
    | keyof Blameable
    | keyof Commentable
  > {
  tariffGroup: string;
  to: Nullable<DateTimeString>;
  from: Nullable<DateTimeString>;
  licenseeId: Nullable<ID>;
}

export const getLicensePressReviewFormValues = (
  pressReview?: LicensePressReview
): LicensePressReviewFormValues => {
  return {
    from: pressReview?.from || null,
    to: pressReview?.to || null,
    type: pressReview?.type || LicensePressReviewType.Internal,
    usage: pressReview?.usage || LicensePressReviewUsage.Digital,
    archiveCharge: pressReview?.archiveCharge || 0,
    discount: pressReview?.discount || 0,
    tariffGroup: pressReview?.tariffGroup || 'TG2',
    lifetime: pressReview?.lifetime || LicensePressReviewLifespan.Year,
    licenseeId: null,
  };
};
