import { DatePicker } from '@mui/lab';
import {
  CardContent,
  Divider,
  Grid,
  TextField,
} from '@mui/material';
import React, { VoidFunctionComponent } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { apiRoutes } from '../../lib';
import { DateFormat, Tariff, TariffFormValues } from '../../model';
import { StyledCardTitle } from '../globals';
import PaginatedAutocomplete from '../paginated-autocomplete';
import { TariffGroupFormFields } from './tariff-group-form-fields';

export const TariffFormFields: VoidFunctionComponent<{
  control: Control<TariffFormValues>;
  tariff?: Tariff;
}> = ({ control, tariff }) => {
  const { t } = useTranslation();

  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <CardContent>
          <StyledCardTitle variant="h6" color="secondary" gutterBottom>
            {t('Stammdaten')}
          </StyledCardTitle>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name={'name'}
                render={({ field, fieldState }) => (
                  <TextField
                    label={t('Name')}
                    fullWidth
                    required
                    {...field}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name="validFrom"
                render={({ field, fieldState }) => (
                  <DatePicker
                    value={field.value}
                    inputFormat={DateFormat.Default}
                    mask={DateFormat.Mask}
                    onChange={field.onChange}
                    label={t('Gültig von')}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        fullWidth
                        required
                        error={fieldState.invalid}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name="validTo"
                render={({ field, fieldState }) => (
                  <DatePicker
                    value={field.value}
                    inputFormat={DateFormat.Default}
                    mask={DateFormat.Mask}
                    onChange={field.onChange}
                    label={t('Gültig bis')}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        fullWidth
                        required
                        error={fieldState.invalid}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name={'apa'}
                render={({ field, fieldState }) => (
                  <TextField
                    label={t('APA Abrechnungsmodus (%)')}
                    fullWidth
                    {...field}
                    error={fieldState.isTouched && fieldState.invalid}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardContent>
          <StyledCardTitle variant="h6" color="secondary" gutterBottom>
            {t('Tarifgruppen')}
          </StyledCardTitle>
          <TariffGroupFormFields control={control} />
        </CardContent>
      </Grid>
      <Divider
        orientation={'vertical'}
        flexItem
        style={{ marginRight: '-1px' }}
      />
      <Grid item xs={12} md={6}>
        <CardContent>
          <StyledCardTitle variant="h6" color="secondary" gutterBottom>
            {t('Medientitel')}
          </StyledCardTitle>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="media"
                render={({ field, fieldState }) => (
                  <PaginatedAutocomplete
                    name="media"
                    label={t('Medien')}
                    value={field.value}
                    onChange={(ids) => field.onChange(ids)}
                    multiple={true}
                    initialData={tariff ? tariff.media : []}
                    optionLabel="name"
                    dataUrl={apiRoutes.media}
                    textFieldProps={{
                      error: fieldState.isTouched && fieldState.invalid,
                      helperText: fieldState.error?.message,
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardContent>
          <StyledCardTitle variant="h6" color="secondary" gutterBottom>
            {t('Sonstiges')}
          </StyledCardTitle>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name={'comment'}
                render={({ field, fieldState }) => (
                  <TextField
                    label={t('Interner Kommentar')}
                    fullWidth
                    multiline={true}
                    {...field}
                    error={fieldState.isTouched && fieldState.invalid}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Grid>
    </Grid>
  );
};
