import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { routes } from '../../lib';
import PressPassUpdateApplicationsDetails from "./details";
import PressPassUpdateApplicationsOverview from "./overview";
import React from "react";

const PressPassUpdateApplications = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <PressPassUpdateApplicationsOverview/>
      </Route>
      <Route exact path={routes.pressPassUpdateApplication(':pressPassApplicationId')}>
        <PressPassUpdateApplicationsDetails/>
      </Route>
    </Switch>
  );
};

export default PressPassUpdateApplications;
