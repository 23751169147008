import { yupResolver } from '@hookform/resolvers/yup';
import { DatePicker, LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from '@mui/material';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import React, { VoidFunctionComponent } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { formatPayloadDate, handleHookFormErrors } from '../../helpers';
import { apiRoutes, request } from '../../lib';
import { DateFormat, DateTimeString, PressPass } from '../../model';
import { usePressPassSuspensionValidationSchema } from "../../hooks/validation/press-pass";

interface SuspensionFormValues {
  passNumber: string
  passNumberNew: string
  comment: string;
  date: DateTimeString;
}

const defaultFormValues = {
  passNumber: '',
  passNumberNew: '',
  comment: '',
  date: '',
};

const SuspensionDialog: VoidFunctionComponent<{
  open: boolean;
  onClose: () => void;
  pressPass: PressPass;
}> = ({ open, onClose, pressPass }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const validationSchema = usePressPassSuspensionValidationSchema();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isValid },
    setError,
    reset,
  } = useForm<SuspensionFormValues>({
    mode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues: defaultFormValues,
  });

  const submitMutation = useMutation(
    async (values: SuspensionFormValues) => {
      values.date = formatPayloadDate(values.date) || '';
      values.passNumber = pressPass.passNumber || '';
      return await request<PressPass>(
        apiRoutes.pressPassSuspend(pressPass.id),
        'post',
        values
      );
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(
          apiRoutes.pressPass(pressPass.id)
        );
        enqueueSnackbar(t('Ausweis wurde erfolgreich gesperrt. Die neue Ausweisnumer '), {
          variant: 'success',
        });
        reset(defaultFormValues);
        onClose();
      },
      onError: (err: AxiosError) => {
        enqueueSnackbar(
          err.response?.data.message || t('Leider ist etwas schief gelaufen.'),
          { variant: 'error' }
        );
        handleHookFormErrors(err, setError);
      },
    }
  );

  return (
    <Dialog open={open} onClose={() => onClose()}>
      <form
        onSubmit={handleSubmit((values) => submitMutation.mutateAsync(values))}
      >
        <DialogTitle>{t('Ausweis #{{passNumber}} ({{name}}) wirklich sperren?', { name: pressPass.person.displayName, passNumber: pressPass.passNumber })}</DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name={'passNumber'}
                render={({ field, fieldState }) => (
                  <TextField
                    label={t('Ausweisnummer (bisher)')}
                    fullWidth
                    {...field}
                    value={pressPass.passNumber}
                    disabled={true}
                    error={fieldState.isTouched && fieldState.invalid}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name={'passNumberNew'}
                render={({ field, fieldState }) => (
                  <TextField
                    label={t('Neue Ausweisnummer')}
                    fullWidth
                    {...field}
                    required
                    error={fieldState.isTouched && fieldState.invalid}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name="date"
                render={({ field, fieldState }) => (
                  <DatePicker
                    value={field.value}
                    inputFormat={DateFormat.Default}
                    mask={DateFormat.Mask}
                    onChange={field.onChange}
                    label={t('Datum des Verlustes/Sperre')}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        required
                        fullWidth
                        helperText={fieldState.error?.message}
                        error={fieldState.isTouched && fieldState.invalid}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name={'comment'}
                render={({ field, fieldState }) => (
                  <TextField
                    label={t('Kommentar')}
                    fullWidth
                    {...field}
                    multiline={true}
                    error={fieldState.isTouched && fieldState.invalid}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose()} disabled={isSubmitting}>
            {t('Abbrechen')}
          </Button>
          <LoadingButton
            type="submit"
            autoFocus
            variant="contained"
            loading={submitMutation.isLoading}
            disabled={!isValid || isSubmitting}
          >
            {t('Speichern')}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default SuspensionDialog;
