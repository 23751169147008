import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import React, { VoidFunctionComponent } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import FormLogo from '../../components/form-logo';
import { StyledCardTitle } from '../../components/globals';
import LoadingButton from '../../components/loading-button';
import MultiUpload from '../../components/multi-upload';
import { buildFormData, handleHookFormErrors } from '../../helpers';
import { useTitle } from '../../hooks';
import { useClient } from '../../hooks/use-client';
import { usePressPassApplicationDocumentsValidationSchema } from '../../hooks/validation/press-pass-application';
import { apiRoutes, request } from '../../lib';
import {
  Client,
  Origin,
  PressPassApplication,
  PressPassApplicationDocumentsFormValues,
  PressPassApplicationFormValues,
  getPressPassApplicationDocumentsFormValues,
} from '../../model';
import SuccessCard from './success-card';

export const FormPageUploadForm: VoidFunctionComponent = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const client = useClient();
  const { token } = useParams<{ token: string }>();

  useTitle(t('Antrag anreichern'), '');

  const validationSchema = usePressPassApplicationDocumentsValidationSchema();

  const {
    control,
    handleSubmit,
    setError,
    formState: { isValid, isSubmitting },
  } = useForm<PressPassApplicationDocumentsFormValues>({
    mode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues: getPressPassApplicationDocumentsFormValues(
      client === Client.Oezv ? Origin.Oezv : Origin.Voez
    ),
  });

  const submitMutation = useMutation(
    async (values: PressPassApplicationDocumentsFormValues) => {
      return await request<PressPassApplication>(
        apiRoutes.pressPassApplicationDocumentUpdate(token),
        'post',
        buildFormData<PressPassApplicationFormValues>(values, (formData) => {
          values.attachments.forEach((attachment, index) => {
            if (!attachment.file) {
              return;
            }
            formData.append(`attachment_${index}`, attachment.file);
          });
        })
      );
    },
    {
      onSuccess: () => {
        enqueueSnackbar(t('Dateien wurde erfolgreich abgeschickt.'), {
          variant: 'success',
        });
      },
      onError: (err: AxiosError) => {
        enqueueSnackbar(
          err.response?.data.message ||
            t('Antrag konnte nicht erstellt werden'),
          {
            variant: 'error',
          }
        );
        handleHookFormErrors(err, setError);
      },
    }
  );

  return (
    <Container maxWidth="md">
      <Box>
        <FormLogo />
        <Typography
          variant="h3"
          component="h1"
          textAlign="center"
          marginBottom="1em"
        >
          {t('Dateien Nachreichen')}
        </Typography>
      </Box>
      {submitMutation.isSuccess ? (
        <SuccessCard
          text={t<string>('Die Dateien wurden erfolgreich nachgereicht.')}
        />
      ) : (
        <form
          onSubmit={handleSubmit((values) =>
            submitMutation.mutateAsync(values)
          )}
        >
          <Card style={{ marginBottom: '4em' }}>
            <CardContent>
              <StyledCardTitle variant="h6" color="secondary" gutterBottom>
                {t('E-Mail Adresse bestätigen')}
              </StyledCardTitle>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Controller
                    control={control}
                    name={'email'}
                    render={({ field, fieldState }) => (
                      <TextField
                        label={t('E-Mail Adresse')}
                        fullWidth
                        {...field}
                        error={fieldState.isTouched && fieldState.invalid}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardContent>
              <StyledCardTitle variant="h6" color="secondary" gutterBottom>
                {t('Dateien hinzufügen')}
              </StyledCardTitle>
              <Grid container spacing={3}>
                <Grid container spacing={3}>
                  <Grid item style={{ display: 'none' }}>
                    <Controller
                      control={control}
                      name="membership"
                      render={({ field }) => (
                        <TextField type="hidden" value={field.value} />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <MultiUpload
                    control={control}
                    textAlign="center"
                    allowedTypes={[
                      'image/png',
                      'image/jpeg',
                      'application/pdf',
                    ]}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="confirmationPrivacy"
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={field.value}
                            onChange={field.onChange}
                            color="primary"
                          />
                        }
                        label={t<string>(
                          'Sollten Sie die Daten für sich selbst eingeben, ' +
                            'erteilen Sie hiermit die Einwillung zur Verarbeitung der Daten im Sinne der DSGVO zu. ' +
                            'Sollten Sie für eine andere Person Daten eingegeben haben, ' +
                            'versichern Sie im Auftrag der Person zu agieren und die entsprechende Einwilligung eingeholt zu haben.'
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="confirmationMoral"
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={field.value}
                            onChange={field.onChange}
                            color="primary"
                          />
                        }
                        label={t<string>(
                          'Ich bestätige alle Angaben nach bestem Wissen und Gewissen getroffen zu haben.'
                        )}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <LoadingButton
                type="submit"
                size="medium"
                color="primary"
                disabled={!isValid}
                variant="contained"
                loading={isSubmitting}
              >
                {t('Antrag jetzt abschicken')}
              </LoadingButton>
            </CardActions>
          </Card>
        </form>
      )}
    </Container>
  );
};

export default FormPageUploadForm;
