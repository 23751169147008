import {Blameable, Commentable, ID, Nullable, Timestampable} from './index';

export enum PayRateType {
  Single = 'single',
  Bundle = 'bundle',
  Flatrate = 'flatrate',
}

export interface PayRate extends Timestampable, Blameable, Commentable {
  id: ID;
  name: string;
  type: PayRateType;
  price: number;
}

export interface PayRateFilters {
  search: string;
  rateType: string;
}

export interface PayRateFormValues {
  name: string;
  type: string;
  price: number;
  comment: Nullable<string>;
}

export const getPayRateFormValues = (
  payRate?: PayRate
): PayRateFormValues => ({
  name: payRate?.name || '',
  type: payRate?.type || '',
  price: payRate?.price || 0,
  comment: payRate?.comment || '',
});
