import { Avatar, Link } from '@mui/material';
import React, { VoidFunctionComponent } from 'react';
import styled from 'styled-components';
import { config } from '../../config';
import { useClient } from '../../hooks/use-client';
import OezvLogo from '../../images/OEZV-Logo.svg';
import VoezLogo from '../../images/logo.png';
import { Client } from '../../model';

const StyledAvatar = styled(Avatar)`
  height: auto;
  width: 4em;
  margin: 2em auto 1em;
`;

export const FormLogo: VoidFunctionComponent = () => {
  const client = useClient();

  if (client === Client.Oezv) {
    return (
      <Link href={config.originLinks.oezv} target="_blank">
        <StyledAvatar src={OezvLogo} />
      </Link>
    );
  }
  return (
    <Link href={config.originLinks.voez} target="_blank">
      <StyledAvatar src={VoezLogo} />
    </Link>
  );
};

export default FormLogo;
