import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useTariffGroupValidation } from '../tariff-group';

export const useTariffValidation = () => {
  const { t } = useTranslation();
  const tariffGroupValidation = useTariffGroupValidation();
  return useMemo(
    () => ({
      name: yup.string().required(t('Name darf nicht leer sein.')),
      apa: yup
        .string()
        .matches(
          /^(\d+(?:[,]\d+)?)$/,
          t('Muss eine Komma-getrennte Dezimalzahl sein.')
        ),
      validFrom: yup.string(),
      validTo: yup.string(),
      tariffGroups: yup.array(yup.object(tariffGroupValidation)),
    }),
    []
  );
};

export const useTariffValidationSchema = () => {
  const validation = useTariffValidation();

  return useMemo(() => {
    return yup.object({
      ...validation,
    });
  }, [validation]);
};
