import { FunctionComponent } from 'react';
import { FormState } from 'react-hook-form';
import { Prompt } from 'react-router';

const PromptIfDirty: FunctionComponent<{ formState: FormState<unknown> }> = ({
  formState,
}) => (
  <Prompt
    message="You have unsaved changes - are you sure to leave this page?"
    when={formState.isDirty && formState.submitCount === 0}
  />
);

export default PromptIfDirty;
