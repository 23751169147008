import { Theme } from '@mui/material';
import { AxiosError } from 'axios';
import * as React from 'react';
import { Path, UseFormSetError } from 'react-hook-form';
import { ApiError } from '../model';

export function handleHookFormErrors<TFieldValues>(
  error: AxiosError<ApiError>,
  setError: UseFormSetError<TFieldValues>
) {
  if (error.response?.data.context?.type !== 'ConstraintViolationList') {
    return;
  }
  const violations = error.response.data.context.violations;
  Object.keys(violations).forEach((field) =>
    setError(
      field
        .replace('][', '.')
        .replace(']', '')
        .replace('[', '') as Path<TFieldValues>,
      { message: violations[field].join(' ') }
    )
  );
}

export function getErrorMessage(error: AxiosError): string {
  if (error.response?.data.message) {
    return error.response?.data.message;
  }
  return 'Error';
}

export function getSelectMenuItemStyles<T extends string | number>(
  value: T,
  selectedValues: T | T[],
  theme: Theme
): {
  fontWeight: React.CSSProperties['fontWeight'] | undefined;
} {
  const selected = Array.isArray(selectedValues)
    ? selectedValues.indexOf(value) !== -1
    : value === selectedValues;
  return {
    fontWeight: selected
      ? theme.typography.fontWeightMedium
      : theme.typography.fontWeightRegular,
  };
}

export function buildFormData<T>(
  content: Partial<T>,
  extension: (formData: FormData) => void
): FormData {
  const formData = new FormData();
  formData.append('content', JSON.stringify(content));
  extension(formData);
  return formData;
}

export * from './utils';
