import { yupResolver } from '@hookform/resolvers/yup';
import { Card, CardActions, Container, Divider, Grid } from '@mui/material';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import React, { VoidFunctionComponent, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router';
import Header from '../../components/header';
import LoadingButton from '../../components/loading-button';
import {
  PersonSelector,
  PressPassFormFields,
  PressPassPersonFormFields,
} from '../../components/press-pass';
import {
  buildFormData,
  formatPayloadDate,
  handleHookFormErrors,
} from '../../helpers';
import { useTitle } from '../../hooks';
import { usePressPassValidationSchema } from '../../hooks/validation/press-pass';
import { apiRoutes, request, routes } from '../../lib';
import {
  ID,
  Nullable,
  PressPass,
  PressPassFormValues,
  PressPassPerson,
  getPressPassFormValues,
} from '../../model';

const CreatePressPass: VoidFunctionComponent = () => {
  const { t } = useTranslation();
  useTitle(t('Neuer Presseausweis'));
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const breadcrumbs = [
    { label: 'Home', link: routes.dashboard },
    { label: 'Presseausweise', link: routes.pressPasses },
    { label: 'Neu' },
  ];

  const validationSchema = usePressPassValidationSchema();

  const {
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting },
    watch,
    getValues,
    setValue,
    reset,
  } = useForm<PressPassFormValues>({
    mode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues: getPressPassFormValues(),
  });

  const personSelector = watch('personSelector');
  const medium = watch('medium');
  const photoUpload = watch('photoUpload');
  const membership = watch('membership');

  useEffect(() => {
    if (!personSelector) {
      reset(getPressPassFormValues());
      return;
    }
    personMutation.mutate(personSelector);
  }, [personSelector]);

  useEffect(() => {
    setValue('deletePhoto', false);
  }, [photoUpload]);

  const personMutation = useMutation(
    async (personId: ID) => {
      return await request<PressPassPerson>(apiRoutes.person(personId), 'get');
    },
    {
      onSuccess: (res) => {
        if (!res) {
          return;
        }
        const person = res.data;
        reset({
          ...getValues(),
          person: {
            id: person.id,
            salutation: person.salutation || '',
            firstName: person.firstName,
            lastName: person.lastName,
            titleBefore: person.titleBefore || '',
            titleAfter: person.titleAfter || '',
            salutationPosition: person.salutationPosition || '',
            nationality: person.nationality || '',
            birthplace: person.birthplace || '',
            birthdate: person.birthdate || '',
          },
        });
      },
      onError: (err: AxiosError) => {
        enqueueSnackbar(
          err.response?.data.message || t('Leider ist etwas schief gelaufen.'),
          {
            variant: 'error',
          }
        );
        handleHookFormErrors(err, setError);
      },
    }
  );

  const submitMutation = useMutation(
    async (values: PressPassFormValues) => {
      values.person.birthdate = formatPayloadDate(values.person.birthdate);
      values.passDate = formatPayloadDate(values.passDate);
      values.passWithdrawalDate = formatPayloadDate(values.passWithdrawalDate);
      values.badgeDate = formatPayloadDate(values.badgeDate);
      values.carBadgeDate = formatPayloadDate(values.carBadgeDate);

      const formData = buildFormData<PressPassFormValues>(
        values,
        (formData) => {
          if (values.photoUpload) {
            formData.append('photoUpload', values.photoUpload);
          }
        }
      );

      return await request<PressPass>(
        apiRoutes.pressPassCreate,
        'post',
        formData
      );
    },
    {
      onSuccess: (res) => {
        enqueueSnackbar('Presseausweis wurde erfolgreich erstellt.', {
          variant: 'success',
        });
        history.push(routes.pressPass(res.data.id));
      },
      onError: (err: AxiosError) => {
        enqueueSnackbar(
          err.response?.data.message || t('Leider ist etwas schief gelaufen.'),
          {
            variant: 'error',
          }
        );
        handleHookFormErrors(err, setError);
      },
    }
  );

  useEffect(() => {
    fetchNextPressPassNumberMutation.mutate(membership);
  }, [membership]);

  const fetchNextPressPassNumberMutation = useMutation(
    async (membership: Nullable<string>) =>
      await request<{ number: string }>(apiRoutes.pressPassNumberNext, 'post', {
        membership,
      }),
    {
      onSuccess: (res) => {
        setValue('passNumber', res.data.number);
      },
      onError: (err: AxiosError) => {
        enqueueSnackbar(err.response?.data.message, { variant: 'error' });
      },
    }
  );

  return (
    <Container maxWidth="xl">
      <form
        onSubmit={handleSubmit((values) => submitMutation.mutateAsync(values))}
        noValidate
      >
        <Header
          title={'Presseausweise'}
          breadcrumbs={breadcrumbs}
          actions={
            <LoadingButton
              type="submit"
              size="medium"
              color="primary"
              loading={isSubmitting}
              variant="contained"
            >
              {t('Speichern')}
            </LoadingButton>
          }
        />
        <Card>
          <Grid container>
            <Grid item xs={12} md={6}>
              <PersonSelector control={control} />
              <Divider />
              <PressPassPersonFormFields control={control} />
            </Grid>
            <Divider
              orientation={'vertical'}
              flexItem
              style={{ marginRight: '-1px' }}
            />
            <Grid item xs={12} md={6}>
              <PressPassFormFields control={control} medium={medium} />
            </Grid>
          </Grid>
          <Divider />
          <CardActions>
            <LoadingButton
              type="submit"
              size="medium"
              color="primary"
              loading={isSubmitting}
              variant="contained"
            >
              {t('Speichern')}
            </LoadingButton>
          </CardActions>
        </Card>
      </form>
    </Container>
  );
};
export default CreatePressPass;
