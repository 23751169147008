import { FormControlLabel, Switch, SwitchProps } from '@mui/material';
import React, { Dispatch, FunctionComponent, SetStateAction } from 'react';
import { FilterFormControl } from '../globals';

export const FilterSwitch: FunctionComponent<
  Omit<SwitchProps, 'onChange' | 'label' | 'value'> & {
    label: string;
    value: boolean;
    onChange: Dispatch<SetStateAction<any>> | ((value: any) => void);
  }
> = ({ label, value, onChange, ...props }) => {
  return (
    <FilterFormControl>
      <FormControlLabel
        control={
          <Switch
            checked={value}
            onChange={(e) => onChange(e.target.checked)}
            {...props}
          />
        }
        label={label}
      />
    </FilterFormControl>
  );
};
