import { Add } from '@mui/icons-material';
import {
  Autocomplete,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from '@mui/material';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import React, { VoidFunctionComponent, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { config } from '../../config';
import { handleHookFormErrors } from '../../helpers';
import { useDebounceState, useFilteredPaginationApi } from '../../hooks';
import { apiRoutes, request } from '../../lib';
import {
  Company,
  CompanyPerson,
  ID,
  LicenseContactPersonFormValues,
  LicenseContactType,
  Licensee,
  Nullable,
  Person,
  getLicenseContactPersonFormValues,
} from '../../model';
import { Position } from '../../model/position';
import { StyledCardTitle } from '../globals';
import LoadingIconButton from '../loading-icon-button';

export interface CompanyPersonOptions {
  id: Nullable<ID>;
  person: Person;
  company: Nullable<Company>;
  position?: Nullable<Position>;
  descriptivePosition?: Nullable<string>;
  email?: Nullable<string>;
  phone?: Nullable<string>;
  assistant?: Nullable<CompanyPerson>;
  defaultCompany?: boolean;
}

export const LicenseeContactPersonForm: VoidFunctionComponent<{
  licensee: Licensee;
}> = ({ licensee }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const [search, setSearch] = useState('');
  const [companyPersonOptions, setCompanyPersonOptions] = useState<
    CompanyPersonOptions[]
  >([]);
  const [inputSearch, setInputSearch] = useDebounceState(search, setSearch);

  const { data: personOptions } = useFilteredPaginationApi<
    { search: string; companyId: ID; mboId: ID | undefined },
    Person
  >(
    apiRoutes.persons,
    { search, companyId: licensee.id, mboId: licensee.mbo?.id },
    'name',
    'asc',
    config.pageSize
  );

  const {
    handleSubmit,
    watch,
    setError,
    control,
    formState: { isSubmitting },
    setValue,
    reset,
  } = useForm<LicenseContactPersonFormValues>({
    mode: 'all',
    defaultValues: getLicenseContactPersonFormValues(),
  });

  const person = watch('person');

  const buildCompanyPersonOptions = (value: Nullable<Person>) => {
    setValue('companyPerson', null);
    if (!value) {
      setCompanyPersonOptions([]);
      return;
    }
    setCompanyPersonOptions([
      ...value.companies,
      {
        person: value,
        id: null,
        company: null,
      },
    ]);
    const companyPerson = value.companies.find(
      (companyPerson) =>
        companyPerson.company.id === licensee.id ||
        companyPerson.company.id === licensee.mbo?.id
    );
    setValue('companyPerson', companyPerson ? companyPerson.id : null);
  };

  const submitMutation = useMutation(
    async (values: LicenseContactPersonFormValues) => {
      return await request<Licensee>(
        apiRoutes.licenseeContactPersonCreate(licensee.id),
        'post',
        values
      );
    },
    {
      onSuccess: async () => {
        reset(getLicenseContactPersonFormValues());
        await queryClient.invalidateQueries(apiRoutes.licensee(licensee.id));
        enqueueSnackbar(t('Kontaktperson wurde erfolgreich hinzugefügt.'), {
          variant: 'success',
        });
      },
      onError: (err: AxiosError) => {
        enqueueSnackbar(
          err.response?.data.message ||
            t('Kontaktperson konnte nicht hinzugefügt werden'),
          {
            variant: 'error',
          }
        );
        handleHookFormErrors(err, setError);
      },
    }
  );

  return (
    <form
      onSubmit={handleSubmit((values) => submitMutation.mutateAsync(values))}
    >
      <StyledCardTitle variant="h6" color="secondary" gutterBottom>
        {t('Kontaktperson hinzufügen')}
      </StyledCardTitle>
      <Grid
        container
        spacing={1}
        style={{ width: '100%' }}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Grid item xs={12} md={2}>
          <FormControl fullWidth>
            <InputLabel>{t('Typ')}</InputLabel>
            <Controller
              control={control}
              name="type"
              render={({ field }) => (
                <Select
                  fullWidth
                  {...field}
                  label={t('Typ')}
                  value={field.value}
                >
                  {Object.keys(LicenseContactType).map((type, key) => (
                    <MenuItem
                      value={
                        LicenseContactType[
                          type as keyof typeof LicenseContactType
                        ]
                      }
                      key={key}
                    >
                      {t(
                        `licensee.contactPerson.type.${
                          LicenseContactType[
                            type as keyof typeof LicenseContactType
                          ]
                        }`
                      )}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={5} md={4}>
          <Controller
            control={control}
            name={`person` as const}
            render={({ field, fieldState }) => (
              <Autocomplete
                multiple={false}
                options={personOptions?.results || []}
                getOptionLabel={(option) => option.displayName}
                noOptionsText={t('Keine Person verfügbar')}
                value={
                  personOptions?.results.find(
                    (person) => person.id === field.value
                  ) || null
                }
                onChange={(event, value) => {
                  field.onChange(value?.id || null);
                  buildCompanyPersonOptions(value);
                }}
                onInputChange={(e) => {
                  if (e && (e.target as HTMLInputElement).value) {
                    setInputSearch((e.target as HTMLInputElement).value);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('Person')}
                    value={inputSearch}
                    error={fieldState.isTouched && fieldState.invalid}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <Controller
            control={control}
            name={`companyPerson` as const}
            render={({ field, fieldState }) => (
              <Autocomplete
                multiple={false}
                options={companyPersonOptions}
                loading={companyPersonOptions.length === 0}
                disabled={!person}
                noOptionsText={t('Kein Unternehmen verfügbar')}
                loadingText={t('Lädt verknüpfte Unternehmen...')}
                getOptionLabel={(option) =>
                  option.company
                    ? `${option.company.name} ${
                        option.company.term ? option.company.term : ''
                      } (${option.company.id})`
                    : t('Privat')
                }
                value={
                  companyPersonOptions.find(
                    (option) => option.id === field.value
                  ) || null
                }
                onChange={(event, value) => field.onChange(value?.id || null)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('Unternehmen (ID)')}
                    error={fieldState.isTouched && fieldState.invalid}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item textAlign="right">
          <Tooltip title={t<string>('Kontaktperson hinzufügen')}>
            <span>
              <LoadingIconButton
                type="submit"
                color="primary"
                size="small"
                disabled={!person}
                loading={isSubmitting || submitMutation.isLoading}
              >
                <Add />
              </LoadingIconButton>
            </span>
          </Tooltip>
        </Grid>
      </Grid>
    </form>
  );
};
