import { Edit, Visibility } from '@mui/icons-material';
import { TableCell, TableRow } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { routes } from '../../lib';
import { Role, User } from '../../model';
import FormattedDate from '../formatted-date';
import { IconLink } from '../icon-link';
import { PaginationTable } from '../pagination';
import PaginationTableHeadCell from '../pagination/table-head-cell';
import UserBadge from './badge';

const UserList: FunctionComponent<{ users?: User[] }> = ({ users }) => {
  const { t } = useTranslation();

  return (
    <PaginationTable
      items={users}
      renderRow={(user: User) => (
        <TableRow key={user.id} hover={true}>
          <TableCell align="center">{user.id}</TableCell>
          <TableCell>
            <UserBadge user={user} />
          </TableCell>
          <TableCell>{user.email}</TableCell>
          <TableCell>
            <FormattedDate date={user.createdAt} />
          </TableCell>
          <TableCell>
            {user.roles
              .map(
                (role) => Object.keys(Role)[Object.values(Role).indexOf(role)]
              )
              .join(', ')}
          </TableCell>
          <TableCell>
            <IconLink
              to={routes.user(user.id)}
              tooltip={t<string>('Ansehen')}
              icon={<Visibility />}
            />
            {!user.inheritedRoles.includes(Role.SuperAdmin) && (
              <IconLink
                to={routes.userEdit(user.id)}
                tooltip={t<string>('Bearbeiten')}
                icon={<Edit />}
              />
            )}
          </TableCell>
        </TableRow>
      )}
    >
      <TableRow>
        <PaginationTableHeadCell sort="id" align="center">
          {t('Id')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell sort="username">
          {t('Name')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell sort="email">
          {t('E-Mail')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell sort="createdAt">
          {t('Erstellt')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell sort="roles">
          {t('Rollen')}
        </PaginationTableHeadCell>
        <TableCell width={110} />
      </TableRow>
    </PaginationTable>
  );
};

export default UserList;
