import { ExpandMore, Launch, Star } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Icon,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from '@mui/material';
import { grey, yellow } from '@mui/material/colors';
import React, { VoidFunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { routes } from '../../lib';
import { CompanyPerson } from '../../model';
import { SpanBlock } from '../globals';
import { GridData } from '../grid-data';

export const PersonCompanyAccordion: VoidFunctionComponent<{
  companyPerson: CompanyPerson;
  isExpanded?: boolean;
}> = ({ companyPerson, isExpanded = false }) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(isExpanded);

  return (
    <Accordion expanded={expanded} style={{ background: grey[100] }}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        onClick={() => setExpanded(!expanded)}
      >
        <Grid
          container
          spacing={1}
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item xs={10}>
            {companyPerson.company.name}{' '}
            <Tooltip title={t<string>('Zum Unternehmen')}>
              <IconButton
                color="primary"
                to={routes.company(companyPerson.company.id)}
                component={RouterLink}
              >
                <Launch />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            {companyPerson.defaultCompany && (
              <Tooltip title={t<string>('Primäres Unternehmen')}>
                <Icon>
                  <Star style={{ color: yellow[800] }} />
                </Icon>
              </Tooltip>
            )}
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          paddingBottom: '8px',
        }}
      >
        <Grid container spacing={1}>
          <GridData xs={12}>
            <SpanBlock>{companyPerson.company.street}</SpanBlock>
            <SpanBlock>
              {companyPerson.company.zipCode} {companyPerson.company.city}
            </SpanBlock>
            <SpanBlock>{companyPerson.company.country}</SpanBlock>
          </GridData>
          {(companyPerson.position || companyPerson.descriptivePosition) && (
            <GridData xs={12} label={t<string>('Funktion')}>
              <SpanBlock>{companyPerson.position?.title}</SpanBlock>
              {companyPerson.descriptivePosition && (
                <i>
                  {companyPerson.descriptivePosition}
                </i>
              )}
            </GridData>
          )}
          {(companyPerson.email ||
            companyPerson.phone ||
            companyPerson.mobilePhone ||
            companyPerson.assistant) && (
            <GridData xs={12} label={t<string>('Kontakt')}>
              <SpanBlock>{companyPerson.email || ''}</SpanBlock>
              {companyPerson.phone && (
                <SpanBlock>Tel.: {companyPerson.phone}</SpanBlock>
              )}
              {companyPerson.mobilePhone && (
                <SpanBlock>Mobil: {companyPerson.mobilePhone}</SpanBlock>
              )}
              {companyPerson.assistant && (
                <>
                  <SpanBlock>{t('Assistenz: ')}</SpanBlock>
                  <Link
                    to={routes.person(companyPerson.assistant.person.id)}
                    style={{ display: 'block' }}
                    component={RouterLink}
                  >
                    {companyPerson.assistant.person.displayName}
                  </Link>{' '}
                  {companyPerson.assistant.phone && (
                    <Typography variant="caption">
                      {companyPerson.assistant.phone}
                    </Typography>
                  )}
                </>
              )}
            </GridData>
          )}
          {companyPerson.invoiceContactId && (
            <GridData xs={12} label={t<string>('BMD Kontaktnummer')}>
              {companyPerson.invoiceContactId}
            </GridData>
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
