import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Card, CardActions, Container, Divider } from '@mui/material';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router';
import { CompanyFormFields } from '../../components/company';
import Header from '../../components/header';
import { handleHookFormErrors } from '../../helpers';
import { useTitle } from '../../hooks';
import { useCompanyValidationSchema } from '../../hooks/validation/company/use-company-validation-schema';
import { apiRoutes, request, routes } from '../../lib';
import { Company, CompanyFormValues, getCompanyFormValues } from '../../model';

const CreateCompany = () => {
  const { t } = useTranslation();
  useTitle(t('Neues Unternehmen'));
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const breadcrumbs = [
    { label: t('Home'), link: routes.dashboard },
    { label: t('Unternehmen'), link: routes.companies },
    { label: t('Neu') },
  ];

  const validationSchema = useCompanyValidationSchema();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    setError,
  } = useForm<CompanyFormValues>({
    mode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues: getCompanyFormValues(),
  });

  const mutation = useMutation(
    async (values: CompanyFormValues) =>
      await request<Company>(apiRoutes.companyCreate, 'post', values),
    {
      onSuccess: (res) => {
        enqueueSnackbar(t('Unternehmen wurde erfolgreich erstellt.'), {
          variant: 'success',
        });
        history.push(routes.company(res.data.id));
      },
      onError: (err: AxiosError) => {
        enqueueSnackbar(err.response?.data.message || 'Error', {
          variant: 'error',
        });
        handleHookFormErrors(err, setError);
      },
    }
  );

  return (
    <Container maxWidth="md">
      <form
        onSubmit={handleSubmit((values) => mutation.mutateAsync(values))}
        noValidate
      >
        <Header
          title={t('Unternehmen')}
          breadcrumbs={breadcrumbs}
          actions={
            <LoadingButton
              type="submit"
              size="medium"
              color="primary"
              variant="contained"
              loading={isSubmitting}
            >
              {t('Speichern')}
            </LoadingButton>
          }
        />
        <Card>
          <CompanyFormFields control={control} />
          <Divider />
          <CardActions>
            <LoadingButton
              type="submit"
              size="medium"
              color="primary"
              variant="contained"
              loading={isSubmitting}
            >
              {t('Speichern')}
            </LoadingButton>
          </CardActions>
        </Card>
      </form>
    </Container>
  );
};

export default CreateCompany;
