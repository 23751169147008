import { Box } from '@mui/material';
import React, { FunctionComponent } from 'react';
import HttpError from '../../components/http-error';
import LoadingContainer from '../../components/loading-container';
import { MediumList } from '../../components/medium';
import { useApi } from '../../hooks';
import { apiRoutes } from '../../lib';
import { Company, Medium } from '../../model';

const Media: FunctionComponent<{
  company: Company;
}> = ({ company }) => {
  const { data, isLoading, error } = useApi<Medium[]>(
    apiRoutes.companyMedia(company.id)
  );

  if (!data || isLoading) {
    return (
      <Box padding={3}>
        <LoadingContainer />
      </Box>
    );
  }

  if (error) {
    return <HttpError error={error} />;
  }

  return <MediumList media={data || []} />;
};

export default Media;
