import {
  AlternateEmail,
  Business,
  ContactMail,
  Contacts, EditNotifications,
  Euro,
  Forward,
  Home,
  ImportContacts,
  Inventory,
  LibraryBooks,
  LocalOffer,
  Newspaper,
  Notifications,
  People,
  Person,
  Work,
} from '@mui/icons-material';
import { ButtonBase, Chip, Divider, Drawer, List, darken } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import { hasRole } from '../../helpers';
import { useCurrentUser } from '../../hooks';
import { apiRoutes, request, routes } from '../../lib';
import { Role } from '../../model';
import Guarded from '../guarded';
import Logo from '../logo';
import Category from './category';
import ListItemLink from './list-item-link';

const StyledLogoContainer = styled(ButtonBase)`
  min-height: 63px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: ${(props) =>
    darken(props.theme.palette.background.paper, 0.1)};
`;

const StyledDrawerContent = styled.div`
  width: ${(props) => props.theme.navigation.width}px;
`;

const Navigation: FunctionComponent = () => {
  const { t } = useTranslation();
  const user = useCurrentUser();

  const { data: pressPassApplicationNewCount } = useQuery(
    apiRoutes.pressPassApplicationNewCount,
    async () => {
      return await request<number>(
        apiRoutes.pressPassApplicationNewCount,
        'GET'
      );
    },
    {
      refetchInterval: 60000,
      enabled: hasRole(user, Role.PressPass),
    }
  );

  const { data: pressPassApplicationNewUpdateCount } = useQuery(
    apiRoutes.pressPassApplicationNewUpdateCount,
    async () => {
      return await request<number>(
        apiRoutes.pressPassApplicationNewUpdateCount,
        'GET'
      );
    },
    {
      refetchInterval: 60000,
      enabled: hasRole(user, Role.PressPass),
    }
  );

  return (
    <Drawer variant="permanent" anchor="left">
      <StyledDrawerContent>
        <StyledLogoContainer>
          <Logo />
        </StyledLogoContainer>
        <Divider />
        <List>
          {' '}
          <ListItemLink
            to={routes.dashboard}
            exact={true}
            icon={<Home />}
            label={t<string>('Home')}
          />
          <Guarded requiredRole={Role.General}>
            <Category label={t('Allgemein')} />
            <ListItemLink
              to={routes.companies}
              icon={<Business />}
              label={t<string>(`Unternehmen`)}
            />
            <ListItemLink
              to={routes.persons}
              icon={<Person />}
              label={t<string>(`Personen`)}
            />
            <ListItemLink
              to={routes.media}
              icon={<Newspaper />}
              label={t<string>(`Medien`)}
            />
          </Guarded>
          <Guarded requiredRole={Role.PressPass}>
            <Category label={t('Presseausweise')} />
            <ListItemLink
              to={routes.pressPasses}
              icon={<Contacts />}
              label={t<string>(`Presseausweise`)}
            />
            <ListItemLink
              to={routes.pressPassApplications}
              icon={<Notifications />}
              label={
                <>
                  {t<string>(`Anträge`)}
                  {pressPassApplicationNewCount?.data !== undefined &&
                    pressPassApplicationNewCount.data > 0 && (
                      <Chip
                        label={pressPassApplicationNewCount.data}
                        size="small"
                        color="primary"
                        style={{ marginLeft: '0.5em' }}
                      />
                    )}
                </>
              }
            />
            <ListItemLink
              to={routes.pressPassUpdateApplications}
              icon={<EditNotifications />}
              label={
                <>
                  {t<string>(`Aktualisierungen`)}
                  {pressPassApplicationNewUpdateCount?.data !== undefined &&
                    pressPassApplicationNewUpdateCount.data > 0 && (
                      <Chip
                        label={pressPassApplicationNewUpdateCount.data}
                        size="small"
                        color="primary"
                        style={{ marginLeft: '0.5em' }}
                      />
                    )}
                </>
              }
            />
          </Guarded>
          <Guarded requiredRole={Role.PDN}>
            <Category label={t('PDN')} />
            <ListItemLink
              to={routes.licensees}
              icon={<Euro />}
              label={t<string>(`Lizenznehmer`)}
            />
            <ListItemLink
              to={routes.tariffs}
              icon={<LocalOffer />}
              label={t<string>(`Tariflisten`)}
            />
            <ListItemLink
              to={routes.payRates}
              icon={<Inventory />}
              label={t<string>(`Lizenzpakete`)}
            />
            <ListItemLink
              to={routes.imports}
              icon={<ImportContacts />}
              label={t<string>(`Import`)}
            />
          </Guarded>
          <Guarded requiredRole={Role.General}>
            <Category label={t('Mail-Versand')} />
            <ListItemLink
              to={routes.mailings}
              icon={<Forward />}
              label={t<string>(`Mailing`)}
            />
            <ListItemLink
              to={routes.mailingLists}
              icon={<AlternateEmail />}
              label={t<string>(`Verteiler`)}
            />
          </Guarded>
          <Guarded requiredRole={Role.General}>
            <ListItemLink
              to={routes.signatures}
              icon={<ContactMail />}
              label={t<string>(`Signaturen`)}
            />
          </Guarded>
          <Guarded requiredRole={Role.Admin}>
            <Category label={t('System')} />
            <ListItemLink
              to={routes.positions}
              icon={<Work />}
              label={t<string>(`Funktionen`)}
            />
            <ListItemLink
              to={routes.users}
              icon={<People />}
              label={t<string>('Benutzer')}
            />
            <ListItemLink
              to={routes.logs}
              icon={<LibraryBooks />}
              label={t<string>('Logs')}
            />
          </Guarded>
        </List>
      </StyledDrawerContent>
    </Drawer>
  );
};

export default Navigation;
