import { Delete } from '@mui/icons-material';
import { TableCell, TableRow } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { apiRoutes, request } from '../../lib';
import { Credit, DateFormat, ID, Licensee } from '../../model';
import FormattedDate from '../formatted-date';
import { CompactTableCell } from '../globals';
import LoadingIconButton from '../loading-icon-button';
import { PaginationTable } from '../pagination';
import PaginationTableHeadCell from '../pagination/table-head-cell';

export const CreditList: FunctionComponent<{
  licensee: Licensee;
  credits?: Credit[];
}> = ({ licensee, credits }) => {
  const { t } = useTranslation();
  const [deleting, setDeleting] = useState<ID[]>([]);
  const [deletingReviews, setDeletingReviews] = useState<ID[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const deleteCreditMutation = useMutation(
    async (credit: Credit) => {
      setDeleting([...deleting, credit.id]);
      return {
        id: credit.id,
        response: await request<Credit>(
          apiRoutes.licenseeCredit(licensee.id, credit.id),
          'delete'
        ),
      };
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(
          apiRoutes.licenseeCredits(licensee.id)
        );
        enqueueSnackbar(t('Guthaben wurde erfolgreich entfernt.'), {
          variant: 'success',
        });
      },
      onError: () => {
        enqueueSnackbar(t('Guthaben konnte nicht entfernt werden.'), {
          variant: 'error',
        });
      },
      onSettled: (res) => {
        if (!res) {
          return;
        }
        setDeletingReviews([
          ...deletingReviews.filter((review) => review !== res.id),
        ]);
      },
    }
  );

  return (
    <PaginationTable
      items={credits}
      renderRow={(credit) => (
        <TableRow key={credit.id} hover={true}>
          <TableCell>
            <FormattedDate
              date={credit.creditDate}
              format={DateFormat.Default}
            />
          </TableCell>
          <TableCell>{credit.grossValue} €</TableCell>
          <TableCell>{credit.netValue} €</TableCell>
          <TableCell>{credit.comment}</TableCell>
          <CompactTableCell align={'right'}>
            <LoadingIconButton
              type="button"
              size="small"
              loading={
                deleteCreditMutation.isLoading &&
                !!deleting.find((c) => c === credit.id)
              }
              onClick={() => {
                if (
                  !window.confirm(
                    t(
                      `Wollen Sie das Guthaben von {{netPrice}} (brutto {{grossPrice}}) wirklich entfernen?`,
                      {
                        netPrice: credit.netValue,
                        grossPrice: credit.grossValue,
                      }
                    )
                  )
                ) {
                  return;
                }

                deleteCreditMutation.mutate(credit);
              }}
            >
              <Delete />
            </LoadingIconButton>
          </CompactTableCell>
        </TableRow>
      )}
    >
      <TableRow>
        <PaginationTableHeadCell sort="creditDate">
          {t('Datum')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell sort="grossValue">
          {t('Brutto')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell sort="netValue">
          {t('Netto')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell sort="comment">
          {t('Kommentar')}
        </PaginationTableHeadCell>
        <TableCell width={110} />
      </TableRow>
    </PaginationTable>
  );
};
