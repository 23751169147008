import { Add } from '@mui/icons-material';
import { Button, MenuItem } from '@mui/material';
import React, { VoidFunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FilterInputSearch, FilterSelect } from '../../components/filter';
import { StyledContainer } from '../../components/globals';
import Guarded from '../../components/guarded';
import Header from '../../components/header';
import { PaginationCard } from '../../components/pagination';
import { PayRateList } from '../../components/pay-rate';
import { config } from '../../config';
import {
  UseQueryStateOptions,
  useDebounceState,
  useFilteredPaginationApi,
  useQueryState,
  useTitle,
} from '../../hooks';
import { apiRoutes, routes } from '../../lib';
import { PayRate, PayRateFilters, PayRateType, Role } from '../../model';

const PayRatesOverview: VoidFunctionComponent = () => {
  const { t } = useTranslation();
  useTitle(t('Lizenzpakete'));

  const breadcrumbs = [
    { label: t('Home'), link: routes.dashboard },
    { label: t('Lizenzpakete') },
  ];

  const queryStateOptions: UseQueryStateOptions = { action: 'replace' };
  const [search, setFilterSearch] = useQueryState(
    '',
    'search',
    queryStateOptions
  );
  const [inputSearch, setInputSearch] = useDebounceState(
    search,
    setFilterSearch
  );

  const [rateType, setFilterRateType] = useQueryState(
    '',
    'rateType',
    queryStateOptions
  );

  const { context } = useFilteredPaginationApi<PayRateFilters, PayRate>(
    apiRoutes.payRates,
    { search, rateType },
    'name',
    'asc',
    config.pageSize
  );

  return (
    <StyledContainer data-test="media-content">
      <Header
        title={t('Lizenzpakete')}
        breadcrumbs={breadcrumbs}
        actions={
          <Guarded requiredRole={Role.PDN}>
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to={routes.payRateCreate}
              startIcon={<Add />}
            >
              {t('Neues Lizenzpaket')}
            </Button>
          </Guarded>
        }
      />
      <PaginationCard
        context={context}
        hasFilters={!!(search || rateType)}
        resetFilters={() => {
          setFilterSearch('');
          setFilterRateType('');
        }}
        filters={
          <>
            <FilterInputSearch
              value={inputSearch}
              label={t('Suche')}
              onChange={setInputSearch}
            />
            <FilterSelect
              label={t('Typ')}
              value={rateType}
              onChange={setFilterRateType}
            >
              <MenuItem value="">
                <em>All</em>
              </MenuItem>
              {Object.keys(PayRateType).map((type, key) => (
                <MenuItem
                  value={PayRateType[type as keyof typeof PayRateType]}
                  key={key}
                >
                  {t(`payRate.type.${type.toLowerCase()}`)}
                </MenuItem>
              ))}
            </FilterSelect>
          </>
        }
      >
        <PayRateList />
      </PaginationCard>
    </StyledContainer>
  );
};

export default PayRatesOverview;
