import { TabPanel } from '@mui/lab';
import DatePicker from '@mui/lab/DatePicker';
import {
  CardContent,
  Checkbox,
  Container,
  FormControl,
  FormGroup,
  TableCell,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import styled from 'styled-components';

export const FilterFormGroup = styled(FormGroup)`
  align-items: center;
`;

export const FilterFormControl = styled(FormControl)`
  min-width: 120px;
  margin-right: 0.5em;
`;

export const CompactTableCell = styled(TableCell)`
  white-space: nowrap;
  width: 1px;
`;

export const DatePickerSmall = styled(DatePicker)`
  label {
    transform: translate(14px, 13px) scale(1);
  }

  input {
    padding-top: 10.5px;
    padding-bottom: 10.5px;
  }
`;

export const StyledContainer = styled(Container)`
  width: ${(props) => props.theme.width.full};
  max-width: none;
`;

export const StyledCardTitle = styled(Typography)`
  margin-bottom: ${(props) => props.theme.spacing(2)}px;
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
`;

export const StyledColoredCardContent = styled(CardContent)`
  background-color: ${grey[100]};
`;

export const StyledFilterFormControl = styled(FilterFormControl)`
  width: ${(props) => props.theme.width['1/5']};
  margin-top: ${(props) => props.theme.spacing(1)}px;
  margin-bottom: ${(props) => props.theme.spacing(1)}px;
`;

export const StyledCheckbox = styled(Checkbox)`
  padding-top: 0;
  padding-bottom: 0;
`;

export const SpanBlock = styled.span`
  display: block;
`;

export const StyledTabPanel = styled(TabPanel)`
  padding: 0;
`;
