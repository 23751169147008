import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

export const useTariffGroupValidation = () => {
  const { t } = useTranslation();

  return useMemo(
    () => ({
      groupKey: yup.string().required(t('Key darf nicht leer sein.')),
      groupNumber: yup.number().required(t('Nummer darf nicht leer sein.')),
      label: yup.string(),
      price: yup
        .string()
        .matches(
          /^(\d+(?:[,]\d+)?)$/,
          t('Muss eine Komma-getrennte Dezimalzahl sein.')
        ),
    }),
    []
  );
};
