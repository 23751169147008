import { Link, Typography } from '@mui/material';
import React, { FunctionComponent, VoidFunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { routes } from '../../lib';
import { Dict } from '../../model';

const EntityLabel: VoidFunctionComponent<{
  entityId: number;
  label: string;
  caption: string;
  link: string;
}> = ({ entityId, label, caption, link }) => {
  return (
    <>
      <Typography
        variant="caption"
        style={{ display: 'block' }}
        color="textSecondary"
      >
        {caption}
      </Typography>
      <Link component={RouterLink} to={link}>
        {label} (#{entityId})
      </Link>
    </>
  );
};

const LogEntityLink: FunctionComponent<{
  entityClass: string;
  entityId: number;
  label: string | null;
}> = ({ entityClass, entityId, label }) => {
  const { t } = useTranslation();

  const mapping: Dict<[(id: number) => string, string]> = {
    'App\\Entity\\User': [routes.user, 'User'],
    'App\\Entity\\Company': [routes.company, t('Kunde')],
    'App\\Entity\\Person': [routes.person, t('Personen')],
    'App\\Entity\\Medium': [routes.medium, t('Medium')],
    'App\\Entity\\MailingList': [routes.mailingList, t('Verteiler')],
    'App\\Entity\\Mailing': [routes.mailing, t('Mailing')],
    'App\\Entity\\Signature': [routes.signature, t('Signatur')],
    'App\\Entity\\PressPass': [routes.pressPass, t('Presseausweis')],
    'App\\Entity\\PressPassApplication': [routes.pressPassApplication, t('Presseausweis Antrag')],
    'App\\Entity\\Position': [routes.position, t('Funktion')],
    'App\\Entity\\Badge': [() => routes.pressPasses, t('Marke')],
    'App\\Entity\\Tariff': [routes.tariff, t('Tarifliste')],
    'App\\Entity\\PayRate': [routes.payRate, t('Lizenzpaket')],
    'App\\Entity\\TariffGroup': [() => routes.tariffs, t('Tarifgruppe')],
    'App\\Entity\\LicenseContactPerson': [() => routes.licensees, t('Lizenznehmer Kontaktperson')],
    'App\\Entity\\LicensePressReview': [() => routes.licensees, t('Lizenznehmer Pressespiegel')],
    'App\\Entity\\Transaction': [() => routes.licensees, t('Lizenznehmer Nutzungsdaten')],
  };

  if (!mapping[entityClass]) {
    return (
      <>
        {entityClass}#{entityId}
      </>
    );
  }

  if (label) {
    return (
      <EntityLabel
        entityId={entityId}
        label={label}
        caption={mapping[entityClass][1]}
        link={mapping[entityClass][0](entityId)}
      />
    );
  }

  return (
    <Link component={RouterLink} to={mapping[entityClass][0](entityId)}>
      {mapping[entityClass][1]}#{entityId}
    </Link>
  );
};

export default LogEntityLink;
