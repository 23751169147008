import { IconButton, Menu, MenuItem } from '@mui/material';
import { VoidFunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useCurrentUser } from '../../hooks';
import { apiRoutes, request, routes } from '../../lib';
import UserAvatar, { AvatarSize } from '../user/avatar';

const UserDropdown: VoidFunctionComponent = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const user = useCurrentUser();
  const open = Boolean(anchorEl);
  const { t } = useTranslation();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const logout = () => {
    localStorage.clear();
    request(apiRoutes.logout).finally(() => window.location.reload());
  };

  return (
    <>
      <IconButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        color="inherit"
        onClick={handleMenu}
        size="large"
      >
        <UserAvatar user={user} size={AvatarSize.Medium} />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
      >
        <MenuItem component={Link} to={routes.profile} onClick={handleClose}>
          {t('Profil')}
        </MenuItem>
        <MenuItem onClick={logout}>{t('Logout')}</MenuItem>
      </Menu>
    </>
  );
};

export default UserDropdown;
