import {
  CardContent,
  Divider,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/css/css.js';
import 'codemirror/mode/xml/xml.js';
import React, { VoidFunctionComponent, useState } from 'react';
import { Controlled as CodeMirror } from 'react-codemirror2';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Signature, SignatureFormValues } from '../../model';
import { StyledCardTitle } from '../globals';

export const SignatureFormFields: VoidFunctionComponent<{
  control: Control<SignatureFormValues>;
  signature?: Signature;
}> = ({ control, signature }) => {
  const [html, setHtml] = useState(signature?.html || '');
  const { t } = useTranslation();

  return (
    <>
      <CardContent>
        <StyledCardTitle variant="h6" color="secondary" gutterBottom>
          {t('Signatur')}
        </StyledCardTitle>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name={'name'}
              render={({ field, fieldState }) => (
                <TextField
                  label={t('Name')}
                  fullWidth
                  required
                  {...field}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name={'email'}
              render={({ field, fieldState }) => (
                <TextField
                  type="email"
                  label={t('E-Mail')}
                  fullWidth
                  required
                  {...field}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="caption" color="textSecondary">
              {t('HTML')}
            </Typography>
            <Controller
              control={control}
              name={'html'}
              render={({ field, fieldState }) => (
                <>
                  <div style={{ border: '1px rgba(0 ,0 ,0 , .12) solid' }}>
                    <CodeMirror
                      options={{
                        mode: 'xml',
                        lineNumbers: true,
                      }}
                      value={field.value}
                      onBeforeChange={(editor, data, value) => {
                        field.onChange(value);
                        setHtml(value);
                      }}
                      onChange={(editor, data, value) => {
                        field.onChange(value);
                        setHtml(value);
                      }}
                    />
                  </div>
                  {fieldState.error?.message && (
                    <FormHelperText error={true}>
                      {fieldState.error.message}
                    </FormHelperText>
                  )}
                </>
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="caption" color="textSecondary">
              {t('Vorschau')}
            </Typography>
            <div
              dangerouslySetInnerHTML={{ __html: html }}
              style={{ overflow: 'auto' }}
            />
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Controller
              control={control}
              name={'comment'}
              render={({ field, fieldState }) => (
                <TextField
                  label={t('Interner Kommentar')}
                  fullWidth
                  multiline={true}
                  {...field}
                  error={fieldState.isTouched && fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
        </Grid>
      </CardContent>
    </>
  );
};
