import { Blameable, Commentable, ID, Timestampable } from './index';

export interface Signature extends Blameable, Commentable, Timestampable {
  id: ID;
  name: string;
  html: string;
  email: string;
}

export interface SignatureFilters {
  search: string;
}

export type SignatureFormValues = Omit<Signature, 'id' | keyof Timestampable | keyof Blameable>;

export const getSignatureFormValues = (
  signature?: Signature
): SignatureFormValues => ({
  name: signature?.name || '',
  html: signature?.html || '',
  comment: signature?.comment || '',
  email: signature?.email || '',
});
