import { Edit, Visibility } from '@mui/icons-material';
import { Checkbox, Link, TableCell, TableRow, Typography } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { routes } from '../../lib';
import { BillTo, ID, PressPass } from '../../model';
import { CompactTableCell } from '../globals';
import { IconLink } from '../icon-link';
import { MembershipChip } from '../membership-chip';
import { PaginationTable, usePaginationContext } from '../pagination';
import PaginationTableHeadCell from '../pagination/table-head-cell';
import { PressPassStatusBadge } from "./status";
import FormattedDate from "../formatted-date";

const PressPassList: FunctionComponent<{
  pressPasses?: PressPass[];
  selected: ID[];
  setSelected: (id: ID[]) => void;
}> = ({ pressPasses, selected, setSelected }) => {
  const { t } = useTranslation();
  const { data } = usePaginationContext();

  const isSelected = (id: ID) => selected.indexOf(id) !== -1;

  return (
    <PaginationTable
      items={pressPasses}
      renderRow={(pressPass) => {
        return (
          <TableRow key={pressPass.id} hover>
            <TableCell
              padding="checkbox"
              onClick={() =>
                setSelected(
                  selected.includes(pressPass.id)
                    ? [...selected].filter((e) => e !== pressPass.id)
                    : [...selected, pressPass.id]
                )
              }
            >
              <Checkbox color="primary" checked={isSelected(pressPass.id)}/>
            </TableCell>
            <CompactTableCell>{pressPass.person.titleBefore}</CompactTableCell>
            <CompactTableCell>{pressPass.person.firstName}</CompactTableCell>
            <TableCell>
              <Link to={routes.pressPass(pressPass.id)} component={RouterLink}>
                {pressPass.person.lastName}
              </Link>
            </TableCell>
            <TableCell>
              {pressPass.medium && (
                <Link href={routes.medium(pressPass.medium.id)}>
                  {pressPass.medium.name}
                </Link>
              )}
            </TableCell>
            <TableCell>
              {pressPass.company && (
                <Link href={routes.company(pressPass.company.id)}>
                  {pressPass.company.name} {pressPass.company.term || ''}
                </Link>
              )}
            </TableCell>
            <TableCell>{pressPass.currentBadge?.year}</TableCell>
            <TableCell>
              {pressPass.passNumber} <br/>
              {pressPass.pressPassSuspended.length > 0
                ? pressPass.pressPassSuspended.sort((a, b) => b.id - a.id).map((pressPassSuspended, index) => (
                  <Typography variant="caption" component="span" color="error" key={index}>
                    {pressPassSuspended.passNumber}¹{' '}
                  </Typography>
                )) : ''
              }
            </TableCell>
            <TableCell>
              {pressPass.passPrintDate && (
                <>
                  <FormattedDate
                    date={pressPass.passPrintDate}
                    format="d.MM.Y"
                  />
                </>
              )}
            </TableCell>
            <TableCell>
              <MembershipChip membership={pressPass.membership}/>
            </TableCell>
            <TableCell>
              <Checkbox
                checked={pressPass.billTo === BillTo.Person}
                readOnly={true}
                name="enabled"
                color="primary"
                disableRipple={true}
              />
            </TableCell>
            <TableCell>
              <Checkbox
                checked={pressPass.enabled}
                readOnly={true}
                name="enabled"
                color="primary"
                disableRipple={true}
              />
            </TableCell>
            <TableCell>
              <PressPassStatusBadge status={pressPass.status}/>
            </TableCell>
            <CompactTableCell align={'right'}>
              <IconLink
                to={routes.pressPass(pressPass.id)}
                tooltip={t('Ansehen')}
                icon={<Visibility/>}
              />
              <IconLink
                to={routes.pressPassEdit(pressPass.id)}
                tooltip={t('Bearbeiten')}
                icon={<Edit/>}
              />
            </CompactTableCell>
          </TableRow>
        );
      }}
    >
      <TableRow>
        {data && (
          <PaginationTableHeadCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={
                selected.length > 0 && selected.length < data.filtered
              }
              checked={data.filtered > 0 && selected.length === data.filtered}
              onChange={() => {
                !selected.length
                  ? setSelected(data.results.map((data) => data.id))
                  : setSelected([]);
              }}
            />
          </PaginationTableHeadCell>
        )}
        <PaginationTableHeadCell>
          {t('Titel')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell sort="firstName">
          {t('Vorname')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell sort="lastName">
          {t('Nachname')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell sort="medium">
          {t('Medium')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell sort="company">
          {t('Unternehmen')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell sort="mark">
          {t('Marke')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell sort="passNumber">
          {t('Ausweisnummer')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell sort="passPrintDate">
          {t('Druckdatum')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell sort="membership">
          {t('Mitglied')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell sort="billTo">
          {t('BEZ. Red')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell sort="enabled">
          {t('Aktiv')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell sort="status">
          {t('Status')}
        </PaginationTableHeadCell>
        <TableCell width={110}/>
      </TableRow>
    </PaginationTable>
  );
};

export default PressPassList;
