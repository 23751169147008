import { Edit, Visibility } from '@mui/icons-material';
import { Link, TableCell, TableRow } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { routes } from '../../lib';
import { DateFormat, Mailing } from '../../model';
import FormattedDate from '../formatted-date';
import { CompactTableCell, SpanBlock } from '../globals';
import { IconLink } from '../icon-link';
import { PaginationTable } from '../pagination';
import PaginationTableHeadCell from '../pagination/table-head-cell';

export const MailingList: FunctionComponent<{ mailings?: Mailing[] }> = ({
  mailings,
}) => {
  const { t } = useTranslation();

  return (
    <PaginationTable
      items={mailings}
      renderRow={(mailing) => (
        <TableRow key={mailing.id} hover={true}>
          <CompactTableCell>
            <Link to={routes.mailing(mailing.id)} component={RouterLink}>
              {mailing.name}
            </Link>
          </CompactTableCell>
          <CompactTableCell>{mailing.subject}</CompactTableCell>
          <TableCell>
            {mailing.mailingLists.map((ml) => (
              <Link
                href={routes.mailingList(ml.id)}
                key={ml.id}
                sx={{ display: 'block' }}
              >
                {ml.name} (
                {ml.persons.filter((mlp) => mlp.person.enabled).length})
              </Link>
            ))}
          </TableCell>
          <TableCell>
            {mailing.deliveries
              .filter((delivery) => !delivery.test)
              .map((delivery) => (
                <SpanBlock key={delivery.id}>
                  <FormattedDate
                    date={delivery.createdAt}
                    format={DateFormat.DateTime}
                  />
                </SpanBlock>
              ))}
          </TableCell>
          <CompactTableCell align={'right'}>
            <IconLink
              to={routes.mailing(mailing.id)}
              tooltip={t('Ansehen')}
              icon={<Visibility />}
            />
            <IconLink
              to={routes.mailingEdit(mailing.id)}
              tooltip={t('Bearbeiten')}
              icon={<Edit />}
            />
          </CompactTableCell>
        </TableRow>
      )}
    >
      <TableRow>
        <PaginationTableHeadCell sort="name">
          {t('Name')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell sort="subject">
          {t('Betreff')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell>{t('Verteiler')}</PaginationTableHeadCell>
        <PaginationTableHeadCell sort="delivery">
          {t('Gesendet am')}
        </PaginationTableHeadCell>
        <TableCell width={110} />
      </TableRow>
    </PaginationTable>
  );
};
