import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { CssBaseline, Theme, createTheme, useMediaQuery } from '@mui/material';
import { StyledEngineProvider } from '@mui/styled-engine-sc';
import { ThemeProvider as MaterialThemeProvider } from '@mui/styles';
import { SnackbarProvider } from 'notistack';
import React, { FunctionComponent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components';
import { setLocale } from 'yup';
import Error from '../../components/error';
import GuardedRoute from '../../components/guarded-route';
import Login from '../../components/login';
import Navigation from '../../components/navigation';
import TopBar from '../../components/top-bar';
import AppContext from '../../context/app';
import { useLocalStorage } from '../../hooks';
import { useClient } from '../../hooks/use-client';
import { routes } from '../../lib';
import { Client, Nullable, Role, User } from '../../model';
import {
  CustomTheme,
  MaterialDarkTheme,
  MaterialLightTheme,
  OezvTheme,
  VoezTheme,
} from '../../theme';
import Companies from '../companies';
import Dashboard from '../dashboard';
import FormPage from '../form-page';
import Import from '../import';
import Licensees from '../licensees';
import Logs from '../logs';
import Mailings from '../mailing';
import MailingLists from '../mailing-lists';
import Media from '../media';
import PayRates from '../pay-rates';
import Persons from '../persons';
import Positions from '../positions';
import PressPassApplications from '../press-pass-applications';
import PressPasses from '../press-passes';
import Profile from '../profile';
import Search from '../search';
import Signatures from '../signatures';
import Tariffs from '../tariffs';
import UserManagement from '../users';
import PressPassUpdateApplications from "../press-pass-update-applications";

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const StyledApp = styled.div`
  display: flex;
  margin-left: ${(props) => props.theme.navigation.width}px;
`;

const StyledContent = styled.div`
  flex-grow: 1;
  padding-bottom: 2em;
`;

const StyledRouteContent = styled.div`
  margin-top: 2em;
`;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

const App: FunctionComponent<{ user: Nullable<User> }> = ({ user }) => {
  const [ctxUser, setCtxUser] = useState(user);
  const defaultTheme = useMediaQuery('(prefers-color-scheme: dark)')
    ? 'dark'
    : 'light';
  const [materialTheme, setMaterialTheme] = useLocalStorage<'light' | 'dark'>(
    'theme',
    defaultTheme
  );
  const client = useClient();

  const muiTheme = useMemo(() => {
    if (client === Client.Voez) {
      return createTheme({ ...VoezTheme });
    }
    if (client === Client.Oezv) {
      return createTheme({ ...OezvTheme });
    }
    return createTheme(
      materialTheme === 'light'
        ? { ...MaterialLightTheme }
        : { ...MaterialDarkTheme }
    );
  }, [client, materialTheme]);
  const [locationTitle, setLocationTitle] = useState('Unknown');
  const { t } = useTranslation();

  setLocale({
    mixed: {
      notType: t('Ungültige Eingabe'),
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <AppContext.Provider
        value={{
          setMaterialTheme,
          user: ctxUser,
          setUser: setCtxUser,
          materialTheme,
          locationTitle,
          setLocationTitle,
          documentTitle: '%title% - VÖZ PA',
        }}
      >
        <MaterialThemeProvider theme={muiTheme}>
          <SnackbarProvider maxSnack={3}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <StyledEngineProvider injectFirst>
                <ThemeProvider theme={{ ...CustomTheme, ...muiTheme }}>
                  <CssBaseline />
                  {client !== Client.Engine ? (
                    <FormPage />
                  ) : (
                    <>
                      {user ? (
                        <Router>
                          <StyledApp>
                            <Navigation />
                            <StyledContent>
                              <TopBar />
                              <StyledRouteContent>
                                <Switch>
                                  <Route path={routes.users}>
                                    <UserManagement />
                                  </Route>
                                  <Route path={routes.profile}>
                                    <Profile />
                                  </Route>
                                  <Route path={routes.logs}>
                                    <Logs />
                                  </Route>
                                  <Route path={routes.search}>
                                    <Search />
                                  </Route>
                                  <Route exact path={routes.dashboard}>
                                    <Dashboard />
                                  </Route>
                                  <GuardedRoute
                                    path={routes.companies}
                                    requiredRole={Role.General}
                                  >
                                    <Companies />
                                  </GuardedRoute>
                                  <GuardedRoute
                                    path={routes.pressPasses}
                                    requiredRole={Role.PressPass}
                                  >
                                    <PressPasses />
                                  </GuardedRoute>
                                  <GuardedRoute
                                    requiredRole={Role.PressPass}
                                    path={routes.pressPassApplications}
                                  >
                                    <PressPassApplications />
                                  </GuardedRoute>
                                  <GuardedRoute
                                    requiredRole={Role.PressPass}
                                    path={routes.pressPassUpdateApplications}
                                  >
                                    <PressPassUpdateApplications/>
                                  </GuardedRoute>
                                  <GuardedRoute
                                    path={routes.persons}
                                    requiredRole={Role.General}
                                  >
                                    <Persons />
                                  </GuardedRoute>
                                  <GuardedRoute
                                    requiredRole={Role.General}
                                    path={routes.media}
                                  >
                                    <Media />
                                  </GuardedRoute>
                                  <GuardedRoute
                                    path={routes.positions}
                                    requiredRole={Role.Admin}
                                  >
                                    <Positions />
                                  </GuardedRoute>
                                  <GuardedRoute
                                    path={routes.mailingLists}
                                    requiredRole={Role.General}
                                  >
                                    <MailingLists />
                                  </GuardedRoute>
                                  <GuardedRoute
                                    path={routes.signatures}
                                    requiredRole={Role.General}
                                  >
                                    <Signatures />
                                  </GuardedRoute>
                                  <GuardedRoute
                                    path={routes.mailings}
                                    requiredRole={Role.General}
                                  >
                                    <Mailings />
                                  </GuardedRoute>
                                  <GuardedRoute
                                    path={routes.payRates}
                                    requiredRole={Role.PDN}
                                  >
                                    <PayRates />
                                  </GuardedRoute>
                                  <GuardedRoute
                                    path={routes.imports}
                                    requiredRole={Role.PDN}
                                  >
                                    <Import />
                                  </GuardedRoute>
                                  <GuardedRoute
                                    path={routes.tariffs}
                                    requiredRole={Role.PDN}
                                  >
                                    <Tariffs />
                                  </GuardedRoute>
                                  <GuardedRoute
                                    path={routes.licensees}
                                    requiredRole={Role.PDN}
                                  >
                                    <Licensees />
                                  </GuardedRoute>
                                  <Route path="*">
                                    <Error title="404" />
                                  </Route>
                                </Switch>
                              </StyledRouteContent>
                            </StyledContent>
                          </StyledApp>
                        </Router>
                      ) : (
                        <Login />
                      )}
                    </>
                  )}
                </ThemeProvider>
              </StyledEngineProvider>
            </LocalizationProvider>
          </SnackbarProvider>
        </MaterialThemeProvider>
      </AppContext.Provider>
    </QueryClientProvider>
  );
};

export default App;
