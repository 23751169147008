import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { routes } from '../../lib';
import SignatureOverview from './overview';
import CreateSignature from './create';
import EditSignature from './edit';
import SignatureDetails from './details';

const Signatures = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <SignatureOverview />
      </Route>
      <Route path={routes.signatureCreate}>
        <CreateSignature />
      </Route>
      <Route path={routes.signatureEdit(':signatureId')}>
        <EditSignature />
      </Route>
      <Route path={routes.signature(':signatureId')}>
        <SignatureDetails />
      </Route>
    </Switch>
  );
};

export default Signatures;
