import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { routes } from '../../lib';
import CreatePerson from './create';
import PersonDetails from './details';
import EditPerson from './edit';
import PersonOverview from './overview';

const Persons = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <PersonOverview />
      </Route>
      <Route path={routes.personCreate}>
        <CreatePerson />
      </Route>
      <Route path={routes.personEdit(':personId')}>
        <EditPerson />
      </Route>
      <Route path={routes.person(':personId')}>
        <PersonDetails />
      </Route>
    </Switch>
  );
};

export default Persons;
