import {
  CheckCircle,
  CommentsDisabled,
  Delete,
  Edit, Error,
  FactCheckRounded,
  FeaturedVideo,
  IndeterminateCheckBox,
  InfoOutlined, OpenInNew,
  Visibility,
} from '@mui/icons-material';
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  Grid, Icon,
  IconButton,
  Link, Table, TableBody, TableCell, TableHead, TableRow,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import { Link as RouterLink, useParams } from 'react-router-dom';
import FormattedDate from '../../components/formatted-date';
import {
  CompactTableCell,
  SpanBlock,
  StyledCardTitle,
  StyledCheckbox,
} from '../../components/globals';
import { GridData } from '../../components/grid-data';
import Guarded from '../../components/guarded';
import Header from '../../components/header';
import HttpError from '../../components/http-error';
import LoadingContainer from '../../components/loading-container';
import { MembershipChip } from '../../components/membership-chip';
import { ModifiedEntity } from '../../components/modified-entity';
import PressPassMarkHistoryDialog from '../../components/press-pass/mark-history-dialog';
import PressPassPhoto from '../../components/press-pass/photo';
import { useTitle } from '../../hooks';
import { apiRoutes, request, routes, usePressPassDetailsApi } from '../../lib';
import { DateFormat, ID, PressPassSuspended, Role } from '../../model';
import { red } from "@mui/material/colors";
import SuspensionDialog from "../../components/press-pass/suspension-dialog";
import LoadingIconButton from "../../components/loading-icon-button";

const PressPassDetails = () => {
  const { t } = useTranslation();
  const { pressPassId } = useParams<{ pressPassId: string | undefined }>();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { isLoading, error, data } = usePressPassDetailsApi(
    pressPassId as unknown as number
  );
  const history = useHistory();
  useTitle(data?.person.displayName || t('Kontakt Details'));
  const [markDialogOpen, setMarkDialogOpen] = useState(false);
  const [deletingPressPassSuspension, setDeletingPressPassSuspension] = useState<ID[]>([]);
  const [suspensionDialogOpen, setSuspensionDialogOpen] = useState(false);

  if (error) {
    return (
      <HttpError
        error={error}
        actions={
          <Button component={RouterLink} to={routes.pressPasses}>
            {t('Zurück zu Presseausweisen')}
          </Button>
        }
      />
    );
  }

  const deletingPressPassSuspensionMutation = useMutation(
    async (pressPassSuspended: PressPassSuspended) => {
      setDeletingPressPassSuspension([...deletingPressPassSuspension, pressPassSuspended.id]);
      return {
        id: pressPassSuspended.id,
        response: await request<PressPassSuspended>(
          apiRoutes.pressPassSuspendDelete(pressPassSuspended.id),
          'delete'
        ),
      };
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(apiRoutes.pressPass(pressPassId as unknown as number));
        enqueueSnackbar(t('Eintrag wurde erfolgreich entfernt.'), {
          variant: 'success',
        });
      },
      onError: () => {
        enqueueSnackbar(t('Eintrag konnte nicht entfernt werden.'), {
          variant: 'error',
        });
      },
      onSettled: (res) => {
        if (!res) {
          return;
        }
        setDeletingPressPassSuspension([
          ...deletingPressPassSuspension.filter((suspension) => suspension !== res.id),
        ]);
      },
    }
  );

  const deleteMutation = useMutation(
    async () => {
      if (!data) {
        return;
      }

      return await request(apiRoutes.pressPass(data.id), 'delete');
    },
    {
      onSuccess: () => {
        enqueueSnackbar(t('Presseausweis wurde erfolgreich gelöscht.'), {
          variant: 'success',
        });
        history.push(routes.pressPasses);
      },
      onError: () => {
        enqueueSnackbar(t('Presseausweis konnte nicht gelöscht werden.'), {
          variant: 'error',
        });
      },
    }
  );

  if (isLoading || !data || !data.person) {
    return <LoadingContainer/>;
  }

  const detailBreadcrumbs = [
    { label: t('Home'), link: routes.dashboard },
    { label: t('Presseausweise'), link: routes.pressPasses },
  ];

  return (
    <Container maxWidth="xl">
      <Header
        title={
          <>
            {data.person.salutation
              ? t(`person.salutation.${data.person.salutation}`) + (data.person.salutationPosition != null ? ' ' + data.person.salutationPosition + ' ' : '') + ' '
              : ''}
            {data.person.displayName}
            <MembershipChip membership={data.membership}/>
          </>
        }
        search={{ route: routes.pressPasses, param: 'search' }}
        breadcrumbs={detailBreadcrumbs}
        actions={
          <>
            <Guarded requiredRole={Role.PressPass}>
              <Button
                variant="outlined"
                color="primary"
                component={RouterLink}
                to={routes.person(data.person.id)}
                startIcon={<Visibility/>}
              >
                {t('Zur Person')}
              </Button>{' '}
              <Button
                variant="contained"
                color="primary"
                component={RouterLink}
                to={routes.pressPassEdit(data.id)}
                startIcon={<Edit/>}
              >
                {t('Bearbeiten')}
              </Button>{' '}
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  if (
                    !window.confirm(
                      t(
                        `Wollen Sie den Presseausweis für {{displayName}} wirklich löschen?`,
                        {
                          displayName: data.person.displayName,
                        }
                      )
                    )
                  ) {
                    return;
                  }
                  deleteMutation.mutateAsync();
                }}
                startIcon={<Delete/>}
              >
                {t('Löschen')}
              </Button>
            </Guarded>
            <ModifiedEntity entity={data}/>
          </>
        }
      />
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <StyledCardTitle variant="h6" color="secondary" gutterBottom>
                {t('Informationen zur Person')}
              </StyledCardTitle>
              <Grid container spacing={3}>
                <GridData xs={12} md={6} label={t<string>('Anschrift PA')}>
                  {data.street && <SpanBlock>{data.street}</SpanBlock>}
                  {(data.zipCode || data.city) && (
                    <SpanBlock>
                      {data.zipCode} {data.city}
                    </SpanBlock>
                  )}
                  {data.country && <SpanBlock>{data.country}</SpanBlock>}
                </GridData>
                <GridData xs={12} md={6} label={t<string>('Nationalität')}>
                  {data.person.nationality}
                </GridData>
                <GridData xs={12} md={6} label={t<string>('Geburtsdatum')}>
                  <FormattedDate
                    format={'d.MM.Y'}
                    date={data.person.birthdate || ''}
                  />
                </GridData>
                <GridData xs={12} md={6} label={t<string>('Geburtsort')}>
                  {data.person.birthplace}
                </GridData>
              </Grid>
            </CardContent>
            <Divider/>
            <CardContent>
              <StyledCardTitle variant="h6" color="secondary" gutterBottom>
                {t('Kontaktdaten PA')}
              </StyledCardTitle>
              <Grid container spacing={3}>
                <GridData xs={12} md={6} label={t<string>('Telefonnummer')}>
                  {data.phone}
                </GridData>
                <GridData xs={12} md={6} label={t<string>('Mobiltelefon')}>
                  {data.mobilePhone}
                </GridData>
                <GridData xs={12} md={6} label={t<string>('E-Mail Adresse')}>
                  {data.email}
                </GridData>
              </Grid>
            </CardContent>
            <Divider/>
            <CardContent>
              <Grid container spacing={3}>
                <GridData xs={12} label={t<string>('Interner Kommentar')}>
                  {data.comment}
                </GridData>
              </Grid>
            </CardContent>
            <Divider/>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <StyledCardTitle variant="h6" color="secondary" gutterBottom>
                    {t('Medium')}
                  </StyledCardTitle>
                  <Grid container spacing={3}>
                    {data.medium ? (
                      <GridData
                        xs={12}
                        md={6}
                        label={t<string>('Medium / Arbeitgeber')}
                      >
                        <SpanBlock>
                          <Link href={routes.medium(data.medium.id)}>
                            {data.medium.name}
                          </Link>
                        </SpanBlock>
                        {data.medium.company && (
                          <>
                            <Link
                              href={routes.company(data.medium.company.id)}
                              color="secondary"
                            >
                              {data.medium.company.name}
                            </Link>
                            <SpanBlock>{data.medium.company.street}</SpanBlock>
                            <SpanBlock>
                              {data.medium.company.zipCode}{' '}
                              {data.medium.company.city}
                            </SpanBlock>
                            <SpanBlock>{data.medium.company.country}</SpanBlock>
                          </>
                        )}
                      </GridData>
                    ) : (
                      <>
                        {data.company && (
                          <GridData
                            xs={12}
                            md={6}
                            label={t<string>('Unternehmen')}
                          >
                            <SpanBlock>
                              <Link href={routes.company(data.company.id)}>
                                {data.company.name}
                              </Link>
                            </SpanBlock>
                            <SpanBlock>{data.company.street}</SpanBlock>
                            <SpanBlock>
                              {data.company.zipCode} {data.company.city}
                            </SpanBlock>
                            <SpanBlock>{data.company.country}</SpanBlock>
                          </GridData>
                        )}
                      </>
                    )}
                    <Grid item xs={12} md={6}>
                      <Grid container spacing={3}>
                        <GridData xs={12} label={t<string>('Dienstverhätnis')}>
                          {data.employmentStatus &&
                            t(
                              `pressPass.employmentStatus.${data.employmentStatus}`
                            )}
                        </GridData>
                        <GridData xs={12} label={t<string>('Tätigkeit')}>
                          {data.position?.title}
                        </GridData>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider/>
                <CardContent>
                  <StyledCardTitle variant="h6" color="secondary" gutterBottom>
                    {t('Verwaltung')}
                  </StyledCardTitle>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <Grid container spacing={3}>
                        <GridData
                          xs={12}
                          label={t<string>('Foto')}
                          mode="richtext"
                        >
                          <PressPassPhoto src={data.photo || ''}/>

                        </GridData>
                        <GridData
                          xs={12}
                          label={t<string>('Foto aktualisiert am')}
                          mode="richtext"
                        >
                          {data.photoUpdatedAt && (
                            <>
                              <FormattedDate
                                date={data.photoUpdatedAt}
                                format="d.MM.Y"
                              />
                            </>
                          )}
                        </GridData>
                        <GridData
                          xs={12}
                          label={t<string>('Rechnungsempfänger')}
                        >
                          {data.billTo && t(`pressPass.billTo.${data.billTo}`)}
                        </GridData>
                        <GridData xs={12} label={t<string>('Autoschild')}>
                          <FormControlLabel
                            control={
                              <StyledCheckbox
                                readOnly={true}
                                name="carBadge"
                                color="primary"
                                checked={data.currentBadge?.carBadge}
                                disableRipple={true}
                                style={{ cursor: 'auto' }}
                                icon={
                                  !data.currentBadge?.carBadge ? (
                                    <IndeterminateCheckBox/>
                                  ) : undefined
                                }
                              />
                            }
                            label={
                              <>
                                {data.currentBadge?.carBadge ? (
                                  <FormattedDate
                                    format={'d.MM.Y'}
                                    date={data.carBadgeDate || ''}
                                  />
                                ) : (
                                  t('Nein')
                                )}
                              </>
                            }
                          />
                        </GridData>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Grid container spacing={3}>
                        <GridData xs={12} label={
                          <>
                            {t<string>('Ausweis')}
                          </>
                        }>
                          <FormControlLabel
                            control={
                              <StyledCheckbox
                                checked={data.pass}
                                readOnly={true}
                                name="pass"
                                color="primary"
                                disableRipple={true}
                                style={{ cursor: 'auto' }}
                                icon={
                                  !data.pass ? (
                                    <IndeterminateCheckBox/>
                                  ) : undefined
                                }
                              />
                            }
                            label={
                              data.pass && data.passNumber
                                ? data.passNumber
                                : t<string>('Nein')
                            }
                          />
                          {data.pass && data.passDate && (
                            <>
                              (
                              <FormattedDate
                                date={data.passDate}
                                format="d.MM.Y"
                              />
                              )
                            </>
                          )}
                          <IconButton
                            size="small"
                            color="warning"
                            title={t('Ausweisnummer sperren')}
                            onClick={() => setSuspensionDialogOpen(true)}
                          >
                            <CommentsDisabled/>
                          </IconButton>
                          <SuspensionDialog
                            open={suspensionDialogOpen}
                            onClose={() => setSuspensionDialogOpen(false)}
                            pressPass={data}
                          />

                        </GridData>
                        <GridData
                          xs={12}
                          label={t<string>('Ausweis eingezogen')}
                        >
                          <FormControlLabel
                            control={
                              <StyledCheckbox
                                checked={data.passWithdrawal}
                                readOnly={true}
                                name="enabled"
                                color="primary"
                                disableRipple={true}
                                style={{ cursor: 'auto' }}
                                icon={
                                  !data.passWithdrawal ? (
                                    <IndeterminateCheckBox/>
                                  ) : undefined
                                }
                              />
                            }
                            label={
                              !data.passWithdrawal ? (
                                t<string>('Nein')
                              ) : (
                                <FormattedDate
                                  date={data.passWithdrawalDate || ''}
                                  format="d.MM.Y"
                                />
                              )
                            }
                          />
                        </GridData>
                        <GridData
                          xs={12}
                          mode="richtext"
                          label={
                            <>
                              {t<string>('Marke')}
                              <IconButton
                                size="small"
                                onClick={() => setMarkDialogOpen(true)}
                              >
                                <InfoOutlined/>
                              </IconButton>
                            </>
                          }
                        >
                          {data.currentBadge?.year}{' '}
                          {data.badgeDate && (
                            <>
                              (
                              <FormattedDate
                                date={data.badgeDate}
                                format="d.MM.Y"
                              />
                              )
                            </>
                          )}
                        </GridData>
                        <Grid item xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={data.enabled}
                                readOnly={true}
                                name="enabled"
                                color="primary"
                                disableRipple={true}
                                style={{ cursor: 'auto' }}
                              />
                            }
                            label={t<string>('Aktiver Presseausweis')}
                          />
                        </Grid>
                        <GridData xs={12} label={t<string>('Druckdatum')}>
                          {data.passPrintDate && (
                            <>
                              <FormattedDate
                                date={data.passPrintDate}
                                format="d.MM.Y"
                              />
                            </>
                          )}
                        </GridData>
                        <GridData xs={12} label={t<string>('Status')}>
                          {data.status && t(`pressPass.status.${data.status}`)}
                        </GridData>
                        <Grid item xs={12}>
                          {data.checkUrl && (
                            <Link href={data.checkUrl} target="_blank">
                              <Icon>
                                <FactCheckRounded
                                  style={{ color: red[500] }}
                                  fontSize="small"
                                />
                              </Icon>
                              {t('Gültigkeitsabfrage')} <OpenInNew fontSize={'small'}/>
                            </Link>)}
                          {data.validHash && (
                            <Icon>
                              <CheckCircle
                                style={{ color: data.validHash ? 'green' : 'red' }}
                                fontSize="small"
                              />
                            </Icon>
                          )}
                          {data.validHash === false && (
                            <Icon>
                              <Error
                                style={{ color: 'red' }}
                                fontSize="small"
                              />
                            </Icon>)
                          }
                        </Grid>
                        <Grid item xs={12}>
                          <Button
                            variant="contained"
                            color="secondary"
                            startIcon={<FeaturedVideo/>}
                            target="_blank"
                            href={`${
                              process.env.REACT_APP_API_URL
                            }/${apiRoutes.pressPassPdf(data.id)}`}
                          >
                            {t('Ausweis Ansehen')}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider/>
                <CardContent>
                  <StyledCardTitle variant="h6" color="secondary" gutterBottom>
                    {t('Historie der Ausweis-Sperrungen')} ({data.pressPassSuspended.length})
                  </StyledCardTitle>
                  <Divider/>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>{t('Ausweisnummer')}</TableCell>
                        <TableCell>{t('Datum')}</TableCell>
                        <TableCell>{t('Erstellt')}</TableCell>
                        <TableCell>{t('Kommentar')}</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {/*sort data.pressPassSuspended by date */}

                      {data.pressPassSuspended.length > 0
                        ? data.pressPassSuspended.sort((a, b) => b.id - a.id).map((pressPassSuspended) => (
                          <>
                            <TableRow key={pressPassSuspended.id}>
                              <CompactTableCell component="th" scope="row">
                                {pressPassSuspended.passNumber}
                              </CompactTableCell>
                              <CompactTableCell>
                                <FormattedDate
                                  date={pressPassSuspended.date}
                                  format={DateFormat.Default}
                                />
                              </CompactTableCell>
                              <TableCell>
                                <small>
                                  <ModifiedEntity entity={pressPassSuspended}/>
                                </small>
                              </TableCell>
                              <TableCell>
                                {pressPassSuspended.comment}
                              </TableCell>
                              <TableCell>
                                <LoadingIconButton
                                  title={t('Eintrag löschen')}
                                  type="button"
                                  size="small"
                                  loading={
                                    deletingPressPassSuspensionMutation.isLoading &&
                                    !!deletingPressPassSuspension.find(
                                      (suspension) => suspension === pressPassSuspended.id
                                    )
                                  }
                                  onClick={() => {
                                    if (
                                      !window.confirm(
                                        t(`Wollen Sie diesen Eintrag mit der Ausweisnummer ${pressPassSuspended.passNumber} wirklich löschen?`)
                                      )
                                    ) {
                                      return;
                                    }
                                    deletingPressPassSuspensionMutation.mutate(pressPassSuspended);
                                  }}
                                >
                                  <Delete/>
                                </LoadingIconButton>
                              </TableCell>
                            </TableRow>
                          </>
                        )) :
                        <>
                          <TableRow>
                            <TableCell colSpan={3}>{t('Keine Einträge gefunden')}</TableCell>
                          </TableRow>
                        </>
                      }
                    </TableBody>
                  </Table>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card>

              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <PressPassMarkHistoryDialog
        open={markDialogOpen}
        onClose={() => setMarkDialogOpen(false)}
        pressPass={data}
      />
    </Container>
  );
};

export default PressPassDetails;
