import { VoidFunctionComponent } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { routes } from '../../lib';
import CreateUser from './create';
import UserDetails from './details';
import EditUser from './edit';
import UserOverview from './overview';

const UserManagement: VoidFunctionComponent = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <UserOverview />
      </Route>
      <Route path={routes.userCreate}>
        <CreateUser />
      </Route>
      <Route path={routes.userEdit(':userId')}>
        <EditUser />
      </Route>
      <Route path={routes.user(':userId')}>
        <UserDetails />
      </Route>
    </Switch>
  );
};

export default UserManagement;
