import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Container,
  Divider, FormControl,
  FormControlLabel, FormLabel,
  Grid, InputAdornment, Radio, RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import React, { VoidFunctionComponent } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import FormLogo from '../../components/form-logo';
import { StyledCardTitle } from '../../components/globals';
import LoadingButton from '../../components/loading-button';
import MultiUpload from '../../components/multi-upload';
import { buildFormData, formatPayloadDate, handleHookFormErrors } from '../../helpers';
import { useTitle } from '../../hooks';
import { useClient } from '../../hooks/use-client';
import { usePressPassApplicationUpdateValidationSchema } from '../../hooks/validation/press-pass-application';
import { apiRoutes, request } from '../../lib';
import Ausweisfoto from '../../images/ausweisfoto.png';
import {
  Client,
  Origin,
  PressPassApplication,
  PressPassApplicationFormValues,
  getPressPassApplicationUpdateFormValues, DateFormat, PressPassApplicationUpdateFormValues, Salutation,
} from '../../model';
import SuccessCard from './success-card';
import { DatePicker } from "@mui/lab";

export const FormPageUpdateForm: VoidFunctionComponent = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const client = useClient();

  useTitle(t('Presseausweis - Aktualisierung'), '');

  const validationSchema = usePressPassApplicationUpdateValidationSchema();

  const {
    control,
    handleSubmit,
    setError,
    formState: { isValid, isSubmitting },
  } = useForm<PressPassApplicationUpdateFormValues>({
    mode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues: getPressPassApplicationUpdateFormValues(
      client === Client.Oezv ? Origin.Oezv : Origin.Voez
    ),
  });

  const submitMutation = useMutation(
    async (values: PressPassApplicationUpdateFormValues) => {
      values.birthdate = formatPayloadDate(values.birthdate) || '';
      return await request<PressPassApplication>(
        apiRoutes.pressPassApplicationUpdate,
        'post',
        buildFormData<PressPassApplicationFormValues>(values, (formData) => {
          values.attachments.forEach((attachment, index) => {
            if (!attachment.file) {
              return;
            }
            formData.append(`attachment_${index}`, attachment.file);
          });
        })
      );
    },
    {
      onSuccess: () => {
        enqueueSnackbar(t('Dateien wurde erfolgreich abgeschickt.'), {
          variant: 'success',
        });
      },
      onError: (err: AxiosError) => {
        enqueueSnackbar(
          err.response?.data.message ||
          t('Antrag konnte nicht erstellt werden'),
          {
            variant: 'error',
          }
        );
        handleHookFormErrors(err, setError);
      },
    }
  );

  return (
    <Container maxWidth="md">
      <Box>
        <FormLogo/>
        <Typography
          variant="h3"
          component="h1"
          textAlign="center"
          marginBottom="1em"
        >
          {t('Presseausweis - Aktualisierung Passbild')}
        </Typography>
      </Box>
      {submitMutation.isSuccess ? (
        <SuccessCard
          text={t<string>('Die Dateien wurden erfolgreich übermittelt.')}
        />
      ) : (
        <form
          onSubmit={handleSubmit((values) =>
            submitMutation.mutateAsync(values)
          )}
        >
          <Card style={{ marginBottom: '4em' }}>
            <CardContent>
              <StyledCardTitle variant="h6" color="secondary" gutterBottom>
                {t('Bestätigen Sie Ihre Daten')}
              </StyledCardTitle>
              <Grid container spacing={3}>
                <Grid item md={12}>

                  <FormControl variant="standard">
                    <FormLabel>{t('Anrede')}</FormLabel>
                    <Controller
                      control={control}
                      name="salutation"
                      render={({ field }) => (
                        <RadioGroup
                          value={field.value}
                          onChange={field.onChange}
                          sx={{ flexDirection: 'row' }}
                        >
                          <FormControlLabel
                            value={Salutation.Female}
                            control={<Radio/>}
                            label={t<string>(
                              `person.salutation.${Salutation.Female}`
                            )}
                          />
                          <FormControlLabel
                            value={Salutation.Male}
                            control={<Radio/>}
                            label={t<string>(
                              `person.salutation.${Salutation.Male}`
                            )}
                          />
                          <FormControlLabel
                            value={Salutation.Other}
                            control={<Radio/>}
                            label={t<string>(
                              `person.salutation.${Salutation.Other}`
                            )}
                          />
                        </RadioGroup>
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    control={control}
                    name={'firstName'}
                    render={({ field, fieldState }) => (
                      <TextField
                        label={t('Vorname')}
                        fullWidth
                        required
                        {...field}
                        error={fieldState.isTouched && fieldState.invalid}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    control={control}
                    name={'lastName'}
                    render={({ field, fieldState }) => (
                      <TextField
                        label={t('Nachname')}
                        fullWidth
                        required
                        {...field}
                        error={fieldState.isTouched && fieldState.invalid}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Controller
                    control={control}
                    name={'passNumber'}
                    render={({ field, fieldState }) => (
                      <TextField
                        label={t('Presseausweis-Nummer')}
                        fullWidth
                        required
                        {...field}
                        error={fieldState.isTouched && fieldState.invalid}
                        helperText={fieldState.error?.message}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">{client === Client.Oezv ? 'ÖZV-' : 'VÖZ-'}</InputAdornment>,
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    control={control}
                    name={'birthdate'}
                    render={({ field, fieldState }) => (
                      <DatePicker
                        value={field.value}
                        inputFormat={DateFormat.Default}
                        mask={DateFormat.Mask}
                        onChange={field.onChange}
                        label={t('Geburtsdatum')}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            variant="standard"
                            fullWidth
                            helperText={fieldState.error?.message}
                            error={fieldState.isTouched && fieldState.invalid}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider/>
            <CardContent>
              <StyledCardTitle variant="h6" color="secondary" gutterBottom>
                {t('Kontakt-Mailadresse')}
              </StyledCardTitle>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Controller
                    control={control}
                    name={'email'}
                    render={({ field, fieldState }) => (
                      <TextField
                        label={t('E-Mail Adresse')}
                        fullWidth
                        {...field}
                        error={fieldState.isTouched && fieldState.invalid}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Controller
                    control={control}
                    name={'comment'}
                    render={({ field, fieldState }) => (
                      <TextField
                        label={t('Anmerkungen')}
                        fullWidth
                        multiline={true}
                        {...field}
                        error={fieldState.isTouched && fieldState.invalid}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider/>
            <CardContent>
              <StyledCardTitle variant="h6" color="secondary" gutterBottom>
                {t('Foto hinzufügen')}
              </StyledCardTitle>
              <Typography variant="body2" gutterBottom>
                Bitte übermitteln Sie ein aktuelles Passfoto. Anforderungen an das Bild: <br/>
                - Format 35 mm breit/45 mm hoch bzw. Seitenverhältnis 7:9<br/>
                - nicht älter als sechs Monate <br/>
                - Porträtbild (der Kopf muss etwa 2/3 des Bildes einnehmen)<br/>
                - Mindestbreite des Bildausschnittes ~600px
                <br/>
                <br/>
              </Typography>

              <Grid container spacing={3}>
                <Grid container spacing={3}>
                  <Grid item style={{ display: 'none' }}>
                    <Controller
                      control={control}
                      name="membership"
                      render={({ field }) => (
                        <TextField type="hidden" value={field.value}/>
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <img src={Ausweisfoto} alt="Ausweisfoto" width="100%"/>
                </Grid>
                <Grid item xs={6}>
                  <MultiUpload
                    control={control}
                    textAlign="center"
                    allowedTypes={[
                      'image/png',
                      'image/jpeg',
                    ]}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider/>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="confirmationPrivacy"
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={field.value}
                            onChange={field.onChange}
                            color="primary"
                          />
                        }
                        label={t<string>(
                          'Sollten Sie die Daten für sich selbst eingeben, ' +
                          'erteilen Sie hiermit die Einwillung zur Verarbeitung der Daten im Sinne der DSGVO zu. ' +
                          'Sollten Sie für eine andere Person Daten eingegeben haben, ' +
                          'versichern Sie im Auftrag der Person zu agieren und die entsprechende Einwilligung eingeholt zu haben.'
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="confirmationMoral"
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={field.value}
                            onChange={field.onChange}
                            color="primary"
                          />
                        }
                        label={t<string>(
                          'Ich bestätige alle Angaben nach bestem Wissen und Gewissen getroffen zu haben.'
                        )}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <LoadingButton
                type="submit"
                size="medium"
                color="primary"
                disabled={!isValid}
                variant="contained"
                loading={isSubmitting}
              >
                {t('Antrag jetzt abschicken')}
              </LoadingButton>
            </CardActions>
          </Card>
        </form>
      )}
    </Container>
  );
};

export default FormPageUpdateForm;
