import { yupResolver } from '@hookform/resolvers/yup';
import { Add } from '@mui/icons-material';
import { Grid, Tooltip } from '@mui/material';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import React, { VoidFunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { formatPayloadDate, handleHookFormErrors } from '../../helpers';
import { useLicensePressReviewValidationSchema } from '../../hooks/validation/licensee';
import { apiRoutes, request } from '../../lib';
import {
  LicensePressReviewFormValues,
  Licensee,
  getLicensePressReviewFormValues,
} from '../../model';
import { StyledCardTitle } from '../globals';
import LoadingIconButton from '../loading-icon-button';
import { PressReviewFormFields } from './press-review-form-fields';

export const LicenseePressReviewForm: VoidFunctionComponent<{
  licensee: Licensee;
}> = ({ licensee }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const validationSchema = useLicensePressReviewValidationSchema();

  const {
    handleSubmit,
    setError,
    control,
    formState: { isSubmitting, isValid },
    reset,
  } = useForm<LicensePressReviewFormValues>({
    mode: 'all',
    defaultValues: getLicensePressReviewFormValues(),
    resolver: yupResolver(validationSchema),
  });

  const submitMutation = useMutation(
    async (values: LicensePressReviewFormValues) => {
      if (!values.from || !values.to) {
        throw new Error(t('Pressespiegel konnte nicht hinzugefügt werden'));
      }
      values.from = formatPayloadDate(values.from) as string;
      values.to = formatPayloadDate(values.to) as string;
      values.licenseeId = licensee.id;
      return await request<Licensee>(
        apiRoutes.licenseePressReviewCreate,
        'post',
        values
      );
    },
    {
      onSuccess: async () => {
        reset(getLicensePressReviewFormValues());
        await queryClient.invalidateQueries(apiRoutes.licensee(licensee.id));
        enqueueSnackbar(t('Pressespiegel wurde erfolgreich hinzugefügt.'), {
          variant: 'success',
        });
      },
      onError: (err: AxiosError) => {
        enqueueSnackbar(
          err.response?.data.message ||
            t('Pressespiegel konnte nicht hinzugefügt werden'),
          {
            variant: 'error',
          }
        );
        handleHookFormErrors(err, setError);
      },
    }
  );

  return (
    <form
      onSubmit={handleSubmit((values) => submitMutation.mutateAsync(values))}
    >
      <StyledCardTitle variant="h6" color="secondary" gutterBottom>
        {t('Pressespiegel hinzufügen')}
      </StyledCardTitle>
      <Grid
        container
        spacing={1}
        style={{ width: '100%' }}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <PressReviewFormFields control={control} inline={true} />
        <Grid item textAlign="right">
          <Tooltip title={t<string>('Pressespiegel hinzufügen')}>
            <span>
              <LoadingIconButton
                type="submit"
                color="primary"
                size="small"
                loading={isSubmitting}
                disabled={!isValid}
              >
                <Add />
              </LoadingIconButton>
            </span>
          </Tooltip>
        </Grid>
      </Grid>
    </form>
  );
};
