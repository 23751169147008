import {
  Check,
  Error,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from '@mui/icons-material';
import {
  Box,
  Chip,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import React, { Fragment, FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DateFormat, ID, MailingDelivery } from '../../model';
import FormattedDate from '../formatted-date';
import { SpanBlock } from '../globals';

export const MailingDeliveryList: FunctionComponent<{
  deliveries: MailingDelivery[];
}> = ({ deliveries }) => {
  const { t } = useTranslation();
  const [openRows, setOpenRows] = useState<ID[]>([]);

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: '50px' }}></TableCell>
            <TableCell>{t('Gesendet am')}</TableCell>
            <TableCell>{t('Gesendet von')}</TableCell>
            <TableCell>{t('Details')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {deliveries.map((delivery) => (
            <Fragment key={delivery.id}>
              <TableRow
                key={delivery.id}
                sx={{ '& > *': { borderBottom: 'unset' } }}
              >
                <TableCell>
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => {
                      if (openRows.includes(delivery.id)) {
                        return setOpenRows(
                          openRows.filter((id) => id !== delivery.id)
                        );
                      }
                      setOpenRows([...openRows, delivery.id]);
                    }}
                  >
                    {openRows.includes(delivery.id) ? (
                      <KeyboardArrowUp />
                    ) : (
                      <KeyboardArrowDown />
                    )}
                  </IconButton>
                </TableCell>
                <TableCell>
                  <FormattedDate
                    date={delivery.createdAt}
                    format={DateFormat.DateTime}
                  />
                </TableCell>
                <TableCell>{delivery.createdBy}</TableCell>
                <TableCell>
                  {delivery.test && (
                    <Chip color="info" label={t('Testversand')} size="small" />
                  )}
                  <SpanBlock>
                    {delivery.stats.success} {t('Erfolgreich')}
                  </SpanBlock>
                  <SpanBlock>
                    {delivery.stats.error} {t('Fehlerhaft')}
                  </SpanBlock>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  colSpan={4}
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                >
                  <Collapse
                    in={openRows.includes(delivery.id)}
                    timeout="auto"
                    unmountOnExit
                  >
                    {delivery.stats.recipients &&
                    delivery.stats.recipients.length > 0 ? (
                      <TableContainer sx={{ maxHeight: 400, mb: 2 }}>
                        <Table size="small" stickyHeader>
                          <TableHead>
                            <TableRow>
                              <TableCell>{t('Empfänger')}</TableCell>
                              <TableCell>{t('Status')}</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {delivery.stats.recipients.map((recipient) => (
                              <TableRow hover key={recipient.recipient}>
                                <TableCell>{recipient.recipient}</TableCell>
                                <TableCell>
                                  {recipient.status === 'success' ? (
                                    <Tooltip
                                      title={t<string>('Erfolgreich gesendet')}
                                    >
                                      <Check />
                                    </Tooltip>
                                  ) : (
                                    <Tooltip
                                      title={t<string>('Fehlgeschlagen')}
                                    >
                                      <Error />
                                    </Tooltip>
                                  )}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    ) : (
                      <Box sx={{ mb: 2 }}>
                        {t('Empfänger nicht gespeichert.')}
                      </Box>
                    )}
                  </Collapse>
                </TableCell>
              </TableRow>
            </Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
