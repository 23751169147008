import { FunctionComponent } from 'react';
import PaginationContext, { PaginationContextValue } from './context';

export const PaginationProvider: FunctionComponent<{
  context: PaginationContextValue<any, any>;
}> = ({ context, children }) => {
  return (
    <PaginationContext.Provider value={context}>
      {children}
    </PaginationContext.Provider>
  );
};
