import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import React, { VoidFunctionComponent } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { getYearOptions, handleHookFormErrors } from '../../helpers';
import { apiRoutes, request } from '../../lib';
import { ID, PressPass } from '../../model';

interface MarkFormValues {
  badge: { year: number };
  pressPassIds: ID[];
}

const PressPassMarkDialog: VoidFunctionComponent<{
  open: boolean;
  onClose: () => void;
  selected: ID[];
}> = ({ open, onClose, selected }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isValid },
    setError,
  } = useForm<MarkFormValues>({
    mode: 'all',
    defaultValues: {
      badge: { year: new Date().getFullYear() },
      pressPassIds: selected,
    },
  });

  const submitMutation = useMutation(
    async (values: MarkFormValues) => {
      values.pressPassIds = selected;
      return await request<PressPass[]>(
        apiRoutes.pressPassMarks,
        'put',
        values
      );
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(apiRoutes.pressPasses);
        enqueueSnackbar(t('Marken wurde erfolgreich verlängert.'), {
          variant: 'success',
        });
        onClose();
      },
      onError: (err: AxiosError) => {
        enqueueSnackbar(
          err.response?.data.message || t('Leider ist etwas schief gelaufen.'),
          { variant: 'error' }
        );
        handleHookFormErrors(err, setError);
      },
    }
  );

  return (
    <Dialog open={open} onClose={() => onClose()}>
      <form
        onSubmit={handleSubmit((values) => submitMutation.mutateAsync(values))}
      >
        <DialogTitle>{t('Marke verlängern')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t(`Ausweis Marken für {{count}} Personen verlängern`, {
              count: selected.length,
            })}
          </DialogContentText>
          <FormControl fullWidth style={{ marginTop: '1em' }}>
            <InputLabel>{t('Marke')}</InputLabel>
            <Controller
              control={control}
              name="badge.year"
              render={({ field }) => (
                <Select {...field}>
                  {getYearOptions().map((year, index) => (
                    <MenuItem key={index} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose()} disabled={isSubmitting}>
            {t('Abbrechen')}
          </Button>
          <LoadingButton
            type="submit"
            autoFocus
            variant="contained"
            loading={submitMutation.isLoading}
            disabled={!isValid || isSubmitting}
          >
            {t('Verlängern')}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default PressPassMarkDialog;
