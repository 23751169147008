import { Add } from '@mui/icons-material';
import { Button, MenuItem, TextField } from '@mui/material';
import React, { VoidFunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FilterSelect } from '../../components/filter';
import {
  FilterFormControl,
  StyledContainer,
  StyledFilterFormControl,
} from '../../components/globals';
import Guarded from '../../components/guarded';
import Header from '../../components/header';
import PaginatedAutocomplete from '../../components/paginated-autocomplete';
import { PaginationCard } from '../../components/pagination';
import { PersonList } from '../../components/person';
import { config } from '../../config';
import {
  UseQueryStateOptions,
  useDebounceState,
  useFilteredPaginationApi,
  useQueryState,
  useTitle,
} from '../../hooks';
import { apiRoutes, routes } from '../../lib';
import {
  Company,
  CompanyFilters,
  Person,
  PersonFilters,
  Role,
} from '../../model';

const PersonOverview: VoidFunctionComponent = () => {
  const { t } = useTranslation();
  useTitle(t('Personen'));

  const breadcrumbs = [
    { label: t('Home'), link: routes.dashboard },
    { label: t('Personen') },
  ];

  const queryStateOptions: UseQueryStateOptions = { action: 'replace' };
  const [search, setFilterSearch] = useQueryState(
    '',
    'search',
    queryStateOptions
  );
  const [inputSearch, setInputSearch] = useDebounceState(
    search,
    setFilterSearch
  );

  const [company, setFilterCompany] = useQueryState(
    '',
    'company',
    queryStateOptions
  );
  const [inputCompany, setInputCompany] = useDebounceState(
    company,
    setFilterCompany
  );

  const [status, setFilterStatus] = useQueryState(
    '',
    'status',
    queryStateOptions
  );

  const { context } = useFilteredPaginationApi<PersonFilters, Person>(
    apiRoutes.persons,
    { search, company, status },
    'lastName',
    'asc',
    config.pageSize
  );

  const { data: companies } = useFilteredPaginationApi<CompanyFilters, Company>(
    apiRoutes.companies,
    { id: +inputCompany },
    'name',
    'desc',
    1
  );

  return (
    <StyledContainer data-test="persons-content">
      <Header
        title={t('Personen')}
        breadcrumbs={breadcrumbs}
        actions={
          <Guarded requiredRole={Role.General}>
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to={routes.personCreate}
              startIcon={<Add />}
            >
              {t('Neue Person')}
            </Button>
          </Guarded>
        }
      />
      <PaginationCard
        context={context}
        hasFilters={!!(search || company || status)}
        resetFilters={() => {
          setFilterSearch('');
          setFilterCompany('');
          setFilterStatus('');
        }}
        filters={
          <>
            <FilterFormControl>
              <TextField
                label={t('Suche')}
                variant="outlined"
                placeholder={t('zB: Name, E-Mail')}
                value={inputSearch}
                onChange={(e) => setInputSearch(e.target.value)}
                size="small"
              />
            </FilterFormControl>
            <StyledFilterFormControl>
              <PaginatedAutocomplete
                name={'company'}
                label={t('Unternehmen')}
                value={company}
                onChange={(ids) =>
                  ids.length === 0
                    ? setInputCompany('')
                    : setInputCompany(ids[0].toString())
                }
                multiple={false}
                optionLabel={(company) =>
                  `${company.name} ${company.term || ''} (${company.id})`
                }
                dataUrl={apiRoutes.companies}
                initialData={companies?.results}
                textFieldProps={{
                  variant: 'outlined',
                  size: 'small',
                }}
              />
            </StyledFilterFormControl>
            <FilterSelect
              label={t('Status')}
              value={status}
              onChange={setFilterStatus}
            >
              <MenuItem value="">
                <em>{t('Alle')}</em>
              </MenuItem>
              <MenuItem value="enabled">{t('Aktiv')}</MenuItem>
              <MenuItem value="disabled">{t('Inaktiv')}</MenuItem>
            </FilterSelect>
          </>
        }
      >
        <PersonList />
      </PaginationCard>
    </StyledContainer>
  );
};

export default PersonOverview;
