import { useMemo } from 'react';
import * as yup from 'yup';

export const useMailingListValidation = () => {
  return useMemo(
    () => ({
      name: yup.string().required('Name darf nicht leer sein.'),
      comment: yup.string().nullable(),
    }),
    []
  );
};

export const useMailingListValidationSchema = () => {
  const mailingListValidation = useMailingListValidation();

  return useMemo(() => {
    return yup.object({
      ...mailingListValidation,
    });
  }, [mailingListValidation]);
};
