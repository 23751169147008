import { yupResolver } from '@hookform/resolvers/yup';
import { Card, CardActions, Container, Divider } from '@mui/material';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router';
import Header from '../../components/header';
import LoadingButton from '../../components/loading-button';
import { SignatureFormFields } from '../../components/signature';
import { handleHookFormErrors } from '../../helpers';
import { useSignatureValidationSchema, useTitle } from '../../hooks';
import { apiRoutes, request, routes } from '../../lib';
import {
  Signature,
  SignatureFormValues,
  getSignatureFormValues,
} from '../../model';

const CreateSignature = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  useTitle(t('Neue Signatur'));

  const validationSchema = useSignatureValidationSchema();

  const {
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting },
  } = useForm<SignatureFormValues>({
    mode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues: getSignatureFormValues(),
  });

  const detailBreadcrumbs = [
    { label: t('Home'), link: routes.dashboard },
    { label: t('Signaturen'), link: routes.signatures },
  ];

  const mutation = useMutation(
    async (values: SignatureFormValues) =>
      await request<Signature>(apiRoutes.signatureCreate, 'post', values),
    {
      onSuccess: (res) => {
        enqueueSnackbar(t('Signatur wurde erfolgreich erstellt.'), {
          variant: 'success',
        });
        history.push(routes.signature(res.data.id));
      },
      onError: (err: AxiosError) => {
        enqueueSnackbar(err.response?.data.message || 'Error', {
          variant: 'error',
        });
        handleHookFormErrors(err, setError);
      },
    }
  );

  return (
    <Container maxWidth="md">
      <Header title={t('Neu')} breadcrumbs={detailBreadcrumbs} />
      <form
        onSubmit={handleSubmit((values) => mutation.mutateAsync(values))}
        noValidate
      >
        <Card>
          <SignatureFormFields control={control} />
          <Divider />
          <CardActions>
            <LoadingButton
              type="submit"
              size="medium"
              color="primary"
              variant="contained"
              loading={isSubmitting}
            >
              {t('Speichern')}
            </LoadingButton>
          </CardActions>
        </Card>
      </form>
    </Container>
  );
};

export default CreateSignature;
