import { Add } from '@mui/icons-material';
import { Button } from '@mui/material';
import React, { VoidFunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FilterInputSearch, FilterSwitch } from '../../components/filter';
import { StyledContainer } from '../../components/globals';
import Header from '../../components/header';
import { MailingListList } from '../../components/mailing-list';
import { PaginationCard } from '../../components/pagination';
import { config } from '../../config';
import {
  UseQueryStateOptions,
  useDebounceState,
  useFilteredPaginationApi,
  useQueryState,
  useTitle,
} from '../../hooks';
import { apiRoutes, routes } from '../../lib';
import { MailingList, MailingListFilters } from '../../model';

const MailingListOverview: VoidFunctionComponent = () => {
  const { t } = useTranslation();
  useTitle(t('Verteiler'));

  const breadcrumbs = [
    { label: t('Home'), link: routes.dashboard },
    { label: t('Verteiler') },
  ];

  const queryStateOptions: UseQueryStateOptions = { action: 'replace' };
  const [search, setFilterSearch] = useQueryState(
    '',
    'search',
    queryStateOptions
  );
  const [inputSearch, setInputSearch] = useDebounceState(
    search,
    setFilterSearch
  );
  const [enabled, setFilterEnabled] = useQueryState<boolean>(
    false,
    'enabled',
    queryStateOptions
  );

  const { context } = useFilteredPaginationApi<MailingListFilters, MailingList>(
    apiRoutes.mailingLists,
    { search, enabled },
    'name',
    'asc',
    config.pageSize
  );

  return (
    <StyledContainer data-test="mailing-list-content">
      <Header
        title={t('Verteiler')}
        breadcrumbs={breadcrumbs}
        actions={
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to={routes.mailingListCreate}
            startIcon={<Add />}
          >
            {t('Neuer Verteiler')}
          </Button>
        }
      />
      <PaginationCard
        context={context}
        hasFilters={!!search || enabled}
        resetFilters={() => {
          setFilterSearch('');
          setFilterEnabled(false);
        }}
        filters={
          <>
            <FilterInputSearch
              placeholder={t('zB: Name')}
              value={inputSearch}
              onChange={setInputSearch}
              label={t('Suche')}
            />
            <FilterSwitch
              value={enabled}
              onChange={setFilterEnabled}
              color="primary"
              label={t<string>('Aktiv')}
            />
          </>
        }
      >
        <MailingListList />
      </PaginationCard>
    </StyledContainer>
  );
};

export default MailingListOverview;
