import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import {format, isValid} from 'date-fns';
import {VoidFunctionComponent} from 'react';
import {WorksheetData} from '../../model/import';

const WorksheetPreview: VoidFunctionComponent<{
  worksheetData: WorksheetData;
}> = ({worksheetData}) => {
  return (
    <TableContainer sx={{maxHeight: 400}}>
      <Table size="small" stickyHeader={true}>
        <TableHead>
          <TableRow>
            <TableCell/>
            {worksheetData.schema.map((column, index) => (
              <TableCell key={index}>{column}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {worksheetData.rows.map((row, index) => (
            <TableRow
              key={index}
              sx={{
                bgcolor:
                  Object.keys(row.violations).length > 0 ? 'grey.200' : '',
              }}
            >
              <TableCell sx={{textAlign: 'center'}}>{row.row}</TableCell>
              {row.data.map((cell, index) => (
                <TableCell
                  key={index}
                  sx={{borderLeft: 1, borderColor: 'grey.300'}}
                >
                  {index === 2 ? (
                    <>
                      <>
                        {isValid(new Date(cell)) ? format(new Date(cell), 'dd.MM.yyyy') : ''}
                      </>
                    </>
                  ) : (
                    <>{cell}</>
                  )}
                  {row.violations.find((r) => r.column === index) && (
                    <Box>
                      {row.violations
                        .filter((r) => r.column === index)
                        .map((violation, index) => (
                          <Box
                            key={index}
                            sx={{
                              fontWeight: 'bold',
                              color: 'error.main',
                            }}
                          >
                            {violation.message}
                          </Box>
                        ))}
                    </Box>
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default WorksheetPreview;
