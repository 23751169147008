import { Add } from '@mui/icons-material';
import { Button, MenuItem } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { CompanyList } from '../../components/company';
import {
  FilterInputSearch,
  FilterSelect,
  getWidth,
} from '../../components/filter';
import { FilterFormControl, StyledContainer } from '../../components/globals';
import Guarded from '../../components/guarded';
import Header from '../../components/header';
import PaginatedAutocomplete from '../../components/paginated-autocomplete';
import { PaginationCard } from '../../components/pagination';
import { config } from '../../config';
import {
  UseQueryStateOptions,
  useDebounceState,
  useFilteredPaginationApi,
  useQueryState,
  useTitle,
} from '../../hooks';
import { apiRoutes, routes } from '../../lib';
import {
  Company,
  CompanyFilters,
  Medium,
  MediumFilters,
  Role,
  States,
} from '../../model';

const CompanyOverview = () => {
  const { t } = useTranslation();
  useTitle(t('Unternehmen'));

  const breadcrumbs = [
    { label: t('Home'), link: routes.dashboard },
    { label: t('Unternehmen') },
  ];

  const queryStateOptions: UseQueryStateOptions = { action: 'replace' };
  const [search, setFilterSearch] = useQueryState(
    '',
    'search',
    queryStateOptions
  );
  const [inputSearch, setInputSearch] = useDebounceState(
    search,
    setFilterSearch
  );
  const [type, setType] = useQueryState('', 'companyType', queryStateOptions);
  const [state, setState] = useQueryState('', 'state', queryStateOptions);

  const [medium, setMedium] = useQueryState('', 'medium', queryStateOptions);
  const [, setInputMedium] = useDebounceState(medium, setMedium);

  const {
    data: media,
    isFetching: mediaIsFetching,
    isLoading: mediaIsLoading,
  } = useFilteredPaginationApi<MediumFilters, Medium>(
    apiRoutes.media,
    { id: +medium },
    'name',
    'desc',
    1
  );

  const { context } = useFilteredPaginationApi<CompanyFilters, Company>(
    apiRoutes.companies,
    { search, type, medium, state },
    'name',
    'asc',
    config.pageSize
  );

  return (
    <StyledContainer data-test="companies-content">
      <Header
        title={t('Unternehmen')}
        breadcrumbs={breadcrumbs}
        actions={
          <Guarded requiredRole={Role.General}>
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to={routes.companyCreate}
              startIcon={<Add />}
            >
              {t('Neues Unternehmen')}
            </Button>
          </Guarded>
        }
      />
      <PaginationCard
        context={context}
        hasFilters={
          search !== '' || type !== '' || state !== '' || medium !== ''
        }
        resetFilters={() => {
          setFilterSearch('');
          setType('');
          setState('');
          setMedium('');
        }}
        filters={
          <>
            <FilterInputSearch value={inputSearch} onChange={setInputSearch} />
            {/*<FilterSelect label={t('Typ')} value={type} onChange={setType}>*/}
            {/*  <MenuItem value="">{t('Alle')}</MenuItem>*/}
            {/*  {Object.keys(CompanyType).map((type, index) => (*/}
            {/*    <MenuItem*/}
            {/*      value={CompanyType[type as keyof typeof CompanyType]}*/}
            {/*      key={index}*/}
            {/*    >*/}
            {/*      {t(`company.type.${type.toLowerCase()}`)}*/}
            {/*    </MenuItem>*/}
            {/*  ))}*/}
            {/*</FilterSelect>*/}
            <FilterSelect
              label={t('Bundesland')}
              value={state}
              onChange={setState}
            >
              <MenuItem value="">{t('Alle')}</MenuItem>
              {States.map((state, key) => (
                <MenuItem key={key} value={state.toLowerCase()}>
                  {state}
                </MenuItem>
              ))}
            </FilterSelect>
            <FilterFormControl style={{ width: getWidth('large') }}>
              <PaginatedAutocomplete
                name="media"
                label={t('Medien')}
                value={medium}
                onChange={(ids) =>
                  ids.length === 0
                    ? setInputMedium('')
                    : setInputMedium(ids[0].toString())
                }
                initialData={media?.results}
                multiple={false}
                loading={mediaIsLoading || mediaIsFetching}
                optionLabel="name"
                dataUrl={apiRoutes.media}
                textFieldProps={{
                  variant: 'outlined',
                  size: 'small',
                }}
              />
            </FilterFormControl>
          </>
        }
      >
        <CompanyList />
      </PaginationCard>
    </StyledContainer>
  );
};

export default CompanyOverview;
