import { format } from 'date-fns';
import { Dict, Nullable, Option, Role, User } from '../model';

export const isUndefinedOrNull = (v: unknown): boolean =>
  v === undefined || v === null;

export const isSame = (var1: unknown, var2: unknown): boolean => {
  if (var1 instanceof Date && var2 instanceof Date) {
    return var1.getTime() === var2.getTime();
  }
  return (
    var1 === var2 ||
    (!isUndefinedOrNull(var1) &&
      !isUndefinedOrNull(var2) &&
      typeof var1 === 'string' &&
      typeof var2 === 'string' &&
      var1.toString() === var2.toString())
  );
};

export const shallowEqual = <T extends { [id: string]: any }>(
  a: T,
  b: T
): boolean => {
  const keys = Object.keys(a);

  if (keys.length !== Object.keys(b).length) {
    return false;
  }

  for (const key of keys) {
    if (!isSame(a[key], b[key])) {
      return false;
    }
  }
  return true;
};

export function numberEnumLabels<
  T extends string,
  TEnumValue extends number | string
>(enumVariable: { [key in T]: TEnumValue }): string[] {
  return Object.keys(enumVariable).filter(
    (key) => !isNaN(Number((enumVariable as any)[key]))
  );
}

export function numberEnumValues<
  T extends string,
  TEnumValue extends number | string
>(enumVariable: { [key in T]: TEnumValue }): number[] {
  return numberEnumLabels(enumVariable).map(
    (key) => (enumVariable as any)[key]
  );
}

export function numberEnumLabelValues<
  T extends string,
  TEnumValue extends number | string
>(enumVariable: { [key in T]: TEnumValue }): [string, number][] {
  return numberEnumLabels(enumVariable).map((key) => [
    key,
    (enumVariable as any)[key],
  ]);
}

export function stringEnumLabels<
  T extends string,
  TEnumValue extends number | string
>(enumVariable: { [key in T]: TEnumValue }): string[] {
  return Object.keys(enumVariable);
}

export function stringEnumValues<
  T extends string,
  TEnumValue extends number | string
>(enumVariable: { [key in T]: TEnumValue }): number[] {
  return stringEnumLabels(enumVariable).map(
    (key) => (enumVariable as any)[key]
  );
}

export function stringEnumLabelValues<
  T extends string,
  TEnumValue extends number | string
>(enumVariable: { [key in T]: TEnumValue }): [string, number][] {
  return stringEnumLabels(enumVariable).map((key) => [
    key,
    (enumVariable as any)[key],
  ]);
}

export function hasRole(user: User, requiredRole: Role): boolean {
  return (user.inheritedRoles || []).includes(requiredRole);
}

let uniqueIdCounter = 0;

export function uniqueId(prefix = ''): string {
  const id = ++uniqueIdCounter;
  return prefix + id;
}

export function createOptionsFromEntities<TEntity extends Dict>(
  entities: TEntity[],
  labelKey: keyof TEntity | ((entity: TEntity) => string),
  valueKey: keyof TEntity
): Option[] {
  return entities
    .filter((e) => !!e)
    .map((entity) => ({
      label:
        typeof labelKey === 'function'
          ? labelKey(entity)
          : (entity[labelKey] as unknown as string),
      value: entity[valueKey] as unknown as string,
    }));
}

export async function asyncForEach<T>(
  array: T[],
  callback: (item: T, index: number, array: T[]) => void
) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

export const getYearOptions = () => {
  const year = new Date().getFullYear();
  const years = [];

  for (let i = year + 1; i >= 2010; i--) {
    years.push(i);
  }
  return years;
};

export const formatPayloadDate = (value: Nullable<string>) =>
  value ? format(new Date(value), 'yyyy-MM-dd HH:mm:ss') : null;
