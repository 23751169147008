import { Dispatch, SetStateAction, createContext } from 'react';
import { Nullable, User } from '../model';

const AppContext = createContext<{
  materialTheme: 'light' | 'dark';
  setMaterialTheme: Dispatch<SetStateAction<'light' | 'dark'>>;
  locationTitle: string;
  setLocationTitle: Dispatch<SetStateAction<string>>;
  user: Nullable<User>;
  setUser: Dispatch<SetStateAction<Nullable<User>>>;
  documentTitle: string;
}>({
  materialTheme: 'light',
  setMaterialTheme: () => undefined,
  locationTitle: '',
  setLocationTitle: () => '',
  user: null,
  setUser: () => '',
  documentTitle: '',
});

export default AppContext;
