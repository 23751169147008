import {
  AttachFile,
  Check,
  Clear,
  CopyAll, Crop, Delete, FactCheck,
  RestartAlt, Update,
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Avatar,
  Button,
  Card,
  CardContent,
  Checkbox,
  Chip,
  Container,
  Divider,
  FormControlLabel,
  Grid, IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText, Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { Link as RouterLink, useParams } from 'react-router-dom';
import Filesize from '../../components/filesize';
import FormattedDate from '../../components/formatted-date';
import { SpanBlock, StyledCardTitle } from '../../components/globals';
import { GridData } from '../../components/grid-data';
import Header from '../../components/header';
import HttpError from '../../components/http-error';
import LoadingContainer from '../../components/loading-container';
import { ModifiedEntity } from '../../components/modified-entity';
import { config } from '../../config';
import { useTitle } from '../../hooks';
import {
  apiRoutes,
  request,
  routes,
  usePressPassApplicationUpdateDetailsApi,
} from '../../lib';
import {
  Attachment,
  ID,
  Origin,
  PressPassApplicationStatus, PressPassStatus,
} from '../../model';
import LoadingIconButton from "../../components/loading-icon-button";
import PressPassApplicationCropImageDialog from "../../components/press-pass-application/crop-image-dialog";

function calculateImageRatioText(attachment: Attachment) {
  if (!attachment.dimensions) {
    return null;
  }
  const ratio = (attachment.dimensions.width / attachment.dimensions.height);

  return `${attachment.dimensions.width} x ${attachment.dimensions.height} (${((9 * ratio).toFixed(2))} : ${(7 * (1 / ratio)).toFixed(2)})`;
}

function calculateImageRatio(attachment: Attachment) {
  if (!attachment.dimensions) {
    return null;
  }
  const ratio = (attachment.dimensions.width / attachment.dimensions.height);
  const width = Math.round(9 * ratio * 100) / 100;
  const height = Math.round(100 * (7 * (1 / ratio)) / 100);
  if (width === 7 && height === 9) {
    return (
      <Chip color={'success'} label={'Seitenverhältnis OK'} size={'small'}/>
    );
  } else {
    return (
      <Chip color={'error'} label={'Seitenverhältnis nicht OK'} size={'small'}/>
    );
  }
}

function copyToClipboard(text: string) {
  if (navigator.clipboard) {
    return navigator.clipboard.writeText(text);
  }
  const textArea = document.createElement('textarea');
  textArea.value = text;
  textArea.style.position = 'fixed';
  textArea.style.left = '-999999px';
  textArea.style.top = '-999999px';
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  return new Promise((resolve, reject) =>
    document.execCommand('copy') ? resolve(text) : reject()
  ).then(() => textArea.remove());
}

const PressPassUpdateApplicationsDetails = () => {
  const { t } = useTranslation();
  const { pressPassApplicationId } =
    useParams<{ pressPassApplicationId: string | undefined }>();
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, error, data } = usePressPassApplicationUpdateDetailsApi(
    pressPassApplicationId as unknown as number
  );
  const queryClient = useQueryClient();
  const [deletingAttachments, setDeletingAttachments] = useState<ID[]>([]);
  const [updatingAttachments, setUpdatingAttachments] = useState<ID[]>([]);
  const [markDialogOpen, setMarkDialogOpen] = useState(false);

  useTitle(t('Aktualisierungsantrag Details'));

  if (error) {
    return (
      <HttpError
        error={error}
        actions={
          <Button component={RouterLink} to={routes.pressPassApplications}>
            {t('Zurück zu Anträgen')}
          </Button>
        }
      />
    );
  }

  const statusMutation = useMutation(
    async (value: PressPassApplicationStatus) => {
      if (!data) {
        return;
      }
      return await request(
        apiRoutes.pressPassApplicationStatus(data.id),
        'put',
        {
          status: value,
        }
      );
    },
    {
      onSuccess: async () => {
        if (!data) {
          return;
        }
        await queryClient.invalidateQueries(
          [apiRoutes.pressPassApplicationPress(data.id)]
        );
        enqueueSnackbar(t('Antrag wurde erfolgreich aktualisiert.'), {
          variant: 'success',
        });
      },
      onError: () => {
        enqueueSnackbar(t('Antrag konnte nicht aktualisiert werden.'), {
          variant: 'error',
        });
      },
    }
  );

  const pressPassStatusMutation = useMutation(
    async (value: PressPassStatus) => {
      if (!data) {
        return;
      }
      return await request(
        apiRoutes.pressPassStatus(data.pressPass.id),
        'put',
        {
          status: value,
        }
      );
    },
    {
      onSuccess: async () => {
        if (!data) {
          return;
        }
        await queryClient.invalidateQueries(
          apiRoutes.pressPassStatus(data.pressPass.id)
        );
        enqueueSnackbar(t('Status wurde erfolgreich aktualisiert.'), {
          variant: 'success',
        });
      },
      onError: () => {
        enqueueSnackbar(t('Status konnte nicht  aktualisiert werden.'), {
          variant: 'error',
        });
      },
    }
  );

  const updateAttachmentMutation = useMutation(
    async (attachment: Attachment) => {
      setUpdatingAttachments([...updatingAttachments, attachment.id]);
      return await request<Attachment>(
        apiRoutes.pressPassApplicationAttachmentUpdate(
          pressPassApplicationId as unknown as number,
          attachment.id
        ),
        'put',
      );
    },
    {
      onSuccess: async () => {
        if (!data) {
          return;
        }
        await queryClient.invalidateQueries(
          apiRoutes.pressPassApplication(data.id)
        );
        enqueueSnackbar(t('Anhang wurde erfolgreich übernommen.'), {
          variant: 'success',
        });
      },
      onError: () => {
        enqueueSnackbar(t('Anhang konnte nicht übernommen werden.'), {
          variant: 'error',
        });
      },
      onSettled: (res) => {
        if (!res) {
          return;
        }
        setUpdatingAttachments([
          ...updatingAttachments.filter(
            (attachment) => attachment !== res.data.id
          ),
        ]);
      },
    }
  );

  const deleteAttachmentMutation = useMutation(
    async (attachment: Attachment) => {
      setDeletingAttachments([...deletingAttachments, attachment.id]);
      return await request<Attachment>(
        apiRoutes.pressPassApplicationAttachmentDelete(
          pressPassApplicationId as unknown as number,
          attachment.id
        ),
        'delete'
      );
    },
    {
      onSuccess: async () => {
        if (!data) {
          return;
        }
        await queryClient.invalidateQueries(
          apiRoutes.pressPassApplication(data.id)
        );
        enqueueSnackbar(t('Anhang wurde erfolgreich gelöscht.'), {
          variant: 'success',
        });
      },
      onError: () => {
        enqueueSnackbar(t('Anhang konnte nicht gelöscht werden.'), {
          variant: 'error',
        });
      },
      onSettled: (res) => {
        if (!res) {
          return;
        }
        setDeletingAttachments([
          ...deletingAttachments.filter(
            (attachment) => attachment !== res.data.id
          ),
        ]);
      },
    }
  );

  if (isLoading || !data) {
    return <LoadingContainer/>;
  }

  const detailBreadcrumbs = [
    { label: t('Home'), link: routes.dashboard },
    { label: t('Anträge - Aktualisierung'), link: routes.pressPassUpdateApplications },
  ];

  return (
    <Container maxWidth="xl">
      <Header
        title={
          <>
            {(data.salutation
                ? t(`person.salutation.${data.salutation}`) + ' '
                : '') +
              data.firstName +
              ' ' +
              data.lastName}
            {data.status === PressPassApplicationStatus.New && (
              <Chip label={t('Neu')} size="small" color="primary"/>
            )}
          </>
        }
        search={{ route: routes.pressPassApplications, param: 'search' }}
        breadcrumbs={detailBreadcrumbs}
        actions={
          <>
            <Button
              color="secondary"
              onClick={() => {
                copyToClipboard(
                  (data.membership === Origin.Oezv
                    ? config.host.oezv
                    : config.host.voez) +
                  '/update'
                )
                  .then(() => {
                    enqueueSnackbar(
                      t('Link wurde in die Zwischenablage kopiert.'),
                      {
                        variant: 'info',
                      }
                    );
                  })
                  .catch(() => console.error('Failed to copy to clipboard'));
              }}
              startIcon={<CopyAll/>}
            >
              {t('Link zum Aktualisierungsformular')}
            </Button>{' '}
            {data.status === PressPassApplicationStatus.UpdatedPhotoDone ||
            data.status === PressPassApplicationStatus.Denied ? (
              <LoadingButton
                variant="contained"
                color="secondary"
                loading={statusMutation.isLoading}
                onClick={() =>
                  statusMutation.mutateAsync(PressPassApplicationStatus.New)
                }
                startIcon={<RestartAlt/>}
              >
                {t('Antrag erneut öffnen')}
              </LoadingButton>
            ) : (
              <>
                <LoadingButton
                  variant="contained"
                  color="success"
                  loading={statusMutation.isLoading}
                  onClick={() =>
                    statusMutation.mutateAsync(PressPassApplicationStatus.UpdatedPhotoDone)
                  }
                  startIcon={<Check/>}
                >
                  {t('Aktualisierung abschließen')}
                </LoadingButton>{' '}
                <LoadingButton
                  variant="contained"
                  color="error"
                  loading={statusMutation.isLoading}
                  onClick={() =>
                    statusMutation.mutateAsync(
                      PressPassApplicationStatus.Denied
                    )
                  }
                  startIcon={<Clear/>}
                >
                  {t('Antrag ablehnen')}
                </LoadingButton>
              </>
            )}
            <ModifiedEntity entity={data}/>
          </>
        }
      />
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <StyledCardTitle variant="h6" color="secondary" gutterBottom>
                {t('Angaben zum Aktualisierungsantrag')}
              </StyledCardTitle>
              <Grid container spacing={3}>
                <GridData xs={12} md={6} label={t<string>('Vorname')}>
                  {data.firstName}
                </GridData>
                <GridData xs={12} md={6} label={t<string>('Nachname')}>
                  {data.lastName}
                </GridData>
                <GridData xs={12} md={6} label={t<string>('Presseausweis-Nummer')}>
                  <Link to={routes.pressPass(data.pressPass.id)} component={RouterLink}>
                    {data.pressPass.passNumber}
                  </Link>
                </GridData>

                <GridData xs={12} md={6} label={t<string>('Geburtsdatum')}>
                  <FormattedDate
                    format={'d.MM.Y'}
                    date={data.birthdate || ''}
                  />
                </GridData>
                <GridData xs={12} md={6} label={t<string>('Kontakt-Email')}>
                  {data.email}
                </GridData>
                <GridData xs={12} md={6} label={t<string>('Mitglied')}>
                  {data.membership}
                </GridData>
                <GridData
                  xs={12}
                  md={6}
                  label={t<string>('Foto (Hochgeladen / zugeschnitten)')}
                  mode="richtext"
                >
                  {data.attachments && (
                    <Grid container spacing={3}>
                      {data.attachments.map((attachment, index) => (
                        <Grid item xs={12} md={12} key={index}>
                          <Grid container>
                            <>
                              <Grid item xs={12}>
                                <Avatar src={attachment.file || ''} style={{ width: '150px', height: '100%' }} variant={"square"}/>
                              </Grid>
                              {attachment.dimensions && (
                                <>
                                  <Grid item xs={12}>
                                    <Typography variant={'caption'}>{attachment.name}<br/>
                                      Bildgröße: {calculateImageRatioText(attachment)}</Typography>
                                    {attachment.dimensions.width < 413 ? (
                                      <Grid item xs={12}>
                                        <Chip
                                          label={t('Bild zu klein')}
                                          size="small"
                                          color="error"
                                        />
                                      </Grid>
                                    ) : (
                                      <Grid item xs={12}>
                                        <Chip
                                          label={t('Bildgröße OK')}
                                          size="small"
                                          color="success"
                                        />
                                      </Grid>
                                    )}
                                  </Grid>
                                  {attachment && (
                                    <Grid item xs={12}>
                                      <Typography variant={'caption'}>{calculateImageRatio(attachment)}</Typography>
                                    </Grid>)}
                                </>
                              )}
                            </>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  )}
                </GridData>
                <GridData
                  xs={12}
                  md={6}
                  label={t<string>('Foto vom Presseausweis')}
                  mode="richtext"
                >
                  <List>
                    <ListItem>
                      <Avatar src={data.pressPass.photo || ''} style={{ width: '150px', height: '100%' }} variant={"square"}/>
                    </ListItem>
                  </List>
                  <Typography component={'span'} variant="caption">
                    {t('Foto zuletzt aktualisiert am: ')}
                    <FormattedDate
                      format={'d.MM.Y'}
                      date={data.pressPass.photoUpdatedAt || ''}
                    />
                  </Typography>
                </GridData>
              </Grid>
            </CardContent>
            <Divider/>
            <CardContent>
              <StyledCardTitle variant="h6" color="secondary" gutterBottom>
                {t('Informationen zur Person (aus Presseausweis)')}
              </StyledCardTitle>
              <Grid container spacing={3}>
                <GridData xs={12} md={6} label={t<string>('Marke')}>
                  {data.pressPass.currentBadge ? (
                    <>
                      {data.pressPass.currentBadge.year} -{' erstellt am '}
                      <FormattedDate
                        format={'d.MM.Y'}
                        date={data.pressPass.currentBadge.createdAt || ''}
                      />
                    </>
                  ) : '-'}
                </GridData>
                <GridData xs={12} md={6} label={t<string>('Status')}>
                  <>
                    {data.pressPass.status}
                    <LoadingIconButton
                      onClick={() => {
                        const confirm = window.confirm(
                          t(
                            `Wollen Sie die den Presseausweils mit der Nummer {{passNumber}} wirklich als 'Bereit für Export' markieren?`,
                            {
                              passNumber: data.pressPass.passNumber,
                            }
                          )
                        );
                        if (!confirm) {
                          return;
                        }
                        pressPassStatusMutation.mutateAsync(PressPassStatus.ReadyForExport)
                      }}
                      loading={pressPassStatusMutation.isLoading}
                    >
                      <FactCheck/>
                    </LoadingIconButton>
                  </>
                </GridData>
                <GridData xs={12} md={6} label={t<string>('Vorname')}>
                  {data.pressPass.person.firstName}
                </GridData>
                <GridData xs={12} md={6} label={t<string>('Nachname')}>
                  {data.pressPass.person.lastName}
                </GridData>
                <GridData
                  xs={12}
                  md={6}
                  label={t<string>('Voranstehender Titel')}
                >
                  {data.pressPass.person.titleBefore}
                </GridData>
                <GridData
                  xs={12}
                  md={6}
                  label={t<string>('Nachstehender Titel')}
                >
                  {data.pressPass.person.titleAfter}
                </GridData>
                <GridData xs={12} md={6} label={t<string>('Geburtsdatum')}>
                  <FormattedDate
                    format={'d.MM.Y'}
                    date={data.pressPass.person.birthdate || ''}
                  />
                </GridData>
                <GridData xs={12} md={6} label={t<string>('Geburtsort')}>
                  {data.pressPass.person.birthplace}
                </GridData>
                <GridData xs={12} md={6} label={t<string>('Anschrift')}>
                  {data.pressPass.street && <SpanBlock>{data.pressPass.street}</SpanBlock>}
                  {(data.pressPass.zipCode || data.city) && (
                    <SpanBlock>
                      {data.pressPass.zipCode} {data.pressPass.city}
                    </SpanBlock>
                  )}
                  {data.pressPass.country && <SpanBlock>{data.pressPass.country}</SpanBlock>}
                </GridData>
                <GridData xs={12} md={6} label={t<string>('Nationalität')}>
                  {data.pressPass.person.nationality}
                </GridData>
              </Grid>
            </CardContent>
            <Divider/>
            <CardContent>
              <StyledCardTitle variant="h6" color="secondary" gutterBottom>
                {t('Kontaktdaten')}
              </StyledCardTitle>
              <Grid container spacing={3}>
                <GridData xs={12} md={6} label={t<string>('Telefonnummer')}>
                  {data.pressPass.phone}
                </GridData>
                <GridData xs={12} md={6} label={t<string>('E-Mail Adresse')}>
                  {data.pressPass.email}
                </GridData>
              </Grid>
            </CardContent>
            <Divider/>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <StyledCardTitle variant="h6" color="secondary" gutterBottom>
                    {t('Medium')}
                  </StyledCardTitle>
                  <Grid container spacing={3}>
                    <GridData xs={12} md={12} label={t<string>('Medium')}>
                      {data.pressPass.medium && data.pressPass.medium.name}
                    </GridData>
                    <GridData xs={12} md={6} label={t<string>('Funktion')}>
                      {data.pressPass.position && data.pressPass.position.title}
                    </GridData>
                    <GridData
                      xs={12}
                      md={6}
                      label={t<string>('Dienstverhältnis')}
                    >
                      {data.pressPass.employmentStatus &&
                        t(
                          `pressPass.employmentStatus.${data.pressPass.employmentStatus}`
                        )}
                    </GridData>
                    <GridData
                      xs={12}
                      md={12}
                      label={t<string>('Adresse des Mediums')}
                    >
                      {data.pressPass.medium && data.pressPass.medium.company &&
                        <Typography component={'span'} variant="body1">
                          {data.pressPass.medium.company.street}, {data.pressPass.medium.company.zipCode} {data.pressPass.medium.company.city}
                        </Typography>
                      }
                    </GridData>
                  </Grid>
                </CardContent>
                <Divider/>
                <CardContent>
                  <StyledCardTitle variant="h6" color="secondary" gutterBottom>
                    {t('Verwaltung')}
                  </StyledCardTitle>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      {data.pressPass && data.pressPass.currentBadge &&
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={data.pressPass.currentBadge.carBadge}
                              readOnly={true}
                              name="enabled"
                              color="primary"
                              disableRipple={true}
                              style={{ cursor: 'auto' }}
                            />
                          }
                          label={t<string>('Autoschild')}
                        />}
                    </Grid>
                    <GridData
                      xs={12}
                      label={t<string>('Dateien')}
                      mode="richtext"
                    >
                      {data.attachments ? (
                        <List>
                          {data.attachments.map((attachment, index) => (
                            <ListItem key={index}>
                              <ListItemIcon>
                                <AttachFile/>
                              </ListItemIcon>
                              <ListItemText
                                style={{
                                  textOverflow: 'ellipsis',
                                  overflow: 'hidden',
                                  whiteSpace: 'nowrap',
                                }}
                                primary={
                                  <Link href={attachment.file} target="_blank">
                                    {attachment.name}
                                  </Link>
                                }
                                secondary={
                                  <Filesize sizeInBytes={attachment.size}/>
                                }
                              />
                              <LoadingIconButton
                                onClick={() => {
                                  const confirm = window.confirm(
                                    t(
                                      `Wollen Sie die Datei {{name}} wirklich entfernen?`,
                                      {
                                        name: attachment.name,
                                      }
                                    )
                                  );
                                  if (!confirm) {
                                    return;
                                  }
                                  deleteAttachmentMutation.mutate(attachment);
                                }}
                                loading={
                                  deleteAttachmentMutation.isLoading &&
                                  !!deletingAttachments.find(
                                    (a) => a === attachment.id
                                  )
                                }
                              >
                                <Delete/>
                              </LoadingIconButton>
                              <LoadingIconButton
                                onClick={() => {
                                  const confirm = window.confirm(
                                    t(
                                      `Wollen Sie die Datei {{name}} wirklich übernehmen?`,
                                      {
                                        name: attachment.name,
                                      }
                                    )
                                  );
                                  if (!confirm) {
                                    return;
                                  }
                                  updateAttachmentMutation.mutate(attachment);
                                }}
                                loading={
                                  updateAttachmentMutation.isLoading &&
                                  !!updatingAttachments.find(
                                    (a) => a === attachment.id
                                  )
                                }
                              >
                                <Update/>
                              </LoadingIconButton>
                              <IconButton
                                onClick={() => {
                                  setMarkDialogOpen(true)
                                }}
                              >
                                <Crop/>
                              </IconButton>
                            </ListItem>
                          ))}
                        </List>
                      ) : (
                        <Typography variant={'caption'}>{t('Keine Dateien')}</Typography>
                      )}
                    </GridData>
                  </Grid>
                </CardContent>
                <Divider/>
                <CardContent>
                  <Grid container spacing={3}>
                    <GridData
                      xs={12}
                      label={t<string>('Kommentar von Antragsteller')}
                    >
                      {data.comment}
                    </GridData>
                  </Grid>
                </CardContent>
                <Divider/>
                <CardContent>
                  <StyledCardTitle variant="h6" color="secondary" gutterBottom>
                    {t('Datenschutz')}
                  </StyledCardTitle>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={data.confirmationPrivacy}
                            readOnly={true}
                            name="enabled"
                            color="primary"
                            disableRipple={true}
                            style={{ cursor: 'auto' }}
                          />
                        }
                        label={t<string>('Zustimmung Datenschutz')}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={data.confirmationMoral}
                            readOnly={true}
                            name="enabled"
                            color="primary"
                            disableRipple={true}
                            style={{ cursor: 'auto' }}
                          />
                        }
                        label={t<string>('Zustimmung Gewissensfrage')}
                      />
                    </Grid>
                  </Grid>
                  <Grid>
                    {data.attachments.length > 0 && (
                      <PressPassApplicationCropImageDialog
                        open={markDialogOpen}
                        onClose={() => setMarkDialogOpen(false)}
                        pressPass={data.pressPass}
                        pressPassApplicationUpdateId={data.id}
                        selected={data.attachments[0]}
                      />
                    )}
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PressPassUpdateApplicationsDetails;
