import { LoadingButton } from '@mui/lab';
import {
  Button, Card, CardActions, CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, Grid, IconButton, Typography,
} from '@mui/material';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import React, { useCallback, useRef, useState, VoidFunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { apiRoutes, request } from '../../lib';
import { Attachment, ID, PressPass, PressPassApplicationWithPressPass } from '../../model';
import { handleHookFormErrors } from "../../helpers";
import Cropper from 'react-easy-crop'
import Slider from '@mui/material/Slider';
import getCroppedImg from './crop-image-helper';
import { StyledCardTitle } from "../globals";
import { Close } from "@mui/icons-material";

interface CropImageFormValues {
  attachment: Attachment
  pressPassApplicationUpdateId: ID;
}

const PressPassApplicationCropImageDialog: VoidFunctionComponent<{
  open: boolean;
  onClose: () => void;
  pressPass: PressPass;
  selected: Attachment;
  pressPassApplicationUpdateId: ID;
}> = ({ open, onClose, selected, pressPassApplicationUpdateId, pressPass }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const imgRef = useRef<HTMLImageElement | null>(null);
  const [croppedImage, setCroppedImage] = useState<Blob | null>(null);

  const {
    handleSubmit,
    formState: { isSubmitting, isValid },
    setError,
  } = useForm<CropImageFormValues>({
    mode: 'all',
    defaultValues: {
      pressPassApplicationUpdateId: pressPassApplicationUpdateId,
      attachment: selected,
    },
  });

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedImage(null);
    setCroppedAreaPixels(croppedAreaPixels);
  }, [croppedImage]);

  const showCroppedImage = useCallback(async () => {
    try {
      if (!imgRef || !imgRef.current) {
        return;
      }

      // @ts-ignore
      const imgRefSrc = imgRef.current.src;
      const croppedImage = await getCroppedImg(
        imgRefSrc,
        croppedAreaPixels,
        0
      )
      setCroppedImage(croppedImage);
    } catch (e) {
      console.error(e)
    }
  }, [croppedAreaPixels]);

  const onCloseDialog = () => {
    onClose();
    setZoom(1);
    setCrop({ x: 0, y: 0 });
    setCroppedImage(null)
  };

  const handleZoom = (event: Event, newValue: number | number[]) => {
    setZoom(newValue as number);
  };

  const submitMutation = useMutation(
    async () => {
      if (!croppedImage) {
        return;
      }
      const formData = new FormData();
      const now = new Date();
      const nowString = now.getFullYear().toString() + now.getMonth().toString() + now.getDate().toString() + now.getHours().toString() + now.getMinutes().toString() + now.getSeconds().toString();

      formData.append('file', croppedImage, nowString + "-" + selected.name);
      formData.append('pressPassApplicationUpdateId', pressPassApplicationUpdateId.toString());
      formData.append('attachmentId', selected.id.toString());

      await request<PressPassApplicationWithPressPass[]>(
        apiRoutes.pressPassApplicationAttachmentUpdateUploadCroppedImage(pressPassApplicationUpdateId),
        'post', formData
      );
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(apiRoutes.pressPasses);
        enqueueSnackbar(t('Bild wurde erfolgreich hochgeladen.'), {
          variant: 'success',
        });
        onCloseDialog();
      },
      onError: (err: AxiosError) => {
        enqueueSnackbar(
          err.response?.data.message || t('Leider ist etwas schief gelaufen.'),
          { variant: 'error' }
        );
        handleHookFormErrors(err, setError);
      },
    }
  );

  return (
    <Dialog open={open} onClose={() => onCloseDialog()} fullWidth={true} fullScreen={true}>
      <form
        onSubmit={handleSubmit(() => submitMutation.mutateAsync())}
      >
        <DialogTitle>{t('Foto Bearbeiten - Presseausweis #' + pressPass.passNumber + ' ' + pressPass.person.displayName)}</DialogTitle>
        <IconButton edge="end" onClick={() => onCloseDialog()}
                    sx={{
                      position: 'absolute',
                      right: 25,
                      top: 25,
                    }}
        >
          <Close/>
        </IconButton>
        <DialogContent dividers>
          <Grid container spacing={3} alignItems="stretch"
          >
            <Grid item xs={12} md={6}>
              <Card>
                <CardContent>
                  <StyledCardTitle variant="h4" color="secondary" gutterBottom>
                    {t('Originalbild')}
                  </StyledCardTitle>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      {selected.file && (
                        <div style={{
                          position: 'relative',
                          width: '100%',
                          height: 600,
                          background: '#eee',
                        }}>
                          <Cropper
                            image={selected.file}
                            style={{ containerStyle: { width: '100%', height: '100%' } }}
                            crop={crop}
                            zoom={zoom}
                            aspect={7 / 9}
                            zoomWithScroll={true}
                            onCropChange={setCrop}
                            onCropComplete={onCropComplete}
                            onZoomChange={setZoom}
                          />
                        </div>
                      )}
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions>
                  <Typography
                    variant="overline"
                  >
                    Zoom
                  </Typography>
                  <Slider
                    style={{
                      padding: '22px 0px',
                      marginLeft: 32,
                      marginRight: 32,
                    }}
                    value={zoom}
                    min={1}
                    max={3}
                    step={0.1}
                    aria-labelledby="Zoom"
                    onChange={(e, zoom) => handleZoom(e, zoom)}
                  />
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card>
                <CardContent>
                  <StyledCardTitle variant="h4" color="secondary" gutterBottom>
                    {t('Vorschau')}
                  </StyledCardTitle>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <div style={{
                        position: 'relative',
                        width: '100%',
                        height: 600,
                        background: '#eee',
                      }}>
                        {croppedImage && (
                          <img
                            crossOrigin={'anonymous'}
                            height={600}
                            alt="Crop"
                            style={{ maxWidth: '100%', background: '#999' }}
                            src={URL.createObjectURL(croppedImage)}
                          />)}
                      </div>
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions>
                  <span>&nbsp;</span>
                </CardActions>
              </Card>
              <div>
                <div>
                  {selected && selected.file && (
                    <img
                      crossOrigin={'anonymous'}
                      ref={imgRef}
                      alt="Crop"
                      style={{ maxWidth: '100%', visibility: 'hidden', position: 'absolute', top: 0, left: 0 }}
                      src={selected.file}
                    />
                  )}
                </div>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={showCroppedImage}
            variant="contained"
            color="secondary"
          >
            {t('Vorschau')}
          </Button>

          <Button onClick={() => onClose()} disabled={isSubmitting}>
            {t('Abbrechen')}
          </Button>
          <LoadingButton
            type="submit"
            autoFocus
            variant="contained"
            loading={submitMutation.isLoading}
            disabled={!isValid || isSubmitting || !croppedImage}
          >
            {t('Übernehmen')}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default PressPassApplicationCropImageDialog;
