import { Box, Grid, GridProps, Typography } from '@mui/material';
import React, { FunctionComponent, ReactChild } from 'react';
import { useTranslation } from 'react-i18next';

interface GridDataProps extends GridProps {
  label?: ReactChild;
  mode?: 'text' | 'richtext';
}

export const GridData: FunctionComponent<GridDataProps> = ({
  label,
  children,
  mode = 'text',
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <Grid item {...props}>
      {label && (
        <Typography variant="overline" color="textSecondary">
          {label}
        </Typography>
      )}
      {mode === 'text' && (
        <Typography variant="body1">{children || t('Keine Angabe')}</Typography>
      )}
      {mode === 'richtext' && (
        <Box alignItems="center">{children || t('Keine Angabe')}</Box>
      )}
    </Grid>
  );
};
