import { Delete } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, Card, CardContent, Container } from '@mui/material';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router';
import { Link as RouterLink, useParams } from 'react-router-dom';
import FormattedDate from '../../components/formatted-date';
import { StyledCardTitle } from '../../components/globals';
import Guarded from '../../components/guarded';
import Header from '../../components/header';
import HttpError from '../../components/http-error';
import LoadingContainer from '../../components/loading-container';
import { ModifiedEntity } from '../../components/modified-entity';
import {
  PaginationProvider,
  PaginationTablePagination,
} from '../../components/pagination';
import { TransactionImportList } from '../../components/transaction';
import { config } from '../../config';
import { useFilteredPaginationApi, useTitle } from '../../hooks';
import { apiRoutes, request, routes, useImportDetailsApi } from '../../lib';
import { DateFormat, ID, Role, Transaction } from '../../model';

const ImportDetails = () => {
  const { t } = useTranslation();
  const { importId } = useParams<{ importId: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, error, data } = useImportDetailsApi(+importId);
  const history = useHistory();
  const { context } = useFilteredPaginationApi<{ importId: ID }, Transaction>(
    apiRoutes.transactions,
    { importId: +importId },
    'importDate',
    'desc',
    config.pageSize
  );

  useTitle(t('Import Details'));

  const detailBreadcrumbs = [
    { label: t('Home'), link: routes.dashboard },
    { label: t('Imports'), link: routes.imports },
  ];

  const deleteMutation = useMutation(
    async () => {
      if (!data) {
        return;
      }
      return await request(apiRoutes.import(data.id), 'delete');
    },
    {
      onSuccess: () => {
        enqueueSnackbar(t('Import wurde erfolgreich gelöscht.'), {
          variant: 'success',
        });
        history.push(routes.imports);
      },
      onError: (err: AxiosError) => {
        enqueueSnackbar(
          err.response?.data.message ||
          t('Import konnte nicht gelöscht werden.'),
          {
            variant: 'error',
          }
        );
      },
    }
  );

  if (error) {
    return (
      <HttpError
        error={error}
        actions={
          <Button component={RouterLink} to={routes.imports}>
            {t('Zurück zu Imports')}
          </Button>
        }
      />
    );
  }

  if (isLoading || !data) {
    return <LoadingContainer/>;
  }

  return (
    <Container maxWidth="xl">
      <Header
        title={
          <FormattedDate date={data.createdAt} format={DateFormat.DateTime}/>
        }
        breadcrumbs={detailBreadcrumbs}
        actions={
          <>
            <Guarded requiredRole={Role.PDN}>
              <LoadingButton
                variant="contained"
                color="primary"
                loading={deleteMutation.isLoading}
                onClick={() => {
                  const confirm = window.confirm(
                    t(
                      `Wollen Sie diesen Import vom {{date}} mit den zugehörigen Nutzungsdaten wirklich löschen?`,
                      {
                        date: data.createdAt,
                      }
                    )
                  );
                  if (!confirm) {
                    return;
                  }
                  deleteMutation.mutateAsync();
                }}
                startIcon={<Delete/>}
              >
                {t('Löschen')}
              </LoadingButton>
            </Guarded>
            <ModifiedEntity entity={data}/>
          </>
        }
      />
      <Card>
        <CardContent>
          <StyledCardTitle variant="h6" color="secondary" gutterBottom>
            {t('Importierte Nutzungsdaten')}
          </StyledCardTitle>
        </CardContent>
        <PaginationProvider context={context}>
          <TransactionImportList/>
          <PaginationTablePagination/>
        </PaginationProvider>
      </Card>
    </Container>
  );
};

export default ImportDetails;
