import { Edit, Visibility } from '@mui/icons-material';
import { Checkbox, Link, TableCell, TableRow } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { routes } from '../../lib';
import { MailingList } from '../../model';
import { CompactTableCell } from '../globals';
import { IconLink } from '../icon-link';
import { PaginationTable } from '../pagination';
import PaginationTableHeadCell from '../pagination/table-head-cell';

export const MailingListList: FunctionComponent<{ mailingLists?: MailingList[] }> = ({
  mailingLists,
}) => {
  const { t } = useTranslation();

  return (
    <PaginationTable
      items={mailingLists}
      renderRow={(mailingList) => (
        <TableRow key={mailingList.id} hover={true}>
          <CompactTableCell>
            <Link
              to={routes.mailingList(mailingList.id)}
              component={RouterLink}
            >
              {mailingList.name}
            </Link>
          </CompactTableCell>
          <CompactTableCell align="center">
            {mailingList.persons.length}
          </CompactTableCell>
          <TableCell>{mailingList.comment}</TableCell>
          <TableCell>
            <Checkbox
              checked={mailingList.enabled}
              readOnly={true}
              name="enabled"
              color="primary"
              disableRipple={true}
              style={{ cursor: 'auto' }}
            />
          </TableCell>
          <CompactTableCell align={'right'}>
            <IconLink
              to={routes.mailingList(mailingList.id)}
              tooltip={t('Ansehen')}
              icon={<Visibility />}
            />
            <IconLink
              to={routes.mailingListEdit(mailingList.id)}
              tooltip={t('Bearbeiten')}
              icon={<Edit />}
            />
          </CompactTableCell>
        </TableRow>
      )}
    >
      <TableRow>
        <PaginationTableHeadCell sort="name">
          {t('Name')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell>{t('Empfänger')}</PaginationTableHeadCell>
        <PaginationTableHeadCell>{t('Kommentar')}</PaginationTableHeadCell>
        <PaginationTableHeadCell>{t('Aktiv')}</PaginationTableHeadCell>
        <TableCell width={110} />
      </TableRow>
    </PaginationTable>
  );
};
