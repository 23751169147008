import { FunctionComponent } from 'react';
import { useCurrentUser } from '../../hooks';
import { Role } from '../../model';

const Guarded: FunctionComponent<{ requiredRole: Role }> = ({
  requiredRole,
  children,
}) => {
  const user = useCurrentUser();
  if (!user) {
    return <></>;
  }
  const roles = user.inheritedRoles;
  if (!roles || !roles.includes(requiredRole)) {
    return <></>;
  }
  return <>{children}</>;
};

export default Guarded;
