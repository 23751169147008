import { useContext, useEffect, useRef } from 'react';
import AppContext from '../context/app';

export const useTitle = (
  title: string,
  documentTitle = '',
  retainOnUnmount = false
): string => {
  const defaultTitle = useRef(document.title);
  const appContext = useContext(AppContext);

  useEffect(() => {
    document.title = appContext.documentTitle.replace(
      '%title%',
      documentTitle === '' ? title : documentTitle
    );
    appContext.setLocationTitle(title);
  }, [title, documentTitle, appContext]);

  useEffect(() => {
    const title = defaultTitle.current;
    return () => {
      if (!retainOnUnmount) {
        document.title = title;
      }
    };
  }, [retainOnUnmount]);

  return title;
};
