import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useCompanyValidation } from '../company/use-company-validation';

export const useLicenseeValidation = () => {
  const { t } = useTranslation();
  const companyValidation = useCompanyValidation();
  return useMemo(
    () => ({
      ...companyValidation,
      license: yup.number().required(t('Lizenznummer darf nicht leer sein.')),
      contractStart: yup.date(),
      contractEnd: yup.date(),
    }),
    [companyValidation]
  );
};

export const useLicenseeValidationSchema = () => {
  const validation = useLicenseeValidation();
  return useMemo(() => {
    return yup.object({
      ...validation,
    });
  }, [validation]);
};

export const useLicensePressReviewValidation = () => {
  const { t } = useTranslation();
  return useMemo(
    () => ({
      from: yup.string().required(t('Von darf nicht leer sein.')),
      to: yup.string().required(t('Bis darf nicht leer sein.')),
      type: yup.string().required(t('Art darf nicht leer sein.')),
      usage: yup.string().required(t('Verwendung darf nicht leer sein.')),
      archiveCharge: yup
        .string()
        .matches(
          /^(\d+(?:[,]\d+)?)$/,
          t('Muss eine Komma-getrennte Dezimalzahl sein.')
        ),
      lifetime: yup.string().required(t('Nutzungsdauer darf nicht leer sein.')),
    }),
    [t]
  );
};

export const useLicensePressReviewValidationSchema = () => {
  const validation = useLicensePressReviewValidation();
  return useMemo(() => {
    return yup.object({
      ...validation,
    });
  }, [validation]);
};

export const useLicenseeCreditValidation = () => {
  const { t } = useTranslation();
  return useMemo(
    () => ({
      grossValue: yup
        .string()
        .matches(
          /^(-?\d+(?:[,]\d+)?)$/,
          t('Muss eine Komma-getrennte Dezimalzahl sein.')
        ),
      netValue: yup
        .string()
        .matches(
          /^(-?\d+(?:[,]\d+)?)$/,
          t('Muss eine Komma-getrennte Dezimalzahl sein.')
        ),
      creditDate: yup.string().required(t('Datum darf nicht leer sein.')),
    }),
    [t]
  );
};

export const useLicenseeCreditValidationSchema = () => {
  const validation = useLicenseeCreditValidation();
  return useMemo(() => {
    return yup.object({
      ...validation,
    });
  }, [validation]);
};

export const useLicenseeEvaluationValidation = () => {
  const { t } = useTranslation();
  return useMemo(
    () => ({
      from: yup.string().required(t('Von darf nicht leer sein.')),
      to: yup.string().required(t('Bis darf nicht leer sein.')),
    }),
    [t]
  );
};

export const useLicenseeEvaluationValidationSchema = () => {
  const validation = useLicenseeEvaluationValidation();
  return useMemo(() => {
    return yup.object({
      ...validation,
    });
  }, [validation]);
};
