import { ReactChild, VoidFunctionComponent } from 'react';
import Error from '../error';

export const NotFound: VoidFunctionComponent<{ actions?: ReactChild }> = ({
  actions,
}) => {
  return (
    <Error
      title={`404`}
      description={`This page doesn't exist.`}
      actions={actions}
    />
  );
};
