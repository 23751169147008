import { Edit, Visibility } from '@mui/icons-material';
import { Link, TableCell, TableRow } from '@mui/material';
import React, { Fragment, FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { routes } from '../../lib';
import { Company } from '../../model';
import { CompactTableCell } from '../globals';
import { IconLink } from '../icon-link';
import { PaginationTable } from '../pagination';
import PaginationTableHeadCell from '../pagination/table-head-cell';

export const CompanyList: FunctionComponent<{ companies?: Company[] }> = ({
  companies,
}) => {
  const { t } = useTranslation();

  return (
    <PaginationTable
      items={companies}
      renderRow={(company) => (
        <TableRow key={company.id} hover={true}>
          {/*<TableCell width="10%">*/}
          {/*  <CompanyType company={company} />*/}
          {/*</TableCell>*/}
          <TableCell>
            <Link to={routes.company(company.id)} component={RouterLink}>
              {company.name}
            </Link>
          </TableCell>
          <TableCell>{company.term}</TableCell>
          <TableCell>{company.street}</TableCell>
          <TableCell>{company.zipCode}</TableCell>
          <TableCell>{company.city}</TableCell>
          <TableCell>{company.state}</TableCell>
          <TableCell>{company.phone}</TableCell>
          <TableCell>
            {company.media.map((media, index) => (
              <Fragment key={index}>
                <Link to={routes.medium(media.id)} component={RouterLink}>
                  {media.name}
                </Link>
                {index < company.media.length - 1 && ', '}
              </Fragment>
            ))}
          </TableCell>
          <CompactTableCell align={'right'}>
            <IconLink
              to={routes.company(company.id)}
              tooltip={t('Ansehen')}
              icon={<Visibility />}
            />
            <IconLink
              to={routes.companyEdit(company.id)}
              tooltip={t('Bearbeiten')}
              icon={<Edit />}
            />
          </CompactTableCell>
        </TableRow>
      )}
    >
      <TableRow>
        {/*<PaginationTableHeadCell sort="type">*/}
        {/*  {t('Typ')}*/}
        {/*</PaginationTableHeadCell>*/}
        <PaginationTableHeadCell sort="name">
          {t('Name')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell sort="term">
          {t('Zusatz')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell sort="street">
          {t('Adresse')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell sort="zipCode">
          {t('PLZ')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell sort="city">
          {t('City')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell sort="state">
          {t('Bundesland')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell sort="phone">
          {t('Telefon')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell sort="medias">
          {t('Medien')}
        </PaginationTableHeadCell>
        <TableCell width={110} />
      </TableRow>
    </PaginationTable>
  );
};
