import { Blameable, Timestampable } from './index';
import { ID } from './types';

export enum Role {
  User = 'ROLE_USER',
  Admin = 'ROLE_ADMIN',
  SuperAdmin = 'ROLE_SUPER_ADMIN',
  AllowedToSwitch = 'ROLE_ALLOWED_TO_SWITCH',
  PressPass = 'ROLE_PRESS_PASS',
  General = 'ROLE_GENERAL',
  PDN = 'ROLE_PDN',
}

export interface User extends Timestampable, Blameable {
  id: ID;
  username: string;
  fullName: string;
  email: string;
  avatar: string | null;
  roles: Role[];
  inheritedRoles: Role[];
  enabled: boolean;
  impersonator?: User;
}

export interface UserFormValues {
  username: string;
  fullName: string;
  email: string;
  roles: Role[];
  plainPassword: string;
  passwordRepeat: string;
  enabled: boolean;
}

export const getUserFormValues = (user?: User): UserFormValues => ({
  username: user?.username || '',
  fullName: user?.fullName || '',
  email: user?.email || '',
  roles: user?.roles || [],
  plainPassword: '',
  passwordRepeat: '',
  enabled: user?.enabled || false,
});
