import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Card,
  CardActions,
  CardContent,
  Grid,
  TextField,
} from '@mui/material';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import React, { VoidFunctionComponent } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { handleHookFormErrors } from '../../helpers';
import { usePasswordValidationSchema } from '../../hooks';
import { apiRoutes, logout, request, routes } from '../../lib';
import { ApiError } from '../../model';
import {useTranslation} from "react-i18next";

const StyledAlert = styled(Alert)`
  margin-bottom: 2em;
`;

interface FormValues {
  currentPassword: string;
  plainPassword: string;
  passwordRepeat: string;
}

const ChangePassword: VoidFunctionComponent = () => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const {t} = useTranslation();
  const validation = usePasswordValidationSchema();
  const {
    control,
    handleSubmit,
    formState: { isValid, isSubmitting },
    setError,
  } = useForm<FormValues>({
    mode: 'all',
    resolver: yupResolver(validation),
    defaultValues: {
      currentPassword: '',
      plainPassword: '',
      passwordRepeat: '',
    },
  });

  const onSubmit = async (values: FormValues) => {
    await request(apiRoutes.profile.changePassword, 'post', values)
      .then(async () => {
        enqueueSnackbar(t('Passwort wurde geändert. Sie werden ausgeloggt.'), {
          variant: 'success',
        });
        logout().finally(() => {
          history.push(routes.dashboard);
          window.location.reload();
        });
      })
      .catch((err: AxiosError<ApiError>) => {
        enqueueSnackbar(t('Password konnte nicht aktualisiert werden.'), { variant: 'error' });
        handleHookFormErrors(err, setError);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card>
        <CardContent>
          <StyledAlert severity="warning">
            {t('Nach dem Ändern des Passworts, werden Sie aus Sicherheitsgründen ausgeloggt.')}
          </StyledAlert>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="currentPassword"
                render={({ field, fieldState }) => (
                  <TextField
                    id="currentPassword"
                    type="password"
                    label={t('Aktuelles Passwort')}
                    fullWidth
                    {...field}
                    error={fieldState.isTouched && fieldState.invalid}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                control={control}
                name="plainPassword"
                render={({ field, fieldState }) => (
                  <TextField
                    id="plainPassword"
                    type="password"
                    label={t('Neues Passwort')}
                    fullWidth
                    {...field}
                    error={fieldState.isTouched && fieldState.invalid}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                control={control}
                name="passwordRepeat"
                render={({ field, fieldState }) => (
                  <TextField
                    id="passwordRepeat"
                    type="password"
                    label={t('Neues Passwort wiederholen')}
                    fullWidth
                    {...field}
                    error={fieldState.isTouched && fieldState.invalid}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <LoadingButton
            type="submit"
            size="medium"
            color="primary"
            disabled={!isValid}
            loading={isSubmitting}
          >
            {t('Speichern')}
          </LoadingButton>
        </CardActions>
      </Card>
    </form>
  );
};

export default ChangePassword;
