import { Visibility } from '@mui/icons-material';
import { Link, TableCell, TableRow } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { routes } from '../../lib';
import { DateFormat } from '../../model';
import { Import } from '../../model/import';
import FormattedDate from '../formatted-date';
import { CompactTableCell } from '../globals';
import { IconLink } from '../icon-link';
import { PaginationTable } from '../pagination';
import PaginationTableHeadCell from '../pagination/table-head-cell';

export const ImportList: FunctionComponent<{ imports?: Import[] }> = ({
                                                                        imports,
                                                                      }) => {
  const { t } = useTranslation();

  return (
    <PaginationTable
      items={imports}
      renderRow={(importEntity) => (
        <TableRow key={importEntity.id} hover={true}>
          <CompactTableCell>
            <Link to={routes.import(importEntity.id)} component={RouterLink}>
              {importEntity.id}
            </Link>
          </CompactTableCell>
          <CompactTableCell>
            <FormattedDate
              date={importEntity.processDate}
              format={DateFormat.DateTime}
            />
          </CompactTableCell>
          <CompactTableCell>{importEntity.filename}</CompactTableCell>
          <CompactTableCell>{importEntity.createdBy}</CompactTableCell>
          <CompactTableCell>{importEntity.stats.total}</CompactTableCell>
          <CompactTableCell align={'right'}>
            <IconLink
              to={routes.import(importEntity.id)}
              tooltip={t('Ansehen')}
              icon={<Visibility/>}
            />
          </CompactTableCell>
        </TableRow>
      )}
    >
      <TableRow>
        <PaginationTableHeadCell sort="title">
          {t('ID')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell sort="date">
          {t('Datum')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell sort="filename">
          {t('Dateiname')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell>
          {t('User')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell>
          {t('Importierte Daten')}
        </PaginationTableHeadCell>
        <TableCell width={55}/>
      </TableRow>
    </PaginationTable>
  );
};
