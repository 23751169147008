import { Dict, Entity } from '../model';
import { usePaginationApi } from './use-pagination-api';

/**
 * Consumes paginated APIs without the results being paginated
 */
export const useUnlimitedPaginationApi = <
  TFilters extends Dict,
  TEntity extends Entity,
  TContext extends Dict = Dict
>(
  url: string,
  filters: Partial<TFilters> = {},
  context?: TContext
) => {
  const query = usePaginationApi<TFilters, TEntity, TContext>(url, {
    filters,
    page: 0,
    pageSize: 0,
    orderBy: 'id',
    order: 'asc',
    context,
  });
  return { ...query, data: query.data?.results || [] };
};
