import { TablePagination } from '@mui/material';
import { VoidFunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { usePaginationContext } from './context';

export const PaginationTablePagination: VoidFunctionComponent = () => {
  const { data, params, changePage, changePageSize } = usePaginationContext();
  const { t } = useTranslation();

  if (!data || !params || !changePage || !changePageSize) {
    return null;
  }

  return (
    <TablePagination
      rowsPerPageOptions={[10, 25, 50, 100, 200, 500]}
      component="div"
      count={data.filtered}
      rowsPerPage={params.pageSize}
      page={params.page}
      onPageChange={(e, page) => changePage(page)}
      onRowsPerPageChange={(event) =>
        changePageSize(parseInt(event.target.value, 10))
      }
      labelRowsPerPage={t('Einträge pro Seite')}
      labelDisplayedRows={({ from, to, count }) =>
        `${from}–${to} ${t('von')} ${
          count !== -1 ? count : `${t('mehr als')} ${to}`
        }`
      }
    />
  );
};
