import { yupResolver } from '@hookform/resolvers/yup';
import { DatePicker, LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from '@mui/material';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import React, { VoidFunctionComponent } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { formatPayloadDate, handleHookFormErrors } from '../../helpers';
import { useLicenseeCreditValidationSchema } from '../../hooks/validation/licensee';
import { apiRoutes, request } from '../../lib';
import { DateFormat, DateTimeString, Licensee } from '../../model';

interface CreditFormValues {
  grossValue: number;
  netValue: number;
  comment: string;
  creditDate: DateTimeString;
}


const defaultFormValues = {
  grossValue: 0,
  netValue: 0,
  comment: '',
  creditDate: '',
};

const LicenseeCreditDialog: VoidFunctionComponent<{
  open: boolean;
  onClose: () => void;
  licensee: Licensee;
}> = ({ open, onClose, licensee }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const validationSchema = useLicenseeCreditValidationSchema();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isValid },
    setError,
    reset,
  } = useForm<CreditFormValues>({
    mode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues: defaultFormValues,
  });

  const submitMutation = useMutation(
    async (values: CreditFormValues) => {
      values.creditDate = formatPayloadDate(values.creditDate) || '';
      return await request<Licensee>(
        apiRoutes.licenseeCreditCreate(licensee.id),
        'post',
        values
      );
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(
          apiRoutes.licenseeCredits(licensee.id)
        );
        enqueueSnackbar(t('Guthaben wurde erfolgreich aufgebucht.'), {
          variant: 'success',
        });
        reset(defaultFormValues);
        onClose();
      },
      onError: (err: AxiosError) => {
        enqueueSnackbar(
          err.response?.data.message || t('Leider ist etwas schief gelaufen.'),
          { variant: 'error' }
        );
        handleHookFormErrors(err, setError);
      },
    }
  );

  return (
    <Dialog open={open} onClose={() => onClose()}>
      <form
        onSubmit={handleSubmit((values) => submitMutation.mutateAsync(values))}
      >
        <DialogTitle>{t('Guthaben Aufbuchen')}</DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name={'grossValue'}
                render={({ field, fieldState }) => (
                  <TextField
                    label={t('Brutto €')}
                    fullWidth
                    {...field}
                    required
                    error={fieldState.isTouched && fieldState.invalid}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name={'netValue'}
                render={({ field, fieldState }) => (
                  <TextField
                    label={t('Netto €')}
                    fullWidth
                    required
                    {...field}
                    error={fieldState.isTouched && fieldState.invalid}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name="creditDate"
                render={({ field, fieldState }) => (
                  <DatePicker
                    value={field.value}
                    inputFormat={DateFormat.Default}
                    mask={DateFormat.Mask}
                    onChange={field.onChange}
                    label={t('Datum')}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        required
                        fullWidth
                        helperText={fieldState.error?.message}
                        error={fieldState.isTouched && fieldState.invalid}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name={'comment'}
                render={({ field, fieldState }) => (
                  <TextField
                    label={t('Kommentar')}
                    fullWidth
                    {...field}
                    multiline={true}
                    error={fieldState.isTouched && fieldState.invalid}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose()} disabled={isSubmitting}>
            {t('Abbrechen')}
          </Button>
          <LoadingButton
            type="submit"
            autoFocus
            variant="contained"
            loading={submitMutation.isLoading}
            disabled={!isValid || isSubmitting}
          >
            {t('Speichern')}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default LicenseeCreditDialog;
