import { Link, TableCell, TableRow } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { routes } from '../../lib';
import { DateFormat, Transaction } from '../../model';
import FormattedDate from '../formatted-date';
import { PaginationTable } from '../pagination';
import PaginationTableHeadCell from '../pagination/table-head-cell';


export const TransactionList: FunctionComponent<{
  transactions?: Transaction[];
}> = ({ transactions }) => {
  const { t } = useTranslation();

  return (
    <PaginationTable
      items={transactions}
      renderRow={(transaction) => (
        <TableRow key={transaction.id} hover={true}>
          <TableCell>
            <Link
              to={routes.licensee(transaction.company.id)}
              component={RouterLink}
            >
              {transaction.license}
            </Link>
          </TableCell>
          <TableCell>
            <Link
              to={routes.medium(transaction.medium.id)}
              component={RouterLink}
            >
              {transaction.referenceId}
            </Link>
          </TableCell>
          <TableCell>
            <FormattedDate
              date={transaction.usageDate}
              format={DateFormat.Default}
            />
          </TableCell>
          <TableCell>
            <FormattedDate
              date={transaction.importDate}
              format={DateFormat.Default}
            />
          </TableCell>
          <TableCell>{transaction.units}</TableCell>
          <TableCell>{transaction.tariffGroup.groupNumber}</TableCell>
          <TableCell>{transaction.unitPrice} €</TableCell>
          <TableCell>{transaction.archiveCharge} %</TableCell>
          <TableCell>{transaction.discount} %</TableCell>
          <TableCell>{transaction.apa} %</TableCell>
          <TableCell>{transaction.grossValue} €</TableCell>
          <TableCell>{transaction.netValue} €</TableCell>
          <TableCell>
            {transaction.billingDateLicensee && (
              <FormattedDate
                date={transaction.billingDateLicensee}
                format={DateFormat.Default}
              />
            )}
          </TableCell>
          <TableCell>
            {transaction.billingDateMedium && (
              <FormattedDate
                date={transaction.billingDateMedium}
                format={DateFormat.Default}
              />
            )}
          </TableCell>
        </TableRow>
      )}
    >
      <TableRow>
        <PaginationTableHeadCell sort="license">
          {t('LN')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell sort="medium.referenceId">
          {t('LG')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell sort="usageDate">
          {t('Nutzung')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell sort="importDate">
          {t('Import')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell sort="units">
          {t('Anzahl')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell sort="tariffGroup">
          {t('TG')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell sort="unitPrice">
          {t('AP Tarifliste')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell sort="archiveCharge">
          {t('Zuschlag')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell sort="discount">
          {t('Rabatt')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell sort="apa">
          {t('APA SL')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell sort="grossValue">
          {t('LU Brutto')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell sort="netValue">
          {t('LU Netto')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell sort="billingDateLicensee">
          {t('Abr LN')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell sort="billingDateMedium">
          {t('Abr LG')}
        </PaginationTableHeadCell>
      </TableRow>
    </PaginationTable>
  );
};
