import { Add } from '@mui/icons-material';
import { Button, Container, MenuItem } from '@mui/material';
import React, { VoidFunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import {
  FilterDatePicker,
  FilterInputSearch,
  FilterSelect,
} from '../../components/filter';
import Header from '../../components/header';
import { PaginationCard } from '../../components/pagination';
import UserList from '../../components/user/list';
import {
  UseQueryStateOptions,
  useDebounceState,
  useFilteredPaginationApi,
  useQueryState,
  useTitle,
} from '../../hooks';
import { apiRoutes, routes } from '../../lib';
import { Role, User } from '../../model';
import {useTranslation} from "react-i18next";

const breadcrumbs = [{ label: 'Home', link: routes.dashboard }];

export interface Filters {
  search?: string;
  role?: string;
  status: string;
  from?: Date | null;
  to?: Date | null;
}

const UserOverview: VoidFunctionComponent = () => {
  const {t} = useTranslation();
  useTitle(t('Benutzer'));

  const queryStateOptions: UseQueryStateOptions = { action: 'replace' };
  const [search, setFilterSearch] = useQueryState(
    '',
    'search',
    queryStateOptions
  );
  const [inputSearch, setInputSearch] = useDebounceState(
    search,
    setFilterSearch
  );
  const [role, setFilterRole] = useQueryState('', 'role', queryStateOptions);
  const [status, setFilterStatus] = useQueryState(
    '',
    'status',
    queryStateOptions
  );
  const [from, setFilterFromDate] = useQueryState<Date | null>(null, 'from', {
    ...queryStateOptions,
    valueType: 'Date',
  });
  const [to, setFilterToDate] = useQueryState<Date | null>(null, 'to', {
    ...queryStateOptions,
    valueType: 'Date',
  });

  const { context } = useFilteredPaginationApi<Filters, User>(
    apiRoutes.users,
    { search, role, status, from, to },
    'username',
    'asc',
    10
  );

  return (
    <Container maxWidth="lg">
      <Header
        title={t('Benutzer')}
        breadcrumbs={breadcrumbs}
        actions={
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to={routes.userCreate}
            startIcon={<Add />}
          >
            {t('Neuer Benutzer')}
          </Button>
        }
      />
      <PaginationCard
        context={context}
        hasFilters={!!(search || role || status || from || to)}
        resetFilters={() => {
          setFilterSearch('');
          setFilterRole('');
          setFilterStatus('');
          setFilterFromDate(null);
          setFilterToDate(null);
        }}
        filters={
          <>
            <FilterInputSearch value={inputSearch} onChange={setInputSearch} />
            <FilterSelect label={t('Rolle')} value={role} onChange={setFilterRole}>
              <MenuItem value="">
                <em>All</em>
              </MenuItem>
              {Object.keys(Role).map((role, key) => (
                <MenuItem value={Role[role as keyof typeof Role]} key={key}>
                  {role}
                </MenuItem>
              ))}
            </FilterSelect>
            <FilterSelect
              label={t('Status')}
              value={status}
              onChange={setFilterStatus}
            >
              <MenuItem value="">
                <em>{t('Alle')}</em>
              </MenuItem>
              <MenuItem value="enabled">{t('Aktiv')}</MenuItem>
              <MenuItem value="disabled">{t('Inaktiv')}</MenuItem>
            </FilterSelect>
            <FilterDatePicker
              label={t('Von')}
              value={from}
              onChange={setFilterFromDate}
            />
            <FilterDatePicker
              label={t('Bis')}
              value={to}
              onChange={setFilterToDate}
            />
          </>
        }
      >
        <UserList />
      </PaginationCard>
    </Container>
  );
};

export default UserOverview;
