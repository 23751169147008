import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

export const useSignatureValidation = () => {
  const { t } = useTranslation();

  return useMemo(
    () => ({
      name: yup.string().required(t('Name darf nicht leer sein.')),
      email: yup.string().required(t('E-Mail darf nicht leer sein.')),
      html: yup.string(),
      comment: yup.string(),
    }),
    []
  );
};

export const useSignatureValidationSchema = () => {
  const signatureValidation = useSignatureValidation();

  return useMemo(() => {
    return yup.object({
      ...signatureValidation,
    });
  }, [signatureValidation]);
};
