import { Visibility } from '@mui/icons-material';
import { Link, TableCell, TableRow } from '@mui/material';
import React, { VoidFunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { routes } from '../../lib';
import { DateFormat, PressPassApplication } from '../../model';
import FormattedDate from '../formatted-date';
import { CompactTableCell } from '../globals';
import { IconLink } from '../icon-link';
import { MembershipChip } from '../membership-chip';
import { PaginationTable } from '../pagination';
import PaginationTableHeadCell from '../pagination/table-head-cell';
import { PressPassApplicationStatusBadge } from './status';

export const PressPassApplicationList: VoidFunctionComponent<{
  pressPassApplications?: PressPassApplication[];
}> = ({ pressPassApplications }) => {
  const { t } = useTranslation();

  return (
    <PaginationTable
      items={pressPassApplications}
      renderRow={(pressPassApplication) => (
        <TableRow key={pressPassApplication.id} hover={true}>
          <CompactTableCell>{pressPassApplication.firstName}</CompactTableCell>
          <TableCell>
            <Link to={routes.pressPassApplication(pressPassApplication.id)} component={RouterLink}>
              {pressPassApplication.lastName}
            </Link>
          </TableCell>
          <TableCell>
            <FormattedDate
              date={pressPassApplication.createdAt}
              format={DateFormat.Default}
            />
          </TableCell>
          <TableCell align="center">
            <MembershipChip membership={pressPassApplication.membership} />
          </TableCell>
          <TableCell align="center">
            <PressPassApplicationStatusBadge
              status={pressPassApplication.status}
            />
          </TableCell>
          <CompactTableCell align={'right'}>
            <IconLink
              to={routes.pressPassApplication(pressPassApplication.id)}
              tooltip={t('Ansehen')}
              icon={<Visibility />}
            />
          </CompactTableCell>
        </TableRow>
      )}
    >
      <TableRow>
        <PaginationTableHeadCell sort="firstName">
          {t('Vorname')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell sort="lastName">
          {t('Nachname')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell sort="createdAt">
          {t('Eingangsdatum')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell sort="membership" align="center">
          {t('Mitglied')}
        </PaginationTableHeadCell>
        <PaginationTableHeadCell sort="status" align="center">
          {t('Status')}
        </PaginationTableHeadCell>
        <TableCell width={110} />
      </TableRow>
    </PaginationTable>
  );
};
