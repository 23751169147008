import { AxiosError } from 'axios';
import { createContext, useContext } from 'react';
import { UseQueryResult } from 'react-query';
import { FilterParams, PaginationResponse, SortHandler } from '../../hooks';
import { Dict, Entity } from '../../model';

export type PaginationContextValue<
  TFilters extends Dict,
  TEntity extends Entity,
  TContext extends Dict = Dict
> = {
  params?: FilterParams<TFilters, TContext>;
  changePage?: (newPage: number) => void;
  changePageSize?: (newPageSize: number) => void;
  sort?: SortHandler;
} & Partial<UseQueryResult<PaginationResponse<TEntity>, AxiosError>>;

const PaginationContext = createContext<PaginationContextValue<any, Entity>>(
  {}
);

export const usePaginationContext = () => useContext(PaginationContext);

export default PaginationContext;
