import {
  Blameable,
  Commentable,
  DateTimeString,
  ID,
  Medium,
  Nullable,
  Timestampable,
} from './index';

export interface Tariff extends Timestampable, Blameable, Commentable {
  id: ID;
  name: string;
  validFrom: DateTimeString;
  validTo: DateTimeString;
  apa: number;
  media: Medium[];
  tariffGroups: TariffGroup[];
}

export interface TariffGroup extends Timestampable, Blameable, Commentable {
  id: ID;
  groupKey: string;
  groupNumber: number;
  label: string;
  price: number;
}

export interface TariffFilters {
  search: string;
}

export interface TariffFormValues {
  name: string;
  validFrom: Nullable<string>;
  validTo: Nullable<string>;
  apa: number;
  media: ID[];
  tariffGroups: TariffGroupFormValues[];
  comment: Nullable<string>;
}

export interface TariffGroupFormValues {
  groupKey: string;
  groupNumber: number;
  label: string;
  price: number;
}

export enum TariffGroupDefaultLabels {
  TG2 = '1-10',
  TG3 = '11-25',
  TG4 = '26-100',
  TG5 = '101-500',
  TG6 = 'ab 501',
  TG7 = 'Extern',
  TG8 = '1-10 (MT)',
  TG9 = 'ST EU',
  TG10 = 'Pauschale',
}

export const getTariffFormValues = (tariff?: Tariff): TariffFormValues => ({
  name: tariff?.name || '',
  validFrom: tariff?.validFrom || '',
  validTo: tariff?.validTo || '',
  apa: tariff?.apa || 0,
  media: tariff?.media.map((medium) => medium.id) || [],
  tariffGroups:
    tariff?.tariffGroups.map((tariffGroup) => ({
      groupKey: tariffGroup.groupKey,
      groupNumber: tariffGroup.groupNumber,
      label: tariffGroup.label,
      price: tariffGroup.price,
    })) ||
    [2, 3, 4, 5, 6, 7, 8, 9, 10].map((groupNumber) => ({
      groupKey: `TG${groupNumber}`,
      groupNumber: groupNumber,
      label:
        TariffGroupDefaultLabels[
          `TG${groupNumber}` as keyof typeof TariffGroupDefaultLabels
        ],
      price: 0,
    })),
  comment: tariff?.comment || '',
});
